import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';
import { AppSettings } from '../../../appSettings';


@Component({
  selector: 'app-form-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.css'],
  providers: [QuestionService]
})
export class InscripcionComponent implements OnInit {

  questions:any;
  type_user:any;
  complete:boolean;
  s3acuerdo:any;
  file_url:string;

  @ViewChild('form_inscription') form_inscription;
  @ViewChild('panel') panel;
  @Input() beneficiario:any;
  @Input() disabled:any;
  @Output() loadEnd = new EventEmitter();
  @Output() loadFile = new EventEmitter();
  @Output() fullForm = new EventEmitter();

  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService) { 
  }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');

    this.questionsService.getQuestionsInscription().map( data => {

      if(this.beneficiario){

        this.s3acuerdo = this.beneficiario.s3acuerdo;
        for(let item in data){
          if(this.beneficiario[data[item].key] != null){
            data[item].value = this.beneficiario[data[item].key] + "";
          }


          if(data[item].key == 'sexual_condition_id' && data[item].value == 30){
            data[this.getQuestionbyKey(data, 'military_card')].disabled =  false
            data[this.getQuestionbyKey(data, 'military_card')].editable =  true
          }

          if(data[item].key == 't_vivienda' && data[item].value == 387){
            data[this.getQuestionbyKey(data, 'other_tipovivienda')].disabled =  false
          }

          if(data[item].key == 'bank_id' && data[item].value != 134){
            data[this.getQuestionbyKey(data, 'type_account_id')].disabled =  false
            data[this.getQuestionbyKey(data, 'number_account')].disabled =  false
          }

          if(data[item].key == 'disease' && data[item].value == 'true'){
            data[this.getQuestionbyKey(data, 'health_care_id')].disabled =  false
          }

          if(data[item].key == 'health_affiliation' && data[item].value == 'true'){
            data[this.getQuestionbyKey(data, 'type_affiliation_id')].disabled =  false
          }

          if(data[item].key == 'early_childhood_id' && data[item].value != 10){
            data[this.getQuestionbyKey(data, 'feeding_childhood')].disabled =  false
            data[this.getQuestionbyKey(data, 'health_childhood')].disabled =  false
            data[this.getQuestionbyKey(data, 'actividadnino')].disabled =  false
          }

          if(data[item].key == 'actividadnino' && data[item].value != 5){
            data[this.getQuestionbyKey(data, 'child_ocupation_time')].disabled =  false
          }

          if(data[item].key == 'actividadnino' && data[item].value == 4){
            data[this.getQuestionbyKey(data, 'otrotiemponino')].disabled =  false
          }

          if(data[item].key == 'actividad_adulto' && data[item].value == 6){
            data[this.getQuestionbyKey(data, 'otra_actividad')].disabled =  false
          }

          if(data[item].key == 'formastrabajo' && data[item].value == 8){
            data[this.getQuestionbyKey(data, 'otraformatrabajo')].disabled =  false
          }


          if(data[item].key == 'currently_studying' && data[item].value == false){
            data[this.getQuestionbyKey(data, 'otraformatrabajo')].disabled =  false
          }

          data[this.getQuestionbyKey(data, 'public_service_id')].hidden = true;


          if(this.type_user==3){
            data[item].disabled = true;
          }
          
          if(data[item].value){
            data[item].disabled = !data[item].editable;
          }


        }



      }
        this.beneficiario.public_services.map( service => {
          console.log(service)
           data[this.getQuestionbyKey(data, 'public_service_id'+service.id)].value = true;
        });
      

      this.questions =  data;
      this.loadEnd.emit({load:false})


      setTimeout(_ => {
        if(this.panel)    
          window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
      });



    });
  }

  onLoadFile(event){
    this.loadFile.emit({load:true})
  }

  submitFile(url_source, name_input){
    this.loadEnd.emit({load:true})
    
    this.beneficiarioService.addFile(url_source.url, name_input, this.beneficiario.user_xml_id)
    .subscribe( data => {
      this.fullForm.emit({isFill:(data.completa == 1), status:data.status})
      this.loadEnd.emit({load:false})
      this.loadFile.emit({load:false})
    });
  }

  onSubmit(form){

    console.log(form);
    let public_service = [];
    this.loadEnd.emit({load:true})

    let i = 0;
    for(let index of Object.keys(form.formData)){

      if(index.indexOf('public_service_id') >= 0){
        if(form.formData[index] == true){
          let idx = index.split('_id');
          idx = idx[idx.length - 1].split('_');
          if(idx[0].length > 0){
            form.formData['public_service['+i+']'] = idx[0]
            //form.formData['public_service[]'] = idx[0]
            i++;
          }
            
            //public_service.push(idx[0]);
        }
      }
    }

    //form.formData['public_service[]'] = public_service;


    this.beneficiarioService.updateForm(this.beneficiario.user_xml_id, form.formData, 1)
    .subscribe( data => {
      if(!data.error){
        if(data.completa == 0){
          this.form_inscription.highWarrningInputsOn();
          alert(data.msg + '\nPero hay información pendiente para completar la etapa')
        }else{
          this.form_inscription.highWarrningInputsOff();
          alert(data.msg)
        }  
        this.complete = data.completa;
        this.fullForm.emit({isFill:(data.completa == 1), status:data.status})
        
      }
      else
        alert("Hubo un error al enviar la información") 

      
      
      this.loadEnd.emit({load:false})

    })
    
  }

  fillForm(event){
    this.fullForm.emit({isFill:event.isFill})
  }

  openDocument(file){
    this.file_url = AppSettings.URL_BACKEND + file;
  }

  closeDocumentPop(event){
    this.file_url = null;
  }


  getQuestionbyKey(quest, key:string){
    for(let item in quest){
      if(quest[item].key == key)
        return item;
    }

    return null;
  }

}
