import { Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import { OperadorDataService, UtilService, PagerServicesService, AnalitycsService} from '../../../services';
import { FormControl, FormGroup, FormArray, Validators} from '@angular/forms';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { GraphComponent } from '../../../components/graph/graph.component';
import { AppSettings } from '../../../appSettings'
import { Utilities } from '../../../shared/utilities';
import { QuestionService } from '../../../components/forms/question.service';


@Component({
	selector: 'app-tiempo-ruta',
	templateUrl: './tiempo-ruta.component.html',
	styleUrls: ['./tiempo-ruta.component.css']
})
export class IntermediacionReportComponent implements OnInit {

	@ViewChild('graph_preinscrito') graph_preinscrito;
	@ViewChild('name_pdf') name_pdf;
	@ViewChild('comentario_pdf') comentario_pdf;
	@ViewChildren('graphs') components:QueryList<GraphComponent>;


	loadPage:boolean = true;
	color_general:any[];
	export_data:any = {};
	title_graph = 'INTERMEDIACIÓN';
	dataSets:any = [];
	dataSetsTables:any = [];
	dataSetsTablesMirrors:any = [];
	dataSetsDemografico:any = [];
	form: FormGroup;
	dropsOptions: any;
	operadores: any;
	ciudadesOperador: any;
	model:any = {};
	url_form:string =  AppSettings.URL_BACKEND + 'pdf/intermediacion.pdf';
	url_excel: string = AppSettings.URL_BACKEND + 'intermediacion.xlsx';
	url_pdf:string = AppSettings.URL_BACKEND;
	graphs = [];
	validInput:boolean;
	token:string;
	type_user:any;

	cut_dataset:any = [];


	historial: any;
	historial_paged:any;
	pager: any = {};

	form_filter:any;
	name_series:any;
	sub_caption = ['Criterio', 'Participantes', 'Porcentaje']
	etapas:any;

	with_mirror = true;

	graphics = ['ANÁLISIS DE LAS PRUEBAS PRE VS POST','EMPRESAS CREADAS POR OPERADOR','RELACIÓN EMPRESAS Y VACANTES','RELACIÓN VACANTES POR PARTICIPANTES','PROCESO DE INTERMEDIACIÓN'];


	amplacion_data = {
		left: {bis1a:true, bis1b:true},
		right: {bis1a:true, bis1b:true}
	}

	constructor(private utilService:UtilService,
		private operadorService: OperadorDataService,
		private dropService:DropsWrapperService,
		private pagerService: PagerServicesService,
		private qService:QuestionService,
		private analyticsService: AnalitycsService) { }

	get InputOperadores(): FormArray{
		return this.form.get('operadores') as FormArray;
	}

	get InputCiudades(): FormArray{
		return this.form.get('ciudades') as FormArray;
	}

	ngOnInit() {
		this.token = localStorage.getItem('session_id');
		this.type_user = localStorage.getItem('user_type');


		this.dropService.minValidateDrop().subscribe( d => {
			this.dropService.addOptions(d)
			this.qService.getQuestionsFilter(4)
				.map( form => {
					this.form_filter = (this.type_user == 2) ? 
						form.filter( f => f.key != 'operador' && f.key != 'ciudad' ) : form
			})
		});
		
		this.model.operador_gnrl = "";
		this.model.ciudad_gnrl = "";
		this.model.operador = "";
		this.model.ciudad = "";
		this.model.etapa = "";

		this.color_general = [
		['#39ca74','#2ea8ed','#f1c154','#e96637','#9a5cb4','#0e69ad','#599bf0','#32a69c','#ef893a','#122a6e','#39ca74','#f97e4a','#2ea8ed','#5d1b7b'], //'graph_operador':
		['#d53e33','#0e69ad','#179a8f'], //'graph#_ciudades':
		['#d53e33','#2ea8ed','#9a5cb4','#f1c154'],//'graph_generos':
		['#39ca74','#f55d52','#0e69ad','#2ea8ed'],//'graph_edades':
		['#0e69ad','#599bf0','#f1c154','#e96637','#5d1b7b','#9a5cb4','#39ca74'], //'graph_poblacion':
		['#0e69ad','#179a8f','#5d1b7b','#d53e33'],//'graph_priorizacion':
		['#2ea8ed','#9a5cb4','#599bf0'],//'graph#_sisben':
		['#179a8f','#5d1b7b'],//'graph_jefehoga#r':
		['#9a5cb4','#d53e33','#0b6b19','#e96637','#599bf0','#0e69ad','#f55d52','#32a69c','#ef893a','#122a6e','#39ca74','#f97e4a','#2ea8ed','#5d1b7b'], //'graph_nivel_educativo':
		['#ef893a','#d53e33', '#39ca74', '#5d1b7b','#9a5cb4','#2ea8ed','#122a6f', '#f55d52'] //'graph_estado_civil':
		]

		this.etapas = [
	        {id:'37', name_stage:'Elegible'},
	        {id:11, name_stage:'Inscripción'},
	        {id:38, name_stage:'En formación'},
	        {id:40, name_stage:'En Intermediación'},
	        {id:19, name_stage:'Intermediación'},
	        {id:20, name_stage:'Colocación'},
	        {id:22, name_stage:'Permanencia 3 meses'},
	        {id:23, name_stage:'Permanencia 6 meses'},
    	]

		this.operadorService.getAllOperadores()
		.subscribe( data => {
			this.operadores = data.operator;
			this.dropService.addOptions$.subscribe( options => {
				this.dropsOptions = options;
				this.form = this.buildForm();	

				this.utilService.getIntermediacionReport()
				.subscribe( data => {
					this.drawData(data);
					this.utilService.getDemograficReport()
					.subscribe( d => {
						this.drawDemografico(d);
						this.loadPage = false;
					})
				})
			})
		}) 
		
	}


	drawData(data){

		this.dataSets = [];

		this.name_series = {
			'pruebas' : ['N/A  (en pre y en post)','No válido (en pre y en post)','Empeoró desempeño (en post menor que en pre)','Continuó igual (mismo resultado en pre y en post)','Mejoró desempeño (en post mejor que en pre)'],
			'rango' : ['Menos de 25','Entre 26 y 50','Entre 51 y 100','Entre 101 y 150','Mas de 150'],
			'rango2' : ['Menos de 2','Entre 3 y 5','Entre 6 y 10','Mas de 10'],
			'estados' : ['Pre alistamiento','Proceso de selección','Proceso de contratación','No elegido: la empresa no eligió al participante','No asistió: el participante no quiso asistir a la entrevista','Reasignar a proceso de contratación','Contratado: ya firmó contrato con la empresa','Desistió: el participante al momento de firmar el contrato, no lo firmó.'],
		};

		this.dataSets.push({
			title: 'ANÁLISIS DE LAS PRUEBAS PRE VS POST',
			graph: 'pre_and_post',
			header: 'pruebas',
			size: 6,
			id: '1',
			type: 'PieChart',
			data:this.getSeriesGraph(this.name_series.pruebas , data.pre_and_post),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: true,
		})


		let operadors = data.operadors.map( op => op.empresas);
		this.name_series['name_operadors'] = data.operadors.map( op => op.name_operator);

		this.dataSets.push({
			title: 'EMPRESAS CREADAS POR OPERADOR',
			graph: 'operadors',
			header: 'name_operadors',
			size: 6,
			id: '2',
			type: 'ColumnChart',
			data: this.getSeriesGraph(this.name_series.name_operadors , operadors),
			sub_caption: ['operador', '# Empresas', 'porcentaje'],
			download_row: true,
			with_filters: false,
			id_excel: data.operadors.map( op => op.id_operador),
			series: ['Operador', 'Empresas']
		})



		this.dataSets.push({
			title: 'RELACIÓN EMPRESAS Y VACANTES',
			graph: 'empresas',
			header: 'rango2',
			size: 6,
			id: '3',
			type: 'PieChart',
			data: this.getSeriesGraph(this.name_series.rango2 , data.empresas, 'vacantes'),
			sub_caption: ['criterio', '# Empresas', 'porcentaje'],
			download_row: true,
			with_filters: true,
		})

		this.dataSets.push({
			title: 'RELACIÓN VACANTES POR PARTICIPANTES',
			graph: 'vacantes',
			header: 'rango2',
			size: 6,
			id: '4',
			type: 'PieChart',
			data: this.getSeriesGraph(this.name_series.rango2 , data.vacantes, 'vacantes'),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: true,
		})

		this.dataSets.push({
			title: 'PROCESO DE INTERMEDIACIÓN',
			graph: 'estados_vacantes',
			header: 'estados',
			size: 12,
			id: '5',
			type: 'PieChart',
			data: this.getSeriesGraph(this.name_series.estados , data.estados_vacantes),
			sub_caption: ['estado vacante', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: true,
		})

		this.dataSets.map( (data_set, i) => {

			let index_excel = (data_set.id_excel) ? data_set.id_excel : null


			this.cut_dataset.push([
				{
					id: 1,
					data_set: data_set.data,
					title: data_set.title,
					color:  this.color_general[0],
					type: data_set.type,
					type_data: (i+1),
					source: data_set.graph,
					header: data_set.header,
					graph_id: data_set.id,
					download_row: data_set.download_row,
					with_filters: data_set.with_filters,
					sub_caption: data_set.sub_caption,
					id_excel: index_excel,
					series: data_set.series
				},
				{
					id: 2,
					data_set: data_set.data,
					title: data_set.title,
					color:  this.color_general[0],
					type: data_set.type,
					type_data: (i+1),
					source: data_set.graph,
					header: data_set.header,
					graph_id: data_set.id,
					download_row: data_set.download_row,
					with_filters: data_set.with_filters,
					sub_caption: data_set.sub_caption,
					id_excel: index_excel,
					series: data_set.series
				},
			]);
		})

		//this.cut_dataset = Utilities.chunk(JSON.parse(JSON.stringify(this.dataSets)), 2);


		this.dataSetsTables.push({
			title: 'TOP 5 EMPRESAS CON MÁS VACANTES',
			graph: 'top_empresas',
			headers: [{title: 'Operador', field: 'name_operator'},
				{title: 'Empresa', field: 'name_company'},
				{title: '# Vacantes', field: 'total_vacante_real'}], 
			
			data: this.getSeriesTable([
				{title: 'Operador', field: 'name_operator'},
				{title: 'Empresa', field: 'name_company'},
				{title: '# Vacantes', field: 'total_vacante_real'}] , data.top_empresas)
		})

		this.dataSetsTables.push({
			title: 'TOP 5 EMPRESAS CON MÁS VACANTES (CONTRATADOS)',
			graph: 'top_empresas_real',
			headers: [{title: 'Operador', field: 'name_operator'},
				{title: 'Empresa', field: 'name_company'},
				{title: '# Vacantes', field: 'total_contratados'}], 
			data: this.getSeriesTable([
				{title: 'Operador', field: 'name_operator'},
				{title: 'Empresa', field: 'name_company'},
				{title: '# Vacantes', field: 'total_contratados'}] , data.top_empresas_real)
		})

		this.dataSetsTables.push({
			title: 'TOP 5 OCUPACIONES CON MÁS VACANTES',
			graph: 'top_areas',
			headers: [{title: 'Sector', field: 'name_area'},
				{title: '# Vacantes', field: 'total'}],
			data:this.getSeriesTable([
				{title: 'Sector', field: 'name_area'},
				{title: '# Vacantes', field: 'total'}] , data.top_areas)
		})

		this.dataSetsTables.push({
			title: 'TOP 5 OCUPACIONES CON MÁS VACANTES (CONTRATADOS)',
			graph: 'top_areas_real',
			headers: [{title: 'Sector', field: 'name_area'},
				{title: '# Vacantes', field: 'contratados'}],
			data:this.getSeriesTable([
				{title: 'Sector', field: 'name_area'},
				{title: '# Vacantes', field: 'contratados'}] , data.top_areas_real)
		})

		this.dataSetsTables.map((data_set, i) => {
			if( (i % 2) == 0){
				if(this.dataSetsTables[(i + 1)]){
					this.dataSetsTablesMirrors.push([
					{
						id: 1,
						data_set: this.dataSetsTables[i].data.data,
						header_set: this.dataSetsTables[i].headers,
						title: this.dataSetsTables[i].title,
						color:  this.color_general[0],
						type: 'TableChart',
						type_data: (i+1),
						source: this.dataSetsTables[i].graph,
						sub_caption: this.dataSetsTables[i].data.header,
						download_row: false,
						with_filters: false
					},
					{
						id: 1,
						data_set: this.dataSetsTables[(i + 1)].data.data,
						header_set: this.dataSetsTables[(i + 1)].headers,
						title: this.dataSetsTables[(i + 1)].title,
						color:  this.color_general[0],
						type: 'TableChart',
						type_data: (i+1),
						source: this.dataSetsTables[(i + 1)].graph,
						sub_caption: this.dataSetsTables[(i + 1)].data.header,
						download_row: false,
						with_filters: false
					},
				])
				}
				
			}

			
		})

	}

	changeExternalFilter(side, field){
		this.loadPage = true;
		let ampliacion = Object.assign({}, this.amplacion_data);
		ampliacion[side][field] = !ampliacion[side][field];
		this.amplacion_data = ampliacion;
	}

	drawDemografico(data){
		const name_series = {
			'poblacion' : ['Sisben','Unidos','Víctimas','Unidos + sisben','Unidos + víctimas','Víctimas + sisben','Unidos + víctimas + sisben'],
			'edad' : ['18 a 24 años','25 a 30 años','31 a 36 años','Mayores de 37 años'],
			'genero' : ['Femenino','Masculino','No reporta','LGBTI']
		};

		this.dataSetsDemografico['poblacion'] = this.getSeriesGraph(name_series.poblacion , data.poblacion);
		this.dataSetsDemografico['edad'] = this.getSeriesGraph(name_series.edad , data.edad);
		this.dataSetsDemografico['genero'] = this.getSeriesGraph(name_series.genero , data.genero);
	}

	getSeriesTable(fileds, source){

		let headers = fileds.map( f => f.title);

		let data = [];

		source.map( (item, i) => {
			let obj = {
				id: (i + 1)
			};

			fileds.map( f => {

				obj[f.field] = item[f.field]
			});	

			data.push(obj)
		})

		return {header: ['#'].concat(headers), data: data};
	}

	getSeriesGraph(name_serie, data, complemento = '', index_obj = null){

		if(index_obj != null){
			data = data.map( d => d[index_obj]);
		}

		return Object.keys(data).map( (serie, index) => {
			return [name_serie[index] + ' ' + complemento, data[serie]]
		});
		
		
	}

	getExportGraph(event, graph){
		this.export_data[graph] = {
			grafica: graph,
			img: event.graph,
			table: event.table
		}
	}


	getKeys(object){
		return Object.keys(object);
	}


	exportData(){

		this.components.map( g => {
			g.exportData();	
		});

		if(this.name_pdf.nativeElement.value.length <= 0){
			this.validInput = false;
		}else{
			this.validInput = true;
			this.loadPage = true;
			setTimeout( _ => {
				let form = <HTMLFormElement> document.getElementById('form-reporte');
				this.name_pdf.nativeElement.value = this.name_pdf.nativeElement.value.replace(/ /g,"_");
				form.submit();
				this.loadPage = false;
				this.name_pdf.nativeElement.value = ''
				this.comentario_pdf.nativeElement.value = ''
			}, 3000)
		}
	}

	buildForm(){
		let arr = {};
		arr['ciudades'] = new FormArray(this.createArrayInput(this.dropsOptions.city));
		arr['operadores'] = new FormArray(this.createArrayInput(this.operadores));

		return new FormGroup(arr);
	}

	fillDataMirror(formData, index, source, header){
		console.log(formData)
		this.loadPage = true;
		this.utilService.getIntermediacionReport(formData.operador, formData.ciudad, formData)
			.subscribe( d => {
				let data_set = this.getSeriesGraph(this.name_series[header], d[source]);
				let color_set = this.color_general[0];

				if(source == 'operadors'){
					let headers = d[source].map( op => op.name_operator);
					let id_operador = d[source].map( op => op.id_operador);
					let index_color = this.name_series[header].indexOf(headers[0]);
					
					data_set = this.getSeriesGraph(d[source].map( op => op.name_operator), d[source], '', 'empresas')

					if(data_set.length == 1)
						color_set = [this.color_general[0][index_color]]

					if(formData.graphic)
						this.cut_dataset[index][formData.graphic - 1].id_excel = id_operador;
					else
						this.cut_dataset[index][0].id_excel = id_operador;
				}

				console.log(this.cut_dataset[index])

				if(formData.graphic){
					this.cut_dataset[index][formData.graphic - 1].data_set = data_set;
					this.cut_dataset[index][formData.graphic - 1].color = color_set;					
				}else{
					this.cut_dataset[index][0].data_set = data_set;
					this.cut_dataset[index][0].color = color_set;	
				}


				console.log(this.cut_dataset.length, index)

				if(formData.mirror_filter){
					if(index >= this.cut_dataset.length){
						this.loadPage = false;	
					}	
				}else{
					this.loadPage = false;	
				}
				
			});
	}

	fillDataMirrorTable(formData, index, source, header){
		this.loadPage = true;
		console.log(formData)
		this.utilService.getIntermediacionReport(formData.operador, formData.ciudad, formData)
			.subscribe( d => {
				let graph = (formData.graphic) ? formData.graphic - 1 : 0; 
				let data_set = this.getSeriesTable(header[graph] , d[source[graph]])
				this.dataSetsTablesMirrors[index][graph].data_set = data_set.data;
				this.loadPage = false;
			});	
	}

	createArrayInput(obj: Array<any>){
		let array = [];

		if(obj)
			obj.map( (o, index) => {
				array.push(new FormControl())
			}) 

		return array;
	}


	fillData(){
		this.loadPage = true;
		this.utilService.getDemograficReport(this.model.operador, this.model.ciudad,  this.model.etapa, this.model.bis1a, this.model.bis1b)
			.subscribe( data => {
				this.drawDemografico(data);
				this.loadPage = false;
			})
	}

	cleanFill(input){
		switch (input) {
			case 1:
				this.model.ciudad = null
				break;

			case 2:
				this.model.operador = null
				break;
			
			default:
				// code...
				break;
		}
	}

	setPage(page: number){
	    if(page < 1 || page > this.pager.totalPages){
	      return
	    }
	    this.pager = this.pagerService.getPager(this.historial.length, page, 10);
	    this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	getExportExcel(evt, graph, id_ext){

		console.log(id_ext, evt)

		if(id_ext[0]){
			if(typeof id_ext[evt.graphic - 1] == "object")
				this.model.intervalo = id_ext[evt.graphic - 1][evt.index];
			else
				this.model.intervalo = id_ext[0];


			console.log(this.model.intervalo)
		}else
			this.model.intervalo = evt.interval;

		this.model.grafica = graph;
		this.model.operador = evt.operador
		this.model.ciudad = evt.ciudad

		this.model.bis1a = (evt.bis1a) ? evt.bis1a : null;
		this.model.bis1b =(evt.bis1b) ?  evt.bis1b : null;

		this.analyticsService.eventAction('DhIntermediacion', 'Descargar:' + this.graphics[graph - 1] );
		let form = <HTMLFormElement> document.getElementById('form-excel');

		setTimeout(_ => {form.submit();}, 100)
	}
}
