import { Component, OnInit } from '@angular/core';
import { AlertasService, AnalitycsService } from '../../../services';

@Component({
  selector: 'app-intermediacion-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
  providers: [AlertasService]
})
export class IntermediacionAlertaComponent implements OnInit {


  alert_data: any = [];
  date_interval: any = [];
  alertas:any = [];
  link_form:any =  [];

  constructor(private alertService:AlertasService,
      private analytcisService: AnalitycsService) { }

  ngOnInit() {
    this.link_form = 'upload_alert_interm.xlsx';
  	this.date_interval = [
    { label: '7 días', level: 1, value: 7},
    { label: '15 días', level: 1, value: 15},
    { label: '20 días', level: 2, value: 20},
    { label: '30 días', level: 2, value: 30},
    { label: 'Más de 30 días', level: 2, value: 31},
    { label: 'Total', level: 0, value: null},
    ]

    this.alertService.getAlertIntermediacion()
    .subscribe( data => {
      console.log(data)
      let alert_data_1 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['intermediacion' + (index + 1)]).filter( o => o != undefined)
        }  
      })


      this.alertas = [{
        nombre: 'Participantes graduados (pruebas post) que no han sido asignados a vacantes en Intermediación',
        table: alert_data_1
      }]

    });

  }

   onExportAlert(name){
    this.analytcisService.eventAction("Alertas", "Descargar:Intermedacion");
  }

}
