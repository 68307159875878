import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { Beneficio } from '../../../struct';
import { Router, ActivatedRoute } from '@angular/router';
import { BeneficiarioDataService } from '../../../services';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service';
import { AppSettings } from '../../../appSettings';


@Component({
	selector: 'app-estrategia-retencion',
	templateUrl: './estrategia-retencion.component.html',
	styleUrls: ['./estrategia-retencion.component.css']
})

export class EstrategiaRetencionComponent implements OnInit {

	validator = {
		number: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		currency: /^[-+]?\d*(?:[\.\,]\d+)?$/,
	};

	title_view: string;
	loadPage: boolean = false;
	id_beneficiary: number;
	disabled: boolean = false;
	send_document: boolean = false;
	is_sendata: boolean = false;
	type_user:any;
	beneficios: any = [];
	model:any = {};
	listaBeneficios: any;
	contador: number = 0;
	warrning_input:boolean = false;
	complete:boolean;
	isFillFiles:boolean = false;
	loadModule:boolean;
	file_url:string;
	
	@Output() fullForm = new EventEmitter();
	@Output() cargaCompleta = new EventEmitter();
	@Input() disabled_form: any;
	@ViewChild('panel') panel;

	constructor( private dropService: DropsWrapperService,
		private beneficiarioService: BeneficiarioDataService,
		private route: ActivatedRoute ) { }

	ngOnInit() {
		this.title_view = 'Beneficios del Participante';
		this.id_beneficiary = this.route.params['value'].id;


		this.beneficiarioService.getEstrategiaRetencion(this.id_beneficiary)
		.subscribe( data => {
			// if (data.error) {
				// 	this.loadModule = true;
				// 	this.listaBeneficios = this.dropService.dropOptons['estrategia_retencion']
				// 	return;
				// }

				console.log('estrategias')
				if(!data.error){
					this.beneficios = data.ben_strategy.map( beneficio => new Beneficio(beneficio));
					this.updateAverage();	
				}


				this.listaBeneficios = this.dropService.optionsDrops[0]['estrategia_retencion']


				if (this.beneficios.length == 0) {
					this.disabled = true;
				}

				this.loadModule = true;

				setTimeout(_ => {
					if(this.panel)    
						window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
				});
			});

		this.type_user = localStorage.getItem('user_type');
	}

	updateAverage(){
		this.model.promedio_beneficios = (this.beneficios.map( item => item.benefit_delivered)
			.slice(1).reduce( (old, current) => old + current, this.beneficios[0].benefit_delivered) / this.beneficios.length)
	}

	onAddBeneficio(event){
		event.preventDefault();
		this.beneficios.push(new Beneficio());
		this.disabled = false;
	}

	onQuitAddBeneficio(event, index){
		event.preventDefault();
		if (index > -1) {
			this.beneficios.splice(index, 1);
			if (this.beneficios.length <= 0) {
				this.disabled = true;
			}
		}
	}

	submitFile(url_source, index){
		this.beneficios[index].document = url_source.url;
		for (let ben of this.beneficios) {
			if (ben.document) {
				this.contador++
			}
		}
		this.contador++
		if (this.alreadyHasDocument()) {
			
			this.completeUpload();
		}
	}

	loadingFile(event){
	}

	fillingFile($event, index){
		this.isFillFiles = true;
		this.beneficios[index]['fillFile'] = $event;
	}

	onSubmitBeneficios(){
		if (this.getFillFiles()) {
			this.is_sendata = false;
			this.cargaCompleta.emit({ loading: true });
			(this.alreadyHasDocument() && !this.isFillFiles) ? this.completeUpload() : this.send_document = true;
		} else {
			alert("Por favor cargue el soporte para poder guardar los datos")
		}
	}

	getFillFiles(){
		for(let beneficio of this.beneficios){
			if(!beneficio.fillFile && !beneficio.document)
				return false;
		}

		return true;
	}

	alreadyHasDocument(){
		var hasDoc: boolean = false;
		console.log(this.beneficios)
		for(let beneficio of this.beneficios){
			beneficio.document ? hasDoc = true: hasDoc = false	
		}

		console.log(hasDoc)

		return hasDoc;
	}

	completeUpload(){
		if(!this.is_sendata){
			this.beneficiarioService.saveEstrategiaRetencion(this.id_beneficiary, this.beneficios).subscribe( 
				data => {
					if(data.error){
						console.log(data.msg)
						this.cargaCompleta.emit({loading: false});
						return;

					} else {
						this.contador = 0;	
						this.loadPage = false;
						this.cargaCompleta.emit({loading: false});
						
						if(data.completa == 0){
							this.warrning_input = true;
							alert(data.msg + '\nPero hay información pendiente para completar la etapa')
						}else{
							this.warrning_input = false;
							alert(data.msg)
						}
						
						this.complete = data.completa;
						this.fullForm.emit({isFill:(data.completa == 1)})
						this.send_document = false;
						this.is_sendata = true;
						
					}
				})
		}

	}


	openDocument(file){
		this.file_url = AppSettings.URL_BACKEND + file;
	}

	closeDocumentPop(event){
		this.file_url = null;
	}

}
