import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { Vacante } from './../../struct';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';

@Injectable()
export class VacanteService {

	constructor(private http: Http) { }

	addVacante(vacante: Vacante){
		var data = new URLSearchParams();
		var session_id = localStorage.getItem('session_id');

		data.append('token', session_id);
		data.append('idvacancy', String(vacante.idvacancy));
		data.append('description', String(vacante.description));
		data.append('quantity', String(vacante.quantity));
		data.append('position_vacancy', String(vacante.position_vacancy));
		data.append('occupational_areas_id', String(vacante.occupational_areas_id));
		data.append('subgenre_id', String(vacante.subgenre_id));
		data.append('domination_id', String(vacante.domination_id));
		data.append('salary_vacancy_id', String(vacante.salary_vacancy_id));
		data.append('company_id', String(vacante.company_id));

		return this.http.post(AppSettings.API_ENDPOINT + 'insert_vacancy', data)
		.map((response: Response) => response.json());
	}

	// La vacante no se puede eliminar
	//
	// deleteVacanteById(id:number) {
	// 	var session_id = localStorage.getItem('session_id');
	// 	var data = new URLSearchParams();
	// 	data.append('token', session_id)
	// 	data.append('idvacancy', id.toString())

	//     return this.http.post(AppSettings.API_ENDPOINT + 'delete_vacancy', data)
	//     .map((response: Response) => response.json());
 //  	}

  	nullifyVacanteById(id:number) {
  		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id)
		console.log(id)
		data.append('id_vacancy', id.toString())

	    return this.http.post(AppSettings.API_ENDPOINT + 'change_state_Nullified', data)
	    .map((response: Response) => response.json());
  	}

	getAllVacantes() {
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);

		return this.http.post(AppSettings.API_ENDPOINT + 'view_all_vacancy', data)
		.map((response: Response) => response.json());
	}

	getVacantesByCompanyId(id: number) {
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id_company', String(id));

		return this.http.post(AppSettings.API_ENDPOINT + 'vacancy_by_company', data)
		.map((response: Response) => response.json());
	}

	getVacantesById(id:number) {
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id_vacancy', String(id));

		return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_vacancy', data)
		.map((response: Response) => response.json());
	}

	assginVacante(id_beneficiary, id_vacancy){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id_beneficiary', String(id_beneficiary));
		data.append('id_vacancy', String(id_vacancy));

		return this.http.post(AppSettings.API_ENDPOINT + 'assing_beneficiary_to_vacancy', data)
		.map((response: Response) => response.json());
	}

	assignVacanteByCompanyId(id_company){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id_company', String(id_company));

		return this.http.post(AppSettings.API_ENDPOINT + 'vacancy_by_company_abierta', data)
		.map((response: Response) => response.json());
	}

	getVacantesByBeneficiario(id_beneficiary){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('user_id', String(id_beneficiary));

		return this.http.post(AppSettings.API_ENDPOINT + 'get_vacantes_user', data)
		.map((response: Response) => response.json());
	}

	deleteVacantesByBeneficiario(id_vacante){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id', String(id_vacante));

		return this.http.post(AppSettings.API_ENDPOINT + 'delete_vacantes_user', data)
		.map((response: Response) => response.json());
	}

	changeVacanteStatus(id_vacante:number, id_status:number){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id);
		data.append('id_relation_vacancy', String(id_vacante));
		data.append('status_select_id', String(id_status));

		return this.http.post(AppSettings.API_ENDPOINT + 'change_status_beneficiary_vacancy', data)
		.map((response: Response) => response.json());
	}

	

	
}
