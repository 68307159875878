import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, JsonpModule} from '@angular/http';
import { CurrencyPipe } from '@angular/common';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {MatDialogModule} from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CrearOperadorComponent } from './pages/operador/crear-operador/crear-operador.component';
import { EditarOperadorComponent } from './pages/operador/crear-operador/editar-operador.component';
import { Routing } from './app.routes';
import { HeaderComponent } from './components/header/header.component';
import { AccordionGroupComponent } from './components/accordion-group/accordion-group.component';
import { LoginComponent } from './pages/login/login.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';

import { AuthGuard } from './guard';
import { AuthenticationService, 
         OperadorDataService, 
         BeneficiarioDataService,
         PagerServicesService,
         UtilService,
         UsuariosService,
         PerfilacionService,
         EmpresaService,
         VerificadorService,
         VacanteService,
         AnalitycsService } from './services';

import  { ChangesAlertService } from './shared/changes-alert.service'

import { QuestionService } from './components/forms/question.service';

//import { ChartModule } from 'angular2-highcharts';
import { ChartsModule } from 'ng2-charts/ng2-charts';

import { FileUploaderS3Component } from './components/file-uploader-s3/file-uploader-s3.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DragNdropDirective } from './components/file-uploader-s3/drag-ndrop.directive';
import { VerOperadoresComponent } from './pages/operador/ver-operadores/ver-operadores.component';
import { VerBeneficiariosComponent } from './pages/beneficiario/ver-beneficiarios/ver-beneficiarios.component';
import { SubirBeneficiariosComponent } from './pages/beneficiario/subir-beneficiarios/subir-beneficiarios.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FilterBeneficiariosPipe } from './pipes/filter-beneficiarios.pipe';
import { FilterPageComponent } from './components/filter-page/filter-page.component';
import { FilterWrappedService } from './shared/filter-wrapped.service' ;
import { DropsWrapperService } from './shared/drops-wrapper.service' ;
import { AssignGroupWrappedService } from './shared/assign-group-wrapped.service' ;
import { DetalleBeneficiarioComponent } from './pages/beneficiario/detalle-beneficiario/detalle-beneficiario.component';
import { OrderBeneficiariosPipe } from './pipes/order-beneficiarios.pipe';
import { ExcelBeneficiarioComponent } from './pages/beneficiario/subir-beneficiarios/excel-beneficiario.component';
import { CurrencyFormatterDirective } from './shared/currency-formatter.directive';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DetalleOperadorComponent } from './pages/operador/detalle-operador/detalle-operador.component';
import { MenuComponent } from './pages/menu/menu.component';
import { VerUsuariosComponent } from './pages/usuarios/ver-usuarios/ver-usuarios.component';
import { EditarUsuarioComponent } from './pages/usuarios/editar-usuario/editar-usuario.component';
import { DescargarBeneficiariosComponent } from './pages/beneficiario/descargar-beneficiarios/descargar-beneficiarios.component';
import { DescargarPilaComponent } from './pages/beneficiario/descargar-pila/descargar-pila.component';
import { DraggableDirective } from './shared/DragNDropDirective/draggable.directive';
import { DropTargetDirective } from './shared/DragNDropDirective/drop-target.directive';
import { DragService } from './shared/DragNDropDirective/drag-service.service';
import { KeyspipePipe } from './pipes/keyspipe.pipe';
import { SubirNoIncluidosComponent } from './pages/beneficiario/subir-beneficiarios/subir-no-incluidos.component';
import { PreinscripcionComponent } from './components/forms/preinscripcion/preinscripcion.component';
import { GraficaComponent } from './pages/reportes/grafica/grafica.component';
import { FilterReportPipe } from './pipes/filter-report.pipe';
import { AssignGroupComponent } from './components/assign-group/assign-group.component';
import { VerGruposComponent } from './pages/perfilamiento/ver-grupos/ver-grupos.component';
import { CrearGrupoComponent } from './pages/perfilamiento/crear-grupo/crear-grupo.component';
import { DetalleGrupoComponent } from './pages/perfilamiento/detalle-grupo/detalle-grupo.component';
import { SubirDeloitteComponent } from './pages/beneficiario/subir-beneficiarios/subir-deloitte.component';
import { InscripcionComponent } from './components/forms/inscripcion/inscripcion.component';
import { DynamicFormComponent } from './components/forms/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/forms/dynamic-form-question/dynamic-form-question.component';
import { OrientacionOcupacionalComponent } from './components/forms/orientacion-ocupacional/orientacion-ocupacional.component';
import { ApoyoPsicosocialComponent } from './components/forms/apoyo-psicosocial/apoyo-psicosocial.component';
import { PreformacionComponent } from './components/forms/preformacion/preformacion.component';
import { FilterTextComponent } from './components/filter-text/filter-text.component';
import { BeneficiaryBoxComponent } from './components/beneficiary-box/beneficiary-box.component';
import { BeneficiaryBoxStateTrafficComponent } from './components/beneficiary-box-state-traffic/beneficiary-box-state-traffic.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CompleteSearchComponent } from './pages/complete-search/complete-search.component';
import { AvatarPhotoUploadComponent } from './components/avatar-photo-upload/avatar-photo-upload.component';
import { CriteriosComponent } from './components/criterios/criterios.component';
import { RadioListComponent } from './components/radio-list/radio-list.component';
import { CrearempresaComponent } from './pages/empresas/crearempresa/crearempresa.component';
import { DetalleEmpresaComponent } from './pages/empresas/detalle-empresa/detalle-empresa.component';
import { CrearVacanteComponent } from './pages/empresas/crear-vacante/crear-vacante.component';
import { DetalleVacanteComponent } from './pages/empresas/detalle-vacante/detalle-vacante.component';
import { VerEmpresaComponent } from './pages/empresas/ver-empresa/ver-empresa.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { EditarEmpresaComponent } from './pages/empresas/editar-empresa/editar-empresa.component';
import { FormacionComponent } from './components/forms/formacion/formacion.component';
import { ButtonVerifyComponent } from './components/button-verify/button-verify.component';
import { IntermediacionComponent } from './components/forms/intermediacion/intermediacion.component';
import { AssignVacancyComponent } from './components/assign-vacancy/assign-vacancy.component';
import { EstrategiaRetencionComponent } from './components/forms/estrategia-retencion/estrategia-retencion.component';
import { FixedNumberPipe } from './pipes/fixed-number.pipe';
import { ColocacionComponent} from './components/forms/colocacion/colocacion.component';
import { PopContentComponent } from './components/pop-content/pop-content.component';
import { CriteriosColocacionComponent } from './components/criterios-colocacion/criterios-colocacion.component';
import { FiltrosExcelComponent } from './pages/reportes/filtros/filtros-excel/filtros-excel.component';
import { FilterExcelPipe } from './pipes/filter-excel.pipe';
import { GraphComponent } from './components/graph/graph.component';
import { SubirPilaComponent } from './pages/beneficiario/subir-beneficiarios/subir-pila.component';
import { SubirPermanenciaTresComponent } from './pages/beneficiario/subir-beneficiarios/subir-permanencia-tres.component';
import { SubirPermanenciaSeisComponent } from './pages/beneficiario/subir-beneficiarios/subir-permanencia-seis.component';
import { VerHistorialPilaComponent } from './pages/beneficiario/ver-historial-pila/ver-historial-pila.component';
import { VerifyKokanComponent } from './components/verify-kokan/verify-kokan.component';
import { VerifyHistoryComponent } from './components/verify-history/verify-history.component';
import { NotificacionComponent } from './components/notificacion/notificacion.component';
import { AlertaColocacionComponent } from './components/alerta-colocacion/alerta-colocacion.component';
import { PermanenciaComponent } from './components/forms/permanencia/permanencia.component';
import { SubirFunelComponent } from './pages/beneficiario/subir-beneficiarios/subir-funel.component';
import { ExplodePipe } from './pipes/explode.pipe';
import { CheckboxgroupComponent } from './components/checkboxgroup/checkboxgroup.component';
import { SelectBoxComponent } from './components/select-box/select-box.component';
import { OptionSelectBoxComponent } from './components/option-select-box/option-select-box.component';
import { MultipleSelectBoxComponent } from './components/multiple-select-box/multiple-select-box.component';
import { FormFunnelComponent } from './components/form-funnel/form-funnel.component';
import { HojaVidaComponent } from './pages/reportes/hoja-vida/hoja-vida.component';
import { PdfHojaVidaComponent } from './pages/reportes/hoja-vida/pdf-hoja-vida/pdf-hoja-vida.component';
import { HistoryTableComponent } from './components/history-table/history-table.component';
import { AlertaComponent } from './pages/alertas/alerta/alerta.component';
import { IntermediacionAlertaComponent } from './pages/alertas/alerta/intermediacion_alerta.component';
import { ColocacionNoEmpleadosAlertaComponent } from './pages/alertas/alerta/colocacion_noempleados_alerta.component';
import { ColocacionAlertaComponent } from './pages/alertas/alerta/colocacion_alerta.component';
import { TablaAlertasComponent } from './components/tabla-alertas/tabla-alertas.component';
import { NovedadesComponent } from './pages/reportes/novedades/novedades.component';
import { DescargarPermanenciaComponent } from './pages/beneficiario/descargar-permanencia/descargar-permanencia.component';
import { TiempoRutaComponent } from './pages/reportes/tiempo-ruta/tiempo-ruta.component';
import { IntermediacionReportComponent } from './pages/reportes/tiempo-ruta/intermediacion-report.component';
import { ColocacionReportComponent } from './pages/reportes/tiempo-ruta/colocacion-report.component';
import { MirrorGraphComponent } from './components/mirror-graph/mirror-graph.component';
import { VerificarParticipantesComponent } from './pages/alertas/verificar-participantes/verificar-participantes.component';
import { SubirVerificarParticipantesComponent } from './pages/alertas/subir-verificar-participantes/subir-verificar-participantes.component';
import { CheckChangesVerifyComponent } from './components/check-changes-verify/check-changes-verify.component';
import { IndicadoresComponent } from './pages/reportes/indicadores/indicadores.component';

import  {AccBarsgraphComponent} from './components/bars-graph/acc-barsgraph.component';
import { ColumnsGraphComponent } from './components/columns-graph/columns-graph.component';
import { DescargarDocumentosUsuariosComponent } from './pages/reportes/descargar-documentos-usuarios/descargar-documentos-usuarios.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    AccordionGroupComponent,
    CrearOperadorComponent,
    EditarOperadorComponent,
    LoginComponent,
    FileUploaderS3Component,
    CapitalizePipe,
    DragNdropDirective,
    VerOperadoresComponent,
    VerBeneficiariosComponent,
    SubirBeneficiariosComponent,
    SafeUrlPipe,
    FilterBeneficiariosPipe,
    DetalleBeneficiarioComponent,
    FilterPageComponent,
    OrderBeneficiariosPipe,
    ExcelBeneficiarioComponent,
    CurrencyFormatterDirective,
    DetalleOperadorComponent,
    MenuComponent,
    VerUsuariosComponent,
    EditarUsuarioComponent,
    DescargarBeneficiariosComponent,
    DescargarPilaComponent,
    DraggableDirective,
    DropTargetDirective,
    KeyspipePipe,
    SubirNoIncluidosComponent,
    PreinscripcionComponent,
    GraficaComponent,
    FilterReportPipe,
    AssignGroupComponent,
    VerGruposComponent,
    CrearGrupoComponent,
    DetalleGrupoComponent,
    SubirDeloitteComponent,
    InscripcionComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    OrientacionOcupacionalComponent,
    ApoyoPsicosocialComponent,
    PreformacionComponent,
    FilterTextComponent,
    BeneficiaryBoxComponent,
    BeneficiaryBoxStateTrafficComponent,
    AccordionComponent,
    CompleteSearchComponent,
    AvatarPhotoUploadComponent,
    CriteriosComponent,
    RadioListComponent,
    CrearempresaComponent,
    DetalleEmpresaComponent,
    CrearVacanteComponent,
    DetalleVacanteComponent,
    VerEmpresaComponent,
    CheckListComponent,
    EditarEmpresaComponent,
    FormacionComponent,
    ButtonVerifyComponent,
    IntermediacionComponent,
    AssignVacancyComponent,
    EstrategiaRetencionComponent,
    FixedNumberPipe,
    ColocacionComponent,
    PopContentComponent,
    CriteriosColocacionComponent,
    FiltrosExcelComponent,
    FilterExcelPipe,
    GraphComponent,
    SubirPilaComponent,
    VerHistorialPilaComponent,
    VerifyKokanComponent,
    VerifyHistoryComponent,
    NotificacionComponent,
    AlertaColocacionComponent,
    PermanenciaComponent,
    SubirFunelComponent,
    ExplodePipe,
    CheckboxgroupComponent,
    SelectBoxComponent,
    OptionSelectBoxComponent,
    MultipleSelectBoxComponent,
    FormFunnelComponent,
    HojaVidaComponent,
    PdfHojaVidaComponent,
    HistoryTableComponent,
    AlertaComponent,
    IntermediacionAlertaComponent,
    ColocacionNoEmpleadosAlertaComponent,
    ColocacionAlertaComponent,
    TablaAlertasComponent,
    NovedadesComponent,
    SubirPermanenciaTresComponent,
    SubirPermanenciaSeisComponent,
    DescargarPermanenciaComponent,
    TiempoRutaComponent,
    IntermediacionReportComponent,
    ColocacionReportComponent,
    MirrorGraphComponent,
    VerificarParticipantesComponent,
    SubirVerificarParticipantesComponent,
    CheckChangesVerifyComponent,
    IndicadoresComponent,
    AccBarsgraphComponent,
    ColumnsGraphComponent,
    DescargarDocumentosUsuariosComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    Routing,
    CurrencyMaskModule,
    ChartsModule,
    //MatDialogModule,
    BrowserAnimationsModule,
    Ng2GoogleChartsModule,
    Ng2DeviceDetectorModule.forRoot()
  ],
  providers: [
    AuthGuard, 
    AuthenticationService,
    OperadorDataService,
    BeneficiarioDataService,
    PagerServicesService,
    FilterBeneficiariosPipe,
    FilterWrappedService,
    AssignGroupWrappedService,
    UtilService,
    OrderBeneficiariosPipe,
    UsuariosService,
    PerfilacionService,
    DragService,
    EmpresaService,
    VerificadorService,
    DropsWrapperService,
    VacanteService,
    QuestionService,
    ChangesAlertService,
    AnalitycsService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
