import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class FilterWrappedService {

	private filters = new Subject<string>();
	addFilters$ = this.filters.asObservable();

	constructor() { }

	addFilters(item: any){
		this.filters.next(item);
	}


}
