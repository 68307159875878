import { Component, OnInit, OnChanges, Input, Output, EventEmitter, forwardRef, SimpleChanges } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';
import { VerificadorService, AnalitycsService } from '../../services';

@Component({
	selector: 'verify-kokan',
	templateUrl: './verify-kokan.component.html',
	styleUrls: ['./verify-kokan.component.css']
})
export class VerifyKokanComponent implements OnInit {


	@Input() id_participante:number;
	@Input() current_criterio:number;
	@Output() loadEnd = new EventEmitter();
	@Input() disabled:any;
	innerValue:any = '';
	model:any = {}
	paso_kokan:any = '';


	constructor(private verificadorService: VerificadorService,
				private analyticsService: AnalitycsService) { }

	ngOnInit() {
	
	}


	onSubmit(){
		this.loadEnd.emit({loading:true})
		if(confirm('Desea guardar este cambio')){
			this.verificadorService.sendUserKokan(this.id_participante, true)
			.subscribe( data => {
				if(data.error)
					return 
				
				this.analyticsService.eventAction('Operador', 'VerificacionKokan');
				alert(data.msg)
				this.disabled = true;
				this.loadEnd.emit({loading:false})
			})	
		}else{
			this.loadEnd.emit({loading:false})
		}
		
	}





}
