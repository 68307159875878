import { Injectable } from '@angular/core';
import { Router,
		 CanActivate,
		 ActivatedRouteSnapshot,
		 RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { AppSettings } from './../appSettings';

@Injectable()

export class AuthGuard implements CanActivate{
	constructor(private router: Router, private authService: AuthenticationService){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

		if(localStorage.getItem('session_id') && localStorage.getItem('user_id')){
			return this.authService.verify_session(localStorage.getItem('session_id'))
				.map( data => {
					if(data.error){
						this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
						return false;
					}

					if(localStorage.getItem('bono') == "2"){
						window.location.href = AppSettings.URL_BONO_2;
					}

					return true;
				})
		}


		this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
		return false;
	}
}
