import { Component, OnInit, OnChanges, Input, Output,ViewChild, EventEmitter} from '@angular/core';
import { VerificadorService  } from '../../services';

@Component({
	selector: 'app-button-verify',
	templateUrl: './button-verify.component.html',
	styleUrls: ['./button-verify.component.css']
})
export class ButtonVerifyComponent implements OnInit, OnChanges {

	@Input() beneficiario:any;
	@Input() active:any;
	@Input() observation:string;
	@ViewChild('observacion') observacion;
	@Output() out_status = new EventEmitter();
	message:string = '¡El beneficiario cambiará a estado';


	constructor(private verificadorService:VerificadorService) { }

	ngOnInit() {
		
	}

	ngOnChanges(changes:any){
		if(changes.observation){
			if(changes.observation.currentValue != undefined && changes.observation.currentValue != null)
				this.observacion.nativeElement.value = String(changes.observation.currentValue);
		}
	}

	verify(type){

		

		if(this.active == null || this.active == 3){

			if(type == 3 && this.observacion.nativeElement.value.length <= 0){
				alert('Debe especificar una observación para el estado pendiente');
				return;
			}

			let message;
			switch (type) {
				case 1:
				message = this.message + ' Cumple!'
				break;
				case 2:
				message = this.message + ' No cumple!'
				break;
				case 3:
				message = this.message + ' Pendiente!'
				break;

				default:
				break;
			}

			if(confirm(message)){
				this.verificadorService.sendVerificacionFormacion(this.beneficiario, type, this.observacion.nativeElement.value)
				.subscribe( data => {
					if(!data.error){
						this.active = data.status_formacion[0].type_fulfill_id;
						switch (this.active) {
							case 1:
							this.out_status.emit({status: 'INTERVENCIÓN COMPLETA'});
							break;
							case 2:
							this.out_status.emit({status: 'INTERVENCIÓN INCOMPLETA'});
							break;
							case 3:
							this.out_status.emit({status: 'PENDIENTE'});
							break;

							default:
							break;
						}
					}
					
					

				})	
			}

		}


	}

}
