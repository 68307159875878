import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../appSettings';

@Component({
  selector: 'app-excel-beneficiario',
  templateUrl: './subir-beneficiarios.component.html',
  styleUrls: ['./subir-beneficiarios.component.css']
})
export class ExcelBeneficiarioComponent implements OnInit {

  urlIframe: any;
  title_page: string;
  constructor() { }

  ngOnInit() {
  	var token = localStorage.getItem('session_id')
  	//this.urlIframe = AppSettings.URL_BACKEND + 'upload_file_excel/' + token;
    this.title_page = 'Subir KOKAN online';
  	this.urlIframe = AppSettings.URL_BACKEND + 'upload_file_excel/';
  }

}
