import { Component, OnInit, OnChanges , Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mirror-graph',
  templateUrl: './mirror-graph.component.html',
  styleUrls: ['./mirror-graph.component.css']
})
export class MirrorGraphComponent implements OnInit, OnChanges  {

  @ViewChild('form_graphic_elm') form_graphic_elm;
  @ViewChild('form_mirror_elm') form_mirror_elm;

  @Input() main_graphic;
  @Input() mirror_graphic;
  @Input() filters;
  @Input() hide_filter;
  @Input() enabled_mirror;
  @Input() external_filter;

  @Output() filter_graph = new EventEmitter();
  @Output() exportLink = new EventEmitter();
  @Output() exportGraph = new EventEmitter();

  form_graphic:any;
  form_mirror:any;

  constructor() { }

  ngOnInit() {
    let filters = JSON.parse(JSON.stringify(this.filters));


    if(this.hide_filter){
      filters = JSON.parse(JSON.stringify(filters.map( field => {
        if(this.hide_filter.filter( h => field.key == h ).length > 0){
           field.controlType = 'text'
           field.type = 'hidden'
        }
        return field
      })))
    }

    if(filters.filter( f => f.type != 'hidden').length <= 0){
      filters = (!this.enabled_mirror) ? filters.filter( f => f.type != 'hidden') : filters;
    }

    console.log("filters",filters)

    this.form_graphic = JSON.parse(JSON.stringify(filters));
    this.form_mirror = JSON.parse(JSON.stringify(filters));  

    if(filters.length > 0){
      this.form_graphic[0].value = 1;
      this.form_mirror[0].value = 2;  
    }
  }

  ngOnChanges(changes){
    console.log(changes)
    if(changes.external_filter){
      
      if(this.form_graphic_elm){
        this.onSubmit({formData:this.form_graphic_elm.form.value, mirror_filter: true}, changes.external_filter.currentValue.left)
      }

      if(this.form_mirror_elm){
        this.onSubmit({formData:this.form_mirror_elm.form.value,  mirror_filter: true}, changes.external_filter.currentValue.right)
      }
      
    }
    
  }




  onSubmit(event, side){

    console.log(event)
    event.mirror_filter = (event.mirror_filter) ? event.mirror_filter: false;
  	this.filter_graph.emit(Object.assign(event.formData, side, {mirror_filter: event.mirror_filter}))
  }

  getExportGraph(event){
  	this.exportGraph.emit(event);
  }

   getExportLink(event, type){
     let formData = null;

     switch (type) {
       case 1:
         formData = Object.assign(this.form_graphic_elm.form.value, this.external_filter.left);

         break;
       case 2:
         formData = Object.assign(this.form_mirror_elm.form.value, this.external_filter.right) ;
         break;

     }

    formData.interval = event.interval;
    formData.index = (event.interval - 1);
    console.log(formData);
   	this.exportLink.emit(formData);
   }

}
