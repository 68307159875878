export class AppSettings {
	public static URL_BACKEND = 'https://plataforma.bonosdeimpactosocial.com.co/'

  //public static URL_BACKEND = 'http://bonosdeimpactosocial.com.co:3001/'
  //public static URL_BACKEND = 'http://localhost:3000/'
  //public static URL_BACKEND = 'http://192.168.0.27:3000/'

  // public static URL_BONO_2 = '/pruebas/bono2/'
  // public static URL_BONO_3 = '/pruebas/bono3/'

  public static URL_BONO_2 = '/bono2/'
  public static URL_BONO_3 = '/bono3/'
  public static URL_BONO_4 = '/bono4/'
  public static URL_BONO_5 = '/bono5/'
  public static URL_BONO_6 = '/bono6/'


	public static API_ENDPOINT = AppSettings.URL_BACKEND + 'webservices/'
	public static API_KEY_S3 = 'AKIAIORBO3YPHPOEKEOQ';
	public static API_PRIVATE_KEY_S3 = 'bNHXajX9vc7B3z3AezP2ISLMU3js8/xpyHaOY5VN';
	public static BUCKET_S3 = 'archivobis';
}
