import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'intl';
import 'intl/locale-data/jsonp/en';

//if (environment.production) {
  enableProdMode();
//}

platformBrowserDynamic().bootstrapModule(AppModule);
