import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Beneficiario } from '../../struct';


@Component({
  selector: 'app-beneficiary-box',
  templateUrl: './beneficiary-box.component.html',
  styleUrls: ['./beneficiary-box.component.css']
})
export class BeneficiaryBoxComponent implements OnInit {

  @Input() beneficiario: Beneficiario;
  @Input() stateToGo:number = 1;
  @Input() type_verify:number = 1;
  @Input() showCheck:boolean;

  @Output() onSelectCheckbox = new EventEmitter();

  states:any;
  type_document:any;
  state_verificacion:any = {
    verificacion_deloitte: "Sin verificar - PENDIENTE CUMPLE ELEGIBILIDAD",
    verificacion_formacion: "Sin verificar - PENDIENTE EMPLEABILIDAD",
    verificacion_permancencia3: "Sin verificar - PENDIENTE CUMPLE PERMANENCIA 3 MESES",
    verificacion_permancencia6: "Sin verificar - PENDIENTE CUMPLE PERMANENCIA 6 MESES"
  };


  constructor() { }

  ngOnInit() {
  	this.states = [
      {id:'-1', name:'PREINSCRITO INCOMPLETO', checked:true, color:' #717171'},
  		{id:'1', name:'PREINSCRIPCIÓN', checked:true, color:' #f1c154'},
  		{id:'2', name:'INSCRIPCIÓN', checked:false, color:'#ef893a'},
  		{id:'3', name:'PERFILAMIENTO', checked:false, color:'#f55d52'},
  		{id:'4', name:'ORIENTACIÓN OCUPACIONAL', checked:false, color:'#d53e33'},
  		{id:'5', name:'PRUEBAS PREFORMACIÓN', checked:false, color:'#5d1b7b'},
  		{id:'6', name:'FORMACIÓN', checked:false, color:'#9a5cb4'},
  		{id:'7', name:'PRUEBAS POST-FORMACIÓN', checked:false, color:'#2ea8ed'},
  		{id:'8', name:'ACOMPAÑAMIENTO PSICOSOCIAL', checked:false, color:'#0e69ad'},
  		{id:'9', name:'ESTRATEGIAS DE RETENCIÓN', checked:false, color:'#122a6e'},
  		{id:'10', name:'INTERMEDIACIÓN', checked:false, color:'#0b6b19'},
  		{id:'11', name:'COLOCACIÓN LABORAL', checked:false, color:'#179a8f'},
  		{id:'12', name:'ACOMPAÑAMIENTO', checked:false, color:'#39ca74'}
  	]

    switch (parseInt(this.beneficiario['type_document_id'])) {
      case 153:
        this.type_document = 'CC'
        break;
      case 154:
        this.type_document = 'TI'
        break;
      case 155:
        this.type_document = 'CE'
        break;
      case 156:
        this.type_document = 'RC'
        break;
      case 158:
        this.type_document = 'SIN'
        break;
      case 162:
        this.type_document = 'NUIP'
        break;

      default:
         this.type_document = 'NA'
    }

    if(this.beneficiario['verificacion_formacion']){
      switch (this.beneficiario['verificacion_formacion']) {
        case 1:
          this.state_verificacion.verificacion_formacion = "Verificado - CUMPLE EMPLEABILIDAD"
          break;

        case 2:
          this.state_verificacion.verificacion_formacion = "Verificado - NO CUMPLE EMPLEABILIDAD"
          break;

        case 3:
          this.state_verificacion.verificacion_formacion = "Sin verificar - PENDIENTE EMPLEABILIDAD"
          break;
      }

    }

    if(this.beneficiario['verificacion_permancencia3']){
      switch (this.beneficiario['verificacion_permancencia3']) {
        case 1:
          this.state_verificacion.verificacion_permancencia3 = "Verificado - CUMPLE PERMANENCIA 3 MESES"
          break;

        case 2:
          this.state_verificacion.verificacion_permancencia3 = "Verificado - NO CUMPLE PERMANENCIA 3 MESES"
          break;

        case 3:
          this.state_verificacion.verificacion_permancencia3 = "Sin verificar - PENDIENTE CUMPLE PERMANENCIA 3 MESES"
          break;
      }
    }

    if(this.beneficiario['verificacion_permancencia6']){
      switch (this.beneficiario['verificacion_permancencia6']) {
        case 1:
          this.state_verificacion.verificacion_permancencia6 = "Verificado - CUMPLE PERMANENCIA 6 MESES"
          break;

        case 2:
          this.state_verificacion.verificacion_permancencia6 = "Verificado - NO CUMPLE PERMANENCIA 6 MESES"
          break;

        case 3:
          this.state_verificacion.verificacion_permancencia6 = "Sin verificar - PENDIENTE CUMPLE PERMANENCIA 6 MESES"
          break;
      }
    }

     if(this.beneficiario['verificacion_deloitte']){
      switch (this.beneficiario['verificacion_deloitte']) {
        case 5:
          this.state_verificacion.verificacion_deloitte = "Verificado - CUMPLE ELEGIBILIDAD"
          break;

        case 2:
          this.state_verificacion.verificacion_deloitte = "Verificado - NO CUMPLE CUMPLE ELEGIBILIDAD"
          break;

        case 3:
          this.state_verificacion.verificacion_deloitte = "Sin verificar - PENDIENTE CUMPLE ELEGIBILIDAD"
          break;
      }
    }


  }


  onSelectBox(event){
    event.stopPropagation();
    this.onSelectCheckbox.emit(event.target.checked);
  }

  getColorState(){

  	let curr_state = this.states.filter( _state => {
      let state = this.beneficiario['stage_id'] == 4 ? -1 : this.beneficiario['stage_id'];
      return state == _state.id
    });

  	return curr_state[0].color;
  }

}
