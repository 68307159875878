import { DatePipe } from '@angular/common';
import * as html2canvas from "html2canvas"

export class ExportGraph {

	private nativeElement;
	private width;
	private heigth;
	private nameGraph;

	constructor(nativeElement, width, height, nameGraph){
		this.nativeElement = nativeElement;
		this.width = width;
		this.heigth = height;
		this.nameGraph = nameGraph;
	}

	exportGraph(){

		return new Promise( resolve => {
			let end_canvas = document.createElement('canvas');
			let ctx = end_canvas.getContext('2d')

			this.inlineCss(this.nativeElement);
			this.canvasSVG(this.nativeElement.querySelector('svg'), this.width, this.heigth).then( url => {
				let image = new Image();
				image.src = String(url)

				image.onload = event => {
					html2canvas(this.nativeElement.querySelector('.LabelGraph')).then( canvas => {
						end_canvas.width = image.width;
						end_canvas.height = canvas.height + (image.height + 20);

						let legend_image = new Image();
						legend_image.onload = event => {
							ctx.fillStyle = "#ffF";
							ctx.fillRect(0,0,end_canvas.width,end_canvas.height);
							ctx.drawImage(image, 0, 0, image.width, image.height)
							ctx.drawImage(legend_image, ((end_canvas.width / 2) - (legend_image.width / 2)), image.height)	
							this.download_image(end_canvas.toDataURL())
						}
						legend_image.src = canvas.toDataURL();
						resolve(true);
					})
				}
				
			})
		})

		

	}

	private canvasSVG(element, width, height){
		var svgString = new XMLSerializer().serializeToString(element);
		var canvas =  document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;

		var ctx = canvas.getContext("2d");
		var DOMURL = self.URL;
		var img = new Image();
		var svg = new Blob([svgString], {type: "image/svg+xml;charset=utf-8"});
		var url = DOMURL.createObjectURL(svg);

		return new Promise((resolve, reject) => {
			img.onload = function() {

			    ctx.drawImage(img, 0, 0);
			    var png = canvas.toDataURL("image/png");
			    resolve(png);
			    DOMURL.revokeObjectURL(png);
			    
			};
			img.src = url;	
		})
	}

	private download_image(image){
		let pom = document.createElement('a');
		pom.setAttribute('href', image);
		pom.setAttribute('download', this.nameGraph + '_' + new DatePipe('en-US').transform(new Date(), 'yyyy_MM_dd_HH_mm_ss'));
		pom.setAttribute('target', '_blank');


		if (document.createEvent) {
			let event = document.createEvent('MouseEvents');
			event.initEvent('click', true, true);
			pom.dispatchEvent(event);
		}
		else {
			pom.click();
		}
	}

	private inlineCss(element){
		let fill_attributes = ['fill', 'fontSize', 'textAnchor', 'fontFamily', 'textTransform', 'stroke', 'strokeWidth']
		element.depth = 0;
		element.next = null;

		var children, i, len;
		var depth;
		var current,last;
		current = element;
		last = current;

		while (current) {
		  children = current.childNodes;
		    for (i = 0, len = children.length; i < len; i++) {
		      let child = children[i];
		      child.depth = current.depth+1;
		      child.next = null;
		      //place new item at the tail of the list
		      last.next = child;
		      last = child;
		    }
		  //removes this item from the linked list
		  if(current.getAttribute){
		  	 let style = getComputedStyle(current)

		  	 for(let key of Object.keys(style)){
		  	 	if(isNaN(parseInt(key))){

		  	 		if(fill_attributes.indexOf(key) > -1){
		  	 			let attribute =  (key.split(/(?=[A-Z])/)) ? key.split(/(?=[A-Z])/).join('-').toLowerCase() : key;
			  	 		current.setAttribute(attribute, style[key])	
		  	 		}
		  	 	}
		  	 }
		  }
		  	
		  current = current.next;
		}

	}
}