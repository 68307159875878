import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.css']
})
export class FilterTextComponent implements OnInit {

  model:any = {};
  open_search:boolean;
  @Output() text_search = new EventEmitter();



  constructor() { }

  ngOnInit() {
  }

  onFilter(){
  	this.open_search = false;

  	if(this.model.buscar){
  		this.text_search.emit({text_search:this.model.buscar})	
  	}
  	this.model.buscar = '';
  }

}
