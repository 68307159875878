export class CriteriosPriorizacion {
	id:number;
	criterio:string;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getData(){
		return [
		{ id:1, criterio:'JÓVENES EN ACCIÓN'},
		{ id:2, criterio:'FAMILIAS EN ACCIÓN'},
		{ id:3, criterio:'UNIDOS'},
		{ id:4, criterio:'VÍCTIMAS'},
		].map( criterio => new CriteriosPriorizacion(criterio)); 
	}

	static getItem(id){
		return CriteriosPriorizacion.getData().filter( criterio => criterio.id == id)[0];
	}
}
