import { Component, OnInit,  } from '@angular/core';
import { AlertasService, AnalitycsService } from '../../../services';


@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
  providers: [AlertasService]
})
export class AlertaComponent implements OnInit {

  alert_data: any = [];
  date_interval: any = [];
  alertas:any = [];
  type_for:any;
  link_form:any;

  constructor(private alertService:AlertasService,
              private analyticsService: AnalitycsService) { }

  ngOnInit() {
    this.link_form = 'upload_alert.xlsx';
    this.date_interval = [
    { label: '15 días', level: 1, value: 15},
    { label: '20 días', level: 1, value: 20},
    { label: '25 días', level: 1, value: 25},
    { label: '30 días', level: 2, value: 30},
    { label: '35 días', level: 2, value: 35},
    { label: '40 días', level: 2, value: 40},
    { label: '45 días', level: 2, value: 45},
    { label: 'Mayor a  45 días', level: 2, value: 46},
    { label: 'Total', level: 0, value: null},
    ]

    this.alertService.getAlertDocumentacion()
    .subscribe( data => {
      let alert_data_1 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['colocacion' + (index + 1)]).filter( o => o != undefined)
        }  
      })

      let alert_data_2 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['permancen1_' + (index + 1)]).filter( o => o != undefined)
        }  
      })

      let alert_data_3 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['permancen2_' + (index + 1)]).filter( o => o != undefined)
        }  
      })


      this.alertas = [
      {
        nombre: 'Certificados de colocación',
        table: alert_data_1,
        type_form: 1
      },
      {
        nombre: 'Certificados de permanencia a 3 meses',
        table: alert_data_2,
        type_form: 2
      },
      {
        nombre: 'Certificados de permanencia a 6 meses',
        table:  alert_data_3,
        type_form: 3
      },
      ]

    });
    

  }

  onExportAlert(name){
    this.analyticsService.eventAction("Alertas", "Descargar:" + name);
  }


}
