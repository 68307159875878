import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';

@Component({
  selector: 'checkbox-group',
  templateUrl: './checkboxgroup.component.html',
  styleUrls: ['./checkboxgroup.component.css'],
  providers: [
  	{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxgroupComponent),
      multi: true
    }
  ]
})

export class CheckboxgroupComponent implements  OnInit, ControlValueAccessor{

	@Input() options;
	@Input() name;
	@Input() warning; 
	@Input('value') _value;

	result: any;
	_result: any = [];

	onChange: any = () => {};
	onTouched: any = () => {};

	get value(){
		return this._value;
	}

	set value(val){
		console.log(val);
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	constructor() { 
		
		
	}

	ngOnInit() {
		console.log(this.value);
	}

	writeValue(value){
		if(value){
			this.value = value;
		}
	}

	registerOnChange(fn: any){
		this.onChange = fn;
	}

	registerOnTouched(fn: any){
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean){}

	change(event, value){
		this.result.push(value)
		console.log(this);
	}

}
