import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BeneficiarioDataService } from '../../services';


@Component({
  selector: 'app-alerta-colocacion',
  templateUrl: './alerta-colocacion.component.html',
  styleUrls: ['./alerta-colocacion.component.css']
})
export class AlertaColocacionComponent implements OnInit {

	@Input() id_participante:number;
  @Input() id_alert:number;
  @Input() options_alert:any;

	@Output() loadEnd = new EventEmitter();
	@Input() disabled:any;
	model:any = {}
	paso_kokan:any = '';
  close_alert:boolean = false;

  constructor(private beneficiarioService:BeneficiarioDataService) { }

  ngOnInit() {
  }

  onSubmit(){
    this.beneficiarioService.sendAlertColocacion(this.id_alert, this.model.razon_soporte)
      .subscribe( data => {
        this.close_alert = true;
      });
  }

}
