import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssignGroupWrappedService } from '../../shared/assign-group-wrapped.service' ;
import { EmpresaService, VacanteService } from '../../services';

@Component({
  selector: 'app-assign-vacancy',
  templateUrl: './assign-vacancy.component.html',
  styleUrls: ['./assign-vacancy.component.css']
})
export class AssignVacancyComponent implements OnInit {

  open_popup:boolean;
  model:any = {};
  beneficiario:any;
  empresas:any = [];
  vacantes:any = [];
  @Input() assign_popup
  @Output() togglePopup = new EventEmitter();
  @Output() vacantes_asignadas = new EventEmitter();

  constructor(private assignGroupService: AssignGroupWrappedService,
      private empresaService: EmpresaService,
      private vacanteService: VacanteService) { }

  ngOnInit() {
     this.assignGroupService.addBeneficiaries$.subscribe( beneficiario => {
      this.open_popup = true;
      this.beneficiario = beneficiario[0];
    });

   this.empresaService.getAllEmpresas()
     .subscribe(data=>{
       this.empresas = data.allcompany;
     });


     
  }

  onClose(event){
    this.open_popup = false;
    this.model = {};
    this.beneficiario = null;
    this.togglePopup.emit({open_popup: false});
  }

  onChangeEmpresa(event, id_empresa){
    this.vacanteService.assignVacanteByCompanyId(id_empresa)
       .subscribe(data=>{
         this.vacantes = data.veneficiary_by_company;
    });
  }

  onAssign(){
    this.vacanteService.assginVacante(this.beneficiario.user_xml_id, this.model.vacante)
       .subscribe(data=>{
         if(data.error){
              alert(data.msg);
              return;
         }

         alert(data.msg);
         this.vacantes_asignadas.emit({vacantes: data.vacantes, completa:data.completa})
         this.open_popup = false;
         this.model = {};
         this.beneficiario = null;
         this.togglePopup.emit({open_popup: false});
    });  
  }

}
