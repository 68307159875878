import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Beneficiario, Edades } from '../../../struct';
import { BeneficiarioDataService, OperadorDataService, UtilService, PagerServicesService, AnalitycsService } from '../../../services';
import { FilterWrappedService } from '../../../shared/filter-wrapped.service' ;
import { FilterReportPipe } from '../../../pipes/filter-report.pipe';
import { FormControl, FormGroup, FormArray, Validators} from '@angular/forms';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { AppSettings } from '../../../appSettings'


@Component({
	selector: 'app-grafica',
	templateUrl: './grafica.component.html',
	styleUrls: ['./grafica.component.css'],
	providers: [FilterReportPipe]
})
export class GraficaComponent implements OnInit {

	@ViewChild('graph_operador') graph_operador;
	@ViewChild('graph_ciudades') graph_ciudades;
	@ViewChild('graph_generos') graph_generos;
	@ViewChild('graph_edades') graph_edades;
	@ViewChild('graph_poblacion') graph_poblacion;
	@ViewChild('graph_priorizacion') graph_priorizacion;
	@ViewChild('graph_sisben') graph_sisben;
	@ViewChild('graph_jefehogar') graph_jefehogar;
	@ViewChild('graph_nivel_educativo') graph_nivel_educativo;
	@ViewChild('graph_estado_civil') graph_estado_civil;
	@ViewChild('name_pdf') name_pdf;
	@ViewChild('comentario_pdf') comentario_pdf;

	graphs = ['graph_operador', 'graph_ciudades', 'graph_generos', 'graph_edades', 'graph_poblacion', 'graph_priorizacion', 'graph_priorizacion', 'graph_sisben', 'graph_sisben', 'graph_jefehogar', 'graph_nivel_educativo', 'graph_estado_civil']

	loadPage:boolean = true;
	beneficiarios: Beneficiario[];
	totalBeneficiarios: number;
	totalBeneficiariosFiltrados: number;
	operadores: any;
	jefeHogar = [{id:1, name:'SI'}, {id:0, name:'NO'}];
	ampliacion = [{id:1, name:'BIS 1A'}, {id:2, name:'BIS 1B'}];
	dropsOptions: any;
	muestraTotal: number;
	variablesFiltro: any[];
	currVariable:any;
	ciudadesOperadores: any[];

	dataSets:any = {};

	dataSetOperadores:any[] = [];
	dataSetCiudades:any[] = [];

	color_default:any[];
	color_operadores:any[];
	color_ciudades:any[];
	color_general:any[];

	export_data:any = {};
	export_filtros: any;

	fill_beneficiarios:any;
	
	form: FormGroup;
	url_form:string;
	url_pdf:string;
	options:any = {};
	token:string;

	filtros: any;
	historial: any;
	historial_paged:any;
	validInput:boolean;

	pager: any = {};
	type_user: any;

	constructor(private beneficiarioService: BeneficiarioDataService,
		private operadorService: OperadorDataService,
		private route: ActivatedRoute,
		private router: Router,
		private utilService: UtilService,
		private filterReport: FilterReportPipe,
		private dropService:DropsWrapperService,
		private pagerService: PagerServicesService, 
		private analyticsService: AnalitycsService) { 
	}

	get InputsAmpliacion():FormArray{
	    return this.form.get('ampliacion') as FormArray;
	}

	get InputOperadores(): FormArray{
		return this.form.get('operadores') as FormArray;
	}

	get InputVariables(): FormControl{
		return this.form.get('variables') as FormControl;
	}

	get InputEstados(): FormArray{
		return this.form.get('estados') as FormArray;
	}


	get InputCiudades(): FormArray{
		return this.form.get('ciudades') as FormArray;
	}

	get InputGenero(): FormArray{
		return this.form.get('generos') as FormArray;
	}

	get InputEdades(): FormArray{
		return this.form.get('edades') as FormArray;
	}

	get InputPoblacion(): FormArray{
		return this.form.get('poblacion') as FormArray;
	}

	get InputPriorizacion(): FormArray{
		return this.form.get('priorizacion') as FormArray;
	}

	get InputRangosSisben(): FormArray{
		return this.form.get('sisben') as FormArray;
	}


	get InputNivelEducativo(): FormArray{
		return this.form.get('nivel_educativo') as FormArray;
	}

	get InputEstadoCivil(): FormArray{
		return this.form.get('estado_civil') as FormArray;
	}

	get InputGetJefeHogar(): FormArray{
		return this.form.get('jefe_hogar') as FormArray;
	}

	ngOnInit() {

		this.dropService.fullValidateDrop().subscribe( d => this.dropService.addOptions(d));
		this.token = localStorage.getItem('session_id')
		this.url_form = AppSettings.URL_BACKEND + 'pdf_report';
		this.url_pdf = AppSettings.URL_BACKEND;
		this.type_user = localStorage.getItem('user_type')

		this.color_general = [
		['#39ca74','#2ea8ed','#f1c154','#e96637','#9a5cb4','#0e69ad','#599bf0','#f1c154'], //'graph_operador':
		['#d53e33','#0e69ad','#179a8f'], //'graph#_ciudades':
		['#d53e33','#2ea8ed','#9a5cb4','#f1c154'],//'graph_generos':
		['#39ca74','#f55d52','#0e69ad','#2ea8ed'],//'graph_edades':
		['#0e69ad','#599bf0','#f1c154','#e96637','#5d1b7b','#9a5cb4','#39ca74'], //'graph_poblacion':
		['#0e69ad','#179a8f','#5d1b7b','#d53e33'],//'graph_priorizacion':
		['#2ea8ed','#9a5cb4','#599bf0'],//'graph#_sisben':
		['#179a8f','#5d1b7b'],//'graph_jefehoga#r':
		['#9a5cb4','#d53e33','#0b6b19','#e96637','#599bf0','#0e69ad','#f55d52','#32a69c','#ef893a','#122a6e','#39ca74','#f97e4a','#2ea8ed','#5d1b7b'], //'graph_nivel_educativo':
		['#ef893a','#d53e33', '#39ca74', '#5d1b7b','#9a5cb4','#2ea8ed','#122a6f', '#f55d52'] //'graph_estado_civil':
		]

		
		this.variablesFiltro = [
		//{value:null, desc:'Todas las etapas'},
			{value:1, desc:'Pre-inscripción'},
			{value:38, desc:'En formación'},
			{value:6, desc:'Colocación laboral'},
			
			{value:7, desc:'Pre-inscripción (Incompleto)'},
			{value:4, desc:'Formación'},
			{value:12, desc:'Colocación laboral (Incompleto)'},
			
			{value:13, desc:'Elegible en adelante'},
			{value:10, desc:'Formación (Incompleto)'}, 
			{value:22, desc:'permanencia 3 meses'},
			
			{value:2, desc:'Inscripción'},
			{value:40, desc:'En Intermediación'}, 
			{value:35, desc:'permanencia 3 meses (Incompleto)'},
			
			{value:8, desc:'Inscripción (Incompleto)'},
			{value:5, desc:'Intermediación'},
			{value:23, desc:'permanencia 6 meses'},

			{value:3, desc:'Orientación socio-ocupacional'},
			{value:11, desc:'Intermediación (Incompleto)'}, 
			{value:36, desc:'permanencia 6 meses (Incompleto)'},

			
			{value:9, desc:'Orientación socio-ocupacional (Incompleto)'},
        	
        	
		]

		this.ciudadesOperadores = [
		{ value:1, desc:'Bogotá'},
		{ value:2, desc:'Pereira'},
		{ value:3, desc:'Cali'},
		];

		this.color_default = ['#dcdcdc'];

		this.options = {
			'colors': this.color_default,
			'legend': {position: 'none'},
			'chartArea': {'width': '90%', 'height': '90%'},
			'fontSize': 14,
			'height':200, width:200,
			tooltip: { hideDelay: 0 }
		};

		this.operadorService.getAllOperadores()
		.subscribe( data => {
			if(!data.error){
				this.operadores = data.operator;
				

				this.dropService.addOptions$.subscribe( options => {
					this.dropsOptions = options;
					this.form = this.buildForm();	
					this.export_filtros = this.filtros;
					this.filtros = this.form.value;
					this.beneficiarioService.getAllBeneficiariosFill()
					.subscribe(data => {
						this.beneficiarios = data.user;
						this.historial = data.history;
						this.totalBeneficiarios = this.beneficiarios.length;
						this.drawData(this.beneficiarios);
						this.loadPage = false;
						this.setPage(1)
					});
				});

				
			}
		})
	}

	fillData(form){
		const variable = this.getVariableByValue(form.variables);
		const f = Object.assign({}, form, {
			estados: form.estados.map((s, i) => { 
				return {id: this.variablesFiltro[i].value, selected:s, value: this.variablesFiltro[i].desc} 
			}),
			operadores: form.operadores.map((s, i) => { 
				return {id: this.operadores[i].id, selected:s, value: this.operadores[i].name_operator} 
			}),
			ciudades: form.ciudades.map((s, i) => { 
				return {id: this.ciudadesOperadores[i].value, selected:s, value:this.ciudadesOperadores[i].desc} 
			}),
			edades: form.edades.map((s, i) => {
				return {id: this.utilService.getRangosEdades()[i].id, selected:s, value:this.utilService.getRangosEdades()[i].rango}
			}),
			estado_civil: form.estado_civil.map((s, i) => {
				return {id: this.dropsOptions.estadocivil[i].id, selected:s, value:this.dropsOptions.estadocivil[i].name_civil_status}
			}),
			generos: form.generos.map((s, i) => {
				return {id: this.dropsOptions.genero[i].id, selected:s, value:this.dropsOptions.genero[i].name_gender}
			}),
			nivel_educativo: form.nivel_educativo.map((s, i) => {
				return {id: this.dropsOptions.niveleducativo[i].id, selected:s, value:this.dropsOptions.niveleducativo[i].name_education_level}
			}),
			poblacion: form.poblacion.map((s, i) => {
				return {id: this.utilService.getPoblacion()[i].id, selected:s, value:this.utilService.getPoblacion()[i].criterio}
			}),
			priorizacion: form.priorizacion.map((s, i) => {
				return {id: this.utilService.getCriteriosPriorizacion()[i].id, selected:s, value:this.utilService.getCriteriosPriorizacion()[i].criterio}
			}),
			sisben: form.sisben.map((s, i) => {
				return {id: this.utilService.getRangosSisben().slice(1)[i].id, selected:s, value:this.utilService.getRangosSisben().slice(1)[i].rango}
			}),
			jefe_hogar: form.jefe_hogar.map((s, i) => {
				return {id: this.jefeHogar[i].id, selected:s, value:this.jefeHogar[i].name}
			}),
			ampliacion: form.ampliacion.map((s, i) => {
				return {id: this.ampliacion[i].id, selected:s, value:this.ampliacion[i].name}
			}),
			variables: variable ? [{id: variable.value, value:variable.desc, selected:true}]: [{id:null, value:null, selected:null}]

		});

		this.currVariable = [];

		
		if(this.variablesFiltro.filter( v =>  v.value == parseInt(f.variables[0].id))[0])
			this.currVariable.push(this.variablesFiltro.filter( v =>  v.value == parseInt(f.variables[0].id))[0].desc);
		


		if(f.estados.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.estados.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.operadores.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.operadores.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));
		
		if(f.ciudades.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.ciudades.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.edades.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.edades.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.estado_civil.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.estado_civil.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.generos.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.generos.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.nivel_educativo.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.nivel_educativo.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.poblacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.poblacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.priorizacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.priorizacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.sisben.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.sisben.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.jefe_hogar.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.jefe_hogar.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toLowerCase() ).join(','));

		if(f.ampliacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value ).length > 0)
			this.currVariable.push(f.ampliacion.filter( o =>  o != undefined && o.selected == true && o.value!= null).map( o => o.value.toUpperCase() ).join(','));

		this.currVariable = this.currVariable.join(',');

		this.filtros = f;
		this.export_filtros = f;

		let _beneficiarios = this.filterReport.transform(this.beneficiarios, f);
		this.fill_beneficiarios = _beneficiarios;
		this.drawData(_beneficiarios);

		
	}

	getVariableByValue(value){
		return this.variablesFiltro.filter(variable => variable.value == value)[0];
	}

	drawData(data){
		this.totalBeneficiariosFiltrados = data.length;

		this.dataSets['operadores'] = this.participantesByOperador(data);
		this.dataSets['ciudades'] = this.participantesByCiudad(data); 
		this.dataSets['genero'] = this.participantesByGenero(data); 
		this.dataSets['edad'] = this.participantesByEdad(data); 
		this.dataSets['poblacion'] = this.participantesByPoblacion(data); 
		this.dataSets['priorizacion'] = this.participantesByPriorizacion(data); 
		this.dataSets['sisben'] = this.participantesByRangoSisben(data); 
		this.dataSets['nivel_educativo'] = this.participantesByNivelEducativo(data); 
		this.dataSets['estado_civil'] = this.participantesByEstadoCivil(data); 
		this.dataSets['jefe_hogar'] = this.participantesByJefeHogar(data);

	}

	getExportGraph(event, graph){
		this.export_data[graph] = {
			grafica: graph,
			img: event.graph,
			table: event.table
		}
	}

	getKeys(object){
		return Object.keys(object);
	}

	buildForm(){
		let arr = {};
		arr['variables'] = new FormControl();
		arr['estados'] = new FormArray(this.createArrayInput(this.variablesFiltro));
		arr['ciudades'] = new FormArray(this.createArrayInput(this.dropsOptions.city));
		arr['operadores'] = new FormArray(this.createArrayInput(this.operadores));
		arr['generos'] = new FormArray(this.createArrayInput(this.dropsOptions.genero));
		arr['edades'] = new FormArray(this.createArrayInput(this.utilService.getRangosEdades()));
		arr['poblacion'] = new FormArray(this.createArrayInput(this.utilService.getPoblacion()));
		arr['priorizacion'] = new FormArray(this.createArrayInput(this.utilService.getCriteriosPriorizacion()));
		arr['sisben'] = new FormArray(this.createArrayInput(this.utilService.getRangosSisben().slice(1)));
		arr['nivel_educativo'] = new FormArray(this.createArrayInput(this.dropsOptions.niveleducativo));
		arr['estado_civil'] = new FormArray(this.createArrayInput(this.dropsOptions.estadocivil));
		arr['jefe_hogar'] = new FormArray(this.createArrayInput(this.jefeHogar));
		arr['ampliacion'] = new FormArray(this.createArrayInput(this.ampliacion));
		return new FormGroup(arr);
	}

	createArrayInput(obj: Array<any>){
		let array = [];

		if(obj)
			obj.map( (o, index) => {
				array.push(new FormControl())
			}) 

		return array;
	}

	participantesByOperador(beneficiarios){
		return this.operadores.map( operador => {
			if(operador.id_operador != 39 && operador.id_operador != 51){
				return [
				operador.name_operator,
				beneficiarios.filter( p => p.id_operador == operador.id_operador).length
				];
			}
		}).filter( item => typeof(item) != 'undefined');
	}

	participantesByCiudad(beneficiarios){
		return this.ciudadesOperadores.map( (ciudad, index) => {
			return [
			ciudad.desc,
			beneficiarios.filter( p => p.city_id == ciudad.value).length]
		});
	}

	participantesByGenero(beneficiarios){
		if(this.dropsOptions.genero)
			return this.dropsOptions.genero.map( (genero, index) => {
				return [
				genero.name_gender,
				beneficiarios.filter( p => p.id_gender == genero.id).length]
			});	

		return null;
	}

	participantesByJefeHogar(beneficiarios){
		return this.jefeHogar.map( (jefe, index) => {
			return [
			jefe.name,
			beneficiarios.filter( p => Number(p.head_household) == jefe.id).length]
		});	
	}

	participantesByEdad(beneficiarios){
		return this.utilService.getRangosEdades().map( (edad, index) => {
			return [
			edad.rango,
			beneficiarios.filter( p => {
				return p.age >= edad.min && p.age <= edad.max
			}).length]

		});	
	}



	participantesByPoblacion(beneficiarios){
		return this.utilService.getPoblacion().map( (poblacion, index) => {
			switch (poblacion.id) {
				case 1:
				beneficiarios.filter( p => {
					return p.exist_victim == 1
				})
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_victim == 1).length]
				case 2:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_united == 1).length]
				case 3:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.score_sisben != null && p.score_sisben != '').length]
				case 4:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_victim == 1 && p.exist_united == 1).length]
				case 5:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_united == 1 && p.score_sisben != null).length]
				case 6:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_victim == 1 && p.score_sisben != null).length]
				case 7:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_victim == 1 && p.score_sisben != null && p.exist_united == 1).length]
			}
			
		});	
	}

	participantesByPriorizacion(beneficiarios){
		return this.utilService.getCriteriosPriorizacion().map( (poblacion, index) => {
			switch (poblacion.id) {
				case 1:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_jea == 1).length]
				case 2:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.mfa_exist == 1).length]
				case 3:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_united == 1).length]
				case 4:
				return [
				poblacion.criterio,
				beneficiarios.filter( p => p.exist_victim == 1).length]
			}
			
		});	


	}

	participantesByRangoSisben(beneficiarios){
		return this.utilService.getRangosSisben().slice(1).map( (sisben, index) => {

			return [
			sisben.rango,
			beneficiarios.filter( p => {
				if(p.score_sisben)
					return p.score_sisben >= sisben.min && p.score_sisben <= sisben.max

				return false;
			}).length]
		});	
	}

	participantesByNivelEducativo(beneficiarios){
		if(this.dropsOptions.niveleducativo)
			return this.dropsOptions.niveleducativo.map( (nivel, index) => {
				return [
				nivel.name_education_level,
				beneficiarios.filter( p => p.education_level_id == nivel.id).length]
			});	

		return null
	}

	participantesByEstadoCivil(beneficiarios){
		if(this.dropsOptions.estadocivil)
			return this.dropsOptions.estadocivil.map( (nivel, index) => {
				return [
				nivel.name_civil_status,
				beneficiarios.filter( p => p.civil_status_id == nivel.id).length]
			});	

		return null;
	}

	setPage(page: number){
	    if(page < 1 || page > this.pager.totalPages){
	      return
	    }
	    this.pager = this.pagerService.getPager(this.historial.length, page, 10);
	    this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	exportData(){

		this.graphs.map( g => {
			if(this[g])
				this[g].exportData();	
		})

		if(this.name_pdf.nativeElement.value.length <= 0){
			this.validInput = false;
		}else{
			this.validInput = true;
			this.loadPage = true;
			setTimeout( _ => {

				let form = <HTMLFormElement> document.getElementById('form-reporte');
				this.analyticsService.eventAction("Reportes", "ExportarExcel");
				this.name_pdf.nativeElement.value = this.name_pdf.nativeElement.value.replace(/ /g,"_");
				form.submit();
				this.loadPage = false;
				this.name_pdf.nativeElement.value = ''
				this.comentario_pdf.nativeElement.value = ''
			}, 3000)

		}
	}

}
