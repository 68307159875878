import { Component, OnInit, Input, Output, forwardRef } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';

@Component({
	selector: 'app-multiple-select-box',
	templateUrl: './multiple-select-box.component.html',
	styleUrls: ['./multiple-select-box.component.css'],
	providers: [
	{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => MultipleSelectBoxComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => MultipleSelectBoxComponent),
		multi: true,
	}]
})
export class MultipleSelectBoxComponent implements OnInit, Validator, ControlValueAccessor  {

	@Input() source: any;
	@Input() name_option:string;
	@Input('value') _value;

	onChange: any = () => {};
	onTouched: any = () => {};
	optionSource: any = [];
	listOptions: any = [];
	model:any = {};
	

	constructor() { }

	ngOnInit() {
		this.optionSource = this.source;
		console.log(this.optionSource)
		if(this._value)
			this._value.split(',').map( item => this.addToList(item));
		
		this.value = this._value;
	}

	get value(){
		return this._value;
	}

	set value(val){
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	writeValue(value){
		if(value){
			this.value = value;
		}
	}

	registerOnChange(fn: any){
		this.onChange = fn;
	}

	registerOnTouched(fn: any){
		this.onTouched = fn;
	}

	public validate(c: FormControl){
		return (this._value) ? null : {
			inputError: {
				valid: false,
			},
		};
	}

	addToList(item){
		console.log(item)
		if(item){
			let option = this.source.filter( opt => opt.id == item);
			let list = this.listOptions.filter( opt => opt.id == item);

			if(list.length <= 0){
				this.optionSource = this.optionSource.filter(opt => opt.id != item);
				this.listOptions.push(option[0]);
				this.value = this.listOptions.map( opt => opt.id).join(',')
			}

		}

	}

	delToList(item){
		let option = this.source.filter( opt => opt.id == item);
		this.optionSource.push(option[0]);
		this.listOptions = this.listOptions.filter(opt => opt.id != item).sort( (a,b) => a.id - b.id);
		this.value = this.listOptions.map( opt => opt.id).join(',')
	}


}
