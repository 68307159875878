import { Component, OnInit, Input, Output, OnChanges, SimpleChanges,EventEmitter } from '@angular/core';
import { AppSettings } from '../../appSettings';
import  { CompressImage } from './CompressImage'; 


import * as AWS from 'aws-sdk'; 


@Component({

  selector: 'file-uploader-s3',
  templateUrl: './file-uploader-s3.component.html',
  styleUrls: ['./file-uploader-s3.component.css']
})

export class FileUploaderS3Component implements OnInit, OnChanges {

  @Input() dropZone: boolean;
  @Input() sendData: boolean;
  @Input() autoSend: boolean;
  @Input() disabled: boolean;
  @Input() canDelete: boolean;
  @Input() showImage: boolean = false;
  @Input() link_image: string;
  @Input() name_input: string;
  @Input() alert_message:string;
  @Input() warrning_input;
  @Input() isFileDeleted: boolean;
  @Output() private allowed_extensions: Array<string> = [];
  @Output() emiterSubmit = new EventEmitter();
  @Output() emiterDelete = new EventEmitter();
  @Output() emiterLoading= new EventEmitter();
  @Output() emiterFillFile= new EventEmitter();

  private S3:AWS.S3 = null;
  private fileList : any = [];
  private imgList: any = [];
  private linkList: any = [];
  private img_source: any;
  private invalidFiles : any = [];
  private multiple: boolean = true;
  private is_uploading : boolean;
  private complete_upload : boolean;
  private file_name: string = 'Seleccione archivo';
  private is_fill_file: boolean = false;
  private progress_upload: number = 0;
  //private END_POINT_S3 = 'https://s3.amazonaws.com/' + AppSettings.BUCKET_S3 + '/'
  private END_POINT_S3 = 'https://s3.us-east-2.amazonaws.com/' + AppSettings.BUCKET_S3 + '/'
  
  constructor() { 
    AWS.config.update({
      credentials: new AWS.Credentials(AppSettings.API_KEY_S3, AppSettings.API_PRIVATE_KEY_S3),
      region: 'us-east-2'
    })
    
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes.link_image){
      if(changes.link_image.currentValue){
        this.is_fill_file = true;
        this.file_name = changes.link_image.currentValue;
        this.link_image = changes.link_image.currentValue;
        this.img_source = this.link_image;

      }  
    }
    
    if(changes.sendData){
      if(changes.sendData.currentValue){
        this.onSendFiles();
      }   
    
    }

    if(changes.isFileDeleted){
      if(changes.isFileDeleted.currentValue){
        this.clearInput();
      } 
    }
    
  }

  ngOnInit() {

    if(!this.file_name == undefined){
      this.is_fill_file = true;
      this.file_name = this.link_image;
      this.link_image = this.link_image;
      this.img_source = this.link_image;
    }
  }

  onDeleteFileEmmit(event){
    this.emiterDelete.emit({delete:true});
   
  }

  onChangeHandler(fileInput: any, allowed_extensions){

    var files = fileInput.target.files;
    
    for(var file of files){
      var ext = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
      if(allowed_extensions.lastIndexOf(ext) != -1){
        this.fileList = [file];
        if(this.autoSend){
          this.onSendFiles();
          return;      
        }else{
          this.file_name = file.name;
        }

        Promise.all(Array.prototype.slice.call(this.fileList, 0).map(this.readFileAsImage))
        .then(data => {this.img_source = data; this.is_fill_file = true; this.emiterFillFile.emit(true)});

      }else{
        this.invalidFiles.push(file);
      }
    }
  }

  onDropHandler(fileList: Array<File>){
    this.fileList = fileList

    if(this.autoSend){
      this.onSendFiles();
      return;      
    }

    Promise.all(Array.prototype.slice.call(this.fileList, 0).map(this.readFileAsImage))
    .then(data => {this.imgList = data; this.is_fill_file = true});
  }

  onInvalidDropHandler(fileList: Array<File>){
    this.invalidFiles = fileList;
  }

  onSendFiles(){
    let self = this;


    if(this.fileList.length > 0){
      this.file_name = 'Subiendo archivo';
      this.is_uploading = true;
      this.emiterLoading.emit(true);
      

      Promise.all(Array.prototype.slice.call(this.fileList, 0).map(this.uploadFile, this))
      .then(data => {
        if(!this.dropZone){
          this.file_name = 'Archivo subido con exito';
          this.complete_upload = true;

          setTimeout(() => {
            self.fileList = [];
            self.file_name = data[0]['s3_link'];
            self.link_image = data[0]['s3_link']
            self.is_fill_file = true;
            self.is_uploading = false;
            self.complete_upload = false;
            self.emiterSubmit.emit({url: this.link_image , name: this.file_name });
          }, 3000);

          
        }
        return 
      });
    }else{
      this.is_uploading = false;
      this.emiterLoading.emit(false);
    }

    
  }  

  onDeleteFile($event, key){
    if (key > -1) {
      this.fileList.splice(key, 1);
      this.imgList.splice(key, 1);
    }

    
  }

  clearInput(){
    this.is_fill_file = false;
    this.file_name = 'Seleccione archivo';
  }

  readFileAsImage(file: File){

    if(file.type == "application/pdf"){
      return new Promise((resolve, reject) => {
        resolve(file)
      })
    }else{
      var max_size_image = 1024;
    var compress = new CompressImage(file, max_size_image);


    return new Promise((resolve, reject) =>{
      compress.getImage().then( f => {
        var reader = new FileReader();
        reader.onloadend = (e) => resolve(reader.result);
        reader.onerror = e => reject(e);
        reader.readAsDataURL(<Blob>f);
      });
    });
    }

    
  }

  sendFile(f, resolve, reject, self){
    var params = {
      Bucket: AppSettings.BUCKET_S3, 
      Key: Date.now() + f['name'] , 
      ContentType: f['type'], 
      Body: f, 
      ServerSideEncryption: 'AES256',
      ACL: 'public-read'
    };

    var S3 = new AWS.S3();

    var request = S3.putObject(params)

    request.on("httpUploadProgress", (progress) => {
      this.progress_upload = (progress.loaded * 100) / progress.total;
    });

    request.on("complete", (response) => {
      if(response.error){
        reject(response.error);
        return;
      }

      resolve({
        s3_link: self.END_POINT_S3 + params.Key,
        name: params.Key
      });
    });

    request.send()

  }

  uploadFile(file: File){

    var self = this;

    var compressImaHandler = (file) => {
      var max_size_image = 1024;
      var compress = new CompressImage(file, max_size_image);
      return compress.getImage();
    }
    
    return new Promise((resolve, reject) => {
      if(file.type == "application/pdf"){
        this.sendFile(file, resolve, reject, self);
      } else {
        compressImaHandler(file).then( f => {
          this.sendFile(f, resolve, reject, self);
        }).catch( err => console.log(err))
      }
    });
  }
}
