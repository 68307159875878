import { Component, OnInit, ViewChild } from '@angular/core';
import { OperadorDataService, VerificadorService, PagerServicesService } from '../../../services';
import { AppSettings } from './../../../appSettings';
import { UtilService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-descargar-permanencia',
	templateUrl: './descargar-permanencia.component.html',
	styleUrls: ['./descargar-permanencia.component.css']
})
export class DescargarPermanenciaComponent implements OnInit {

	@ViewChild('link_zip') link_zip;
	model: any = {};
	historial: any = [];
	historial_paged: any;
	pager: any = {};
	loadPage:boolean = false;
	link_action: string;
	token:string;
	type_permanencia:any;
	month:any;

	constructor(private operadorService: OperadorDataService,
		private utilService: UtilService,
		private verificadorService: VerificadorService,
		private pagerService: PagerServicesService,
		private router: Router, 
		private route: ActivatedRoute) { }

	ngOnInit() {

		this.route.params
		.subscribe(p => {
			this.type_permanencia = p.id;
			this.month = (this.type_permanencia == 1) ? 3 : 6;
			
			this.utilService.getHistoryPermanencia(p.id)
			.subscribe( data => {
				if(data.error)
					return;
				this.historial = data.history;
				this.setPage(1);

			})
		})

		
	}

	setPage(page: number){
		if(page < 1 || page > this.pager.totalPages){
			return
		}
		this.pager = this.pagerService.getPager(this.historial.length, page, 10);
		this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	downloadPila(){
		this.loadPage = true;
		this.verificadorService.getExcelPermanencia(this.type_permanencia).subscribe( data => {
			if(data.error){
				alert(data.msg);
				this.loadPage = false;
				return;
			}

			this.link_zip.nativeElement.href = data.link
			this.link_zip.nativeElement.click()
			this.loadPage = false;
		});
	}

}
