import { DatePipe } from '@angular/common'

export class Hito {
  id:number;
  capacidad: number;
  date: string;
  milestone: number;
  funnel_phase_id: number;
  porcentage_success: number;

  constructor(values: Object = {}){
    Object.assign(this, values);
    this.date = new DatePipe('en-US').transform(this.date, 'yyyy-MM-dd');
  }
}
