export const HISTORY_PERMANENCIA = [
	{
		size: "12",
		label: "EMPLEO",
		value: "title",
		type: 'text',
		title: true
	},

	{
		size: "12",
		label: "MES",
		value: "mes",
		type: 'text'
	},
	
	{
		size: "6",
		label: "CERTIFICADO LABORAL",
		value: "file_certificate_per",
		type: 'document',
		outside:true
	},
	{
		size: "6",
		label: "CERTIFICADO APORTE EMPLEADO",
		value: "file_contribution_per",
		type: 'document',
		outside:true
	},
	{
		size: "12",
		label: "¿CONTINUA EMPLEADO?",
		value: "continua_empleado",
		type: "boolean"
	},
	{
		size: "12",
		label: "MOTIVO",
		value: "reason",
		type: "text"
	},

	{
		size: "12",
		label: "FECHA DE FINALIZACIÓN CONTRATO/DESPIDO/RENUNCIA",
		value: "contract_end_date",
		type: "text"
	},
	{
		size: "12",
		label: "CUMPLIÓ CON LAS EXPECTITIVAS ESPERADAS EN TERMINOS DE PRODUCTIVIDAD",
		value: "expectation",
		type: "boolean"
	},
	{
		size: "12",
		label: "CUMPLIÓ CON LOS HORARIOS ESTABLECIDOS",
		value: "schedules",
		type: "boolean"
	},
	{
		size: "12",
		label: "SU COMPORTAMIENTO ERA ADECUADO EN TERMINOS DE SUBORDINACIÓN",
		value: "subordination",
		type: "boolean"
	},
	{
		size: "12",
		label: " OTRO ¿CUÁL?",
		value: "other_company",
		type: "text"
	},
	{
		size: "4",
		label: "ESTABA A GUSTO CON LA EMPRESA",
		value: "satisfaced_company",
		type: "boolean"
	},
	{
		size: "4",
		label: "EL HORARIO Y SALARIO COMPENSABAN EL TRABAJO",
		value: "salary",
		type: "boolean"
	},
	{
		size: "4",
		label: "LE GUSTO EL AMBIENTE DE TRABAJO",
		value: "environment_working",
		type: "boolean"
	},
	{
		size: "12",
		label: "OTRO ¿CUÁL?",
		value: "other_competitor",
		type: "text"
	},
	{
		size: "4",
		label: "NIVEL DE SATISFACCIÓN DEL PARTICIPANTE",
		value: "satisfaction_competitor",
		type: "text"
	},
	{
		size: "4",
		label: "NIVEL DE SATISFACCIÓN DE LA EMPRESA",
		value: "satisfaction_company",
		type: "text"
	},
	{
		size: "4",
		label: "RIESGO DE DESERCIÓN LABORAL",
		value: "desertion_risk",
		type: "text"
	},
	{
		size: "12",
		label: "OBSERVACIONES",
		value: "observation",
		type: "text"
	},

]

