export class EstadosParticipantes {
	id:number;
	estado:string;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getData(){
		return [
		{ id:1, estado:'PREINSCRITOS'},
		{ id:2, estado:'INCLUIDOS KOKAN'},
		{ id:3, estado:'NO INCLUIDOS KOKAN'},
		{ id:4, estado:'ELEGIBLES DELOITTE'},
		{ id:5, estado:'NO ELEGIBLES DELOITTE'},
		].map( estado => new EstadosParticipantes(estado));
	}

	static getItem(id){
		return EstadosParticipantes.getData().filter( estado => estado.id == id)[0];
	}
}
