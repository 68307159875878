import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppSettings } from './../../appSettings';



@Component({
	selector: 'app-tabla-alertas',
	templateUrl: './tabla-alertas.component.html',
	styleUrls: ['./tabla-alertas.component.css']
})
export class TablaAlertasComponent implements OnInit {

	@Input() operador_data;
	@Input() date_interval;
	@Input() type_form;
	@Input() link_form: string;


  	@ViewChild('interval') interval;
  	@ViewChild('operators') operators;
  	@ViewChild('type') type;
	@ViewChild('formExcel') formExcel;

	@Output('onExport') export = new EventEmitter();
  	

	_link_form: string;
	total_list: any = [];
	operador_table: any = [];
	all_operators: any;
	token:string;
	arr_colors = ['', '#f1c154', '#d53e33'];

	constructor() { }

	ngOnInit() {

		this.token = localStorage.getItem('session_id')

		this._link_form = AppSettings.URL_BACKEND + this.link_form;
		this.total_list = this.date_interval.map( item => []);

		this.operador_data =  Object.assign([], this.operador_data).map( (item, index) => {
			item.alertas.push(item.alertas.reduce( (prev, curr) => prev + curr ))
			item.index = index;
			return item;
		});

		this.all_operators = Object.assign([], this.operador_data).map(item => item.id_operador).join(',');

		console.log(this.all_operators)

		for(let operador of this.operador_data){
			for(let date in this.date_interval){
				this.total_list[date].push(operador.alertas[date]);
			}
		}

		this.total_list = this.total_list.map( item => item.reduce( (prev, curr) => prev + curr ));
	}

	showOperador(event, operador){
		if(event.target.checked){
			this.operador_table.push(operador)
		}else{
			let index = this.operador_table.indexOf(operador);
			this.operador_table.splice(index, 1)
		}
		this.operador_table = this.operador_table.sort( (a,b)  => a.index - b.index)
	}


	downloadAlert(interval, operador = null){
		this.interval.nativeElement.value = interval;
		this.operators.nativeElement.value = operador;
		this.type.nativeElement.value = this.type_form;
		this.export.emit(true);
		this.formExcel.nativeElement.submit();
	}

}
