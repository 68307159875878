import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';


@Injectable()
export class VerificadorService {

  constructor(private http: Http) { }

  sendVerificacionInscripcion(id_beneficiario, data_verificador){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('user_id', id_beneficiario)
    data.append('empleo', data_verificador.criterio_elegibilidad)
    data.append('edad', data_verificador.criterio_edad)
    data.append('no_inclusion', data_verificador.criterio_programas)
    data.append('sisben', data_verificador.criterio_poblacion.list.criterio_poblacion_1)
    data.append('red_unidos', data_verificador.criterio_poblacion.list.criterio_poblacion_2)
    data.append('victimas', data_verificador.criterio_poblacion.list.criterio_poblacion_3)
    data.append('bachiller', data_verificador.criterio_bachiller)
    data.append('observation', data_verificador.observacion)
    data.append('comment', data_verificador.comentario)

    return this.http.post(AppSettings.URL_BACKEND + 'index/validacion_verificador', data)
    .map((response: Response) => response.json());

  }

  sendVerificacionFormacion(id_beneficiario, type_verificador, observacion){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('user_id', id_beneficiario)
    data.append('type_fulfill_id', type_verificador)
    data.append('observation', observacion)

    return this.http.post(AppSettings.URL_BACKEND + 'index/validacion_verificador_formacion', data)
    .map((response: Response) => response.json());

  }

  sendUserKokan(id_beneficiario, user_kokan){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('user_id', id_beneficiario)
    data.append('user_kokan', user_kokan)

    return this.http.post(AppSettings.URL_BACKEND + 'index/user_kokan', data)
    .map((response: Response) => response.json());    
  }

  sendNotificacion(id_beneficiario, message, type){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('user_id', id_beneficiario)
    data.append('msg', message)
    data.append('type', type)

    return this.http.post(AppSettings.URL_BACKEND + 'index/notificacion', data)
    .map((response: Response) => response.json());   
  }

  getVerificacionInscripcion(id_beneficiario){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('user_id', id_beneficiario)

    return this.http.post(AppSettings.URL_BACKEND + 'index/ver_validacion_verificador', data)
    .map((response: Response) => response.json());
  }


  getHistorialPila(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)

    return this.http.post(AppSettings.URL_BACKEND + 'index/view_history_certificado', data)
    .map((response: Response) => response.json());
  }

  getHistorialVerificacion(user_id){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('user_id', user_id)

    return this.http.post(AppSettings.URL_BACKEND + 'index/history_user', data)
    .map((response: Response) => response.json()); 
  }

  getExcelPila(users = null){
    var session_id = localStorage.getItem('session_id');
    var users_string = (users != null) ? users.join(',') : "";

    var data = {"token": session_id, "users":users_string};

    

    return this.http.get(AppSettings.URL_BACKEND + 'index/generate_excel_certificate', {search: data})
    .map((response: Response) => response.json());
  }

  getExcelPreinscritosPila(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)

    return this.http.post(AppSettings.URL_BACKEND + 'RegisterKokanOnline', data)
    .map((response: Response) => response.json()); 
  }

  getExcelPermanencia(type_permanencia, users = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    var users_string = (users != null) ? users.join(',') : "";
    //data.append('token', session_id)
    data.append('permanencia', type_permanencia)
    data.append('users', users_string)

    

    console.log(users_string)
    // var data = {
    //   //token: session_id,
    //   users:users_string,
    //   permanencia:type_permanencia
    // }

    return this.http.post(AppSettings.URL_BACKEND + 'index/generate_excel_certificate_perm',  data)
    .map((response: Response) => response.json()); 
  }

  getNotificationUser(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)

    return this.http.post(AppSettings.URL_BACKEND + 'index/getNotificacionUser', data)
    .map( (response:Response) => response.json() ); 
  }

  getIssuesParticiantes(etapa=null, operador=null, stage_id=null, documento=null, page=0){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('etapa', etapa)
    data.append('operator', operador)
    data.append('stage_id', stage_id)
    data.append('document', documento)
    data.append('limit', isNaN(page) ? '15' : String(page))

    return this.http.post(AppSettings.URL_BACKEND + 'index/notificaciones', data)
    .map( (response:Response) => response.json() );  
  }

  setStatusNotification(phase_id, user_id){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('phases_id', phase_id)
    data.append('user_id', user_id)

    return this.http.post(AppSettings.URL_BACKEND + 'index/changeStatusNotificacionOperator', data)
    .map( (response:Response) => response.json() );   
  }

   archiveStatusNotification(id_notification){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('id', id_notification)

    return this.http.post(AppSettings.URL_BACKEND + 'index/changeStatusNotificacionAdmin', data)
    .map( (response:Response) => response.json() );   
  }

  getIndicadores(filters = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    if(filters){
      if(!(filters.bis1a && filters.bis1b)){
        data.append('bis1a', filters.bis1a ? filters.bis1a :null)
        data.append('bis1b', filters.bis1b ? filters.bis1b :null)  
      }
    }
    

    data.append('token', session_id)

    return this.http.post(AppSettings.URL_BACKEND + 'index/indicadores', data)
    .map( (response:Response) => response.json() ); 
  }



  

}