import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../../struct';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuariosService, AnalitycsService } from '../../../services';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})
export class EditarUsuarioComponent implements OnInit {

	id_usuario:number;
	model: any = {};
  usuario: Usuario;
	loadPage = false;

  constructor(private userService: UsuariosService,
  				private router: Router,
  				private route: ActivatedRoute,
          private analyticsService: AnalitycsService) { }

  ngOnInit() {
  	this.id_usuario = this.route.params['value'].id;
  	this.userService.getUserById(this.id_usuario)
  		.subscribe( data => {
        this.usuario = new Usuario(data.userxoperatorid[0]);
        this.model = this.usuario
        this.model['new_user'] = this.usuario.username;
        this.model['new_password'] = '';
  		});
  }

  onSubmitUsuario(){
  	var usuario = new Usuario({
      username: this.model.new_username,
      password: this.model.new_password,
      id_userxoperator: this.model.id
    });



  	this.loadPage = true;

  	this.userService.addUsuario(usuario)
  		.subscribe( data => {
  			if(data.error){
  				console.log(data.msg);
  				return;
  			}

  			this.router.navigate(['/usuarios']);
        this.analyticsService.eventAction('Usuario', 'EditarUsuario');
  			this.loadPage = false;
  		});

  }

}
