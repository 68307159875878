import { Injectable } from '@angular/core';
declare var ga: Function;

@Injectable()
export class AnalitycsService {

  constructor() { }

  pageView(url){
    ga('set', 'page', url);
    ga('send', 'pageview');

  }

  eventLogin(user){
  	this.sendEvent('Sesion', 'InicioSesion', user);
  }

  eventLogout(user, time){
  	this.sendEvent('Sesion', 'CerrarSesion', user, Math.round(time));
  }

  eventLink(page, user, time){
    this.sendEvent('Pagina', page, user, Math.round(time));
  }

  eventButton(tab, user){

    let time = 0;
    if(localStorage.getItem("time_page")){
      time = (new Date().getTime() - parseInt(localStorage.getItem("time_page")))/1000/60;
    }

    localStorage.setItem('time_page', new Date().getTime().toString());
    this.sendEvent('Seccion', tab, user, Math.round(time));
  }

  eventAction(cat, action){
    console.log(cat, action, localStorage.getItem('username'))
    this.sendEvent(cat, action, localStorage.getItem('username'));
  }


  sendEvent(category, acction, tag, value = 0){
  	ga('send', 'event', category, acction, tag, value);
  }

}
