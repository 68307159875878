import { Component, Input, Output, Injector, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Http  } from '@angular/http';

import { QuestionBase } from '../question-base';




@Component({
  selector: 'df-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent implements OnChanges {

  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  @Output() changeHandler = new EventEmitter();
  @Output() clickBindHandler = new EventEmitter();



  ngOnChanges(changes){
  }
  
  get isValid() {  
  	if(!this.form.controls)
  		return false;

  	return this.form.controls[this.question.key].valid || this.form.controls[this.question.key].disabled; 
  }

  onClickBinding(event){
    if(this.question['action']){
      this.clickBindHandler.emit({
        type:this.question.controlType,
        index: this.question['index'],
        input: this.question['inputs'],
        action: this.question['action'],
      })
    }
  }

  onChangeDrop($event, input_in){

    if(this.question['serviceSource'] || this.question['action']){
      console.log(this.question)
      let value = this.form.controls[input_in].value;
      this.changeHandler.emit({
        type:this.question.controlType,
        value:value,
        valueEnabled: this.question['valueToEnabled'],
        output: this.question['outputInput'],
        outputValue: this.question['outputValue'],
        input: this.question['inputInput'],
        clean: this.question['cleanInput'],
        service: this.question['serviceSource'],
        action: this.question['action'],
        source:this.question['outputSource'],
        restrict:this.question['restrictInput'],
        only_disabled:this.question['onlyDisabled']
      })
    }
  }


  onClickCheck(event, input_in){
    let value = this.form.controls[input_in].value;

    this.changeHandler.emit({
        type:this.question.controlType,
        value:value,
        check:event.target.checked,
        action:this.question['action'],
        out_input:this.question['outputInput'],
        in_input:this.question['inputInput'],
        only_disabled:this.question['onlyDisabled']

    })
  }


}
