import { Component, Input, Output, EventEmitter,  OnInit } from '@angular/core';
import { VerificadorService  } from '../../services';
import * as _ from 'underscore';

@Component({
	selector: 'app-criterios-colocacion',
	templateUrl: './criterios-colocacion.component.html',
	styleUrls: ['./criterios-colocacion.component.css']
})
export class CriteriosColocacionComponent implements OnInit {

	
	criterios:any = [];
	model:any = {};
	currents_values:any = {};
	can_save:boolean = true;
	loader:boolean = true;
	status:number ;

	@Input() id_beneficiario:any;
	@Output() out_status = new EventEmitter();

	constructor(private verificadorService:VerificadorService) { }

	ngOnInit() {
		this.criterios = [
		{id: 1, description: 'Cumple'},
		{id: 2, description: 'No Cumple'},
		{id: 3, description: 'Pendiente'}
		]

		this.loader = false;
	}

	onChange(event){
		this.getStatus();
	}

	onSubmitList(event){

		console.log(this.status)

		let message;
		switch (this.status) {
			case 1:
			case 5:
			message = 'El participante cumple los requisitos!'
			break;
			case 2:
			message = 'El participante no cumple los requisitos!'
			break;
			case 3:
			case 4:
			message = 'El participante pendiente los requisitos!'
			break;

			default:
			break;
		}


		if(confirm(message)){
			// this.verificadorService.sendVerificacionInscripcion(this.id_beneficiario, this.model)
			// .subscribe( data => {
			// 	if(data.status_deliotte.length > 0){
			// 		this.status = data.status_deliotte[0].type_fulfill_id
			// 		this.can_save = !(this.status == 2 || this.status == 5);
			// 		this.out_status.emit({status:data.status_deliotte[0].name_type_fulfill})
			// 	}
			// })
			console.log(this.model)

			alert('enviado')
		}



	}

	getStatus(){
		let statuses = [];

		for(let d in this.model){

			if(this.model[d] != null){
				if(typeof(this.model[d]) == 'object'){

					if(this.model[d].result < 0)
						statuses.push(2)
					else
						statuses.push(this.model[d].result)
				}else{
					if(typeof(this.model[d]) != 'string'){
						statuses.push(this.model[d])  
					}

				}
			}

		}

		let _statuses = _.uniq(statuses)

		console.log(_statuses)

		if(_statuses.indexOf(2) > -1){
			this.status = 2
		}else if (_statuses.indexOf(3) > -1){
			this.status = 3
		}else{
			this.status = 1;
		}

		console.log(this.model)
	}

}
