export const FORM_COLOCACION = [
	{
		"key":"opcion_si_no",
		"label":"1. ¿EL PARTICIPANTE FIRMÓ CONTRATO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"boolSource",
		"optional":"true",
		"verify":true,
		"editable":true
	},
	{
		"key":"type_contract_id",
		"label":"2. TIPO DE CONTRATO FIRMADO",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source": "tipo_contrato",
		"optional":"true",
		"action":"enabledOnValue",
		"valueToEnabled": 1,
		"cleanInput":"duration_contract",
		"outputInput":"date_finish",
		"inputInput":"contract_end",
		"restrictInput":"contract_end",
		"verify":true,
		"editable":true

	},
	{
		"key":"date_start",
		"label":"3. ¿CUÁL ES LA FECHA DEL CONTRATO FIRMADO?",
		"size":"6",
		"type":"date",
		"controlType":"textbox",
		"optional":"true",
		"verify":true,
		"disabled":false,
		"editable":false
	},
	{
		"key":"date_finish",
		"label":"4. ¿CUÁL ES LA FECHA DE FINALIZACIÓN DEL CONTRATO?",
		"size":"6",
		"type":"date",
		"controlType":"textbox",
		"optional":"true",
		"verify":false,
		"editable":true
	},
	{
		"key":"salary_contract",
		"label":"5. ¿CUÁL ES EL SALARIO DEL CONTRATO?",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"pattern":"currency",
		"verify":true,
		"editable":true
	},
	{
		"key":"duration_contract",
		"label":"DURACIÓN TOTAL DEL CONTRATO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":false,
		"editable":true
	},
	{
		"key":"range_duration_contract",
		"label":"RANGO DURACIÓN TOTAL DEL CONTRATO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":true
	},
	{
		"key":"contract_end",
		"label":"6. FINALIZAR CONTRATO",
		"size":"3",
		"controlType":"checkbox",
		"optional":"true",
		"action":"enabledInput",
		"inputInput":["date_finish"].join(''),
		"outpuInput":[""].join(''),

	},

	{
		"key":"label_colcacion",
		"label":"",
		"size":"12",
		"controlType":"label",
	},

	{
		"key":"person_charge",
		"label":"7. NOMBRE DE LA PERSONA DE CONTACTO EN LA EMPRESA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"person_position",
		"label":"8. CARGO DE LA PERSONA DE CONTACTO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"phone_person",
		"label":"9. TELÉFONO DE LA PERSONA DE CONTACTO ",
		"size":"6",
		"type":"phone",
		"controlType":"textbox",
		"pattern":"phone",
		"verify":true,
		"editable":true
		
	},
	{
		"key":"authorized",
		"label":"Señor Operador usted certifica que el participante ha cumplido con la totalidad del esquema de intervención de acuerdo al anexo técnico del contrato firmado entre su organización y Fundación Corona? SI / NO ",
		"size":"12",
		"controlType":"checkbox",
		"optional":"true",
		"disabled":"true",
		"hidden":true,
		"visible": false
	},
	{
		"key":"comment",
		"label":"Comentario",
		"size":"12",
		"controlType":"textarea",
		"optional":"true",
		"disabled":"true"
	}
	// {
	// 	"key":"ajuste_verificador",
	// 	"label":"¿Señor Operador usted confirma que los ajustes solicitados por el verificador quedaron resueltos de manera satsifactoria?",
	// 	"size":"12",
	// 	"controlType":"checkbox",
	// 	"optional":"true",
	// },
	]