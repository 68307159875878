import { Hito } from './hito'

export class Operador {
  id: number;
  name_operator: string;
  nit: string;
  address:string;
  numero_tel: string;
  number_cell_phone: string;
  email: string;
  goal_success: number;
  participant_value: number;
  hitos: Hito[];

  constructor(values: Object = {}){
    Object.assign(this, values);
  }
}