import { Component, OnInit } from '@angular/core';
import { Operador, Hito } from '../../../struct';
import { OperadorDataService, AnalitycsService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';
import { UtilService } from '../../../services/util.service' ;

@Component({
  selector: 'app-editar-operador',
  templateUrl: './crear-operador.component.html',
  styleUrls: ['./crear-operador.component.css']
})
export class EditarOperadorComponent implements OnInit {

  model:any = {}
  title_view:string;
  hitos: Array<Hito> = new Array<Hito>();
  id_operador:number;
  operador: Operador;
  loadPage: boolean = false;
  send_photo: boolean = false;
  milestones: any;
  type_user:any;


  constructor(private operadorService: OperadorDataService,
    private router: Router, 
    private route: ActivatedRoute,
    private utilService: UtilService,
    private analyticsService: AnalitycsService) { }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');
  	this.title_view = 'Editar Operador';
  	this.id_operador = this.route.params['value'].id;
  	
  	if(this.id_operador){
  		this.operadorService.getOperadorById(this.id_operador)
      .subscribe(data => {
        if(data.operator){
          this.hitos = data.hitos.map(hito => new Hito(hito));
          console.log(this.hitos);
          this.operador = new Operador(data.operator)
          this.operador.hitos = this.hitos;
          this.model = this.operador;
        }

      });
    }

    this.utilService.getAllMilestones().subscribe(
      data => {
        this.milestones = data.milestones;
      }
      )

  }

  onSubmitOperador(){
  	var operador = new Operador(this.model);


  	operador.hitos = this.hitos.map(hito => {
      hito['variable_milestone_id'] = hito.funnel_phase_id
      return hito
    });

    console.log(operador.hitos)

    this.loadPage = true;
    this.operadorService.addOperador(operador)
    .subscribe( data => {
      if(data.error){
        console.log(data.msg);
        return;
      }

      this.id_operador = data.id_operator;
      this.send_photo = true;

      this.loadPage = false;
      this.analyticsService.eventAction('Operador', 'EditarOperador');

    })
  }

  onAddHito(event){
  	event.preventDefault();
  	this.hitos.push(new Hito());
  }

  onQuitAddHito(event, index, id_hito){
    event.preventDefault();
    if(confirm('Desea eliminar el Hito')){
      this.operadorService.delHito(this.id_operador, id_hito)
      .subscribe( data => {
        if (index > -1) {
          this.hitos.splice(index, 1);
          alert('El hito fue eliminado con exito');
        }    
      })
    }

    
  }

  submitFile(url_source, name_input){
    console.log('autosen')
    this.operadorService.addFile(url_source.url, name_input, this.id_operador)
    .subscribe( data => {
      this.router.navigate(['/operadores'])
    });
  }



}