import { Component, OnInit } from '@angular/core';
import { PerfilacionService } from '../../../services';
import { GrupoPerfilacion } from '../../../struct';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-crear-grupo',
  templateUrl: './crear-grupo.component.html',
  styleUrls: ['./crear-grupo.component.css']
})
export class CrearGrupoComponent implements OnInit {

  model:any = {};
  groupPerfilacion:GrupoPerfilacion;

  constructor(private perfilacionService:PerfilacionService,
              private router: Router, 
              private route: ActivatedRoute,) { }

  ngOnInit() {
  }

  onCreateGroup(){
  	this.perfilacionService.createGroup(this.model.nombre_grupo, this.model.descripcion)
      .subscribe( data => {
        if(data.error)
            return;

         this.router.navigate(['/grupos'])
      });
  }

  onClose(){
     this.router.navigate(['/grupos'])
  }

}
