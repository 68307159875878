import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { VerificadorService } from '../../services';
import { ChangesAlertService} from '../../shared/changes-alert.service';


@Component({
	selector: 'app-check-changes-verify',
	templateUrl: './check-changes-verify.component.html',
	styleUrls: ['./check-changes-verify.component.css']
})
export class CheckChangesVerifyComponent implements OnInit {

	@Input('fase') phaseId
	@Input('user') userId
	@ViewChild('header') header_alert;
	disabled:boolean;
	

	constructor(private verificadorService:VerificadorService, private alertService: ChangesAlertService) { }

	ngOnInit() {

	}	

	onCheck(evt){
		if(evt.target.checked){
			if(confirm("¿Señor operador usted confirma que los ajustes solicitados por el verificador quedaron resueltos de manera satisfactoria?")){
				this.verificadorService.setStatusNotification(this.phaseId, this.userId)
					.subscribe( data => {
						this.disabled = true;
						alert("Confirmación completa de forma satisfactoria");
						this.alertService.changeAlert(true);
					})
			}else{
				return false;
			}
			
		}
	}

}
