export class RangosSisben {
	id:number;
	rango:string;
	min:number;
	max:number;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getData(){
		return [
		{id:1, rango:'TODOS LOS NIVELES', min:0, max:100},
		{id:2, rango:'SISBEN Nivel 1 (0-11,00)', min:0, max:11},
		{id:3, rango:'SISBEN Nivel 2 (11,01-22,00)', min:11.01, max:22},
		{id:4, rango:'SISBEN Nivel 3 (22,01-41,74)', min:22.01, max:41.74},
		].map( edad => new RangosSisben(edad)); 
	}

	static getItem(id){
		return RangosSisben.getData().filter( rango => rango.id == id)[0];
	}

}
