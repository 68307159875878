import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { FormControl, FormGroup, FormArray, Validators} from '@angular/forms';
import { OperadorDataService, BeneficiarioDataService, UtilService, AnalitycsService } from '../../../../services';
import { Operador, 
  Edades,
  EstadosParticipantes, 
  RangosSisben,
  CriteriosPriorizacion,
  TypeReport,
  RazonesNoElegibilidad,
  Beneficiario
} from './../../../../struct';
import { FilterExcelPipe } from '../../../../pipes/filter-excel.pipe';
import { FilterWrappedService } from '../../../../shared/filter-wrapped.service' ;
import { DropsWrapperService } from '../../../../shared/drops-wrapper.service' ;
import { AppSettings } from '../../../../appSettings';
import * as _ from 'underscore';

@Component({
  selector: 'app-filtros-excel',
  templateUrl: './filtros-excel.component.html',
  styleUrls: ['./filtros-excel.component.css']
})
export class FiltrosExcelComponent implements OnInit {

  typeReport: TypeReport;
  operadores: Operador;
  participantes: Beneficiario[];
  sendParticipantes:any;
  sendInputs: any;
  generos: any;
  edades: Edades[];
  estadosParticipantes: EstadosParticipantes[];
  rangosSisben: RangosSisben[];
  criteriosPriorizacion: CriteriosPriorizacion[];
  razonesNoElegibilidad: RazonesNoElegibilidad[];
  ampliacion = [{id:1, name:'BIS 1A'}, {id:2, name:'BIS 1B'}];
  jefeHogar = [{id:1, name:'SI'}, {id:0, name:'NO'}];
  estudiaActualmente = [{id:1, name:'SI'}, {id:0, name:'NO'}];
  ciudadesOperador = [{ value:1, desc:'Bogotá'},{ value:2, desc:'Pereira'},{ value:3, desc:'Cali'}]
  estados:any;
  filtros: any = {};
  dropsOptions:any = {};
  form: FormGroup;
  url_form:string;
  loadPage:boolean = true;
  invalidRangeHoras:boolean = false;
  invalidRangeSalario:boolean = false;
  token:string;

  constructor(private beneficiarioService: BeneficiarioDataService,
    private operadorService: OperadorDataService,
    private utilService: UtilService,
    private router: Router,
    private filterService: FilterWrappedService,
    private dropService:DropsWrapperService,
    private analyticsService: AnalitycsService) {

  }

  get InputsAmpliacion():FormArray{
    return this.form.get('ampliacion') as FormArray;
  }
  
  get InputsEstados():FormArray{
    return this.form.get('estados') as FormArray;
  }  

  get InputsOperador():FormArray{
    return this.form.get('operadores') as FormArray;
  }

  get InputsCiudad():FormArray{
    return this.form.get('ciudades') as FormArray;
  }

  get InputsJefeHogar():FormArray{
    return this.form.get('jefe_hogar') as FormArray;
  }

  get InputsEstudiaActual():FormArray{
    return this.form.get('estudia_actual') as FormArray;
  }

  get InputsCriteriosPriorizacion():FormArray{
    return this.form.get('criterios') as FormArray;
  }

  get InputsGeneros():FormArray{
    return this.form.get('generos') as FormArray;
  }

  get InputsEstadoCivil():FormArray{
    return this.form.get('estadosciviles') as FormArray;
  }

  get InputsEdades():FormArray{
    return this.form.get('edad') as FormArray;
  }

  get InputsSisben():FormArray{
    return this.form.get('sisben') as FormArray;
  }

  get InputsNoElegibilidad():FormArray{
    return this.form.get('razonNoElegibilidad') as FormArray;
  }

  get InputsNivelesEducacion():FormArray{
    return this.form.get('niveleducativo') as FormArray;
  }

  get InputsResultadoPreformacion():FormArray{
    return this.form.get('resultadopre') as FormArray;
  }

  get InputsResultadoPostformacion():FormArray{
    return this.form.get('resultadopost') as FormArray;
  }

  get InputsFormacionDura():FormArray{
    return this.form.get('formacion_dura') as FormArray;
  }

  get InputsModalidad():FormArray{
    return this.form.get('modalidad') as FormArray;
  }

  get InputsFormacionBlanda():FormArray{
    return this.form.get('formacion_blanda') as FormArray;
  }

  get InputsTipoAcompanamiento():FormArray{
    return this.form.get('tipoacompanamiento') as FormArray;
  }

  get InputsRemision():FormArray{
    return this.form.get('remision') as FormArray;
  }

  get InputsCuidadoPsico():FormArray{
    return this.form.get('cuidadopsicosocial') as FormArray;
  }

  get InputsCierrePsico():FormArray{
    return this.form.get('cierrepsicosocial') as FormArray;
  }

  get InputsAreaOcupacional():FormArray{
    return this.form.get('areaocupacional') as FormArray;
  }

  get InputsTipoContrato():FormArray{
    return this.form.get('tipo_contrato') as FormArray;
  }

  ngOnInit() {
    this.url_form = AppSettings.URL_BACKEND + 'Sabana_excel.xlsx';
    this.token = localStorage.getItem('session_id');

    this.dropService.fullValidateDrop().subscribe( d => this.dropService.addOptions(d));
    this.typeReport = TypeReport.KOKAN;
    this.operadorService.getAllOperadores()
    .subscribe( data => {
    this.operadores = data.operator.map( o => new Operador(o)).filter( o => o.operator_id != 39 && o.operator_id != 51);

     this.utilService.getAllStages().subscribe( data => {
        this.estados = this.createArrayStates(data.stage);
        this.dropService.addOptions$.subscribe( options => {this.dropsOptions = options; this.form = this.buildForm();  this.loadPage = false});
     });


    });

    this.edades = this.utilService.getRangosEdades();
    this.estadosParticipantes = this.utilService.getEstadosParticipantes();
    this.rangosSisben = this.utilService.getRangosSisben();
    this.criteriosPriorizacion = this.utilService.getCriteriosPriorizacion();
    this.razonesNoElegibilidad = this.utilService.getRazonesNoElegibilidad(this.typeReport)

  }

  onSubmitForm(form){


    this.validateRanges(form);

     const f = Object.assign({}, form, {
      ampliacion: form.ampliacion.map((s, i) => { 
        if(s)
          return this.ampliacion[i].id 
      }).filter(s => s != null),

      estados: form.estados.map((s, i) => { 
        if(s)
          return this.estados[i].id 
      }).filter(s => s != null),

      operadores: form.operadores.map((s, i) => { 
        if(s)
          return this.operadores[i].id 
      }).filter(s => s != null),

      ciudades: form.ciudades.map((s, i) => { 
        if(s)
          return this.ciudadesOperador[i].value
      }).filter(s => s != null),

      jefe_hogar: form.jefe_hogar.map((s, i) => { 
        if(s)
          return this.jefeHogar[i].id 
      }).filter(s => s != null),

      estudia_actual: form.estudia_actual.map((s, i) => { 

        if(s)
          return this.estudiaActualmente[i].id 
      }).filter(s => s != null),

      criterios: form.criterios.map((s, i) => { 
        if(s)
          return this.criteriosPriorizacion[i].id
      }).filter(s => s != null),

      generos: form.generos.map((s, i) => { 
        if(s)
          return this.dropsOptions.genero[i].id

      }).filter(s => s != null),

      estadosciviles: form.estadosciviles.map((s, i) => { 
        if(s)
          return this.dropsOptions.estadocivil[i].id
      }).filter(s => s != null),

      edad: form.edad.map((s, i) => { 
        if(s)
          return this.edades[i].id
      }).filter(s => s != null),

      sisben: form.sisben.map((s, i) => { 
        if(s)
          return this.rangosSisben[i].id
      }).filter(s => s != null),

      razonNoElegibilidad: form.razonNoElegibilidad.map((s, i) => { 
        if(s)
          return this.razonesNoElegibilidad[i].id
      }).filter(s => s != null),

      niveleducativo: form.niveleducativo.map((s, i) => { 
        if(s)
          return this.dropsOptions.niveleducativo[i].id
      }).filter(s => s != null),

      resultadopre: form.resultadopre.map((s, i) => { 
        if(s)
          return this.dropsOptions.resultadoconsolidado[i].id
      }).filter(s => s != null),

      resultadopost: form.resultadopost.map((s, i) => { 
        if(s)
          return this.dropsOptions.resultadoconsolidado[i].id
      }).filter(s => s != null),

      formacion_dura: form.formacion_dura.map((s, i) => { 
        if(s)
          return this.dropsOptions.programa_formacion_duro[i].id
      
      }).filter(s => s != null),

      modalidad: form.modalidad.map((s, i) => { 
        if(s)
          return this.dropsOptions.modalidad_form[i].id
      }).filter(s => s != null),

      formacion_blanda: form.formacion_blanda.map((s, i) => { 
        if(s)
          return this.dropsOptions.programa_formacion_blando[i].id
      }).filter(s => s != null),

      tipoacompanamiento: form.tipoacompanamiento.map((s, i) => { 
        if(s)
          return this.dropsOptions.tipoacompanamiento[i].id
      }).filter(s => s != null),

      remision: form.remision.map((s, i) => { 
        if(s)
          return this.dropsOptions.remision[i].id
      }).filter(s => s != null),

      cuidadopsicosocial: form.cuidadopsicosocial.map((s, i) => { 
        if(s)
          return this.dropsOptions.cuidadopsicosocial[i].id
      }).filter(s => s != null),

      cierrepsicosocial: form.cierrepsicosocial.map((s, i) => { 
        if(s)
          return this.dropsOptions.cierrepsicosocial_all[i].id
      }).filter(s => s != null),

      areaocupacional: form.areaocupacional.map((s, i) => { 
        if(s)
          return this.dropsOptions.areocupacional[i].id
      }).filter(s => s != null),

      tipo_contrato: form.tipo_contrato.map((s, i) => { 
        if(s)
          return this.dropsOptions.tipo_contrato[i].id
      }).filter(s => s != null),

    })

    this.sendInputs = f;
    this.sendFilter();
    
  }

  validateRanges(form){
    this.invalidRangeHoras = (form.horas_desde_asignadas != null || form.horas_hasta_asignadas != null)
                                && (form.horas_desde_asignadas == null || form.horas_hasta_asignadas == null);

    this.invalidRangeSalario = ((form.min_salario != null ||  form.max_salario != null) && (form.min_salario > 0 || form.max_salario > 0))
                                && (form.min_salario == null ||  form.min_salario <= 0 ||  form.max_salario == null || form.max_salario <= 0);
  }

  getKeys(obj){
    return _.keys(obj);
  }

  sendFilter(){

    this.loadPage = true;
    setTimeout( _ => {
      let form = <HTMLFormElement> document.getElementById('form-reporte');
      this.analyticsService.eventAction('Sabana', 'DescargarSabana');
      form.submit();
      this.loadPage = false;  
    }, 2000);
  }


  buildForm(){
    let arr = {};
    arr['ampliacion'] = new FormArray(this.createArrayInput(this.ampliacion));
    arr['operadores'] = new FormArray(this.createArrayInput(this.operadores));
    arr['estados'] = new FormArray(this.createArrayInput(this.estados));
    arr['ciudades'] = new FormArray(this.createArrayInput(this.dropsOptions.city));
    arr['jefe_hogar'] = new FormArray(this.createArrayInput(this.jefeHogar));
    arr['estudia_actual'] = new FormArray(this.createArrayInput(this.estudiaActualmente));
    arr['criterios'] = new FormArray(this.createArrayInput(this.criteriosPriorizacion));
    arr['generos'] = new FormArray(this.createArrayInput(this.dropsOptions.genero));
    arr['estadosciviles'] = new FormArray(this.createArrayInput(this.dropsOptions.estadocivil));
    arr['edad'] = new FormArray(this.createArrayInput(this.edades));
    arr['sisben'] = new FormArray(this.createArrayInput(this.rangosSisben));
    arr['razonNoElegibilidad'] = new FormArray(this.createArrayInput(this.razonesNoElegibilidad));
    arr['niveleducativo'] = new FormArray(this.createArrayInput(this.dropsOptions.niveleducativo));
    arr['resultadopre'] = new FormArray(this.createArrayInput(this.dropsOptions.resultadoconsolidado));
    arr['resultadopost'] = new FormArray(this.createArrayInput(this.dropsOptions.resultadoconsolidado));
    arr['formacion_dura'] = new FormArray(this.createArrayInput(this.dropsOptions.programa_formacion_duro));
    arr['modalidad'] = new FormArray(this.createArrayInput(this.dropsOptions.modalidad_form));
    arr['formacion_blanda'] = new FormArray(this.createArrayInput(this.dropsOptions.programa_formacion_blando));
    arr['tipoacompanamiento'] = new FormArray(this.createArrayInput(this.dropsOptions.tipoacompanamiento));
    arr['remision'] = new FormArray(this.createArrayInput(this.dropsOptions.remision));
    arr['cuidadopsicosocial'] = new FormArray(this.createArrayInput(this.dropsOptions.cuidadopsicosocial));
    arr['cierrepsicosocial'] = new FormArray(this.createArrayInput(this.dropsOptions.cierrepsicosocial_all));

    arr['areaocupacional'] = new FormArray(this.createArrayInput(this.dropsOptions.areocupacional));
    arr['tipo_contrato'] = new FormArray(this.createArrayInput(this.dropsOptions.tipo_contrato));
    
    arr['horas_desde_asignadas'] = this.createInput();
    arr['horas_hasta_asignadas'] = this.createInput();

    arr['min_salario'] = this.createInput();
    arr['max_salario'] = this.createInput();

    return new FormGroup(arr);   

  }


  createArrayStates(estados){
    let _estados = estados.map( state => {
      if(state.id == 5)
        state.name_stage = 'Certificado (formación)'

      if(state.id == 2)
          state.name_stage = 'Elegible (estado)'

      
      return state
    })


    console.log(_estados)
    
   _estados = _estados.concat([
        {id:6, name_stage:'Pendiente'},
        {id:37, name_stage:'Elegible en adelante'},
        {id:7, name_stage:'No elegible'},
        {id:8, name_stage:'KOKAN Incluidos'},
        {id:9, name_stage:'KOKAN No Incluidos'},
        {id:21, name_stage:'certificado (colocación)'},
        {id:10, name_stage:'preinscripción'  },

        {id:24, name_stage:'preinscripción (Incompleto)'  },
        {id:11, name_stage:'inscripción'},
        {id:25, name_stage:'inscripción (Incompleto)'},
        {id:12, name_stage:'perfilamiento'},
        {id:13, name_stage:'orientación ocupacional'},
        {id:27, name_stage:'orientación ocupacional (Incompleto)'},
        {id:14, name_stage:'acompañamiento psicosocial'},
        {id:28, name_stage:'acompañamiento psicosocial (Incompleto)'},
        {id:15, name_stage:'estrategias de retención'},
        {id:29, name_stage:'estrategias de retención (Incompleto)'},
        {id:16, name_stage:'pruebas pre-formación'},
        {id:30, name_stage:'pruebas pre-formación (Incompleto)'},

        {id:38, name_stage:'En formación'},
        {id:17, name_stage:'formación'},
        {id:31, name_stage:'formación (Incompleto)'},
        {id:18, name_stage:'pruebas post-formación'},
        {id:32, name_stage:'pruebas post-formación (Incompleto)'},
        {id:40, name_stage:'En intermediación'},
        {id:19, name_stage:'intermediación'},
        {id:33, name_stage:'intermediación (Incompleto)'},
        {id:20, name_stage:'colocación laboral'},
        {id:34, name_stage:'colocación laboral (Incompleto)'},
        {id:22, name_stage:'permanencia 3 meses'},
        {id:35, name_stage:'permanencia 3 meses (Incompleto)'},
        {id:23, name_stage:'permanencia 6 meses'},
        {id:36, name_stage:'permanencia 6 meses (Incompleto)'},
        
        {id:39, name_stage:'Recolocado'},
    ]);

 

   let cut_states = [], cut_i = 0, cut_j = 0, cut_separate = 3, cut_limit = Math.ceil(_estados.length / cut_separate);
   let odd_items;


    console.log(cut_limit, cut_separate)

   if(cut_limit % 2 > 0){
     //cut_limit = cut_limit - 1;  
     //odd_items = true;
   }
   

   for(let i = 0; i < (cut_limit * cut_separate); i++){
     cut_states.push(_estados[(cut_i * cut_limit) + cut_j]);  

     if(cut_i+1 < cut_separate){
       cut_i++;
     }else{
       cut_j = cut_j+1 < cut_limit ?  cut_j+1 : 0;
       cut_i =  0;  
     }  
   }

   console.log(cut_states)

   if(odd_items){
     console.log(_estados[_estados.length - 1])
     cut_states.push(_estados[_estados.length - 1]);
   }

   _estados = cut_states;
   return _estados;
  }


  createArrayInput(object){
    let array = [];
    if(object)
      object.map( (o, index) => {
        array.push(this.createInput())
      }) 

    return array;
  }

  createInput(){
    return new FormControl();
  }

}
