import { Component, Inject, OnInit, Input, Output, EventEmitter, InjectionToken, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from '../question.service';
import { HISTORY_COLOCACION } from '../../../data/history_colocacion';
import { BeneficiarioDataService, VacanteService } from '../../../services';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service';
import * as _ from 'underscore';


const INSERT_COLOCACION:number = 5

@Component({
	selector: 'app-colocacion',
	templateUrl: './colocacion.component.html',
	styleUrls: ['./colocacion.component.css'],
	providers: [QuestionService]
})

export class ColocacionComponent implements OnInit {

	validator = {
		number: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		phone: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		currency: /^[-+]?\d*(?:[\.\,]\d+)?$/,
	};

	title_view: string;
	empresa: any;
	questions:any;
	history:any = [];
	type_user:any;
	id_beneficiary: number;
	listaContratos: any = [];
	s3file: string;
	file_certificate: string;
	file_contribution: string;
	diferenciaDeFechas: any;
	rangosDeFechas: any;
	complete:boolean;
	isVacancyOK:boolean = false;
	isFileDeleted_s3file:boolean = false;
	isFileDeleted_file_certificate:boolean = false;
	isFileDeleted_file_contribution:boolean = false;
	alert:any;
	options_alert:any;
	history_alert:any;
	template_history = HISTORY_COLOCACION;
	nuevo_empleo: boolean;

	openPopUp:boolean;
	fecha_contrato:any;
	canEditDate:boolean;

	
	@Input() enabled_module:boolean;
	@Input() beneficiario:any;
	@Input() comentario:any;
	@Input() disabled:any;
	@Output() loadEnd = new EventEmitter();
	@Output() fullForm = new EventEmitter();
	@Output() loadFile = new EventEmitter();
	@ViewChild('duration_contract') duration_contract;
	@ViewChild('colocacion_form') colocacion_form;
	@ViewChild('panel') panel;

	constructor(private questionsService:QuestionService,
		private beneficiarioService: BeneficiarioDataService,
		private dropService: DropsWrapperService,
		private route: ActivatedRoute,
		private vacantesService: VacanteService) { }

	ngOnInit() {
		this.title_view = "INFORMACIÓN CONTRACTUAL";
		this.type_user = localStorage.getItem('user_type');
		this.id_beneficiary = this.route.params['value'].id;



		this.dropService.addOptions$.subscribe(
			options => this.listaContratos = options['tipo_contrato']);

		this.questionsService.getQuestionsColocacion().map( questions => {
			this.beneficiarioService.getColocacion(this.id_beneficiary).subscribe(
				data => {
					this.loadEnd.emit({ load:true })
					if (data.error) {
						console.log(data.msg)
					} else {
						this.history_alert = data.history_alert;
						
						if(data.details_company_vacancy){
							this.empresa = data.details_company_vacancy[0] ?  data.details_company_vacancy : null;

							if(this.empresa)
								this.empresa.forEach( emp => {
									if(emp.id_status == 7){
										this.empresa = [emp];
									}
								});

						}
						this.nuevo_empleo = data.cambio_empleo;
						this.alert = data.alert;
						this.options_alert = data.options_alert;


						if(data.details_jobplacment){
							this.s3file = data.details_jobplacment.file_contract

							this.file_certificate = data.details_jobplacment.file_certificate
							this.file_contribution = data.details_jobplacment.file_contribution
							

							this.canEditDate = (this.s3file || this.file_certificate) ? false : true;

							questions[0].value = data.details_jobplacment.contract
							questions[1].value = data.details_jobplacment.id_typecontract
							questions[2].value = data.details_jobplacment.date_start
							questions[3].value = data.details_jobplacment.date_finish
							questions[4].value = data.details_jobplacment.salary_contract
							
							questions[7].value = data.details_jobplacment.contract_end				      		

							questions[9].value = data.details_jobplacment.person_charge
							questions[10].value = data.details_jobplacment.person_position
							questions[11].value = data.details_jobplacment.phone_person

							
							
							questions[13].value = this.comentario;


							const {diferenciaDeFechas, rangosDeFechas} = this.getDateDifferenceInMonths(new Date(data.details_jobplacment.date_start), 
								new Date(data.details_jobplacment.date_finish))

							this.diferenciaDeFechas = diferenciaDeFechas;
							this.rangosDeFechas = rangosDeFechas;


							questions[5].value = (questions[1].value == 1) ? '' : this.diferenciaDeFechas;
							questions[6].value = (questions[1].value == 1) ? '' : this.rangosDeFechas;

							//questions[2].disabled = !((questions[2].value == null && (this.file_certificate ||  this.s3file)));
							questions[3].disabled = (questions[1].value == 1 && !(questions[6].value === true));
							questions[7].disabled = !(questions[1].value == 1 && !(questions[6].value === true));

							this.loadEnd.emit({ load:false })
						}

						questions[12].value = data.authorized


						if(this.file_certificate ||  this.s3file){
							if(this.beneficiario.completa_colocacion == 2)
								questions[12].disabled = data.authorized	
						}
						
						

						for(let item in questions){
						  if(questions[item].value)
            				questions[item].disabled = !questions[item].editable;
            			}



            			if(data.details_jobplacment_history.length > 0){

            				
            				let i = 1;
            				for(let job of data.details_jobplacment_history){
            					let h = JSON.parse(JSON.stringify(this.template_history))
            					for(let key of h){
            						const {diferenciaDeFechas, rangosDeFechas} = this.getDateDifferenceInMonths(new Date(job.date_start), new Date(job.date_finish))

            						if(job[key.value] !== undefined){
	            						key.value = job[key.value];
            						}

            						if(key.value == 'duration_contract')
            							key.value = diferenciaDeFechas

            						if(key.value == 'range_duration_contract')
            							key.value = rangosDeFechas

            						if(key.value == 'empleo')
	            						key.value = 'Empleo ' + i;



	            				}

	            				i++;
            					this.history.push(h)
            				}
            			}

            			if(data.details_company_vacancy_history.length > 0){

            				for(let index in data.details_jobplacment_history){
            					let vacancy = data.details_company_vacancy_history[index];
            					let h = this.history[index]


            					for(let key of h){
            						console.log(key.value, vacancy)
            						if(vacancy[key.value] !== undefined)
	            						key.value = vacancy[key.value];



	            				}
            					
            					this.history[index] = h;
            				}
            			}

					}
					this.questions =  questions;
					this.loadEnd.emit({ load:false })

					setTimeout(_ => {
			            if(this.panel)    
			              window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
		          	});
				});
		});
	}

	cambioEmpleo(){
		if(confirm('¿Desea asignar un nuevo empleo al participante?')){
			this.beneficiarioService.cambiarEmpleo(this.id_beneficiary)
			.subscribe( data => {
				alert('El nuevo empleo a sido asignado');
				window.location.reload();
			});	
		}
		
	}

	onSubmit(form){
		form.formData['contract_end'] = (form.formData['contract_end'] === true) ? 1 : 0;

		this.loadEnd.emit({ load:true })
		
		this.beneficiarioService.updateForm(this.id_beneficiary, form.formData, INSERT_COLOCACION)
		.subscribe( data => {
			if(!data.error) {
				const {diferenciaDeFechas, rangosDeFechas} =  this.getDateDifferenceInMonths(new Date(data['beneficiary_job'].date_start), 
					new Date(data['beneficiary_job'].date_finish))


				this.diferenciaDeFechas = diferenciaDeFechas;
				this.rangosDeFechas = rangosDeFechas;

				document.getElementById('duration_contract')['value'] = diferenciaDeFechas;
				document.getElementById('range_duration_contract')['value'] = rangosDeFechas;

				if(data['beneficiary_job'].date_start){
					this.colocacion_form.enabledInput('date_start', false);
				}

				if(this.colocacion_form.getValueInput('authorized')){
					this.colocacion_form.enabledInput('authorized', false);	
				}

				if(data.completa == 0){
					this.colocacion_form.highWarrningInputsOn();
					alert('Datos guardados con éxito.\nPero hay información pendiente para completar la etapa')
				}else{
					this.colocacion_form.highWarrningInputsOff();
					alert("Datos guardados con éxito.")

					if(!this.colocacion_form.getValueInput('authorized')){
						this.colocacion_form.enabledInput('authorized', true);	
					}	
					
				}

				this.complete = data.completa;
				this.fullForm.emit({isFill:(data.completa == 1)})
			} else {
				alert("Hubo un error al enviar la información") 
			}

			
			this.loadEnd.emit({ load:false })

		})
	}

	submitFileColocacion(url_source, name_file){
		this[name_file] = url_source.url
		this.beneficiarioService.addFileColocacion(url_source.url, name_file, this.id_beneficiary)
		.subscribe( data => {
			if(name_file == 's3file' || name_file == 'file_certificate'){
				//if(!this.questions[2].value){
					//this.colocacion_form.enabledInput('date_start', true);
				//}

				if(this.canEditDate){
					if(confirm("La actual fecha de contrato firmado es "+this.questions[2].value+". ¿Desea cambiar esta fecha de contrato?")){
						this.openPopUp = true;
					}	
				}
				

				if(data.completa == 1){
					if(!this.colocacion_form.getValueInput('authorized')){
						this.colocacion_form.enabledInput('authorized', true);	
					}		
				}
			}

			this.complete = data.completa;
			this.fullForm.emit({isFill:(data.completa == 1)})
			
			this.loadEnd.emit({loading:false}) 
			this.loadFile.emit({load: false})
		});
	}

	fillForm(event){
		this.fullForm.emit({isFill:event.isFill})
	}

	loadingFile(){
		this.loadFile.emit({load: true})
	} 

	onDeleteFileEmmit(event, type_document){
		if(confirm('Desea eliminar este documento') === true){
			this.isFileDeleted_s3file = (type_document == 1);
			this.isFileDeleted_file_certificate = (type_document == 2);
			this.isFileDeleted_file_contribution = (type_document == 3);
			
			this.beneficiarioService.deteleFileColocacion(this.id_beneficiary, type_document)
				.subscribe( data => {
					if(data.error)
						return;

					if((this.isFileDeleted_s3file && this.file_certificate == null) || (this.isFileDeleted_file_certificate && this.s3file == null)){
						this.colocacion_form.enabledInput('date_start', false);	

						if(!this.colocacion_form.getValueInput('authorized')){
							this.colocacion_form.enabledInput('authorized', false);	
						}

					}

					this.complete = data.completa;
					this.fullForm.emit({isFill:(data.completa == 1)})

					
					alert('Se eliminó el documento con éxito');
				});	
		}
		
	}

	changeStatus(vacante, status_id){
		if(confirm('¿Desea cambiar el estado de la vacante?')){
			this.vacantesService.changeVacanteStatus(vacante.id_userxmlhasvacancy, status_id).subscribe(
				data => {
					if(!data.error){
						for(let vac in this.empresa){
							if(this.empresa[vac]){
								if(vacante.id_vacancy == this.empresa[vac].id_vacancy){
									alert(data.msg)
									if(status_id == 8){
										this.empresa.splice(vac, 1);
									}
									else{
										vacante.id_status = status_id;
										this.empresa = [vacante];
									}
								}
							}
						}
					}else{
						alert(data.msg);
					}
				})
		}
	}

	getDateDifferenceInMonths(firstDate: Date, secondDate: Date){

		var diferenciaDeFechas, rangosDeFechas;

		var _MS_PER_DAY = 1000 * 60 * 60 * 24;
		var utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
		var utc2 = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());


		var totalDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
		var totalYears = Math.trunc(totalDays / 365);
		totalYears = totalYears * 12;
		var totalMonths = Math.trunc((totalDays % 365) / 30);
		var remainingDays = Math.trunc((totalDays % 365) % 30);


		if(utc1 > 0 && utc2 > 0){
			diferenciaDeFechas =  ((totalMonths + totalYears) == 0) ? remainingDays + ' dias.' : (totalMonths + totalYears) + ' meses, ' + remainingDays + ' dias.'

			if(totalDays <= 90){
				rangosDeFechas = 'Menor a tres meses';
			}else if(totalDays > 90 && totalDays <= 180){
				rangosDeFechas = 'Menor a seis meses';
			}else if(totalDays > 180 && totalDays <= 360){
				rangosDeFechas = 'Menor a un año';
			}else{
				rangosDeFechas = 'Mayor a un año';
			}


		}
		else 
			diferenciaDeFechas = '';


		return {diferenciaDeFechas:diferenciaDeFechas, rangosDeFechas:rangosDeFechas}

	}

	saveNewContractDate(){
		if(this.fecha_contrato){

			this.beneficiarioService.setDateContract(this.id_beneficiary, this.fecha_contrato)
				.subscribe( date => {
					this.colocacion_form.setValueInput('date_start', this.fecha_contrato);
					this.openPopUp = false;
				})

		}else{
			alert("Debe especificar una fecha de contrato");
		}


	}


}
