export const FORM_APOYO_PSICOSOCIAL = [
	{
		"key":"acompanamiento",
		"label":"NO NECESITA ACOMPAÑAMIENTO PSICOSOCIAL",
		"size":"12",
		"controlType":"checkbox",
		"action":"enabledInput",
		"inputInput": ['psocial_close_reason'].join(','),
		"outputInput": [
			'continue_accomp',
			].join(','),
		"onlyDisabled": [
			'psicosocial[0][psocial_care]',
			'psicosocial[1][psocial_care]',
			'psicosocial[2][psocial_care]',
			'acompanamiento_psico[0][type_accompaniment_id]',
			'acompanamiento_psico[1][type_accompaniment_id]',
			'acompanamiento_psico[2][type_accompaniment_id]',
			'psocial_close_id'
			].join(','),
		"verify":false,
		"editable":false
	},
	{
		"key":"psocial_close_reason",
		"label":"RAZÓN EN CASO DE QUE EL PARTICIPANTE NO REQUIERE ACOMPAÑAMIENTO PSICOSOCIAL",
		"size":"12",
		"type":"number",
		"controlType":"dropdown",
		"source":"rawSource",
		"plane_options":[
			{
				key:"Es un participante activo y autonomo que cumple con todas las actividades de su proceso de formación e intermediación", 
				value:"Es un participante activo y autonomo que cumple con todas las actividades de su proceso de formación e intermediación"
			},
			{
				key:"El Participante cuenta con redes de apoyo que lo acompañan en su proceso de formación e intermediación",
				value:"El Participante cuenta con redes de apoyo que lo acompañan en su proceso de formación e intermediación"
			}],
		"disabled": true,
		"verify":true,
		"editable":true
	},
	{
		"key":"continue_accomp",
		"label":"¿CONTINUA EN ACOMPAÑAMIENTO PSICOSOCIAL? ",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"action":"enabledOnValue",
		"valueToEnabled": "true",
		"inputInput": ['acompanamiento_psico[0][type_accompaniment_id]',
			'acompanamiento_psico[1][type_accompaniment_id]',
			'acompanamiento_psico[2][type_accompaniment_id]',
			'remission',
			].join(','),
		"onlyDisabled": [
			'psicosocial[0][psocial_care]',
			'psicosocial[1][psocial_care]',
			'psicosocial[2][psocial_care]',
			].join(','),
		"outputInput": 'psocial_close_id',
		"optional":"true",
		"verify":true,
		"editable":true
	},
	{
		"key":"acompanamiento_psico[0][type_accompaniment_id]",
		"label":"1 - ¿CUÁL ES EL MOTIVO DE ACOMPAÑAMIENTO?",
		"size":"6",
		"type":"number",
		"controlType":"dropdown",
		"source":"tipoacompanamiento",
		"action":"enabledInput",
		"valueToEnabled": 11,
		"outputInput": 'psicosocial[0][psocial_care]',
		"outputValue": 'acompanamiento_psico[0][other]',
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true,
		"disabled":true

	},
	{
		"key":"acompanamiento_psico[0][other]",
		"label":"CUÁL",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"optional":"true",
		"disabled": true,
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true
	},
	{
		"key":"psicosocial[0][psocial_care]",
		"label":"1 - ¿CUÁLES SON LAS ACCIONES PARA ACOMPAÑAR AL PARTICIPANTE?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cuidadopsicosocial",
		"disabled": true,
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true
	},
	{
		"key":"acompanamiento_psico[1][type_accompaniment_id]",
		"label":"2 - ¿CUÁL ES EL MOTIVO DE ACOMPAÑAMIENTO?",
		"size":"6",
		"type":"date",
		"controlType":"dropdown",
		"source":"tipoacompanamiento",
		"action":"enabledInput",
		"valueToEnabled": 11,
		"outputInput": 'psicosocial[1][psocial_care]',
		"outputValue": 'acompanamiento_psico[1][other]',
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 1,
		"disabled":true
		
	},
	{
		"key":"acompanamiento_psico[1][other]",
		"label":"CUÁL",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled": true,
		"optional":"true",
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 1,
		

	},
	{
		"key":"psicosocial[1][psocial_care]",
		"label":"2 - ¿CUÁLES SON LAS ACCIONES PARA ACOMPAÑAR AL PARTICIPANTE?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cuidadopsicosocial",
		"disabled": true,
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 1,
		
	},
	{
		"key":"acompanamiento_psico[2][type_accompaniment_id]",
		"label":"3 - ¿CUÁL ES EL MOTIVO DE ACOMPAÑAMIENTO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"tipoacompanamiento",
		"action":"enabledInput",
		"valueToEnabled": 11,
		"outputInput": 'psicosocial[2][psocial_care]',
		"outputValue": 'acompanamiento_psico[2][other]',
		
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 2,
		"disabled":true

	},
	{
		"key":"acompanamiento_psico[2][other]",
		"label":"CUÁL",
		"size":"6",
		"type":"text",
		"disabled": true,
		"controlType":"textbox",
		"optional":"true",
		
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 2,
	},
	{
		"key":"psicosocial[2][psocial_care]",
		"label":"3 - ¿CUÁLES SON LAS ACCIONES PARA ACOMPAÑAR AL PARTICIPANTE?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cuidadopsicosocial",
		"disabled": true,
		
		"verifyGroup":"acompanamiento_psico",
		"verifyGroupIndex": 2,
	},

	{
		"key":"remission",
		"label":"¿QUIÉN REMITIO AL PARTICIPANTE?",
		"size":"6",
		"type":"date",
		"controlType":"dropdown",
		"source":"remision",
		"action":"enabledOnValue",
		"valueToEnabled": 5,
		"outputInput": '',
		"inputInput":"other_remission",
		"cleanInput":"",
		"disabled":true
	},
	{
		"key":"other_remission",
		"label":"CUÁL",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":true
	},
	
	
	
	{
		"key":"psocial_close_id",
		"label":"¿CUÁL ES LA RAZÓN DE CIERRE DE LA ATENCIÓN AL PARTICIPANTE?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cierrepsicosocial",
		"verify":true,
		"editable":true,
		"disabled":true
	}
]