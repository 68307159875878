export class Usuario{
	id:number;
	username:string;
	name_operator:string;
	password:string;
	last_activity:string;


	constructor(value: Object = {}){
		Object.assign(this, value);
	}
}