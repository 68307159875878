import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../../struct';
import { EmpresaService, UtilService, AnalitycsService } from '../../../services';
import { Validators } from '../../../app.validators';
import { Router} from '@angular/router';

@Component({
	selector: 'app-crearempresa',
	templateUrl: './crearempresa.component.html',
	styleUrls: ['./crearempresa.component.css']
})
export class CrearempresaComponent implements OnInit {
	
	model: any = {}
  	title_view: string;
  	loadPage: boolean = true;
  	id_company: any;
  	name_company: any;
  	ciudades: any = [];
  	validators = Validators;
  
  	constructor(private empresaService: EmpresaService,
  		private router: Router,
  		private utilService: UtilService, 
  		private analyticsService: AnalitycsService) { }

	ngOnInit() {
		this.title_view = 'CREAR EMPRESA';
		this.utilService.getAllMunicipally().subscribe( 
			data => {
			this.ciudades = data.municipality;
			this.loadPage = false;
		})
	}

	onSubmitEmpresa(){
		var empresa = new Empresa(this.model);
		
		this.empresaService.addEmpresa(empresa).subscribe( 
			data => {
				this.id_company = data.id_company;
	  			if(data.error){
	  				console.log(data.msg);
	  				return;
	  			}
	  			
	  			this.analyticsService.eventAction('Empresas', 'CrearEmpresa');
  				this.router.navigate(['/empresas']);
  		});
        
	}

}