import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<string>{
	controlType = 'dropdown';
	options: {key: string, value:string}[] = [];
	plane_options: {key: string, value:string}[] = [];
	readonly: boolean
	action: string;
	serviceSource: string;
	outputSource:string;
	outputInput:string;
	inputInput:string;
	valueToEnabled:any;
	cleanInput:any
	restrictInput:any
	outputValue:any;
	onlyDisabled:string;
	source: string;
	type:string
	neddleName:string;
	default_text:string;
	init_options: boolean;


	constructor(options:{} = {}){
		super(options);
		this.plane_options = options['plane_options'] || [];
		this.options = options['options'] || [];
		this.readonly = options['readonly'] || false;
		this.action = options['action'] || null;
		this.serviceSource = options['serviceSource'] || null;
		this.outputSource = options['outputSource'] || null;
		this.outputValue = options['outputValue'] || null;
		this.inputInput = options['inputInput'] || null;
		this.outputInput = options['outputInput'] || null;
		this.valueToEnabled = options['valueToEnabled'] || null;
		this.cleanInput = options['cleanInput'] || null;
		this.restrictInput = options['restrictInput'] || null;
		this.onlyDisabled = options['onlyDisabled'] || '';
		this.source = options['source'] || '';
		this.type = options['type'] || '';
		this.neddleName = options['neddle_name'] || '';
		this.default_text = options['default_text'] || '';
		this.init_options = options['init_options'] || false;
		
	}

	
}
