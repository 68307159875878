import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { HabilidadFormacion } from './../../../struct/habilidad-formacion';
import { BeneficiarioDataService } from '../../../services';
import { Beneficiario } from '../../../struct';

@Component({
	selector: 'app-hoja-vida',
	templateUrl: './hoja-vida.component.html',
	styleUrls: ['./hoja-vida.component.css']
})
export class HojaVidaComponent implements OnInit {

	model: any = {}
	habilidades_duras:any = [];
	habilidades_blandas:any = [];
	experiencia_laboral:any = [];
	dropsOptions:any;
	loadPage:boolean = true;
	id_participante:any;
	participante;

	validator = {
		number: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		phone: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
		currency: /^[-+]?\d*(?:[\.\,]\d+)?$/,

	};

	constructor(private dropService:DropsWrapperService,
		private beneficiarioService:BeneficiarioDataService,
		private router: Router, 
		private route: ActivatedRoute) { }

	ngOnInit() {
		this.id_participante = this.route.params['value'].id_participante;

		this.dropService.fullValidateDrop().subscribe( d => this.dropService.addOptions(d));
		this.dropService.addOptions$.subscribe( options => {
			this.beneficiarioService.getBeneficiarioById(this.id_participante)
			.subscribe( data => {
				if(data.error)
					return;

				this.participante = new Beneficiario(data.beneficiary[0]);
				this.model = this.participante;

				if(data.profile.length > 0)
					this.model.perfil_laboral = data.profile[0].profile;

				this.experiencia_laboral.push({
					last_job_id: this.participante.last_job_id,
					activity: this.participante.activity,
					duration_id: this.participante.duration_id,
				});

				if(data.other_experence.length > 0){
					for(let exp of data.other_experence)
						this.experiencia_laboral.push(exp);
				}

				this.dropsOptions = options
				this.loadPage = false;

			});
		});
	}

	sendForm(form){

		console.log(form);

		let formData = [];
		let index = 0;
		let index_field;
		let exp_lab = this.experiencia_laboral.slice(1)


		for(let field in form){
			index_field = field;

			if(field == 'last_job_id_0'){
				index_field = 'last_job_id'
			}

			if(field == 'activity_0'){
				index_field = 'activity'
			}

			if(field == 'duration_id_0'){
				index_field = 'duration_id'
			}
					
			formData.push({[index_field]:form[field]});
		}

		for(let hard in exp_lab){
			for(let key in exp_lab[hard]){
				formData.push({ [`other_experiencia[${hard}][${key}]`] : exp_lab[hard][key]})
			}
			index++;
		}


		this.beneficiarioService.updateBeneficiarioInfo(this.id_participante, formData)
		.subscribe( data => {
			this.router.navigate(['reportes/hoja_vida/pdf/', this.id_participante]);
		})
	}

	addExperiencia(event){
		event.preventDefault();
		this.experiencia_laboral.push({
			last_job_id:null,
			activity:null,
			duration_id:null,
		});
	}

	delExperiencia(event, index){
		event.preventDefault();

		if(this.experiencia_laboral.length <= 1){
			alert('No puede borrar toda la experiencia laboral.')
			return;
		}

		if (index > -1) {
			this.experiencia_laboral.splice(index, 1);
		}
	}

	addHabilidadDura(event){
		event.preventDefault();
		this.habilidades_duras.push(new HabilidadFormacion({type_competition_id:1}));
	}

	delHabilidadDura(event, index){
		event.preventDefault();

		if(this.habilidades_duras.length <= 1){
			alert('No puede borrar todas las capacidades duras.')
			return;
		}

		if (index > -1) {
			this.habilidades_duras.splice(index, 1);
		}
	}


	addHabilidadBlanda($event){
		event.preventDefault();
		this.habilidades_blandas.push(new HabilidadFormacion({type_competition_id:2}));
	}

	delHabilidadBlanda(event, index){
		event.preventDefault();

		if(this.habilidades_blandas.length <= 1){
			alert('No puede borrar todas las habilidades blandas.')
			return;
		}


		if (index > -1) {
			this.habilidades_blandas.splice(index, 1);
		}
	}

}
