import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from '../question-base';
import { QuestionControlService } from '../question-control-service.service';
import { DropsWrapperService  } from '../../../shared/drops-wrapper.service';


import * as _ from 'underscore';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input() questions: QuestionBase<any>[] = [];
  @Input() disabled: boolean = false;
  @Input() button_text:string = 'GUARDAR';
  
  @Output() submitForm = new EventEmitter();
  @Output() fillForm = new EventEmitter();
  
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: QuestionControlService,
    private dropServices: DropsWrapperService) { }

  ngOnInit(){
    let questions:QuestionBase<any>[] = this.questions;

    if(this.disabled){
      for(let q in this.questions){
        this.questions[q].disabled = true;
      }
    }
    this.form = this.qcs.toFormGroup(questions); 

  }

  ngOnChanges(changes:SimpleChanges){
  }

  onSubmit(){
    if(this.form.status == 'VALID'){
      this.submitForm.emit({formData: this.form.value});

    }
  }

  enabledInput(index, enabled){
    if(this.form.controls[index])
      this.form.controls[index]
          .reset({value: this.form.controls[index].value  , disabled: !enabled}, { onlySelf: true });  
  }

  //  enabledInput(index, enabled){
  //   if(this.form.controls[index])
  //     this.form.controls[index]
  //         .reset({value: this.form.controls[index].value  , disabled: !enabled}, { onlySelf: true });  
  // }

  getValueInput(index){
    return this.form.controls[index].value;
  }

  setValueInput(index, value){
     if(this.form.controls[index])
      this.form.controls[index]
          .reset({value: value  , disabled: this.form.controls[index].disabled}, { onlySelf: true }); 
  }



  highWarrningInputsOn(){

    this.highWarrningInputsOff();

    let group_fill = [];
    for(let q in this.questions){
      if(this.questions[q].verifyGroup.length > 0){
        if(this.questions[q].value){
          group_fill.push(this.questions[q].verifyGroupIndex);
        }
      }
    }

    if(group_fill.length > 0){
      for(let q in this.questions){
        if(this.questions[q].verifyGroup.length > 0 && group_fill.indexOf(this.questions[q].verifyGroupIndex) <= -1 ){
          if(group_fill.indexOf(q) <= -1){
            this.questions[q].verify = false;
          }  
        }
        
      } 
    }


    for(let q in this.questions){
      if(!this.form.controls[this.questions[q].key].value || this.form.controls[this.questions[q].key].value == null){
        if(!this.form.controls[this.questions[q].key].disabled && !this.questions[q].no_warrning){
          if(this.questions[q].verify){
            this.questions[q].warrning_input = true;
          }
        }
      }else{
        this.questions[q].warrning_input = false;
      }
    }

  }

  highWarrningInputsOff(){
    for(let q in this.questions){
      this.questions[q].warrning_input = false;
    }
  }

  changeHandler(event){

    switch (event.type) {
      case "dropdown":
      this.dropCases(event);
      break;
      case "checkbox":
      this.checkCases(event);
      break;
      
      default:
      // code...
      break;
    }
    
  }

  clickCases(event){
    let inputs, outputs, value, valueOnEnabled, clean, outputsValue, onlyDisabled;
    switch (event.action) {
      case "toggleForm":

        inputs = event.input ? event.input.split(',') : []



        for(let input in this.form.controls){
          
          if(input.indexOf('_' + event.index) > -1){
            if(this.getQuestion(input)['controlType'] != 'button'){
              if(inputs.filter( i => i + '_' + event.index == input).length <= 0){
                  let disabled = !this.getQuestion(input)['visible']
                this.getQuestion(input)['visible'] = disabled  
                this.getQuestion(input)['hidden'] = !disabled     
              }
               
            }
            this.getQuestion(input)['toggleClass'] = !this.getQuestion(input)['toggleClass']
            
          }
          
        }


        // for(let i in inputs){
        //   let index_input = inputs[i] + '_' + event.index;
        //   let disabled = !this.form.controls[index_input].disabled
        //   this.form.controls[index_input]
        //     .reset({value: ''  , disabled: disabled}, { onlySelf: true });  

        //    this.getQuestion(index_input)['hidden'] = disabled

        //   this.form.patchValue({
        //     [inputs[i]]:''
        //   })
        // }
        
        break;
      
      default:
        // code...
        break;
    }
  }

  getQuestion(id_question){
    return this.questions.filter( question => {
      return question.key == id_question;
    })[0];
  }

  getSameIndex(obj, neddle) {
    for(let o in obj){
      if(o.indexOf(neddle) > -1)
        return obj[o];
    }
  }

  dropCases(event){
    let inputs, outputs, value, valueOnEnabled, clean, outputsValue, onlyDisabled;

    switch (event.action) {
      case "fillPlainDrop":
      console.log(event)

        outputsValue = event.outputValue ? event.outputValue.split(',') : [];
        
        if(outputsValue.indexOf(event.value) > -1)
          this.getQuestion(event.output)['options'] = event.service;
        else
          this.getQuestion(event.output)['options'] = this.getQuestion(event.output)['plane_options']
      break;
      case "fillDrop":
      this.dropServices.chainDrop(event.service, event.value)
      .subscribe( data => {
        this.getQuestion(event.output)['options'] = data[event.source].map( source => {
          return {
            key: source.id,
            value: this.getSameIndex(source, 'name')
          }
        });
      })
      break;
      case "enabledInput":
      outputs = event.output ?  event.output.split(',') : [];
      outputsValue = event.outputValue ? event.outputValue.split(',') : [];
      valueOnEnabled = !isNaN(parseInt(event.valueEnabled)) ? parseInt(event.valueEnabled) : null;  
      value = !isNaN(parseInt(event.value)) ? parseInt(event.value) : null;
      
      for(let i in outputs){
        this.form.controls[outputs[i]]
        .reset({value: this.form.controls[outputs[i]].value  , disabled: (value == null)}, { onlySelf: true });  
      }

      for(let i in outputsValue){
        this.form.controls[outputsValue[i]]
        .reset({value: ''  , disabled: (value != valueOnEnabled)}, { onlySelf: false });   
      }
      
      break;

      case "enabledOnValue":
      outputs = event.output ? event.output.split(','):[] 
      inputs = event.input ? event.input.split(',') : []
      clean = event.clean ?event.clean.split(','):[]
      onlyDisabled = event.only_disabled ? event.only_disabled.split(',') : []
      
      if(event.valueEnabled === 'true' || event.valueEnabled === 'false'){
        valueOnEnabled = event.valueEnabled;  
        value = (event.value.length > 0 ) ? event.value: (event.valueEnabled === 'true' ? 'false' : 'true');
      }else{
        value = !isNaN(parseInt(event.value)) ? parseInt(event.value) : null;
        valueOnEnabled = !isNaN(parseInt(event.valueEnabled)) ? parseInt(event.valueEnabled) : null;  
      }


      for(let i in outputs){

        this.form.controls[outputs[i]]
          .reset({value: ''  , disabled: (value == valueOnEnabled)}, { onlySelf: true });  

        this.form.patchValue({
          [outputs[i]]:''
        })
      } 

      for(let i in inputs){
        this.form.controls[inputs[i]]
        .reset({value: ''  , disabled: (value != valueOnEnabled)}, { onlySelf: true });  

        this.form.patchValue({
          [inputs[i]]:''
        })
      }

      for(let i in onlyDisabled){
        this.form.controls[onlyDisabled[i]]
         .reset({value: ''  , disabled: true}, { onlySelf: true });  
      }

      for(let i in clean){
        this.form.controls[clean[i]].reset({value: ''  , disabled: false}, { onlySelf: true });  
      }
      break;

      case "onlyOption":
        outputs = event.output ? event.output.split(','):[] 
        inputs = event.input ? event.input.split(',') : []


        if(event.valueEnabled === 'true' || event.valueEnabled === 'false'){
          valueOnEnabled = event.valueEnabled;  
          value = (event.value.length > 0 ) ? event.value: (event.valueEnabled === 'true' ? 'false' : 'true');
        }else{
          value = !isNaN(parseInt(event.value)) ? parseInt(event.value) : null;
          valueOnEnabled = !isNaN(parseInt(event.valueEnabled)) ? parseInt(event.valueEnabled) : null;  
        }

        for(let i in outputs){
          if(value == valueOnEnabled){
            this.getQuestion(outputs[i])['init_options'] = _.clone(this.getQuestion(outputs[i])['options']);
            this.getQuestion(outputs[i])['options'].splice(0,1)
          }else{
            if(this.getQuestion(outputs[i])['init_options']){
              this.getQuestion(outputs[i])['options'] = this.getQuestion(outputs[i])['init_options']
              delete this.getQuestion(outputs[i])['init_options']
            }
          }
        } 
      break;

      case "cleanInputs":
        clean = event.clean ?event.clean.split(','):[]


        for(let i in clean){
          this.form.controls[clean[i]].reset({value: ''  , disabled: false}, { onlySelf: true });  
        }

        break;

      
      default:
      // code...
      break;
    }
  }

  checkCases(event){
    let inputs, outputs, onlyDisabled;
    switch (event.action) {
      case "disabledForm":
      Object.keys(this.form.controls).slice(2).forEach(key => {
        this.form.controls[key]
        .reset({value: this.form.controls[key].value  , disabled: event.check}, { onlySelf: true });
      });
      break;
      case "disabledInput":
      outputs = event.out_input.split(',')
      for(let i in outputs){
        this.form.controls[outputs[i]]
        .reset({value: this.form.controls[outputs[i]].value  , disabled: !event.check}, { onlySelf: true });  
      }

      break;
      case "enabledInput":

      outputs = event.out_input.length > 0 ? event.out_input.split(',') : []
      inputs = event.in_input.length > 0 ? event.in_input.split(',') : []
      onlyDisabled = event.only_disabled.length > 0 ? event.only_disabled.split(',') : []

      for(let i in outputs){
        this.form.controls[outputs[i]]
        .reset({value: ''  , disabled: event.check}, { onlySelf: true });  
      }

      for(let i in inputs){
        this.form.controls[inputs[i]]
        .reset({value: ''  , disabled: !event.check}, { onlySelf: true });  
      }

      if(event.check){
        for(let i in onlyDisabled){
          this.form.controls[onlyDisabled[i]]
          .reset({value: ''  , disabled: true}, { onlySelf: true });  
        }
      }


      break;
      
      default:
      // code...
      break;
    }
  }

}
