import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { AppSettings } from './../../appSettings';


import { AuthenticationService, AnalitycsService } from '../../services';

declare var snb;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	model:any = {};
	loading = false;
  error:string;
  returnUrl: string;
  showNewBono: boolean = snb

  constructor(
  	private route: ActivatedRoute,
  	private router: Router,
  	private authService: AuthenticationService,
    private analitycsService: AnalitycsService) { }

  ngOnInit() {
  	this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(){
  	this.loading = true;
  	this.authService.login(this.model.username, this.model.password, this.model.type_bis)
  		.subscribe(
  			data =>{

          console.log(data);


          if(data.error){
            this.error = data.msg;
            this.loading = false;
            return
          }

          if (this.model.type_bis == 2) {
            window.location.href = AppSettings.URL_BONO_2;
          } else if (this.model.type_bis == 4) {
            window.location.href = AppSettings.URL_BONO_3;
          } else if (this.model.type_bis == 5) {
            window.location.href = AppSettings.URL_BONO_4;
          } else if (this.model.type_bis == 6) {
            window.location.href = AppSettings.URL_BONO_5;
          } else if (this.model.type_bis == 7) {
            window.location.href = AppSettings.URL_BONO_6;
          } else {
            this.analitycsService.eventLogin(this.model.username);
            this.router.navigate([this.returnUrl]);
          }

  			},
  			error =>{
          this.loading = false;
          this.error = 'Intente nuevamente';
  			});

  }

}
