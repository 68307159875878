import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';

@Injectable()
export class AlertasService {

  constructor(private http:Http) { }

  getAlerta(path){
  	var session_id = localStorage.getItem('session_id');
  	var data = new URLSearchParams();
    data.append('token', session_id);

  	return this.http.post(AppSettings.URL_BACKEND + 'index/'+path, data)
  		.map( (response:Response) => response.json() );
  }

  getAlertDocumentacion(){
  	return this.getAlerta('alerta_documentacion');
  }

  getAlertIntermediacion(){
  	return this.getAlerta('alerta_intermediacion');
  }

  getAlertColocacion(){
  	return this.getAlerta('alerta_colocacion');
  }

  getAlertColocacionNoEmpleado(){
  	return this.getAlerta('alerta_colocacion_no_empleado');
  }


}
