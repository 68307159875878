export const FORM_INTERMEDIACION = [
	{
		"key":"perfil_laboral",
		"label":"PERFIL LABORAL",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":true,
		"editable":true
	},
	{
		"key":"areas_conocimiento",
		"label":"ÁREA DE CONOCIMIENTO",
		"size":"12",
		"controlType":"dropdown",
		"source": "areaknowledge",
		"verify":true,
		"editable":true
	},
	{
		"key":"currently_studying",
		"label":"¿ACTUALMENTE ESTUDIA?",
		"size":"3",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"education_level_id",
		"label":"¿CUÁL ES SU MÁXIMO NIVEL DE EDUCACIÓN ALCANZADO?",
		"size":"5",
		"controlType":"dropdown",
		"source": "niveleducativo",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"profession",
		"label":"¿QUE ESTÁ ESTUDIANDO O QUE ESTUDIÓ?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"year_graduation",
		"label":"¿EN QUÉ AÑO SE GRADUÓ DE SU MÁXIMO NIVEL DE EDUCACIÓN? (DEJE EN BLANCO EN CASO DE QUE NO HAYA OBTENIDO EL TÍTULO)",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"training_id",
		"label":"¿SI HA TENIDO ALGUNA CAPACITACIÓN NO FORMAL MENCIONE QUÉ TIPO DE CAPACITACIÓN FUE?",
		"size":"8",
		"controlType":"dropdown",
		"source": "formacion",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"program_name",
		"label":"¿NOMBRE DEL PROGRAMA DE FORMACIÓN?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"institution",
		"label":"¿EN QUÉ INSTITUCIÓN CURSÓ EL PROGRAMA?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"duration_hours",
		"label":"¿CUÁNTO DURÓ EL PROGRAMA?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"certification_date",
		"label":"¿EN QUÉ FECHA LE DIERON LA CERTIFICACIÓN?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"sector",
		"label":"SECTOR",
		"size":"6",
		"controlType":"dropdown",
		"source": "sector",
		"verify":true,
		"editable":true
	},
	{
		"key":"subsector",
		"label":"SUBSECTOR",
		"size":"6",
		"controlType":"dropdown",
		"source": "subsector",
		"verify":true,
		"editable":true
	},
	{
		"key":"areas_ocupacion",
		"label":"ÁREA DE OCUPACIÓN",
		"size":"12",
		"controlType":"dropdown",
		"source": "areocupacional",
		"verify":true,
		"editable":true
	},
	{
		"key":"subgrupo_vacante",
		"label":"SUBGRUPO DE LA OCUPACIÓN DE LA VACANTE",
		"size":"12",
		"controlType":"dropdown",
		"source": "subgeneros",
		"verify":true,
		"editable":true
	},
	{
		"key":"denominzacion_ocupacion",
		"label":"DENOMINACIÓN DE LA OCUPACIÓN",
		"size":"12",
		"controlType":"dropdown",
		"source": "dominacion",
		"verify":true,
		"editable":true
	},
	{
		"key":"last_job_id",
		"label":"¿SU ÚLTIMO EMPLEO ERA FORMAL O NO FORMAL?",
		"size":"6",
		"controlType":"dropdown",
		"source": "ultimotrabajo",
		"verify":true,
		"editable":true,
		"disabled":true
	},
	{
		"key":"activity",
		"label":"¿CUÁL ERA SU CARGO O QUÉ ACTIVIDAD DESARROLLABA?",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"duration_id",
		"label":"¿CUÁNTO TIEMPO DURÓ EN ESE CARGO O DESARROLLANDO ESA ACTIVIDAD?",
		"size":"12",
		"controlType":"dropdown",
		"source": "duracion",
		"verify":true,
		"editable":true,
		"disabled":true
	},
	{
		"key":"salary_id",
		"label":"EN QUÉ RANGO SE ENCONTRABA EL ÚLTIMO SALARIO QUE USTED TENÍA EN ESE CARGO O DESARROLLANDO ESA ACTIVIDAD? SELECCIONAR UNA DE LAS OPCIONES",
		"size":"12",
		"controlType":"dropdown",
		"source": "salario",
		"verify":true,
		"editable":true,
		"disabled":true
	},


]