export const FORM_ORIENTACION = [
	{
		"key":"currently_studying",
		"label":"¿ACTUALMENTE ESTUDIA?",
		"size":"3",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"education_level_id",
		"label":"¿CUÁL ES SU MÁXIMO NIVEL DE EDUCACIÓN  ALCANZADO?",
		"size":"5",
		"type":"number",
		"controlType":"dropdown",
		"source":"niveleducativo",
		"verify":true,
		"editable":true
	},
	{
		"key":"profession",
		"label":"¿QUE ESTÁ ESTUDIANDO O QUE ESTUDIÓ?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"year_graduation",
		"label":"¿EN QUÉ AÑO SE GRADUÓ DE SU MÁXIMO NIVEL DE EDUCACIÓN? (DEJE EN BLANCO EN CASO DE QUE NO HAYA OBTENIDO EL TÍTULO)",
		"size":"12",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"training_id",
		"label":"¿SI HA TENIDO ALGUNA CAPACITACIÓN NO FORMAL MENCIONE QUÉ TIPO DE CAPACITACIÓN FUE?",
		"size":"8",
		"type":"text",
		"controlType":"dropdown",
		"source":"formacion",
		"action":"enabledOnValue",
		"valueToEnabled": 6,
		"outputInput": ["program_name", "institution", "duration_hours", "certification_date"].join(),
		"inputInput":'',
		"cleanInput":"",
		"verify":true,
		"editable":true
	},
	{
		"key":"program_name",
		"label":"¿NOMBRE DEL PROGRAMA DE FORMACIÓN?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true,
	},
	{
		"key":"institution",
		"label":"¿EN QUÉ INSTITUCIÓN CURSÓ EL PROGRAMA?",
		"size":"4",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"duration_hours",
		"label":"¿CUÁNTO DURÓ EL PROGRAMA?",
		"size":"4",
		"type":"number",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"certification_date",
		"label":"¿EN QUÉ FECHA LE DIERON LA CERTIFICACIÓN?",
		"size":"4",
		"type":"date",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"work_experience",
		"label":"¿CUENTA CON EXPERIENCIA LABORAL?",
		"size":"4",
		"type":"text",
		"controlType":"drowpdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"last_job_id",
		"label":"¿SU ÚLTIMO EMPLEO ERA FORMAL O NO FORMAL?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"ultimotrabajo",
		"action":"enabledOnValue",
		"valueToEnabled": 3,
		"outputInput": ["activity", "duration_id", "salary_id"].join(),
		"inputInput":'',
		"cleanInput":"",
		"verify":true,
		"editable":true
	},
	{
		"key":"activity",
		"label":"¿CUÁL ERA SU CARGO O QUÉ ACTIVIDAD DESARROLLABA?",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"duration_id",
		"label":"¿CUÁNTO TIEMPO DURÓ EN ESE CARGO O DESARROLLANDO ESA ACTIVIDAD?",
		"size":"12",
		"type":"number",
		"controlType":"dropdown",
		"source":"duracion",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"salary_id",
		"label":"EN QUÉ RANGO SE ENCONTRABA EL ÚLTIMO SALARIO QUE USTED TENÍA EN ESE CARGO O DESARROLLANDO ESA ACTIVIDAD? SELECCIONAR UNA DE LAS OPCIONES",
		"size":"12",
		"type":"number",
		"controlType":"dropdown",
		"source":"salario",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"unemployment_time_id",
		"label":"¿CUÁNTO TIEMPO LLEVA DESEMPLEADO?",
		"size":"6",
		"type":"number",
		"controlType":"dropdown",
		"source":"tiempodesempleo",
		"verify":true,
		"editable":true
	},
	{
		"key":"unemployment_activity",
		"label":"¿QUÉ ACTIVIDADES DESARROLLÓ DURANTE SU DESEMPLEO?",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"occupational_areas_id",
		"label":"¿CUÁLES SON SUS INTERESES OCUPACIONALES?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"areocupacional",
		"action": "fillDrop",
		"serviceSource":"domination_by_areas",
		"outputSource":"domination",
		"outputInput":"domination_id",
		"verify":true,
		"editable":true
	},
	{
		"key":"domination_id",
		"label":"DENOMINACIONES DE LA OCUPACIÓN",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"dominacion",
		"verify":true,
		"editable":true
	},
	
	{
		"key":"meet_employment_center",
		"label":"¿CONOCE OTROS CENTROS DE EMPLEO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true

	},
	{
		"key":"seen_employment_center",
		"label":"¿HA VISITADO OTROS CENTROS DE EMPLEO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"perspertive_training",
		"label":"¿USTED CREE QUE UNA MAYOR FORMACIÓN, GENERA MAYORES INGRESOS Y MEJORA LA CALIDAD DE VIDA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"component_addressing",
		"label":"DIRECCIONAMIENTO AL COMPONENTE DE FORMACIÓN",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"another_formation",
		"label":"OTRO PROGRAMA DE FORMACIÓN",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"title_oso",
		"label":"¿Qué otras actividades realizó para fortalecer la orientación socio ocupacional y el proyecto de vida del participante?",
		"size":"12",
		"controlType":"label"
	},
	{
		"key":"arr_oso[0][check]",
		"label":"SESIONES DE SEGUIMIENTO AL PROYECTO DE VIDA",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[0][description]",

	},
	{
		"key":"arr_oso[0][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":1
	},
	{
		"key":"arr_oso[0][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"arr_oso[1][check]",
		"label":"TALLERES GRUPALES DE ORIENTACIÓN Y PROYECTO DE VIDA",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[1][description]",
	},
	{
		"key":"arr_oso[1][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":2
	},
	{
		"key":"arr_oso[1][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},

	{
		"key":"arr_oso[2][check]",
		"label":"ENTREVISTA CON EL PSICOPEDAGOGO",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[2][description]",
		
	},
	{
		"key":"arr_oso[2][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":3
	},
	{
		"key":"arr_oso[2][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},

	{
		"key":"arr_oso[3][check]",
		"label":"ACTIVIDADES LÚDICAS",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[3][description]",
		
	},
	{
		"key":"arr_oso[3][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":4
	},
	{
		"key":"arr_oso[3][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},

	{
		"key":"arr_oso[4][check]",
		"label":"VISITAS A EMPRESAS",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[4][description]",
		
	},
	{
		"key":"arr_oso[4][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":5
	},
	{
		"key":"arr_oso[4][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"arr_oso[5][check]",
		"label":"SALIDAS PEDAGÓGICAS",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[5][description]",
		
	},
	{
		"key":"arr_oso[5][type_oso_seleccion_id]",
		"label":"",
		"size":"12",
		"type":"hidden",
		"controlType":"textbox",
		"value":6
	},
	{
		"key":"arr_oso[5][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},

	{
		"key":"arr_oso[6][check]",
		"label":"OTRA, ¿CUÁL?",
		"size":"12",
		"controlType":"checkbox",
		"action":"disabledInput",
		"outputInput":"arr_oso[6][description],arr_oso[6][other]",
	},
	{
		"key":"arr_oso[6][type_oso_seleccion_id]",
		"label":"",
		"size":"1",
		"type":"hidden",
		"controlType":"textbox",
		"value":8
	},
	{
		"key":"arr_oso[6][other]",
		"label":"",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"arr_oso[6][description]",
		"label":"DESCRIPCIÓN",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"disabled":true,
		"verify":true,
		"editable":true
	},

]








