import { Component, OnInit } from '@angular/core';
import {AccordionGroupComponent} from './../accordion-group/accordion-group.component';


@Component({
	selector: 'app-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

	groups: Array<AccordionGroupComponent> = [];

	constructor() { }

	ngOnInit() {
	}

	addGroup(group: AccordionGroupComponent): void {
		this.groups.push(group);
	}

	colseOthers(openGroup: AccordionGroupComponent){
		this.groups.forEach((group: AccordionGroupComponent) => {
			if(group !== openGroup)
				group.isOpen = false;
		});
	}



}
