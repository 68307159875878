import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { GrupoPerfilacion } from '../../struct';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';


@Injectable()
export class PerfilacionService {

  grupos: GrupoPerfilacion[];

  constructor(private http: Http) { }

  getAllGroups(){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    return this.http.post(AppSettings.API_ENDPOINT + 'view_all_group', data)
      .map((response: Response) => response.json());
  }

  getGroupById(id_group){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id_group', id_group)

    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_group', data)
      .map((response: Response) => response.json());
  }

  getGroupsByBeneficiary(id_beneficiary){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiary)

    return this.http.post(AppSettings.API_ENDPOINT + 'view_beneficiaries_group', data)
      .map((response: Response) => response.json());
  }

  deleteGroupById(id_group:any){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id_group', id_group)
    
    return this.http.post(AppSettings.API_ENDPOINT + 'delete_group', data)
      .map((response: Response) => response.json());
  }

  createGroup(name_group, description){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('name_group', name_group)
    data.append('description', description)
    
    return this.http.post(AppSettings.API_ENDPOINT + 'insert_group', data)
      .map((response: Response) => response.json());
  }

  deteleBeneficiaryByGroup(id_group, id_beneficiary){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id_group', id_group)
    data.append('id_beneficiary', id_beneficiary)
    
    return this.http.post(AppSettings.API_ENDPOINT + 'delete_beneficiary_by_group', data)
      .map((response: Response) => response.json());

  }

  assingBeneficiariosToGroup(id_group, name_group = '', description = '', beneficiaries, documento = '', tipo_documento = ''){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    id_group = id_group > 0 ? id_group : '';

    data.append('token', session_id)
    data.append('id_group', id_group)
    data.append('name_group', name_group)
    data.append('description', description)
    data.append('id', beneficiaries)
    data.append('doc', documento)
    data.append('tipo_doc', tipo_documento)
    
    return this.http.post(AppSettings.API_ENDPOINT + 'assign_beneficiary_to_group', data)
      .map((response: Response) => response.json());
  }




}
