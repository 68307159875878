import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { AppSettings } from './../../appSettings';
import { Beneficiario } from './../../struct';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/expand';



@Injectable()
export class BeneficiarioDataService {

  Beneficiarios: Beneficiario[];
  constructor(private http: Http) { }

  getAllBeneficiarios(type:any = 0, verify_id:any=0, filters:any = {}, paged=0){

      console.log(verify_id);

      var session_id = localStorage.getItem('session_id');
      var data = new URLSearchParams();

      for(let index of Object.keys(filters)){
          data.append(index, filters[index]);
      }  
      data.append('token', session_id)
      data.append('type', type)
      data.append('verify_id', verify_id)
      
      data.append('limit', isNaN(paged) ? '15' : String(paged))
      return this.http.post(AppSettings.URL_BACKEND + 'index/view_all_beneficiary', data)
       .map((response: Response) => response.json());

  }

  getAllBeneficiariosFill(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'filter_participant', data)
    .map((response: Response) => response.json());    
  }

  getBeneficiarioById(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('beneficiary_id', String(id))
    return this.http.post(AppSettings.API_ENDPOINT + 'view_beneficiary_id', data)
    .map((response: Response) => response.json());
  }

  getBeneficiarioByDocument(tdc:number, document:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('type_doc', tdc.toString())
    data.append('document', document.toString())
    
    return this.http.post(AppSettings.API_ENDPOINT + 'get_user_by_document', data)
    .map((response: Response) => response.json());
  }

  getBeneficiarioByStep(step:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('type', String(step))
    
    return this.http.post(AppSettings.URL_BACKEND + 'index/participantes_complete', data)
    .map((response: Response) => response.json());
  }

  updateBeneficiarioInfo(id_participante, form){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('user_id', id_participante)

     for(let input in form){
       for(let index in form[input]){
        data.append(index, form[input][index])     
       }
    }

    return this.http.post(AppSettings.URL_BACKEND + 'index/update_info_user', data)
    .map((response: Response) => response.json()); 
  }

  addFile(url_source, type_document, id_beneficiario){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('beneficiary_id', id_beneficiario)
    data.append(type_document, url_source)

    return this.http.post(AppSettings.API_ENDPOINT + 'subir_archivo', data)
    .map((response: Response) => response.json());
  }

  addFilePreformacion(url_source, type_document, type_form , id_beneficiario){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    var service_file = (type_document == 1) ? 'subir_archivo_preformacion' : 'subir_archivo_resultado_preformacion'

    data.append('token', session_id)
    data.append('beneficiary_id', id_beneficiario)
    data.append('type_form', type_form)
    data.append('file_preformation', url_source)

    return this.http.post(AppSettings.API_ENDPOINT + service_file, data)
    .map((response: Response) => response.json());
  }

  addFileFormacion(url_source,  id_beneficiario){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiario)
    data.append('s3certificated', url_source)

    return this.http.post(AppSettings.API_ENDPOINT + 'subir_certificado_formacion', data)
    .map((response: Response) => response.json());
  }

  addFileColocacion(url_source, name_file, id_beneficiario, type_file = null, type_permanencia = null){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');
    
    var service_file =  null;

    if(type_file){
      console.log(type_file);
      if(type_file == 1){
        service_file =  AppSettings.API_ENDPOINT +  'subir_archivo_colocacion_laboral'
      }else{
        service_file =  AppSettings.URL_BACKEND + 'index/subir_archivo_permanence'
        data.append('type', type_permanencia)  
      }
      
    }
    else{
      service_file =  AppSettings.API_ENDPOINT +  'subir_archivo_colocacion_laboral'
    }
    
    data.append('id_beneficiary', id_beneficiario)
    data.append('token', session_id)
    data.append(name_file, url_source)

    return this.http.post(service_file, data)
    .map((response: Response) => response.json());
  }

  editImageAvatar(url_source, id_beneficiario){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiario)
    data.append('imagebeneficiary', url_source)

    return this.http.post(AppSettings.API_ENDPOINT + 'upload_picture_beneficiary', data)
    .map((response: Response) => response.json());
  }

  deteleFileColocacion(id_participante, type_documnet){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('id_beneficiary', id_participante)
    data.append('type_file', type_documnet)

    return this.http.post(AppSettings.API_ENDPOINT + 'delete_archivo_colocacion_laboral', data)
    .map((response: Response) => response.json());
  }

  downloadXmlBeneficiarios(desde, hasta){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('desde', desde)
    data.append('hasta', hasta)

    return this.http.post(AppSettings.URL_BACKEND + 'Kokan_online', data)
    .map((response: Response) => response.json());   
  }

  getPsicosocial(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('beneficiary_id', String(id))
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_psicosocial', data)
    .map((response: Response) => response.json());
  }

  getOrientacion(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('beneficiary_id', String(id))
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_orientacion', data)
    .map((response: Response) => response.json());
  }


  getPreformacion(id:number, type_form:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('beneficiary_id', id+"")
    data.append('type', type_form+"")
    
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_preformacion', data)
    .map((response: Response) => response.json());
  }

  getFormacion(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('id_beneficiary', id+"")
    
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_formation', data)
    .map((response: Response) => response.json());
  }

  getIntermediacion(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('user_id', id+"")
    
    
    return this.http.post(AppSettings.API_ENDPOINT + 'get_intermediacion_user', data)
    .map((response: Response) => response.json());
    
  }

  getColocacion(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('id_beneficiary', String(id))
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_jobplacement', data)
    .map((response: Response) => response.json());
  }


  getPermanencia(id:number, type:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('user_id', String(id))
    data.append('type', String(type))
    
    return this.http.post(AppSettings.URL_BACKEND + 'index/permanecia', data)
    .map((response: Response) => response.json());
  }


  sendAlertColocacion(id_user_alert, option){
     var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('option', String(option))
    data.append('id_user_alert', String(id_user_alert))
    
    return this.http.post(AppSettings.API_ENDPOINT + 'update_alert_user', data)
    .map((response: Response) => response.json());
  }


  sendMailPreIncompleto(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    var session_id = localStorage.getItem('session_id');
    return this.http.post(AppSettings.API_ENDPOINT + 'sendmailer_preinscritoincompleto', data)
    .map((response: Response) => response.json());
  }

  updateUserData(id_beneficiario, form){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('user_id', id_beneficiario)

    for(let input in form){
      data.append(input, form[input])
    }
    

    return this.http.post(AppSettings.URL_BACKEND + 'index/update_data_user', data)
    .map((response: Response) => response.json());
  }


  updateForm(id_beneficiario, form, type_request, type_form = 0){

    var form_link = '';

    switch (type_request) {
      case 1:
      form_link = 'insert_inscription'
      break;
      case 2:
      form_link = 'insert_orientacion'
      break;
      case 3:
      form_link = 'insert_psicosocial'
      break;
      case 4:
      form_link = 'insert_preformacion'
      break;
      case 5:
      form_link = 'insert_beneficiary_jobplacement'
      break;
      
      default:
      form_link = ''
      break;
    }


    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('type_form', type_form+"")
    form_link == 'insert_beneficiary_jobplacement' ? data.append('id_beneficiary', id_beneficiario) : data.append('id', id_beneficiario)

    console.log(form)
    for(let input in form){
      console.log(input, form[input])
      data.append(input, form[input])
    }
    

    return this.http.post(AppSettings.API_ENDPOINT + form_link, data)
    .map((response: Response) => response.json());
    
  }

  saveFormation(id_beneficiario, form){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');
    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiario);

    for(let input in form){
      for(let key in form[input]){
        let value = form[input][key]
        data.append(key, value)
      }
    }

    return this.http.post(AppSettings.API_ENDPOINT + 'insert_formation', data)
    .map((response: Response) => response.json());
  }

  saveIntermediacionForm(id_beneficiario, form){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');
    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiario);

    for(let input in form){
      for(let key in form[input]){
        let value = form[input][key]
        data.append(key, value)
      }
    }

    return this.http.post(AppSettings.API_ENDPOINT + 'form_intermediacion', data)
    .map((response: Response) => response.json());
  }

  getSubSectorByIdSector(id:any){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('sector_id', id)

    return this.http.post(AppSettings.API_ENDPOINT + 'subsector_by_sectoId', data)
    .map((response: Response) => response.json());
  }

  getDominationBySubgenre(id:any){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id', id)

    return this.http.post(AppSettings.API_ENDPOINT + 'domination_by_subgenreId', data)
    .map((response: Response) => response.json());
  }

  addFileEstrategiaRetencion(url_source,  id_beneficiario){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiario)
    data.append('s3certificated', url_source)

    return this.http.post(AppSettings.API_ENDPOINT + 'subir_certificado_formacion', data)
    .map((response: Response) => response.json());
  }

  saveEstrategiaRetencion(id_beneficiary, beneficios){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');
    data.append('token', session_id)
    data.append('id_beneficiary', id_beneficiary);

    for(var i in beneficios){
      data.append('estrategia[' + i +'][id_beneficiary]', id_beneficiary);
      data.append('estrategia[' + i +'][benefit_delivered]', beneficios[i].benefit_delivered);
      data.append('estrategia[' + i +'][id_beneficio]', beneficios[i].benefit_id);
      data.append('estrategia[' + i +'][document_description]', beneficios[i].document_description);
      data.append('estrategia[' + i +'][document]', beneficios[i].document);
      data.append('estrategia[' + i +'][other]', beneficios[i].other);      
    }

    return this.http.post(AppSettings.API_ENDPOINT + 'insert_retention_strategy', data)
    .map((response: Response) => response.json());
  }

  getEstrategiaRetencion(id:number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    
    data.append('token', session_id)
    data.append('id_beneficiary', id+"")
    
    
    return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_beneficiary_by_retention_strategy', data)
    .map((response: Response) => response.json());
  }

  getAreaBySubgrupo(id:any){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id', id)

    return this.http.post(AppSettings.API_ENDPOINT + 'area_by_subgrupo', data)
    .map((response: Response) => response.json());
  }

  serviceFilter(id, service){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id', id)

    return this.http.post(AppSettings.API_ENDPOINT + service, data)
    .map((response: Response) => response.json());
  }

  sendFunelNovedad(id_participante, form, type_phase){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('user_id', id_participante);
    data.append('funnel_phase_id', type_phase);

    console.log(form)

     for(let input in form){
        let value = form[input]
        data.append(input, value)
    }

     return this.http.post(AppSettings.URL_BACKEND + 'index/novedades', data)
    .map((response: Response) => response.json());




  }

  cambiarEmpleo(id_participante){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('user_id', id_participante);


     return this.http.post(AppSettings.URL_BACKEND + 'index/cambio_empleo', data)
    .map((response: Response) => response.json());
  }


  savePermanencia(id_participante, id_permanencia, form, type){
    
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('user_id', id_participante);
    data.append('permanece_id_1', id_permanencia[0]);
    data.append('permanece_id_2', id_permanencia[1]);
    data.append('permanece_id_3', id_permanencia[2]);

    data.append('type', type);

    for(let input in form){
      data.append(input, form[input])
    }

    return this.http.post(AppSettings.URL_BACKEND + 'index/insert_permanencia', data)
    .map((response: Response) => response.json());
  }

  setDateContract(id_participante, data_start){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('user_id', id_participante);
    data.append('date_start', data_start);


    return this.http.post(AppSettings.URL_BACKEND + 'index/update_date_start', data)
    .map((response: Response) => response.json());
  }

  sendDownloadFiles(email){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('email', email);

    return this.http.post(AppSettings.URL_BACKEND + 'index/download_files', data)
    .map((response: Response) => response.json());
  }


  

}
