import { Component, OnInit, Input, ElementRef, Renderer } from '@angular/core';
import { SelectBoxComponent } from './../select-box/select-box.component';

@Component({
	selector: 'app-option-select-box',
	templateUrl: './option-select-box.component.html',
	styleUrls: ['./option-select-box.component.css']
})
export class OptionSelectBoxComponent implements OnInit {


	@Input() value;
	@Input() label: string; 
	visible:boolean = true;


	constructor(private selectBox: SelectBoxComponent) {
		this.selectBox.addOption(this);
	}

	ngOnInit() {
	}

	setValueSelect(){
		this.selectBox.setValue(this.value);
	}

	setVisible(isVisible){
		this.visible = isVisible;
	}

}
