import { Component, OnInit,ViewChild } from '@angular/core';
import { VerificadorService, PagerServicesService } from '../../../services';

@Component({
  selector: 'app-ver-historial-pila',
  templateUrl: './ver-historial-pila.component.html',
  styleUrls: ['./ver-historial-pila.component.css']
})
export class VerHistorialPilaComponent implements OnInit {

  @ViewChild('link_zip') link_zip;
  historial: any;
  historial_paged: any;
  pager: any = {};
  loadPage:boolean = false;

  constructor(private verificadorService: VerificadorService,
  			  private pagerService: PagerServicesService) { }

  ngOnInit() {
  	this.verificadorService.getHistorialPila().subscribe( data => {
  		this.historial = data.history
  		this.setPage(1);
  	})
  }

  setPage(page: number){
	    if(page < 1 || page > this.pager.totalPages){
	      return
	    }
	    this.pager = this.pagerService.getPager(this.historial.length, page, 10);
	    this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	    console.log(this.historial_paged);
	}

   downloadExcelPila(){
    this.loadPage = true;
    this.verificadorService.getExcelPila().subscribe( data => {
      if(data.error){
         alert(data.msg);
         this.loadPage = false;
         return;
      }

      this.link_zip.nativeElement.href = data.link
      this.link_zip.nativeElement.click()
      this.loadPage = false;
    });
  }

}
