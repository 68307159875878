import { 
	Directive,
	Input,
	Output,
	EventEmitter,
	HostListener,
	HostBinding  } from '@angular/core';

import { DragService } from './drag-service.service';

@Directive({
  selector: '[dropTarget]'
})
export class DropTargetDirective {

  constructor(private dragService: DragService) { }

  @Input()
  set dropTarget(options: DropTargetOptions){
  	if(options){
  		this.options = options;
  	}
  }

  @Output('onDrop') drop = new EventEmitter();

  private options: DropTargetOptions;

  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  onDragOver(evt){
   const {zone = 'zone'} = this.options;

   if(this.dragService.accepts(zone)){
   	evt.preventDefault();
   }
  }

  @HostListener('drop', ['$event'])
  onDrop(evt){
  	const data = JSON.parse(evt.dataTransfer.getData('Text'));
  	this.drop.next(data);
  }


}


export interface DropTargetOptions{
	zone?: string;
}