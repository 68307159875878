export const FORM_PREINSCRIPCION = [
	{
		"key":"first_name",
		"label":"PRIMER NOMBRE",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"second_name",
		"label":"SEGUNDO NOMBRE",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"surname",
		"label":"PRIMER APELLIDO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"second_surname",
		"label":"SEGUNDO APELLIDO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"ethnicity_id",
		"label":"ETNIA",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"etnia",
		"verify":false,
		"editable":true
	},
	{
		"key":"source_announcement",
		"label":"FUENTE DE LA CONVOCATORIA",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":false,
		"editable":true
	},

]