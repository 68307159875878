import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';


import { OptionSelectBoxComponent } from './../option-select-box/option-select-box.component';

@Component({
	selector: 'app-select-box',
	templateUrl: './select-box.component.html',
	styleUrls: ['./select-box.component.css'],
	providers: [
	{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SelectBoxComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => SelectBoxComponent),
		multi: true,
	}
	]
})

export class SelectBoxComponent implements OnInit, Validator, ControlValueAccessor {

	onChange: any = () => {};
	onTouched: any = () => {};
	options: Array<OptionSelectBoxComponent> = [];
	fill_options: Array<OptionSelectBoxComponent> = [];
	text: string;
	openList:boolean;

	@Input('value') _value;
	@Input() name:string;

	get value(){
		return this._value;
	}

	set value(val){

		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	constructor() { }

	ngOnInit() {
	}

	addOption(option: OptionSelectBoxComponent) : void{
		this.options.push(option);
		this.fill_options = Object.assign([], this.options);
	}

	writeValue(value){
		if(value){
			this.value = value;
		}
	}

	registerOnChange(fn: any){
		this.onChange = fn;
	}

	registerOnTouched(fn: any){
		this.onTouched = fn;
	}

	fillList(event){
		let search = event.target.value.toLowerCase();
		
		this.fill_options = this.options.map( opt => {
			opt.setVisible(opt.label.toLowerCase().indexOf(search) > -1);
			return opt;
		});

	}

	closeList(){
		setTimeout( _ => this.openList = false, 500)
	}

	setValue(value){
		let optionValue = this.options.filter( opt => opt.value == value)[0];

		this.value = value;
		this.text = optionValue.label;
		this.openList = false;
	}

	public validate(c: FormControl){
		return (this._value) ? null : {
			inputError: {
				valid: false,
			},
		};
	}
}
