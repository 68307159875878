import * as _ from 'underscore';

export class Beneficiario {
	estado: number
	operator_id:number
	username: string
	user: string
	name_document: string
	document: string
	first_name: string
	second_name: string
	surname: string
	second_surname: string
	name_municipality: string
	name_gender: string
	gender_id: number
	birthday: Date
	phone: string
	celphone_1: string
	celphone_2: string
	name_municipality_atencion:string
	name_zone:string
	corregimiento:string
	sidewalk:string
	location:string
	locality:string
	neighborhood:string
	address:string
	date_capture: Date
	dateupdateuploadPBIS: Date
	age: number
	ti_united: number
	exist_united: number
	score_united: number
	ti_victim: number
	exist_victim: number
	score_victim: number
	ti_mfa: number
	mfa_exist: number
	mfa_score: number
	ti_jea: number
	exist_jea: number
	score_jea: number
	ti_sisben: number
	score_sisben: number
	id_area_sisben: number
	total_score: number
	s3documento:string
	s3diploma: string
	s3preinscription: string
	s3acuerdo: string
	user_xml_id:number

	constructor(values: Object = {}){

		// const ignore_null = ['s3documento', 's3diploma', 's3preinscription', 's3acuerdo'];
	 //    for(let obj of _.keys(values)){
	 //    	if(ignore_null.indexOf(obj) <= -1)
	 //    		values[obj] = (values[obj] == null || values[obj].length <= 0) ? '-' : values[obj];

	    	
 	//      } 

	    Object.assign(this, values);
	  }

	  get length(){
	  	return  _.keys(this).length;
	  }

	  isUnidos(){
	  	return this.exist_united == 1;
	  }

	  isVictimas(){
	  	return this.exist_victim == 1;
	  }
	  isJovenesEnAccion(){
	  	return this.exist_jea == 1;
	  }
	  isFamiliasEnAccion(){
	  	return this.mfa_exist == 1;
	  }

	  isOperador(id_operador){
	  	return this.operator_id == id_operador;
	  }

	  sinPoblacion(){
	  	return !this.isUnidos() && !this.isVictimas() && !this.isJovenesEnAccion() && !this.isFamiliasEnAccion() 
	  }






}
	