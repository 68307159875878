import { QuestionBase } from './question-base';

export class TextAreaQuestion extends QuestionBase<string>{
	controlType = 'textarea';
	maxlength:number;
	

	constructor(options: {} = {}){
		super(options);
		this.maxlength = (options['type'] == 'text') && null;
	}
}
