import { Component, OnInit } from '@angular/core';
import { Beneficiario } from '../../../struct';
import { BeneficiarioDataService, PerfilacionService, VerificadorService, AnalitycsService } from '../../../services';
import { AssignGroupWrappedService } from '../../../shared/assign-group-wrapped.service' ;
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { Router, ActivatedRoute} from '@angular/router';
import { AppSettings } from '../../../appSettings';

@Component({
  selector: 'app-detalle-beneficiario',
  templateUrl: './detalle-beneficiario.component.html',
  styleUrls: ['./detalle-beneficiario.component.css']
})
export class DetalleBeneficiarioComponent implements OnInit {


  model:any = {};
  id_beneficiario:number;
  beneficiario: Beneficiario;
  atributosFunnel: any;
  loading:boolean = true;
  can_open:boolean;
  assign_popup = false;
  groups_prefilamiento:any = [];
  states:any = [];
  stateRoute:number;
  
  isFillFormInscripcion:boolean;
  isFillFormOrientacion:boolean;
  isFillFormPreformacion:boolean;
  isFillFormFormacion:boolean;
  isFillFormPostformacion:boolean;
  isFillFormPsicosocial:boolean;
  isFillFormIntermediacion:boolean;
  isFillFormColocacion:boolean;
  isFillFormEstrategiaRetencion:boolean;
  isFillFormPermanenciaTres:boolean;
  isFillFormPermanenciaSeis:boolean;

  loadInscription:boolean;
  loadOrientacion:boolean;
  loadPsicosocial:boolean;
  loadEstrategiaRetencion:boolean;
  loadPreformacion:boolean;
  loadFormacion:boolean;
  loadPostformacion:boolean;
  loadIntermediacion:boolean;
  loadColocacion:boolean;
  loadPermanenciaTres:boolean;
  loadPermanenciaSeis:boolean;

  type_user:string;
  type_verificacion:number;
  headerPreinscripcion:string = '';
  headerFormacion:string = '';
  headerColocacion:string = '';
  comentarioDelloite:string = '';
  commentColocacion:string = '';
  statusRevisionFormacion:any;
  refresh_postformacion:boolean = true;
  observacionFormacion:string;
  open_pop_document:boolean = false;
  file_url:string;
  type_document:string;

  notifiacion_colocacion:any;
  notificacion_permanencia3:any;
  notificacion_permanencia6:any;

  options:any;

  constructor(private beneficiarioService: BeneficiarioDataService,
    private perfilacionService: PerfilacionService,
    private router: Router, 
    private route: ActivatedRoute,
    private assignGroupService: AssignGroupWrappedService,
    private dropService:DropsWrapperService,
    private verificadorService:VerificadorService,
    private analitycsService:AnalitycsService) { }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');



    this.states = [
    {id:'1', name:'PREINSCRIPCIÓN', checked:true, color:' #f1c154'},
    {id:'2', name:'INSCRIPCIÓN', checked:false, color:'#ef893a'},
    {id:'3', name:'PERFILAMIENTO', checked:false, color:'#f55d52'},
    {id:'4', name:'ORIENTACIÓN OCUPACIONAL', checked:false, color:'#d53e33'},
    {id:'5', name:'PRUEBAS PREFORMACIÓN', checked:false, color:'#5d1b7b'},
    {id:'6', name:'FORMACIÓN', checked:false, color:'#9a5cb4'},
    {id:'7', name:'PRUEBAS POST-FORMACIÓN', checked:false, color:'#2ea8ed'},
    {id:'8', name:'ACOMPAÑAMIENTO PSICOSOCIAL', checked:false, color:'#0e69ad'},
    {id:'9', name:'ESTRATEGIAS DE RETENCIÓN', checked:false, color:'#122a6e'},
    {id:'10', name:'INTERMEDIACIÓN', checked:false, color:'#0b6b19'},
    {id:'11', name:'COLOCACIÓN LABORAL', checked:false, color:'#179a8f'},
    {id:'12', name:'ACOMPAÑAMIENTO', checked:false, color:'#39ca74'}
    ]

    this.id_beneficiario = this.route.params['value'].id;

    if(this.id_beneficiario){
      this.beneficiarioService.getBeneficiarioById(this.id_beneficiario).
      subscribe( data => {
        if(!data.error){
          this.beneficiario = new Beneficiario(data.beneficiary[0]);

          this.beneficiario['total_contrato'] = data.total_dias_contrato;
          this.beneficiario['public_services'] = data.public_services;
          this.atributosFunnel = data.atributos;
          this.beneficiario.user_xml_id = this.id_beneficiario;
          this.model = this.beneficiario;
          

          
          this.type_verificacion = this.route.params['value'].type_verify;

          this.isFillFormInscripcion = this.beneficiario['completa_inscripcion'] == 2;
          this.isFillFormOrientacion = this.beneficiario['completa_orientacion'] == 2;
          this.isFillFormPreformacion = this.beneficiario['completa_preform'] == 2;
          this.isFillFormFormacion = this.beneficiario['completa_formacion'] == 2;
          this.isFillFormPostformacion = this.beneficiario['completa_postform'] == 2;
          this.isFillFormPsicosocial = this.beneficiario['completa_psocial'] == 2;
          this.isFillFormIntermediacion = this.beneficiario['completa_intermediacion'] == 2;
          this.isFillFormColocacion = this.beneficiario['completa_colocacion'] == 2;
          this.isFillFormEstrategiaRetencion = this.beneficiario['completa_retencion'] == 2;
          this.isFillFormPermanenciaTres = this.beneficiario['completa_permanecia1'] == 2;
          this.isFillFormPermanenciaSeis = this.beneficiario['completa_permanecia2'] == 2;

          this.notifiacion_colocacion = data.notificacion_colocacion;
          this.notificacion_permanencia3 = data.notificacion_permanencia3;
          this.notificacion_permanencia6 = data.notificacion_permanencia6;


            let name_stage = this.beneficiario['name_stage'];
            if(this.beneficiario['verificacion_permancencia3'] == 1){
              this.beneficiario['name_stage'] = name_stage + ' - CUMPLE PERMANENCIA 3 MESES'
            }

            if(this.beneficiario['verificacion_permancencia6'] == 1){
              this.beneficiario['name_stage'] = name_stage + ' - CUMPLE PERMANENCIA 6 MESES'
            }

          switch (parseInt(this.beneficiario['type_document_id'])) {
            case 153:
              this.type_document = 'CC'
              break;
            case 154:
              this.type_document = 'TI'
              break;
            case 155:
              this.type_document = 'CE'
              break;
            case 156:
              this.type_document = 'RC'
              break;
            case 158:
              this.type_document = 'SIN'
              break;
            case 162:
              this.type_document = 'NUIP'
              break;

            default:
               this.type_document = 'NA'
          }

          this.perfilacionService.getGroupsByBeneficiary(this.id_beneficiario)
          .subscribe( data => {
            this.groups_prefilamiento = data.grupos;
          });

          this.dropService.fullValidateDrop().subscribe( d => { this.options = d}, err=>console.log(err), () => {this.dropService.addOptions(this.options)});

          this.dropService.addOptions$.subscribe( options => {
            this.loading = false
            this.stateRoute = (this.canNextSteps()) ? this.route.params['value'].state : 0;
            console.log(this.stateRoute)
          });

          this.verificadorService.getVerificacionInscripcion(this.id_beneficiario)
          .subscribe(data =>  {



            if(!data.error && data.status_deliotte.length > 0){
              this.headerPreinscripcion = '(' + data.status_deliotte[0]['name_type_fulfill'] + ')'
              this.comentarioDelloite = data.status_deliotte[0]['comment'];
            }


            if(!data.error && data.status_colocacion.length > 0){
              this.headerColocacion = '(' + data.status_colocacion[0]['name_type_fulfill'] + ' Empleabilidad) '
              this.commentColocacion = data.status_colocacion[0]['comment'];
            }
          });

        }else{
          if(!data.noaccess)
            this.router.navigate(['/participantes'])
          else
            this.router.navigate(['/login'])
        }


        this.getDisabledNovedad(7);

      });


    }
  }

  openTab(event, tab, isClose){

    let button = "";
    let user = localStorage.getItem("username");

    if(!isClose){
       switch (tab) {
        case 1:
          this.loadInscription = event.open;
          button = "inscripcion"
          break;
        case 3:
          this.loadOrientacion = event.open;
          button = "orientacion"
          break;
        case 4:
          this.loadPsicosocial = event.open;
          button = "psicosocial"
          break;
        case 5:
          this.loadEstrategiaRetencion = event.open;
          button = "retencion"
          break;
        case 6:
          this.loadPreformacion = event.open;
          button = "preformacion"
          break;
        case 7:
          this.loadFormacion = event.open;
          button = "formacion"
          break;
        case 8:
          this.loadPostformacion = event.open;
          button = "postformacion"
          break;
        case 9:
          this.loadIntermediacion = event.open;
          button = "intermediacion"
          break;
        case 10:
          this.loadColocacion = event.open;
          button = "colocacion"
          break;
        case 11:
          this.loadPermanenciaTres = event.open;
          button = "permanencia3"
          break;
        case 12:
          this.loadPermanenciaSeis = event.open;
          button = "permanencia6"
          break;
        
        default:
          break;
      }

    }

    if(event.open){
      console.log(button, user) 
      this.analitycsService.eventButton(button, user);
    }

   
    
  }

  loadEndPreinscripcion(event){
    console.log(event)

    if( event.dataUpdate){
      this.beneficiario.first_name = event.dataUpdate.first_name
      this.beneficiario.second_name = event.dataUpdate.second_name
      this.beneficiario.surname = event.dataUpdate.surname
      this.beneficiario.second_surname = event.dataUpdate.second_surname  
    }
    

  }


  loadingFile(){
    //this.loading = true;
  }  

  changeProfileImage(url_source){
    this.beneficiarioService.editImageAvatar(url_source.url, this.id_beneficiario)
    .subscribe( data => {
      this.loading = false;
    });
  }

  submitFile(url_source, name_input){
    this.beneficiarioService.addFile(url_source.url, name_input, this.id_beneficiario)
    .subscribe( data => {
      if(name_input == 's3acuerdobeneficiario'){
        this.isFillFormInscripcion = data.completa;
        this.model.name_stage = data.status;
      }

      this.loading = false;
    });
  }

  

  openPopUpAssign(){
    this.assignGroupService.addBeneficiaries([this.beneficiario]);
    this.assign_popup = true;
    document.body.style.overflow = 'hidden';
  }

  
  closePopUpAssign($event){
    this.assign_popup = event['open_popup'];
    document.body.style.overflow = '';
  }

  closeDocumentPop(event){
    console.log(event)
    this.file_url = null;
  }

  openDocument(file){
    this.file_url = AppSettings.URL_BACKEND + file;
  }

  addNewGroup(event){
    if(!event.is_asgin){
      this.groups_prefilamiento.push(event.group[0])  
    }
  }


  deleleGroupPerfilamiento(event, group){
    event.stopPropagation();
    if(confirm('Desea eliminar el beneficario ' 
      + ' del Grupo ' + group.name_group)){
      this.perfilacionService.deteleBeneficiaryByGroup(group.profiling_group_id, this.id_beneficiario).subscribe(
        data => {
          if(!data.error){
            for(let op in this.groups_prefilamiento){
              if(data.beneficiarygroup[0].profiling_group_id == this.groups_prefilamiento[op].profiling_group_id){
                this.groups_prefilamiento.splice(op, 1);
              }
            }
          }else{
            alert(data.msg);
          }
        });
    }
  }

  getAttrFunel(id_phase){
    const phase = this.atributosFunnel.map( a => {
      if(a.funnel_phase_id == id_phase){
        return a.funnel_status_id;
      }
        
    }).filter( a => a != undefined);


    if(phase[0]){
      switch (phase[0]) {
        case 1: return 'Activo'
        case 2: return 'Salio'
        case 3: return 'Pendiente'
        default:
          return ''
      }  
    }

    return '';
  }

  getIdNovedadFunel(id_phase){
    const phase = this.atributosFunnel.filter( a => {
      if(a.funnel_phase_id == id_phase)
        return a;
    });

    return phase[0];
  }

   getNovedadFunel(id_phase){
    const phase = this.atributosFunnel.map( a => {
      if(a.funnel_phase_id == id_phase)
        return a.option;
    }).filter( a => a != undefined);

    if(phase[0]){

      return phase[0];
    }

    return '';
  }

  getDisabledNovedad(phase_id){
     const phase = this.atributosFunnel.map( a => {
       if(phase_id == 3){
       console.log(phase_id, a)}
        return {[a.funnel_phase_id] : a.funnel_status_id};
      }).filter( a => { return a != undefined  && a[Object.keys(a)[0]] != 1 && a[Object.keys(a)[0]] != null });

     if(phase_id == 3){
       console.log("PHASE:", phase)  
     }
     

    let disabled = true;


     if(phase[0] ){
        if(phase_id == 3){
       console.log(phase[0] , phase_id, Object.keys(phase[0])[0])
     }
       disabled = !(phase_id > Object.keys(phase[0])[0]) ;
     }


     return disabled;
  }

  getActivateNovedad(id_fase){
    let prev_fase = id_fase - 1;
    let ret  = this.getDisabledNovedad(id_fase);

  

    if(prev_fase == 0)
        return false;

    let status_prev_fase = this.getIdNovedadFunel(prev_fase);
    let status_fase = this.getIdNovedadFunel(id_fase);

    if(id_fase == 7){
      console.log(ret)
      console.log(status_prev_fase)
      console.log(status_fase)
    }
    

    if(status_fase){
      if(status_fase.funnel_status_id){
        console.log("STATUS:", id_fase, status_fase.funnel_status_id, this.getDisabledNovedad(id_fase));
        ret = status_fase.funnel_status_id == 1 && this.getDisabledNovedad(id_fase); 
      }
    }else if(status_prev_fase){
      if(status_prev_fase.funnel_status_id){
         ret = status_prev_fase.funnel_status_id == 1 && this.getDisabledNovedad(id_fase);     
      }
    }




    return ret; 
  }

  getColorState(state){
    let curr_state = this.states.filter( _state => this.model['stage_id'] == _state.id);
    return curr_state[0].color;
  }

  getActiveState(state){
    if(state <= this.model['stage_id'])
      return true;
  }

  loadEnd(event){
    this.loading = event.loading;
  }

  getStatusCriterios(event){
    this.headerPreinscripcion = '(' + event.status + ')';
  }

  getStatusFormacion(event){
    this.headerFormacion = '(' + event.status + ')';
  }

  fillFormInscripcion(event){
    setTimeout(_ => {
      this.isFillFormInscripcion = event.isFill 
    })
  }




  fillFormApoyoPsico(event){
    setTimeout(_ => {
      this.isFillFormPsicosocial = event.isFill  
    })

  }

  fillFormPreformacion(event){
    setTimeout(_ => {
      this.isFillFormPreformacion = event.isFill
      this.model.name_stage = event.status;
    })
  }

  fillFormPostformacion(event){
    setTimeout(_ => {
      this.isFillFormPostformacion = event.isFill
      this.model.name_stage = event.status;
    })
  }

  fillFormFormacion(event){
    setTimeout(_ => {
      this.isFillFormFormacion = event.isFill
      this.model.name_stage = event.status;
    })
  }

  fillFormIntermediacion(event){
    setTimeout(_ => {
      this.isFillFormIntermediacion = event.isFill
    })
  }

  fillFormOrientacion(event){
    setTimeout(_ => {
      this.isFillFormOrientacion = event.isFill
    })
  }


  fillFormPerfilacion(){
    return this.groups_prefilamiento.length > 0 && this.getActivateNovedad(3)
  }


  fillFormColocacion(event){
    setTimeout(_ => {
      this.isFillFormColocacion = event.isFill
    })
  }


  fillFormPermanenciaTres(event){
    setTimeout(_ => {
      this.isFillFormPermanenciaTres = event.isFill
    })
  }

  fillFormPermanenciaSeis(event){
    setTimeout(_ => {
      this.isFillFormPermanenciaSeis = event.isFill
    })
  }


  fillFormEstrategiaRetencion(event){
   setTimeout(_ => {
      this.isFillFormEstrategiaRetencion = event.isFill
    }) 
  }



  submitPreformacion(event){
    if(event.type_form == 1)
      this.refresh_postformacion = event.refresh;

  }

  
  canNextSteps(){
    return this.beneficiario['stage_id'] > 1 && this.beneficiario['stage_id'] != 4
  }

  cantEdit(){
    return parseInt(this.type_user) == 3
              || parseInt(this.type_user) == 4
                || parseInt(this.type_user) == 5;
  }

  canValidateFormation(){
    return this.type_user == '3' &&
    this.isFillFormPreformacion &&
    this.isFillFormFormacion &&
    this.isFillFormPostformacion;
  }

   canEnabableColocacion(){
    return this.isFillFormIntermediacion == true;
  }

  canEnabableIntermediacion(){
    return this.isFillFormPreformacion &&
    this.isFillFormFormacion &&
    this.isFillFormPostformacion; 
  }

  canEnabablePermanenciaTres(){
    return this.beneficiario['total_dias_contrato'] > 0;
  }

  canEnabablePermanenciaSeis(){
    return this.beneficiario['total_dias_contrato'] > 90;
  }

}
