import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../appSettings';

@Component({
  selector: 'app-subir-deloitte',
  templateUrl: './subir-beneficiarios.component.html',
  styleUrls: ['./subir-beneficiarios.component.css']
})
export class SubirDeloitteComponent implements OnInit {

   title_page:any;
   urlIframe:any;

  constructor() { }

  ngOnInit() {
  	var token = localStorage.getItem('session_id')
  	this.title_page = 'Subir Archivo Elegibilidad';
  	this.urlIframe = AppSettings.URL_BACKEND + 'upload_file_deloitte/' + token;
  }

}
