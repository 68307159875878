import { Component, OnInit } from '@angular/core';
import { AlertasService, AnalitycsService } from '../../../services';

@Component({
  selector: 'app-colocacion-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
  providers: [AlertasService]
})
export class ColocacionAlertaComponent implements OnInit {


  alert_data: any = [];
  date_interval: any = [];
  alertas:any = [];
  link_form:any;

  constructor(private alertService:AlertasService,
              private analyticsService: AnalitycsService) { }


  ngOnInit() {
    this.link_form = 'upload_alert_colocacion.xlsx';
  	this.date_interval = [
    { label: '15 días', level: 1, value: 15},
    { label: '20 días', level: 1, value: 20},
    { label: '35 días', level: 1, value: 35},
    { label: '45 días', level: 1, value: 45},
    { label: '55 días', level: 2, value: 55},
    { label: '65 días', level: 2, value: 65},
    { label: '75 días', level: 2, value: 75},
    { label: 'Mayor a  75 días', level: 2, value: 76},
    { label: 'Total', level: 0, value: null},
    ]

    this.alertService.getAlertColocacion()
    .subscribe( data => {
      console.log(data);
      let alert_data_1 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['colocacion' + (index + 1)]).filter( o => o != undefined)
        }  
      })

      this.alertas = [{
        nombre: 'Participantes con Intermediación completa que no han sido colocados',
        table: alert_data_1
      }]

    });


  }

  onExportAlert(name){
    this.analyticsService.eventAction("Alertas", "Descargar:Colocacion");
  }

}
