import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { OperadorDataService, UtilService, PagerServicesService, AnalitycsService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { GraphComponent } from '../../../components/graph/graph.component';
import { AppSettings } from '../../../appSettings'
import { QuestionService } from '../../../components/forms/question.service';

@Component({
	selector: 'app-novedades',
	templateUrl: './novedades.component.html',
	styleUrls: ['./novedades.component.css']
})
export class NovedadesComponent implements OnInit {

	@ViewChild('name_pdf') name_pdf;
	@ViewChild('comentario_pdf') comentario_pdf;
	@ViewChildren('graphs') components:QueryList<GraphComponent>;

	loadPage:boolean;
	tabla_caidas:any = [];
	grafica:any = [];
	export_data:any = {};
	data_set:any = [];
	data_set_op:any = [];
	url_form:string =  AppSettings.URL_BACKEND + 'pdf/dashboard_general.pdf';
	url_excel:string;
	color_general:any = [
	['#fe569b', '#feaacc', '#777777', '#c3c0c0', '#ffdeeb'], 
	['#4472c4', '#a1b8e2', '#777777', '#c3c0c0', '#dae2f3'], 
	['#ff8f40', '#ffc69f', '#777777', '#c3c0c0', '#fdefe7'],
	['#179a8f', '#8accc6', '#777777', '#c3c0c0', '#ffdeeb'], 
	['#f9cd47', '#fbe5a2', '#777777', '#c3c0c0', '#fff7e1'],
	

	['#4472c4', '#698ed0', '#777777', '#c3c0c0', '#dae2f3'], 
	['#f4b183', '#f6c19b', '#777777', '#c3c0c0', '#fdefe7'],
	['#ffd966', '#ffe185', '#777777', '#c3c0c0', '#fff7e1'],

	];
	color_convention = ['#717171','#f1c154','#ef893a','#d53e33','#0e69ad','#122a6e','#0b6b19','#179a8f','#39ca74','#89e0ac','#89e0ac'];
	sub_caption = ['Etapa', 'Meta', 'Activos','Salio', 'Pendiente','% Cump']
	dropsOptions: any;
	operadores:any = []
	ciudadesOperador: any;
	data_set_motivos: any;
	data_set_motivos_espejo: any;
	model:any = {};
	general_funnel:boolean;
	validInput:boolean;
	token:string;
	type_graph:number;
	cut_dataset:any = [];
	url_pdf:string = AppSettings.URL_BACKEND;

	historial: any;
	historial_paged:any;
	pager: any = {};
	type_user:any;
	labelsMotivos:any = {};


	dropsNovedades = [{id:1, name:'Salio'}, {id:2, name:'Pendiente'}]
	sub_caption_motivos = ['Criterio', 'Desde', 'Participantes', 'Porcentaje']
	sub_caption_tipos = ['Criterio', 'Participantes', 'Porcentaje']

	form_filter:any;
	form_filter_caida:any;

	graphs_motivos = [];

	headers:any = {};
	titles_novedades:any = [];

	id_novedad:any;
	load_content:boolean;

	amplacion_data = {
		bis1a:true, bis1b:true
	}

	amplacion_mirror = {
		left: {bis1a:true, bis1b:true},
		right: {bis1a:true, bis1b:true}
	}

	constructor(private operadorService:OperadorDataService,
		private utilService:UtilService,	
		private dropService:DropsWrapperService,
		private router: Router, 
		private route: ActivatedRoute,
		private pagerService: PagerServicesService,
		private qService:QuestionService,
		private analyticsSercice: AnalitycsService) { }

	ngOnInit() {


		this.headers = {
			alto: 'Alto',
			bajo: 'Bajo',
			medio: 'Medio',
			medio_alto: 'Medio alto',
			medio_bajo: 'Medio bajo',
		}



		this.titles_novedades = ['NIVEL DE SATISFACCIÓN DEL PARTICIPANTE', 'NIVEL DE SATISFACCIÓN DE LA EMPRESA', 'RIESGO DE DESERCIÓN LABORAL'];
		  
		this.labelsMotivos = {
			'no_elegibles': 'No Elegibles',
			'pendiente': 'Pendientes',
			'no_contesta':'No contestan',
			'consiguio_trabajo':'Ya consiguieron trabajo',
			'no_interesado':'No interesados',
			'no_llega': ' Contactados interesados pero no llegaron',
			'pendiente_aplazado': 'Pendientes / Aplazados',
			
			//desde la empresa
			'expecativas': 'No cumple con las expectativas esperadas en términos de productividad*Desde la empresa',
			'horarios': 'No cumple los horarios establecidos*Desde la empresa', 
			'subordinacion': 'No gusta de la subordinación*Desde la empresa', 
			
			//desspe el participante
			'agusto_empresa': 'No estaba agusto con la Empresa*Desde el participante', 
			'horario': 'El horario y salario no compensaba*Desde el participante', 
			'ambiente': 'No le gusto el ambiente de trabajo*Desde el participante', 
		}

		this.dropService.minValidateDrop().subscribe( d => {
			this.dropService.addOptions(d)
			this.qService.getQuestionsFilter(1)
				.map( form => {
					
					this.form_filter = (this.type_user == 2) ? 
						form.filter( f => f.key != 'operador' && f.key != 'ciudad' ) : form

					this.form_filter[1].options =  this.form_filter[1].options.filter( o => o.key != 8)
				})

				console.log("form_filter", this.form_filter)

			this.qService.getQuestionsFilter(2)
				.map( form => {
					this.form_filter_caida = (this.type_user == 2) ? 
						form.filter( f => f.key != 'operador' && f.key != 'ciudad' ) : form
					
				})

				console.log(this.form_filter_caida )
		});
		this.token = localStorage.getItem('session_id')
		this.type_user = localStorage.getItem('user_type')

		let colors_motivos = JSON.parse(JSON.stringify(this.color_convention));
		colors_motivos.shift();



		this.data_set_motivos = {
			id: 1,
			data_set: [],
			title: 'NOVEDADES CAÍDAS',
			color: colors_motivos,
			type: 'PieChart',
			sub_caption: this.sub_caption_motivos,
			download_row:true
		}

		this.data_set_motivos_espejo = {
			id: 2,
			data_set: [],
			title: 'NOVEDADES CAÍDAS',
			color:  colors_motivos,
			type: 'PieChart',
			sub_caption: this.sub_caption_motivos,
			download_row:true,
			label_total: ""
		}

		this.route.params.subscribe(params => {
			this.data_set = null;
			this.operadores = [];
			this.loadPage = true;
			this.id_novedad = null;

			this.amplacion_data = {
				bis1a:true, bis1b:true
			}

			this.amplacion_mirror = {
				left: {bis1a:true, bis1b:true},
				right: {bis1a:true, bis1b:true}
			}

			this.id_novedad = params.type;

			console.log(this.id_novedad, this.type_user)



				this.dropService.addOptions$.subscribe( options => {
					this.dropsOptions = options; 
					this.model.operador = ""
					this.model.ciudad = ""
					this.model.novedad = ""
					this.model.etapa = ""
				})


				if(Number(params.type) == 4){

					this.operadorService.getFunnelData(3, null, null, null, 1).
						subscribe( data => {
							this.loadFunnels(data, Number(params.type), "data_set_op");
						});	
				}else{
					this.operadorService.getFunnelData(Number(params.type)).
						subscribe( data => {
							this.loadFunnels(data, Number(params.type), "data_set");
						});
				}


				this.utilService.getMergeNovedadesPermanencia(1)
						.subscribe( data => {
							data.map( (d, i) => {
								let data_set = this.getSeriesGraph(this.headers, d.novedad)

								this.graphs_motivos.push([
									{
										id: 1,
										data_set: data_set,
										title: this.titles_novedades[i],
										color:  colors_motivos,
										type: 'PieChart',
										type_data: (i+1),
										sub_caption: this.sub_caption_tipos,
										download_row: true,
										with_filters: true
									},
									{
										id: 2,
										data_set: data_set,
										title: this.titles_novedades[i],
										color:  colors_motivos,
										type: 'PieChart',
										type_data: (i+1),
										sub_caption: this.sub_caption_tipos,
										download_row: true,
										with_filters: true
									},
								]);

							})

						})
		})
	}

	getSeriesGraph(name_serie, data){
		return Object.keys(data).map( (serie, index) => {
			return [name_serie[serie], data[serie]]
		});
	}

	fillDataMirror(formData){

		console.log(formData)


		// if(this.loadPage)
		// 	return;


		if(formData.etapa == "", formData.novedad == ""){
			alert('Debe seleccionar la etapa y la novedad')
			return;
		}

		this.loadPage = true;

		this.utilService.getMotivosReport(formData.etapa, formData.novedad, formData.operador, formData.ciudad, formData)
			.subscribe( data => {

				let novedades = (formData.etapa == 9 || formData.etapa == 10) ? 
								["Despedidos", "Renunciaron", "Finalizaron contrato"]
									: ["Salio", "Pendiente"]



				console.log(data);

				let data_fill = Object.keys(data.novedad).map(item => {
						return [
							this.labelsMotivos[item], 
							data.novedad[item]
						]
						
					})					

				console.log(data_fill);

				let custom_header = false;
				let custom_fill = data_fill.map( data => {
					if(data[0].indexOf('*') > -1)
						custom_header = true;
				})

				console.log(this.form_filter)

				if(formData.graphic == 1){
					this.data_set_motivos.data_set = data_fill;
					

					if(custom_header){
						this.data_set_motivos.sub_caption = this.sub_caption_motivos
					}else{
						this.data_set_motivos.sub_caption = this.sub_caption_tipos
					}

					if(data.total > 0)
						this.data_set_motivos.label_total = data.total + " Participante(s) " + novedades[formData.novedad - 1];


				}

				if(formData.graphic == 2){
					this.data_set_motivos_espejo.data_set = data_fill;
					if(data.total > 0)
						this.data_set_motivos_espejo.label_total = data.total + " Participante(s) " + novedades[formData.novedad - 1];

					if(custom_header){
						this.data_set_motivos_espejo.sub_caption = this.sub_caption_motivos
					}else{
						this.data_set_motivos_espejo.sub_caption = this.sub_caption_tipos
					}
				}



				this.loadPage = false;
			})
	}

	fillDataMirrorCaidas(formData, index, type_data){

		// if(this.loadPage)
		// 	return;

		console.log(formData)
		if(formData.mes == ""){
			alert('Debe seleccionar un mes')
			return;
		}

		let colors_motivos = JSON.parse(JSON.stringify(this.color_convention));
		colors_motivos.shift();
		this.loadPage = true;


		this.utilService.getNovedadesPermanencia(formData.mes, type_data, formData.operador, formData.ciudad, formData)
			.map(response => response.json())
			.subscribe( d => {
				let data_set = this.getSeriesGraph(this.headers, d.novedad)
				this.graphs_motivos[index][formData.graphic - 1].data_set = data_set;
				this.loadPage = false;
			});
	}




	fillData(){

		if(this.loadPage)
			return;

		this.loadPage = true;
		this.utilService.getMotivosReport(this.model.etapa, this.model.novedad, this.model.operador, this.model.ciudad)
			.subscribe( data => {

				this.data_set_motivos = Object.keys(data.novedad).map(item => {
					console.log(item, data.novedad[item])
					return [
						this.labelsMotivos[item], 
						data.novedad[item]
					]
					
				})
				this.loadPage = false;
			})
		
	}

	fillFuneles(field){

		if(this.loadPage)
			return;

		this.loadPage = true;
		this.amplacion_data[field] = !this.amplacion_data[field];
		console.log(this.id_novedad)
		
		if(Number(this.id_novedad) == 4){
			this.type_graph = 3;
			this.operadorService.getFunnelData(3, null, null, this.amplacion_data, 1).
				subscribe( data => {
					this.loadFunnels(data,Number(this.id_novedad), "data_set_op");
				});	
		}else{
			this.type_graph = Number(this.id_novedad);
			this.operadorService.getFunnelData(Number(this.id_novedad), null, null, this.amplacion_data).
				subscribe( data => {
					this.loadFunnels(data,Number(this.id_novedad), "data_set");
				});
		}

			// 	this.fillDataCaidas();

		// this.operadorService.getFunnelData(Number(this.id_novedad), null, null, this.amplacion_data).
		// subscribe( data => {

		// 	this.type_graph = Number(this.id_novedad);
		// 	this.general_funnel = false;
		// 	this.model = {};

		// 	if(data.operator_hash){
				
		// 		this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_operador.pdf';

		// 		this.data_set = data.operator_hash.map( operator => {
		// 			operator.operator.name = operator.operator.name_operator;

		// 			this.operadores.push(operator.operator);
		// 			return operator.milestone.map( mil => {

		// 				mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 0.01;
		// 				mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.9;


		// 				if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
		// 					mil.dato_esperado = 0;
		// 					mil.activos = 0;
		// 				}


		// 				if(mil.dato_esperado == 0.01){
		// 					mil.dato_esperado = (mil.activos > 300 ) ? 1 : 0.01;	
		// 				}else{

		// 				}


		// 				return [
		// 				mil.name,
		// 				{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
		// 				{"v":Number(mil.activos), "f":mil.activos},
		// 				'stroke-color: #ffffff; stroke-width: 1;',
		// 				{"v":Number(mil.salen), "f":mil.salen},
		// 				'stroke-color: #ffffff; stroke-width: 1;',
		// 				{"v":Number(mil.pendientes), "f":mil.pendientes},
		// 				'stroke-color: #ffffff; stroke-width: 1;'
		// 				]		
		// 			})

		// 		})
		// 	}

		// 	if(data.city_has){
		// 		this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_ciudades.pdf';
		// 		this.data_set = data.city_has.map( city => {

		// 			city.city.name = city.city.name_city;
		// 			this.operadores.push(city.city);
		// 			return city.milestone.map( mil => {

		// 				mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 1;
		// 				mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.9;

		// 				if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
		// 					mil.dato_esperado = 0;
		// 					mil.activos = 0;
		// 				}

		// 				if(mil.dato_esperado == 0.01){
		// 					mil.dato_esperado = (mil.activos > 300) ? 1 : 0.01;
		// 				}


		// 				return [
		// 				mil.name,
		// 				{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
		// 				{"v":Number(mil.activos), "f":mil.activos},
		// 				'stroke-color: #ffffff; stroke-width: 1;',
		// 				{"v":Number(mil.salen), "f":mil.salen},
		// 				'stroke-color: #ffffff; stroke-width: 1;',
		// 				{"v":Number(mil.pendientes), "f":mil.pendientes},
		// 				'stroke-color: #ffffff; stroke-width: 1;'
		// 				]		
		// 			})

		// 		})
		// 	}

		// 	if(data.milestone){
		// 		this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_general.pdf';
		// 		this.general_funnel = true;
		// 		this.operadores.push({
		// 			name: 'General'
		// 		})

		// 		this.getCaidas(data.milestone);

		// 		this.data_set = [data.milestone.map( mil => {

		// 			mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 1;
		// 			mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.9;

		// 			if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
		// 				mil.dato_esperado = 0;
		// 				mil.activos = 0;
		// 			}

		// 			if(mil.data_esperado == 1)
		// 				mil.dato_esperado = (mil.activos > 300) ? 1 : 0.01;

		// 			return [
		// 			mil.name,

		// 			{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
		// 			{"v":Number(mil.activos), "f":mil.activos},
		// 			'stroke-color: #ffffff; stroke-width: 1;',
		// 			{"v":Number(mil.salen), "f":mil.salen},
		// 			'stroke-color: #ffffff; stroke-width: 1;',
		// 			{"v":Number(mil.pendientes), "f":mil.pendientes},
		// 			'stroke-color: #ffffff; stroke-width: 1;'
		// 			]		
		// 		})]


		// 	}

		// 	this.historial = data.history;
		// 	this.loadPage = false;
		// 	this.setPage(1)
		// })
	}

	fillDataCaidas(){
		let type_graph = this.type_graph;
		this.loadPage = true;

		if(this.model.operador)
			type_graph = 1;

		if(this.model.ciudad)
			type_graph = 2;

		this.operadorService.getFunnelData(type_graph, this.model.operador, this.model.ciudad, this.amplacion_data)
			.subscribe( data => {

				if(data.milestone){
					this.getCaidas(data.milestone);
				}

				if(data.operator_hash){
					this.getCaidas(data.operator_hash[0].milestone);
				}

				if(data.city_has){
					this.getCaidas(data.city_has[0].milestone);
				}
				this.loadPage = false;
			})
	}



	getCaidas(data){

		this.tabla_caidas = []

		let tabla_caidas = data.map( (m, i) => {

			let porcentaje = (Number(m.activos) / Number(m.dato_esperado) ) * 100;
			let caidas = 0;
			let meta_caida = 0;
			
			if(data[i + 1]){
				caidas = 100 - (100 * Number(data[i + 1].activos) / Number(m.activos))
				caidas = (caidas > 0) ? caidas : 0;

				meta_caida = 100 - (100 * Number(data[i + 1].dato_esperado) / Number(m.dato_esperado))
				meta_caida = (meta_caida > 0) ? meta_caida : 0;
			}

			return{
				'name': m.name,
				'meta': m.dato_esperado,
				'caida_meta': Number(meta_caida.toFixed(2)),
				'total': m.activos,
				'cump': !isNaN(porcentaje) && isFinite(porcentaje) ? Number(porcentaje.toFixed(0)) : 0,
				'caida': Number(caidas.toFixed(2)),
				
			}
		})

		tabla_caidas.map( (row, i) => {
			let table_clone = JSON.parse(JSON.stringify(tabla_caidas));

			if(tabla_caidas[(i + 1)]){
				table_clone[(i + 1)].caida = ""
				table_clone[(i + 1)].caida_meta = ""

				this.tabla_caidas.push(
					[row, table_clone[(i + 1)]]
				)									
			}
		})

		console.log(this.tabla_caidas)

	}

	loadFunnels(data, type_graph, data_set){

		this.type_graph  = type_graph;
		console.log("LOAD FUNNEL",data);
			this.general_funnel = false;
			this.model = {};

			if(data.operator_hash){
				
				this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_operador.pdf';

				this[data_set] = data.operator_hash.map( operator => {
					operator.operator.name = operator.operator.name_operator;

					this.operadores.push(operator.operator);
					return operator.milestone.map( mil => {

						mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 0.01;
						mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.01;


						if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
							mil.dato_esperado = 0;
							mil.activos = 0;
						}

						if(mil.dato_esperado == 0.01){
							mil.dato_esperado = (mil.activos > 300 ) ? 1 : 0.01;	
						}


						return [
						mil.name,
						{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
						{"v":Number(mil.activos), "f":mil.activos},
						'stroke-color: #ffffff; stroke-width: 1;',
						{"v":Number(mil.salen), "f":mil.salen},
						'stroke-color: #ffffff; stroke-width: 1;',
						{"v":Number(mil.pendientes), "f":mil.pendientes},
						'stroke-color: #ffffff; stroke-width: 1;'
						]		
					})

				})


			}

			if(data.city_has){
				this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_ciudades.pdf';
				this[data_set] = data.city_has.map( city => {

					city.city.name = city.city.name_city;
					this.operadores.push(city.city);
					return city.milestone.map( mil => {

						mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 1;
						mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.01;

						if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
							mil.dato_esperado = 0;
							mil.activos = 0;
						}

						if(mil.dato_esperado == 0.01){
							mil.dato_esperado = (mil.activos > 300) ? 1 : 0.01;
						}


						return [
						mil.name,
						{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
						{"v":Number(mil.activos), "f":mil.activos},
						'stroke-color: #ffffff; stroke-width: 1;',
						{"v":Number(mil.salen), "f":mil.salen},
						'stroke-color: #ffffff; stroke-width: 1;',
						{"v":Number(mil.pendientes), "f":mil.pendientes},
						'stroke-color: #ffffff; stroke-width: 1;'
						]		
					})

				})
			}

			if(data.milestone){
				this.url_form =  AppSettings.URL_BACKEND + 'pdf/dashboard_general.pdf';
				this.general_funnel = true;
				this.operadores.push({
					name: 'General'
				})

				this.getCaidas(data.milestone);

				this[data_set] = [data.milestone.map( mil => {

					mil.dato_esperado = mil.dato_esperado > 0 ? mil.dato_esperado : 1;
					mil.activos = (mil.activos + mil.salen + mil.pendientes) > 0 ? mil.activos : 0.01;

					if(mil.dato_esperado == 0.01 && mil.activos == 0.01){
						mil.dato_esperado = 0;
						mil.activos = 0;
					}

					if(mil.data_esperado == 1)
						mil.dato_esperado = (mil.activos > 300) ? 1 : 0.01;

					return [
					mil.name,

					{"v":Number(-1*mil.dato_esperado), "f":mil.dato_esperado}, 	
					{"v":Number(mil.activos), "f":mil.activos},
					'stroke-color: #ffffff; stroke-width: 1;',
					{"v":Number(mil.salen), "f":mil.salen},
					'stroke-color: #ffffff; stroke-width: 1;',
					{"v":Number(mil.pendientes), "f":mil.pendientes},
					'stroke-color: #ffffff; stroke-width: 1;'
					]		
				})]


			}

			this.historial = data.history;
			this.loadPage = false;
			this.setPage(1)
	}

	getExportGraph(event, graph, name, model){

		this.export_data[graph] = {
			name: name,
			grafica: graph,
			img: event.graph,
			table: event.table
		}

		if(model){
			Object.keys(model).map( item => {
				this.export_data[graph][item] = model[item]
			})	

		}
	}

	getNameDrop(drop, index){
		return drop.filter( f => f.id == index && f)[0];
	}

	getKeys(object){
		return Object.keys(object);
	}

	exportData(){

		this.components.map( g => {
			g.exportData();	
		});

		if(this.name_pdf.nativeElement.value.length <= 0){
			this.validInput = false;
		}else{
			this.validInput = true;
			this.loadPage = true;
			setTimeout( _ => {
				let form = <HTMLFormElement> document.getElementById('form-reporte');
				this.name_pdf.nativeElement.value = this.name_pdf.nativeElement.value.replace(/ /g,"_");
				form.submit();
				this.loadPage = false;
				this.name_pdf.nativeElement.value = ''
				this.comentario_pdf.nativeElement.value = ''
			}, 3000)
		}
	}

	cleanFill(input){
		switch (input) {
			case 1:
				this.model.ciudad = null
				break;

			case 2:
				this.model.operador = null
				break;
			
			default:
				// code...
				break;
		}
	}	

	getExcelFunnel(event, id_item){

		console.log(event, id_item);

		this.url_excel =  AppSettings.URL_BACKEND + 'excel_novedades.xlsx'
		
		if(this.type_graph == 1)
			this.model.operador = id_item;

		if(this.type_graph == 2)
			this.model.ciudad = id_item;

		
		this.model.intervalo = event.interval;
		this.model.tipo = event.state;

		this.model.bis1a = (this.amplacion_data.bis1a) ? this.amplacion_data.bis1a : null;
		this.model.bis1b =(this.amplacion_data.bis1b) ?  this.amplacion_data.bis1b : null;

		if(this.model.bis1a == true && this.model.bis1b == true){
			this.model.bis1a = null;
			this.model.bis1b = null;
		}

		let form = <HTMLFormElement> document.getElementById('form-excel');
		if(!id_item){
			this.analyticsSercice.eventAction("Novedades", "DescargarGeneral");
		}else{
			if(this.type_graph == 1)
				this.analyticsSercice.eventAction("Novedades", "DescargarPorOperador");

			if(this.type_graph == 2)
				this.analyticsSercice.eventAction("Novedades", "DescargarPorCiudad");


		}
		setTimeout(_ => {form.submit();}, 100)
	}

	getExcelMotivos(event){
		this.url_excel =  AppSettings.URL_BACKEND + 'dashboard_motivos.xlsx'

		this.model = {}

		
		this.model.ciudad = event.ciudad;
		this.model.operador = event.operador;

		this.model.etapa = event.etapa;
		this.model.tipo = event.novedad;
		this.model.intervalo = event.interval;
		

		console.log(event)


		let form = <HTMLFormElement> document.getElementById('form-excel');
		this.analyticsSercice.eventAction("Novedades", "DescargarExcelMotivos");
		setTimeout(_ => {form.submit();}, 100)
	}

	getExcelCaidas(event, type){

		this.url_excel =  AppSettings.URL_BACKEND + 'dashboard_niveles.xlsx'	

		this.model.intervalo = event.interval;
		this.model.ciudad = event.ciudad;
		this.model.operador = event.operador;
		this.model.mes = event.mes;
		this.model.tipo = type;

		this.model.bis1a = (event.bis1a) ? event.bis1a : null;
		this.model.bis1b =(event.bis1b) ?  event.bis1b : null;

		let form = <HTMLFormElement> document.getElementById('form-excel');
		this.analyticsSercice.eventAction("Novedades", "DescargarExcelTiposAcompanamiento:" + this.titles_novedades[type-1]);
		setTimeout(_ => {form.submit();}, 100)
	}

	setPage(page: number){
	    if(page < 1 || page > this.pager.totalPages){
	      return
	    }
	    this.pager = this.pagerService.getPager(this.historial.length, page, 10);
	    this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	changeExternalFilter(side, field){
		this.loadPage = true;
		let ampliacion = Object.assign({}, this.amplacion_mirror);
		ampliacion[side][field] = !ampliacion[side][field];
		this.amplacion_mirror = ampliacion;
		this.fillFuneles(this.amplacion_mirror);
	}
}
