import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AppSettings } from '../appSettings';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';

@Injectable()
export class DropsWrapperService {

	private options = new Subject<any>();
	dropOptons:any;
	addOptions$ = this.options.asObservable();

	get optionsDrops(){
		return this.dropOptons;
	}

	constructor(private http: Http) { }

	addOptions(item: any){
		this.dropOptons = [item] //Object.keys(item).map( i => item[i]) ;
		this.options.next(item);
	}


	fullValidateDrop(){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id)
		
		return this.http.post(AppSettings.API_ENDPOINT + 'fullservices', data)
		.map((response: Response) => response.json());
	}

	minValidateDrop(){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id)
		
		return this.http.post(AppSettings.API_ENDPOINT + 'fullservices2', data)
		.map((response: Response) => response.json());
	}

	chainDrop(service, id){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id)
		data.append('id', id)
		
		return this.http.post(AppSettings.API_ENDPOINT + service, data)
		.map((response: Response) => response.json());	
	}


}
