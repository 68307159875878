import { Component, OnInit } from '@angular/core';
import { UsuariosService, PagerServicesService } from '../../../services';

@Component({
  selector: 'app-ver-usuarios',
  templateUrl: './ver-usuarios.component.html',
  styleUrls: ['./ver-usuarios.component.css']
})
export class VerUsuariosComponent implements OnInit {

  usuarios: any = [];
  pager: any = {};
  usuarios_paged = [];

  constructor(private userService:UsuariosService,
  		private pagerService: PagerServicesService) { }

  ngOnInit() {
  	this.userService.getAllUsers().subscribe(
  		data => {
  			this.usuarios = data.viewuserxoperator;
  			this.setPage(1);
  		})
  }

   setPage(page: number){
     if(page < 1 || page > this.pager.totalPages){
      return
    }

    this.pager = this.pagerService.getPager(this.usuarios.length, page, 15);
    console.log(this.pager)
    this.usuarios_paged = this.usuarios.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

}
