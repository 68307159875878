export class Poblacion {
	id:number;
	criterio:string;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getDataPoblacion(){
		return [
		{ id:1, criterio:'VÍCTIMAS'},
		{ id:2, criterio:'UNIDOS'},
		{ id:3, criterio:'SISBEN'},
		{ id:4, criterio:'UNIDOS + VÍCTIMAS'},
		{ id:5, criterio:'UNIDOS + SISBEN'},
		{ id:6, criterio:'VÍCTIMAS + SISBEN'},
		{ id:7, criterio:'UNIDOS + VÍCTIMAS + SISBEN'},
		].map( criterio => new Poblacion(criterio)); 
	}

	static getItem(id){
		return Poblacion.getDataPoblacion().filter( criterio => criterio.id == id)[0];
	}
}
