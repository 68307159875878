import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pop-content',
  templateUrl: './pop-content.component.html',
  styleUrls: ['./pop-content.component.css']
})
export class PopContentComponent implements OnInit, OnChanges {

  @Input() content;
  @Input() type;
  @Input() isOpen;
  @Output() closePop = new EventEmitter();

  constructor() { }

  ngOnChanges(changes:any){
  	if(changes.content.currentValue && changes.content.currentValue != null){
  		this.content = changes.content.currentValue;
  	}
  }
  ngOnInit() {
  }

  closePopup(){
  	this.content = null;
  	this.closePop.emit(true);
  }

}
