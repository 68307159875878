export const FORM_INSCRIPCION = [
	{
		"key":"date_survey",
		"label":"1. FECHA DE LA ENCUESTA",
		"size":"12",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":false
	},
	{
		"key":"idhouse",
		"label":"2. NÚMERO CONSECUTIVO QUE IDENTIFICA EL HOGAR",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"idfamily",
		"label":"3. NÚMERO CONSECUTIVO QUE IDENTIFICA LA FAMILIA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false

	},
	{
		"key":"idperson",
		"label":"4. NÚMERO CONSECUTIVO QUE IDENTIFICA LA PERSONA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"x",
		"label":"5. COORDENADA GEOGRÁFICA DE LATITUD",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"y",
		"label":"6. COORDENADA GEOGRÁFICA DE LONGITUD",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"elevation",
		"label":"7. ALTURA",
		"size":"3",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"precision",
		"label":"8. PRECISIÓN",
		"size":"3",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"estrato_house_id",
		"label":"9. ¿CUÁL ES EL ESTRATO DE SU VIVIENDA?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"estrato",
		"verify":true,
		"editable":true
	},
	{
		"key":"phone_family",
		"label":"10. NÚMERO DE TELÉFONO FIJO DE LA FAMILIA",
		"size":"6",
		"type":"phone",
		"controlType":"textbox",
		"verify":false,
		"editable":true
	},
	{
		"key":"number_person_family",
		"label":"11. NÚMERO DE PERSONAS EN EL NÚCLEO FAMILIAR",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"smaller_five",
		"label":"12. NÚMERO DE PERSONAS MENORES DE 5 AÑOS EN EL NÚCLEO FAMILIAR",
		"size":"6",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"greater_sixty",
		"label":"13. NÚMERO DE PERSONAS MAYORES DE 60 AÑOS EN EL NÚCLEO FAMILIAR",
		"size":"6",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"pregnant_woman",
		"label":"14. NÚMERO DE PERSONAS GESTANTE-LACTANTE EN EL NÚCLEO FAMILIAR",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"description_address",
		"label":"15. INDICACIONES DE UBICACIÓN DEL PREDIO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":false,
		"editable":false
	},
	{
		"key":"municipio_nac",
		"label":"16. MUNICIPIO DE NACIMIENTO DEL PARTICIPANTE",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"municipios",
		"verify":true,
		"editable":false

	},
	{
		"key":"municipioexpedicion",
		"label":"17. MUNICIPIO DE EXPEDICIÓN DEL DOCUMENTO DEL PARTICIPANTES",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"municipios",
		"verify":true,
		"editable":false
	},
	{
		"key":"expedition_document",
		"label":"18. ¿CUÁL ES LA FECHA DE EXPEDICIÓN?",
		"size":"6",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":false
	},
	{
		"key":"email",
		"label":"19. DIRECCIÓN DE CORREO ELECTRÓNICO DEL PARTICIPANTES",
		"size":"6",
		"type":"email",
		"controlType":"textbox",
		"verify":true,
		"editable":false
	},
	{
		"key":"sexual_condition_id",
		"label":"20. ¿CUÁL ES EL SEXO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"condicionsexual",
		"action":"enabledOnValue",
		"outputInput": '',
		"inputInput":"military_card",
		"valueToEnabled": 30,
		"verify":true,
		"editable":false

	},
	{
		"key":"civil_status_id",
		"label":"21. ¿EL ESTADO CIVIL ES?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"estadocivil",
		"verify":true,
		"editable":true
	},
	{
		"key":"education_level_id",
		"label":"22. ¿CUÁL ES EL NIVEL EDUCATIVO MÁS ALTO  ALCANZADO?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"niveleducativo",
		"verify":true,
		"editable":true

	},
	{
		"key":"disability_id",
		"label":"23. ¿TIENE DISCAPACIDAD? ¿EN QUE CATEGORÍA SE ENCUENTRA SU DISCAPACIDAD?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"discapacidad",
		"verify":true,
		"editable":true
	},
	{
		"key":"t_vivienda",
		"label":"24. ¿TIPO DE VIVIENDA?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"tipovivienda",
		"action":"enabledOnValue",
		"valueToEnabled": 387,
		"outputInput": '',
		"inputInput":"other_tipovivienda",
		"cleanInput":"",
		"verify":true,
		"editable":true

	},
	{
		"key":"other_tipovivienda",
		"label":"25. ¿ OTRO CUAL?",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"optional":"true",
		"disabled": true,
		"verify":true,
		"editable":true
	},
	{
		"key":"house_id",
		"label":"26. ¿LA VIVIENDA OCUPADA POR ESTE HOGAR ES?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"vivienda",
		"verify":true,
		"editable":true

	},
	{
		"key":"description_house",
		"label":"27. DESCRIPCIÓN OTRO TIPO DE VIVIENDA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"wall_material_id",
		"label":"28. ¿CUÁL ES EL MATERIAL PREDOMINANTE DE LAS PAREDES EXTERIORES DE LA VIVIENDA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"materialpared",
		"verify":true,
		"editable":true

	},
	{
		"key":"floor_material_id",
		"label":"29. ¿CUÁL ES EL MATERIAL PREDOMINANTE DE LOS PISOS DE LA VIVIENDA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"materialpiso",
		"verify":true,
		"editable":true
	},
	{
		"key":"public_service_id",
		"label":"30. ¿CON CUÁLES DE LOS SIGUIENTES SERVICIOS PÚBLICOS, PRIVADOS O COMUNALES CUENTA LA VIVIENDA?",
		"size":"12",
		"type":"groupcheckbox",
		"controlType":"groupcheckbox",
		"source":"serviciopublico",
		"verify":true,
		"editable":true
	},
	{
		"key":"disaster_id",
		"label":"31. EN LOS ÚLTIMOS 24 MESES, LA VIVIENDA HA SIDO AFECTADA POR",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"desastre",
		"verify":true,
		"editable":true
	},
	{
		"key":"space_house",
		"label":"32. INCLUYENDO SALA Y COMEDOR, ¿DE CUÁNTOS CUARTOS EN TOTAL DISPONE ESTE HOGAR DE MANERA EXCLUSIVA? (EXCLUIR COCINA, BAÑOS, GARAJES Y CUARTOS DESTINADOS A NEGOCIOS)",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"room",
		"label":"33. ¿EN CUÁNTOS DE ESOS CUARTOS DUERMEN LAS PERSONAS DE ESTE HOGAR?",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"sanitary_service_id",
		"label":"34. EL SERVICIO SANITARIO QUE UTILIZA EL HOGAR ES",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"serviciosanitario",
		"verify":true,
		"editable":true

	},
	{
		"key":"water_id",
		"label":"35. EL AGUA PARA CONSUMO Y PREPARACIÓN DE LOS ALIMENTOS LA OBTIENEN PRINCIPALMENTE DE",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"acueducto",
		"verify":true,
		"editable":true
	},
	{
		"key":"credit_expense",
		"label":"36. ¿CUÁNTO PAGAN MENSUALMENTE POR CUOTA DEL CRÉDITO?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true

	},
	{
		"key":"rent_expense",
		"label":"37. SI TUVIERA QUE PAGAR ARRIENDO POR ESTA VIVIENDA, ¿CUÁNTO ESTIMA QUE TENDRÍA QUE PAGAR MENSUALMENTE?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"relationship_id",
		"label":"38. ¿CUÁL ES SU RELACIÓN CON LA PERSONA JEFE DEL HOGAR?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"relacionfamiliar",
		"verify":true,
		"editable":true
	},
	{
		"key":"head_household_jefe",
		"label":"39. ¿ES JEFE DE HOGAR?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"inscription_day",
		"label":"40. FECHA EN LA QUE SE REALIZA LA INSCRIPCIÓN",
		"size":"6",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":false
	},
	{
		"key":"dead",
		"label":"41. FALLECIDO",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"disabled":"true",
		"verify":false,
		"editable":false
	},
	{
		"key":"read_write",
		"label":"42. ¿SABE LEER Y ESCRIBIR?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":false
	},
	{
		"key":"addition_subtraction",
		"label":"43. ¿SABE SUMAR Y RESTAR?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":false
	},
	{
		"key":"currently_studying",
		"label":"44. ¿ESTUDIA ACTUALMENTE?",
		"size":"3",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true,
		"action":"enabledOnValue",
		"valueToEnabled": 'false',
		"outputInput": "",
		"inputInput":'not_study',
		"cleanInput":"",
	},
	{
		"key":"not_study",
		"label":"45. SI NO ESTUDIA, EXPLICAR LA RAZÓN POR LA CUAL NO LO HACE",
		"size":"9",
		"type":"text",
		"controlType":"textbox",
		"verify":true,
		"editable":true,
		"disabled":true
	},
	{
		"key":"military_card",
		"label":"46. ¿TIENE TARJETA MILITAR?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":false,
		"disabled":true
	},
	{
		"key":"health_affiliation",
		"label":"47. ¿ESTÁ AFILIADO, ES COTIZANTE O BENEFICIARIO DE ALGUNA ENTIDAD DE SEGURIDAD SOCIAL EN SALUD (EPS)?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true,
		"action":"enabledOnValue",
		"valueToEnabled": 'false',
		"outputInput": "type_affiliation_id",
		"inputInput":'',
	},
	{
		"key":"type_affiliation_id",
		"label":"48. ¿A CUÁL DE LOS SIGUIENTES REGÍMENES  DE SEGURIDAD SOCIAL EN SALUD ESTÁ AFILIADO ? ",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"tipoafiliacion",
		"verify":true,
		"editable":true,
		"disabled":true
	},
	{
		"key":"bank_id",
		"label":"49. ¿TIENE CUENTA BANCARIA? ¿EN QUÉ BANCO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"banco",
		"action":"enabledOnValue",
		"valueToEnabled": 134,
		"outputInput": ["type_account_id", "number_account"].join(),
		"inputInput":'',
		"cleanInput":"",
		"verify":true,
		"editable":true
	},
	{
		"key":"type_account_id",
		"label":"50. ¿QUÉ TIPO DE CUENTA BANCARIA TIENE?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"tipocuenta",
		"disabled": true,
		"verify":true,
		"editable":true
	},
	{
		"key":"number_account",
		"label":"51. ¿CUÁL ES EL NÚMERO DE LA CUENTA BANCARIA?",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"disabled": true,
		"verify":true,
		"editable":true
	},
	{
		"key":"disease",
		"label":"52. EN LOS ÚLTIMOS 30 DÍAS, ¿TUVO ALGUNA ENFERMEDAD, ACCIDENTE, PROBLEMA ODONTOLÓGICO O ALGÚN OTRO PROBLEMA DE SALUD QUE NO HAYA IMPLICADO HOSPITALIZACIÓN?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"action":"enabledOnValue",
		"valueToEnabled": 'false',
		"outputInput": "health_care_id",
		"inputInput":'',
		"cleanInput":"",
		"verify":false,
		"editable":true
	},
	{
		"key":"health_care_id",
		"label":"53. PARA TRATAR ESE PROBLEMA DE SALUD ¿QUÉ HIZO PRINCIPALMENTE?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cuidadosalud",
		"disabled": true,
		"verify":false,
		"editable":true
	},
	{
		"key":"early_childhood_id",
		"label":"54. PARA LOS NIÑOS MENORES DE 5 AÑOS ¿DÓNDE O CON QUIÉN PERMANECE DURANTE LA MAYOR PARTE DEL TIEMPO ENTRE SEMANA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"cuidadonino",
		"action":"enabledOnValue",
		"valueToEnabled": 10,
		"outputInput": ["feeding_childhood", "health_childhood"].join(),
		"inputInput":'',
		"cleanInput":"",
		"verify":true,
		"editable":true


	},
	{
		"key":"feeding_childhood",
		"label":"55. PARA LOS NIÑOS MENORES DE 5 AÑOS ¿RECIBE ALMUERZO EN EL ESTABLECIMIENTO EN FORMA GRATUITA O POR UN PAGO SIMBÓLICO",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"health_childhood",
		"label":"56. PARA LOS NIÑOS MENORES DE 5 AÑOS ¿EN LOS ÚLTIMOS 6 MESES, HA ASISTIDO A CONTROLES DE CRECIMIENTO Y DESARROLLO?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"actividadnino",
		"label":"57. PARA NIÑOS ENTRE 5 Y 9 AÑOS: ¿EN QUÉ ACTIVIDADES OCUPÓ  EL TIEMPO LA SEMANA PASADA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"ocupacionnino",
		"action":"enabledOnValue",
		"valueToEnabled": 4,
		"outputInput": "",
		"inputInput":"otrotiemponino",
		"cleanInput":"child_ocupation_time",
		"verify":true,
		"editable":true
	},
	{
		"key":"otrotiemponino",
		"label":"58. OTRO CUAL",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"optional":"true",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"child_ocupation_time",
		"label":"59. ¿CUÁNTAS HORAS DEDICÓ A ESA ACTIVIDAD LA SEMANA PASADA?",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"actividad_adulto",
		"label":"60. PARA ADULTOS: ¿EN QUE ACTIVIDAD OCUPÓ LA MAYOR PARTE DEL TIEMPO LA SEMANA PASADA?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"actividadadulto",
		"action":"enabledOnValue",
		"valueToEnabled": 6,
		"outputInput":"",
		"inputInput":"otra_actividad",
		"cleanInput":"",
		"verify":true,
		"editable":true
	},
	{
		"key":"otra_actividad",
		"label":"61. OTRA CUAL",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"optional":"true",
		"disabled":true,
		"verify":true,
		"editable":true

	},
	{
		"key":"job",
		"label":"62. AUNQUE NO TRABAJÓ LA SEMANA PASADA, ¿TENÍA DURANTE ESA SEMANA ALGÚN TRABAJO, ACTIVIDAD O NEGOCIO POR EL QUE RECIBE INGRESOS?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"job_availability",
		"label":"63. SI LE HUBIERA RESULTADO ALGÚN TRABAJO, ¿ESTABA DISPONIBLE LA SEMANA PASADA PARA EMPEZAR A TRABAJAR?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"looking_job",
		"label":"64. ¿DURANTE CUANTAS SEMANAS HA ESTADO O ESTUVO BUSCANDO TRABAJO?",
		"size":"6",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"work_income",
		"label":"65. ¿RECIBIÓ O GANÓ EL MES PASADO INGRESOS POR CONCEPTO DE TRABAJO?",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	
	{
		"key":"formastrabajo",
		"label":"66. EN LA SEMANA PASADA, ¿CUÁL DE LAS SIGUIENTES FORMAS DE TRABAJO REALIZÓ?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"formatrabajo",
		"action":"enabledOnValue",
		"valueToEnabled": 8,
		"outputInput": '',
		"inputInput":"otraformatrabajo",
		"cleanInput":"",
		"verify":true,
		"editable":true
	},
	{
		"key":"otraformatrabajo",
		"label":"67. OTRO CUAL",
		"size":"12",
		"type":"text",
		"controlType":"textbox",
		"optional":"true",
		"disabled":true,
		"verify":true,
		"editable":true
	},
	{
		"key":"gain_work",
		"label":"68. ¿CUÁL FUE LA GANANCIA NETA O LOS HONORARIOS NETOS DE ESA ACTIVIDAD, NEGOCIO, PROFESIÓN O FINCA, EL MES PASADO ?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"time_gain",
		"label":"69. ¿ A CUÁNTOS MESES CORRESPONDE LO QUE RECIBIÓ ?",
		"size":"12",
		"type":"number",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},

	{
		"key":"pension",
		"label":"70. ¿ESTÁ COTIZANDO ACTUALMENTE A UN FONDO DE PENSIONES?",
		"size":"12",
		"type":"text",
		"controlType":"dropdown",
		"source":"booleanSource",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_rent",
		"label":"71. EL MES PASADO, ¿RECIBIÓ PAGOS POR CONCEPTO DE ARRIENDOS DE CASAS, APARTAMENTOS, FINCAS, LOTES, VEHÍCULOS, EQUIPOS ETC?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},


	{
		"key":"value_retirement",
		"label":"72. EL MES PASADO, ¿RECIBIÓ PAGOS POR CONCEPTO DE PENSIONES O JUBILACIONES POR VEJEZ, INVALIDEZ O SUSTITUCIÓN PENSIONAL?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},

	{
		"key":"value_patern",
		"label":"73. EL MES PASADO, ¿RECIBIÓ PAGOS POR CONCEPTO DE PENSIÓN ALIMENTICIA POR PATERNIDAD, DIVORCIO O SEPARACIÓN?",
		"size":"12", 
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},


	{
		"key":"value_person_country",
		"label":"74. DINERO DE OTROS HOGARES O PERSONAS RESIDENTES EN EL PAÍS?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_out_country",
		"label":"75. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ DINERO DE OTROS HOGARES O PERSONAS RESIDENTES FUERA DEL PAÍS?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_institution",
		"label":"76. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ  AYUDAS EN DINERO DE INSTITUCIONES DEL PAÍS O DE  FUERA DEL PAÍS?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_cdt",
		"label":"77. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ DINERO POR INTERESES DE PRESTAMOS, CDT'S, DEPÓSITOS DE AHORRO O UTILIDADES DE INVERSIONES?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_cessation",
		"label":"78. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ DINERO POR CONCEPTO DE CESANTÍAS Y/O INTERESES A LAS CESANTÍAS?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"value_games",
		"label":"79. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ DINERO DINERO DE OTRAS FUENTES (GANANCIAS EN JUEGOS DE AZAR, CHANCES, LOTERÍAS, INDEMNIZACIONES, LIQUIDACIONES, VENTA DE PROPIEDADES, VEHÍCULOS)",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},


	{
		"key":"subsidy",
		"label":"80. DURANTE LOS ÚLTIMOS DOCE MESES ¿RECIBIÓ DINERO DE PROGRAMAS SOCIALES?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"expenses",
		"label":"81. ¿CUÁL FUE EL VALOR DE LOS GASTOS DEL HOGAR EN EL ÚLTIMO MES?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"income",
		"label":"82. ¿CUÁL FUE EL VALOR DE LOS INGRESOS DEL HOGAR EN EL ÚLTIMO MES?",
		"size":"12",
		"type":"currency",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	}]