import { Component, OnInit, ViewChild, AfterViewInit
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PagerServicesService, VerificadorService, OperadorDataService, AnalitycsService }  from '../../../services'
import { Operador } from '../../../struct/';


@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit{


	data: any;
  salarios: any;
  duracion: any;
  tasas: any = [];
  data_paged: any = [];
  data_paged_g: any = [];
  data_paged_t: any = [];
  data_filled: any;
  data_general: any;
  data_filled_g: any;
  data_filled_t: any;
  max_page: number = 5;
  pager: any = {};
  pager_tasas: any = {};
  tab_switch:number = 1;
  loadPage: boolean = true;
  open_fills: boolean = false;
  type_user: any
  table_data: any = {};
  operator_id: any;
  percent_graph:boolean = false;
  color_set = [];
  tabs_labels = ['Indicador de Efectividad', 'Indicadores de Gestión', 'Salarios Promedio', 'Duración Promedio'];

  color_catalog = {
    'First': 'rgb(46, 204, 113)',
    'Second': 'rgb(41, 128, 185)',
    'Third':  'rgb(192, 57, 43)',
    'Fourth': 'rgb(241, 196, 15)',
    'Fifth': 'rgb(155, 89, 182)',
    'Sixth': 'rgb(255, 235, 59)',
    'Seventh': 'rgb(211, 84, 0)',
    'Eighth': 'rgb(22, 160, 133)',
    'Ninth': 'rgb(205, 220, 57)',
    'Tenth': 'rgb(33, 150, 243)',
    'Eleven': 'rgb(255, 152, 0)',
    'Twelve': 'rgb(244, 67, 54)',
    'Thirten': 'rgb(103, 58, 183)',
  }

  operadores:any = [];

  yArrayEfectividad = [
    [
      { name: "Meta Proyectada Colocación", value:"meta_proyectado_colocacion", color:"First", type:"number" },
      { name: "Meta Actual Colocación", value:"meta_actual_colocacion", color:"Second", type:"number" },
    ],
    [
      { name: "Meta Proyecto Retención 3M", value:"meta_proyectado_retencion_3", color:"Third", type:"number" },
      { name: "Meta Actual Retención 3M", value:"meta_actual_retencion3", color:"Fourth", type:"number" },
    ],
    [
      { name: "Meta Proyecto Retención 6M", value:"meta_proyectado_retencion_6", color:"Fifth", type:"number" },
      { name: "Meta Actual Retención 6M", value:"meta_actual_retencion_6", color:"Eighth", type:"number" },
    ]
  
  ]

  yArrayEfectividadTotals = [
      [
        { name: "Colocados/Meta Proyecto", value:"total_colocados", percent:"colocados_x_meta_proyectada", color:"First"},
        { name: "Colocados/Meta Actual", value:"total_colocados", percent:"colocados_x_meta_actual", color:"Second"}
      ],
      [
        { name: "Retenidos/Meta Proyecto 3M", value:"total_retenidos_3", percent:"retenidos_x_meta_proyectada_3", color:"Third"},
        { name: "Retenidos/Meta Actual 3M", value:"total_retenidos_3", percent:"retenidos_x_meta_actual_3", color:"Fourth"}
      ],
      [
        { name: "Retenidos/Meta Proyecto 6M", value:"total_retenidos_6",percent:"retenidos_x_meta_proyectada_6",  color:"Fifth"},
        { name: "Retenidos/Meta Actual 6M", value:"total_retenidos_6", percent:"retenidos_x_meta_actual_6", color:"Eighth"}
      ]
  ];

  yTableEfectividad = [
    { name: "Meta Proyectada Colocación", value:"meta_proyectado_colocacion", color:"First", type:"number" },
    { name: "Meta Actual Colocación", value:"meta_actual_colocacion", color:"Second", type:"number" },
    { name: "Meta Proyecto Retención 3M", value:"meta_proyectado_retencion_3", color:"Third", type:"number" },
    { name: "Meta Actual Retención 3M", value:"meta_actual_retencion3", color:"Fourth", type:"number" },
    { name: "Meta Proyecto Retención 6M", value:"meta_proyectado_retencion_6", color:"Fifth", type:"number" },
    { name: "Meta Actual Retención 6M", value:"meta_actual_retencion_6", color:"Sixth", type:"number" },
    { name: "Colocados/Meta Proyecto", value:"colocados_x_meta_proyectada", color:"Seventh"},
    { name: "Colocados/Meta Actual", value:"colocados_x_meta_actual", color:"Eighth"},
    { name: "Retenidos/Meta Proyecto 3M", value:"retenidos_x_meta_proyectada_3", color:"Ninth"},
    { name: "Retenidos/Meta Actual 3M", value:"retenidos_x_meta_actual_3", color:"Tenth"},
    { name: "Retenidos/Meta Proyecto 6M", value:"retenidos_x_meta_proyectada_6", color:"Eleven"},
    { name: "Retenidos/Meta Actual 6M", value:"retenidos_x_meta_actual_6", color:"Twelve"},
  ]

  yElementsEfectividad = this.yArrayEfectividad[0];
  yElementsEfectividadTotals = this.yArrayEfectividadTotals[0];

  yArrayGestion = [
    [
      { name: "Colocados", value:"total_colocados", color:"Second", type:"number" },
    ],
    [
      { name: "Retenidos 3M", value:"total_retenidos_3", color:"Fourth", type:"number" },
    ],
    [
      { name: "Retenidos 6M", value:"total_retenidos_6", color:"Sixth", type:"number" },
    ]
  ]

  yArrayGestionTotals = [
    [
      { name: "Soportes Colocados", value:"soporte_colocados", percent:"soporte_colocados_por", color:"First"},
    ],
    [
      { name: "Soportes Retenidos 3M", value:"soporte_retenidos_3", percent:"soporte_retenidos_3_por", color:"Third"},
    ],
    [
      { name: "Soportes Retenidos 6M", value:"soporte_retenidos_6", percent:"soporte_retenidos_6_por", color:"Fifth"},
    ]
  ]


  yTableGestion = [
  { name: "Colocados", value:"total_colocados", color:"Second", type:"number" },
  { name: "Soportes Colocados", value:"soporte_colocados", color:"Third", type:"number" },
  { name: "Retenidos 3M", value:"total_retenidos_3", color:"Fourth", type:"number" },
  { name: "Soportes Retenidos 3M", value:"soporte_retenidos_3", color:"Third", type:"number" },
  { name: "Retenidos 6M", value:"total_retenidos_6", color:"Sixth", type:"number" },
  { name: "Soportes Retenidos 6M", value:"soporte_retenidos_6", color:"Third", type:"number" },

  ]

  yElementsGestion = this.yArrayGestion[0];
  yElementsGestionTotals = this.yArrayGestionTotals[0];

  yArraySalario = [
    [
      { name: "Entre $561.600 y Salario mínimo",  value:"salario1", color:"Second", type:"number" },
      { name: "Salario mínimo",                   value:"salario2", color:"Fourth", type:"number" },
      { name: "Entre Salario mínimo y 1'000.000", value:"salario3", color:"Sixth", type:"number" },
      { name: "Entre $1'000.001 y $1'500.000",    value:"salario4", color:"Eighth", type:"number" },
      { name: "Entre $1'500.001 y $2'000.000",    value:"salario5", color:"Tenth", type:"number" },
      { name: "Más de $2'000.000",                value:"salario6", color:"Twelve", type:"number" },  
      { name: "Colocados", value:"total_colocados", color:"First", type:"total" },
    ],
  ]

  yTableSalario = [
  { name: "Colocados", value:"total_colocados", color:"Second", type:"number" },
  { name: "Entre $561.600 y Salario mínimo",  value:"salario1", color:"Second", type:"number" },
  { name: "Salario mínimo",                   value:"salario2", color:"Fourth", type:"number" },
  { name: "Entre Salario mínimo y 1'000.000", value:"salario3", color:"Sixth", type:"number" },
  { name: "Entre $1'000.001 y $1'500.000",    value:"salario4", color:"Eighth", type:"number" },
  { name: "Entre $1'500.001 y $2'000.000",    value:"salario5", color:"Tenth", type:"number" },
  { name: "Más de $2'000.000",                value:"salario6", color:"Twelve", type:"number" },  
  ]

  yElementsSalario = this.yArraySalario[0];


  yArrayDuracion = [
    [
      { name: "Menor a tres meses",  value:"mes1", color:"First", type:"number"},
      { name: "Menor a seis meses",  value:"mes2", color:"Second", type:"number"},
      { name: "Menor a un año", value:"mes3", color:"Third" , type:"number"},
      { name: "Mayor a un año", value:"indefinido", color:"Fourth" , type:"number"},
      { name: "Colocados", value:"total_colocados", color:"Fifth", type:"total" },
    ],
  ]

  yTableDuracion = [
  { name: "Colocados", value:"total_colocados", color:"Second", type:"number" },
  { name: "Menor a tres meses",  value:"mes1", color:"First", type:"number"},
  { name: "Menor a seis meses",  value:"mes2", color:"Second", type:"number"},
  { name: "Menor a un año", value:"mes3", color:"Third" , type:"number"},
  { name: "Mayor a un año", value:"indefinido", color:"Fourth" , type:"number"},
  ]

  yElementsDuracion = this.yArrayDuracion[0];

  yElementnsTableEfectividad = [
  { name: "Meta Proyecto Colocación",  value:"meta_proyectado_colocacion", type:"number" },
  { name: "Meta Actual Colocación",  value:"meta_actual_colocacion", type:"number"  },
  { name: "Meta Proyecto Retención 3M",  value:"meta_proyectado_retencion_3", type:"number"  },
  { name: "Meta Proyecto Retención 6M",  value:"meta_proyectado_retencion_6", type:"number"  },
  ]

  yElementnsTableGestion  = [
  { name: "El salario promedio de todos los colocados",  value:"salario_promedio", type:"number" }
  ]

  yElementnsTableSalario = [
  { name: "El salario promedio de todos los colocados",  value:"salario_promedio", type:"money" }
  ]

  yElementnsTableDuracion = [
  { name: "Meta Proyecto Colocación",  value:"meta_proyectado_colocacion", type:"number" }
  ]

  yArrayTasas = [
  { name: "Tasa Retenidos 3M",  value:"tasa_retenidos_3"},
  { name: "Tasa Deserción 3M",  value:"tasa_desercion_3"},
  { name: "Tasa Retenidos 6M",  value:"tasa_retenidos_6"},
  { name: "Tasa Deserción 6M",  value:"tasa_desercion_6"},
  ]

  yTasas:any;

  yArray:any = this.yArrayEfectividad;
  yArrayTotals:any = this.yArrayEfectividadTotals
  
  yElements:any = this.yArray[0];
  yElementsTotals:any = this.yArrayTotals[0];


  filter:any = {
    operador: {},
    indicador: {},
    salario: {},
    duracion:{},
    ampliacion:{},
    max_page: this.max_page
  };

  @ViewChild('formFilter') formFilter;

  constructor(private pagerService: PagerServicesService, 
    private verificadorService: VerificadorService,
    private operadorService: OperadorDataService,
    private analyticsService: AnalitycsService) { }

  ngOnInit() {

    this.type_user = Number(localStorage.getItem('user_type'));

    this.verificadorService.getIndicadores()
    .subscribe( data => {

      let data_general = data.operators;
      this.data = data.operators;
      this.data_filled = data.operators

      this.operator_id = data.user_id_session;


      let operator_general = {};

      if(this.operator_id){
        data_general = [
          data.operators.filter( op => op.id_operador == this.operator_id)[0],
          this.getTotales(data.operators)
        ] 
        this.data = data_general;
      }else{
        data_general = this.data;
        data_general.push(this.getTotales(data.operators))
      }

      this.salarios = data_general.map( op => {
        op.salario['name_operator'] = op.name_operator;
        op.salario['id_operador'] = op.id_operador;
        op.salario['total_colocados'] = op.total_colocados;
        return op.salario;
      });


      this.duracion = data_general.map( op => {
        op.duracion_contrato['name_operator'] = op.name_operator  ;
        op.duracion_contrato['id_operador'] = op.id_operador  ;
        op.duracion_contrato['total_colocados'] = op.total_colocados;
        return op.duracion_contrato;
      });

      for(let tasa of this.yArrayTasas){
        let row = [];
        data_general.map( (op, index) => {
          Object.keys(op).map( key => {
            if(key == tasa.value){
              row.push({
                operator: op.name_operator,
                value: op[key],
                color: this.color_catalog[Object.keys(this.color_catalog)[index]]
              })
            }
          });
        });

        this.tasas.push({
          name:tasa.name,
          tasas: row
        })

      }


      this.data_general = data_general;
      this.data_filled_g = data_general;
      this.data_filled_t = data_general;
      this.setPage(1);
      this.pageTasas(1)
      this.loadPage = false;
      this.generateTableIndicador();

      console.log(this.data_filled_g)
    })

    this.operadorService.getAllOperadores()
    .subscribe( data => {

      this.operadores = data.operator.map( op => new Operador(op));

      //if(this.type_user == 2){
        this.operadores.push(new Operador({
          id_operador: 100,
          name_operator: 'Total'
        }))
      //}

      this.operadores.map( (op:Operador) => {
        this.filter.operador[op.name_operator] = true;
      });
    })



    // this.yElementsEfectividad.map( ind => {
    //   this.filter.indicador[ind.value] = true;
    // });

    this.yElementsGestion.map( ind => {
      this.filter.indicador[ind.value] = true;
    });

    this.yElementsSalario.map( ind => {
      this.filter.salario[ind.value] = true;
    });

    this.yElementsDuracion.map( ind => {
      this.filter.duracion[ind.value] = true;
    });

    this.filter.ampliacion.bis1a = true;
    this.filter.ampliacion.bis1b = true;

  }

  getTotales(data_operators){
    let operator_general = {};
    let salarios = [];
    let duracion = []
    data_operators.map( operator => {
      Object.keys(operator).map( key => {
        if(typeof operator[key] != 'string' && typeof operator[key] != 'object'){
          operator_general[key] = (operator_general[key]) ? (Number(operator_general[key]) + Number(operator[key])) : operator[key];  
        }else{
          operator_general[key] = operator[key];

          if(typeof operator[key] == 'object'){
            if(key == 'duracion_contrato')
              duracion.push(operator_general[key]);

            if(key == 'salario')
              salarios.push(operator_general[key]);
          }
        }
      })
    })

    Object.keys(operator_general).map( key => {
      if(typeof operator_general[key] != 'string' && typeof operator_general[key] != 'object'){
        operator_general[key] = operator_general[key];

        if(key == 'colocados_x_meta_proyectada'){
          operator_general[key] = (operator_general['total_colocados'] * 100) / operator_general['meta_proyectado_colocacion'] 
        }

        if(key == 'colocados_x_meta_actual'){
          console.log(operator_general['total_colocados'],  operator_general['meta_actual_colocacion'] )
          operator_general[key] = (operator_general['meta_actual_colocacion'] > 0) ? (operator_general['total_colocados'] * 100) / operator_general['meta_actual_colocacion'] : 0
        }

        if(key == 'retenidos_x_meta_proyectada_3'){

          operator_general[key] = ( operator_general['meta_proyectado_retencion_3']  > 0) ?  (operator_general['total_retenidos_3'] * 100) / operator_general['meta_proyectado_retencion_3'] : 0
        }

        if(key == 'retenidos_x_meta_actual_3'){
          operator_general[key] = (operator_general['meta_actual_retencion3'] > 0) ? (operator_general['total_retenidos_3'] * 100) / operator_general['meta_actual_retencion3'] : 0
        }

        if(key == 'retenidos_x_meta_proyectada_6'){
          operator_general[key] = (operator_general['meta_proyectado_retencion_6']  > 0 ) ? (operator_general['total_retenidos_6'] * 100) / operator_general['meta_proyectado_retencion_6'] : 0
        }

        if(key == 'retenidos_x_meta_actual_6'){
          operator_general[key] = operator_general['meta_actual_retencion_6'] > 0 ? (operator_general['total_retenidos_6'] * 100) / operator_general['meta_actual_retencion_6'] : 0
        }

        if(key == 'tasa_desercion_3'){

          operator_general[key] = operator_general['meta_actual_retencion_6'] > 0 ? (operator_general['total_desercion_3'] * 100) / operator_general['meta_actual_retencion_6'] : 0
          console.log(operator_general['total_desercion_3'],  operator_general['total_colocados_3'], operator_general[key])
        }

        if(key == 'tasa_desercion_6'){
          operator_general[key] = operator_general['total_colocados_6']  > 0 ? (operator_general['total_desercion_6'] * 100) / operator_general['total_colocados_6'] : 0
        }

        if(key == 'tasa_retenidos_3'){
          operator_general[key] = operator_general['total_colocados_3'] > 0 ? (operator_general['total_retenidos_3'] * 100) / operator_general['total_colocados_3'] : 0
        }

        if(key == 'tasa_retenidos_6'){
          operator_general[key] = operator_general['total_colocados_6'] > 0 ? (operator_general['total_retenidos_6'] * 100) / operator_general['total_colocados_6'] : 0
        }

        if(key == 'soporte_colocados_por'){
          operator_general[key] = operator_general['total_colocados']  > 0 ? (operator_general['soporte_colocados'] * 100) / operator_general['total_colocados'] : 0
        }

        if(key == 'soporte_retenidos_3_por'){
          operator_general[key] = operator_general['total_retenidos_3']  > 0 ? (operator_general['soporte_retenidos_3'] * 100) / operator_general['total_retenidos_3'] : 0
        }

        if(key == 'soporte_retenidos_6_por'){
          operator_general[key] = operator_general['total_retenidos_6'] > 0 ? (operator_general['soporte_retenidos_6'] * 100) / operator_general['total_retenidos_6'] : 0
        }









      }
    })

    let duracion_contrato = {};
    duracion.map( op => {
      Object.keys(op).map( key => {
        duracion_contrato[key] = (duracion_contrato[key]) ? (Number(duracion_contrato[key]) + Number(op[key])) : op[key];  
      })
    })

    let salario_gnrl = {};
    salarios.map( op => {
      Object.keys(op).map( key => {
        salario_gnrl[key] = (salario_gnrl[key]) ? (Number(salario_gnrl[key]) + Number(op[key])) : op[key];  
      })
    })

    Object.keys(duracion_contrato).map( key => {
      duracion_contrato[key] = Math.round(duracion_contrato[key]);
    });
    operator_general['duracion_contrato'] = duracion_contrato;

    Object.keys(salario_gnrl).map( key => {
      salario_gnrl[key] = Math.round(salario_gnrl[key]);
    });

    operator_general['salario'] = salario_gnrl;


    operator_general['name_operator'] = 'Total';
    operator_general['id_operador'] = 100;

    console.log(operator_general)

    return operator_general;
  }



  changeTab(tab){
    this.tab_switch = tab;

    this.yElementsGestion.map( ind => {
      this.filter.indicador[ind.value] = true;
    });

    this.yElementsSalario.map( ind => {
      this.filter.salario[ind.value] = true;
    });

    this.yElementsDuracion.map( ind => {
      this.filter.duracion[ind.value] = true;
    });

    switch (this.tab_switch ) {
      case 1:
      this.data_filled_g = this.data;
      this.data_filled_t = this.data;
      this.data_filled = this.data
      this.yArrayTotals = this.yArrayEfectividadTotals;
      this.yElementsTotals = this.yArrayTotals[0];
      this.yArray = this.yArrayEfectividad;
      this.yElements = this.yArrayEfectividad[0]

      break;
      case 2:

      this.data_filled_g = this.data;
      this.data_filled_t = this.data;
      this.data_filled = this.data
      this.yArrayTotals = this.yArrayGestionTotals;
      this.yElementsTotals = this.yArrayTotals[0];
      this.yArray = this.yArrayGestion;
      this.yElements = this.yArrayGestion[0]


      break;
      case 3:
      this.yArray = this.yArraySalario;
      this.yElements = this.yArraySalario[0]
      this.data_filled = this.salarios
      this.data_filled_t = this.salarios;
      this.data_filled_g = this.getDataStackedGraph();
      break;
      case 4:
      this.yArray = this.yArrayDuracion;
      this.yElements = this.yArrayDuracion[0]
      this.data_filled = this.duracion
      this.data_filled_t = this.duracion;
      this.data_filled_g = this.getDataStackedGraph();
      break;
      
      default:
        // code...
        break;
      }

      this.yElementsGestion.map( ind => {
        this.filter.indicador[ind.value] = true;
      });

      this.yElementsSalario.map( ind => {
        this.filter.salario[ind.value] = true;
      });

      this.yElementsDuracion.map( ind => {
        this.filter.duracion[ind.value] = true;
      });

      this.operadores.map( (op:Operador) => {
        this.filter.operador[op.name_operator] = true;
      });

      this.analyticsService.eventButton('Indicadores:' + this.tabs_labels[tab - 1], localStorage.getItem("username"));

      this.setPage(1);
      this.generateTableIndicador();
    }

  generateTableIndicador(){
    this.table_data = [];


    let typeIndicador, typeLabels;
    switch (this.tab_switch) {
      case 1:
      typeIndicador = 'yElements';
      typeLabels = 'yTableEfectividad'
      break;
      case 2:
      typeIndicador = 'yElements';
      typeLabels = 'yTableGestion'
      break;
      case 3:
      typeIndicador = 'yElements';
      typeLabels = 'yTableSalario'
      break;
      case 4:
      typeIndicador = 'yElements';
      typeLabels = 'yTableDuracion'
      break;

      default:
      break;
    }

    this[typeLabels].map( page => {
      let row = [];
      let row_sum : any;

      row.push(page.name);

      this.operadores.map( op => {
        let data_op = this.data_paged_t.filter( f => f.name_operator == op.name_operator);
        if(data_op.length > 0){
          if(page.type){

            if(page.type == 'money'){
              row.push(isNaN(data_op[0][page.value]) ? data_op[0][page.value] :(new CurrencyPipe('es-CO')).transform(data_op[0][page.value], 'USD', true, '1.2-2').replace(/[USD]/g, ''));  
            }else{
              row.push(isNaN(data_op[0][page.value]) ? data_op[0][page.value] : Math.round(data_op[0][page.value]));
            }

          }else{
            row.push(isNaN(data_op[0][page.value]) ? data_op[0][page.value] : Math.round(data_op[0][page.value]) + '%');
          }

        }
      })


      if(!this.operator_id){

        if(page.type){
          if(row){
            row_sum = (row.map( item =>{
              let value = (typeof item == 'string') ?  Number(item.replace(/[\$\. )]/g, '').replace(',00', '')) :  Number(item);

              if(isNaN(value))
                return item;
              else
                return value;
            }).filter( f => !isNaN(f)).reduce(
            (prev, curr, index) => {
              return prev + curr;
            })) / (row.length - 1)  


            if(page.type == 'money'){
              row_sum =  (new CurrencyPipe('es-CO')).transform(row_sum, 'USD', true, '1.2-2').replace(/[USD]/g, '');
              row.push(row_sum); 
            }else{

              //row.push(Math.round(row_sum));   
            }
          }
        }else{
          row_sum = (row.map( item =>{
            if(item)
              return  Number(item.slice(0, item.length - 1))

            return 0;
          }).filter( f => !isNaN(f)).reduce(
          (prev, curr, index) => {
            return prev + curr;
          })) / (row.length - 1)

          //row.push(Math.round(row_sum)+'%');  
        }
      }
      this.table_data[page.value] = row;
    });
  }

  GetKeys(object){
    return Object.keys(object);
  }

  chagePagination(event){
    this.max_page = event;
    this.setPage(1);
    this.generateTableIndicador();
  }


  chageData(event){
    this.loadPage = true;
    this.verificadorService.getIndicadores(this.filter.ampliacion)
    .subscribe( data => {


      let data_general = data.operators;
      this.data = data.operators;
      this.data_filled = data.operators
      this.operator_id = data.user_id_session;

      let operator_general = {};

      if(this.operator_id){
        data_general = [
          data.operators.filter( op => op.id_operador == this.operator_id)[0],
          this.getTotales(data.operators)
        ] 
        this.data = data_general;
      }else{
        data_general = this.data;
        data_general.push(this.getTotales(data.operators))
      }

      console.log(data_general);
      this.salarios = data_general.map( op => {
        op.salario['name_operator'] = op.name_operator;
        op.salario['id_operador'] = op.id_operador;
        op.salario['total_colocados'] = op.total_colocados;
        return op.salario;
      });

      console.log(this.salarios);

      this.duracion = data_general.map( op => {
        op.duracion_contrato['name_operator'] = op.name_operator  ;
        op.duracion_contrato['id_operador'] = op.id_operador  ;
        op.duracion_contrato['total_colocados'] = op.total_colocados;
        return op.duracion_contrato;
      });

      for(let tasa of this.yArrayTasas){
        let row = [];
        data_general.map( (op, index) => {
          Object.keys(op).map( key => {
            if(key == tasa.value){
              row.push({
                operator: op.name_operator,
                value: op[key],
                color: this.color_catalog[Object.keys(this.color_catalog)[index]]
              })
            }
          });
        });

        this.tasas.push({
          name:tasa.name,
          tasas: row
        })

      }

       this.operadores.map( (op:Operador) => {
        this.filter.operador[op.name_operator] = true;
      });


      if(this.tab_switch == 3){
        this.data_filled = this.salarios
        this.data_filled_t = this.salarios;
        this.data_filled_g = this.getDataStackedGraph();
      }else if(this.tab_switch == 4){
        this.data_filled = this.duracion
        this.data_filled_t = this.duracion;
        this.data_filled_g = this.getDataStackedGraph();
      }else{
        this.data_filled = this.data
        this.data_general = data_general;
        this.data_filled_g = data_general;
        this.data_filled_t = data_general;
      }


      this.setPage(1);
      this.loadPage = false;
      this.generateTableIndicador();
    })
  }


  chageForm(event){

    Object.keys(this.filter).map( fill => {
      let obj = this.filter[fill];
      let operators = [];

      if(fill == 'operador'){
        if(this.data_filled){

          if(Object.keys(obj).length > 0){
            let filter_data = [];
            this.data_filled_g = [];
            this.data_filled_t = [];
            this.data_filled = [];

            for(let item of Object.keys(obj)){

              if(obj[item] == true){
                operators.push(this.operadores.filter(f => f.name_operator == item)[0])
                if(this.tab_switch == 1 || this.tab_switch == 2){
                  filter_data = this.data_general.filter( f => f.name_operator == item)
                }else if(this.tab_switch == 3){
                  filter_data = this.salarios.filter( f => f.name_operator == item)
                }else if(this.tab_switch == 4){
                  filter_data = this.duracion.filter( f => f.name_operator == item)
                }

                filter_data.map( item => {
                  this.data_filled_t.push(item)
                  this.data_filled_g.push(item)
                  this.data_filled.push(item)

                })  
              }
            }

            console.log();

          }else{
            this.data_filled = this.data_general;
          }
        }
      }

      let index_total = this.data_filled.findIndex( f => f.id_operador == 100);
      operators = this.data_filled.filter(f => f.id_operador != 100);
      this.data_filled[index_total] = this.getTotales(operators.filter(f => f.id_operador != 100));

      this.data_filled_t = this.data_filled
      this.data_filled_g = this.data_filled

      
     
      switch (this.tab_switch) {
       
        case 3:
          this.data_filled_g = this.getDataStackedGraph();
          break;
        case 4:
          this.data_filled_g = this.getDataStackedGraph();
          break;
        }


      this.generateTableIndicador();
      this.setPage(1);
    });
  }

  checkPagination(direction){

    switch (direction) {
      case "Next":
      return !(this.pager.currentPage === this.pager.totalPages)
      case "Previous":
      return !(this.pager.currentPage === 1);

      default:
      return false;
    }

  }

  setPage(page: number){
    if(page < 1 || page > this.pager.totalPages){
      return
    }

    this.pager = this.pagerService.getPager(this.yArray.length, page, 1);
    this.yElements = this.yArray[this.pager.currentPage - 1];
    this.yElementsTotals = this.yArrayTotals[this.pager.currentPage - 1];

    this.percent_graph = !(this.yElements[0]['type'])

    this.data_paged_g = this.data_filled_g  
    this.data_paged_t = this.data_filled_t

    this.generateTableIndicador();
  }

  changePaged(direction){

    switch (direction) {
      case "Next":
      if(this.tab_switch != 1 && this.tab_switch != 2)
        this.data_filled_g = this.getDataStackedGraph(this.pager.currentPage);

      this.setPage(this.pager.currentPage + 1);
      break;
      case "Prev":
       if(this.tab_switch != 1 && this.tab_switch != 2)
        this.data_filled_g = this.getDataStackedGraph(this.pager.currentPage - 2);

      
      this.setPage(this.pager.currentPage - 1);

      break;

      default:

      return 1;
    }
  }


    getDataStackedGraph(index = 0){


      let source_data = this.yArray[index];
      let return_data:any = [];
      let color_data = [];
      let total = 0;

      return_data.push(source_data.map( item => item.name.toUpperCase() ));
      return_data[0].unshift('Operador')

      for(let operator of this.data_filled){
        let row_data = [];

        Object.keys(operator).map( key => {
          let source_element = source_data.filter( f => key == f.value);
          if(source_element.length > 0){
            if(source_element[0].type == 'total'){
              let sum = (operator[key] - total) > 0 ? operator[key] - total : 0
              row_data.push({v:sum, f:operator[key]});
              total = 0;
            }else{
              row_data.push({v:operator[key], f:operator[key]});
              total+=operator[key];  
            }
           
            color_data.push(this.color_catalog[source_data.filter( f => key == f.value)[0].color]);
            
          }
        })

        row_data.unshift(operator['name_operator'].toUpperCase())

        return_data.push(row_data)
      }

      this.color_set = color_data;

      return return_data;
    }

    pageTasas(page: number){
      if(page < 1 || page > this.pager_tasas.totalPages){
        return
      }

      this.pager_tasas = this.pagerService.getPager(this.tasas.length, page, 1);
      this.yTasas = this.tasas[this.pager_tasas.currentPage - 1];

    }

  }
