import { Component, OnInit } from '@angular/core';
import { PagerServicesService, OperadorDataService, VerificadorService } from '../../../services/';
import { ChangesAlertService } from '../../../shared/changes-alert.service';


@Component({
  selector: 'app-verificar-participantes',
  templateUrl: './verificar-participantes.component.html',
  styleUrls: ['./verificar-participantes.component.css']
})
export class VerificarParticipantesComponent implements OnInit {

	model: any = {
    etapa: "",
    operador: "",
    estado: "",
    documento: "",
  };
	operadores: any = [];
	numberPages:number = 15;
	pager: any = {};
  total_pages:number;

	incidentes: any = [];
  type_user:any;

  constructor(private operadorService: OperadorDataService,
    private pagerService: PagerServicesService, 
    private verificadorService: VerificadorService,
    private alertService:ChangesAlertService) { }

  ngOnInit() {

     this.type_user = localStorage.getItem('user_type');
     if(this.type_user == 1){
      this.model.estado = 2;   
     }

     if(this.type_user == 2 || this.type_user == 3){
      this.model.estado = 1;    
     }
    

  	this.operadorService.getAllOperadores()
    .subscribe( data => {
      this.operadores = data.operator.map( operator => {
        return { id: operator.id, name: operator.name_operator };
      });
    })

    this.loadIncidentes(1);
  }

  filterData(){
  	this.loadIncidentes(1);
  }

  setPage(page: number){
  	if(page < 1 || page > this.pager.totalPages){
      return
    }

    this.pager = this.pagerService.getPager(this.total_pages , page, this.numberPages);
  }

  changePage(page){
    this.loadIncidentes(page);
  }



  loadIncidentes(page){
    
    this.incidentes = [];
    this.verificadorService.getIssuesParticiantes(this.model.etapa, this.model.operador, this.model.estado, this.model.documento, (page - 1) * this.numberPages)
      .subscribe( data => {
          if(data.error)
             return;

          this.total_pages = data.total_notificaciones;

          for(let issue of data.notificaciones){

              let link_fase = 0;

              switch (issue.phases_id) {
                case 11:
                  link_fase = 8
                  break;
                case 12:
                  link_fase = 13
                  break;
                case 13:
                  link_fase = 14
                  break;
              }


              console.log(link_fase);

              this.incidentes.push( {
                id: issue.id,
                user:issue.user_xml_id,
                fase:link_fase,
                etapa: issue.phase,
                operador: issue.name_operator,
                tdc: issue.name_document,
                documento: issue.document,
                nombres: issue.first_name,
                apellido: issue.last_name,
                observacion: issue.comment,
                estado: issue.name_stage,
                estado_id: issue.states_notification_id
              })
          }
          this.setPage(page);
      });

    
  }

  archiveIncident(evt, id_notification){

    evt.stopPropagation();
    if(confirm("¿Desea archivar esta notificación?")){
      this.verificadorService.archiveStatusNotification(id_notification)
        .subscribe( data => {
          console.log(data);
           alert("La notificación fue archivada exitosamente");
           this.loadIncidentes(this.pager.currentPage);
           this.alertService.changeAlert(true);

        })
    }
  }

}
