import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class ChangesAlertService {

	private alert_status = new Subject<boolean>();
	changeAlert$ = this.alert_status.asObservable();

	constructor() { }

	changeAlert(item: boolean){
		this.alert_status.next(item);
	}

}
