import { 
	Directive,
	Input,
	Output,
	EventEmitter,
	HostListener,
	HostBinding } from '@angular/core';

 import { DragService } from './drag-service.service';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {

  constructor(private dragService: DragService) { }

  @HostBinding('draggable')
  get draggable(){
	return true;
  }

  @Input()
  set draggableOptions(options: DraggableOptions){
  	if(options){
  		this.options = options;
  	}
  }

  @Output() drag = new EventEmitter();

  private options : DraggableOptions = {};

  @HostListener('dragstart', ['$event'])
  public onDragStart(evt){
  	const {
  		zone =  'zone',
  		data = {}
  	} = this.options;

    this.dragService.startDrag(zone);
    evt.dataTransfer.setData('Text', JSON.stringify(data));
    this.drag.next(data);

  }

  

}

export interface DraggableOptions{
	zone?: string;
	data?: any;
}
