import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service';
import { Utilities } from '../../../shared/utilities';
import { HISTORY_PERMANENCIA } from '../../../data/history_permanencia';

@Component({
  selector: 'app-permanencia',
  templateUrl: './permanencia.component.html',
  styleUrls: ['./permanencia.component.css'],
  providers: [QuestionService]
})
export class PermanenciaComponent implements OnInit {


  empresa:any;
  colocacion:any;
  history:any = [];
  s3file: string;
  file_certificate: string;
  file_contribution: string;
  complete:any;
  questions:any;
  contract_time: any;
  contract_days: any;
  permanencia_id_1: any = null;
  permanencia_id_2: any = null;
  permanencia_id_3: any = null;
  data_meses: any = [];
  isLoadModule:boolean = false; 
  template_history = HISTORY_PERMANENCIA;


  @ViewChild('permanencia_form') permanencia_form;
  @ViewChild('panel') panel;
  @Input() enabled_module:boolean = false;
  @Input() beneficiario:any;
  @Input() disabled:any;
  @Input() type_permanencia:any;
  @Output() loadEnd = new EventEmitter();
  @Output() loadFile = new EventEmitter();
  @Output() fullForm = new EventEmitter();


  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService,
    private dropService: DropsWrapperService) { }

  ngOnInit() {
  	this.questionsService.getQuestionsPermanencia().map( questions => {

      let quest = [];
      for(let i = 0; i < 3; i++){
        questions.map( q => {
          if(q.key.indexOf('permanece3') <= -1 && q.key.indexOf('permanece6') <= -1 ) {
            let clone = Object.assign({}, q);  

            clone.key = clone.key + '_' + (i + 1)
            clone.index = (i + 1);

            if(q.key == 'title_mes'){
              if(this.type_permanencia == 1){
                clone.label = q.label + ' ' + (i + 1)
              }else if(this.type_permanencia == 2){
                clone.label = q.label + ' ' + (i + 4)
              } 
            }

            if(q.key == 'button_mes'){
              if(this.type_permanencia == 1){
                clone.text = q.text + ' ' + (i + 1)
              }else if(this.type_permanencia == 2){
                clone.text = q.text + ' ' + (i + 4)
              } 
            }else{
              clone.visible = false;
              clone.hidden = true;
            }

            if(clone.inputInput)
              clone.inputInput = clone.inputInput.split(',').map( input => input + '_' + (i + 1)).join(',')

            if(clone.outputInput)
              clone.outputInput = clone.outputInput.split(',').map( output => output + '_' + (i + 1)).join(',')


            quest.push(clone);
          }
        });
      }

      //quest.push(questions[this.getQuestionbyKey(questions, 'ajuste_verificador')])

      // if(this.type_permanencia == 1){
      //   quest.push(questions[this.getQuestionbyKey(questions, 'permanece3')]) 
      // }

      // if(this.type_permanencia == 2){
      //   quest.push(questions[this.getQuestionbyKey(questions, 'permanece6')]) 
      // }

      //Duplicar form
      for(let item in quest){
        if(quest[item].key.indexOf('_1') > 0 || quest[item].key.indexOf('_2') > 0 || quest[item].key.indexOf('_3') > 0){
          quest[item]['hidden'] = true; 
        }   
      }
      console.log("beneficiario:", this.beneficiario)

      this.beneficiarioService.getPermanencia(this.beneficiario.user_xml_id, this.type_permanencia).subscribe(
        data => {
          this.loadEnd.emit({ load:true })
          if(data.error){
            alert(data.msg);
            return;
          }



          if(data.files.length > 0){
            this.file_certificate = data.files[0].file_certificate_per;
            this.file_contribution = data.files[0].file_contribution_per;

            //  if(this.type_permanencia == 1){
            //   quest[this.getQuestionbyKey(quest, 'permanece3')]['value'] = data.permanece3;

            //   if(this.beneficiario.completa_permanecia1 == 2){
            //     if( this.file_certificate || this.file_contribution)
            //       quest[this.getQuestionbyKey(quest, 'permanece3')]['visible'] = true;
            //   }
            // }


            // if(this.type_permanencia == 2){
            //   quest[this.getQuestionbyKey(quest, 'permanece6')]['value'] = data.permanece6;

            //   if(this.beneficiario.completa_permanecia2 == 2){
                
            //     if( this.file_certificate || this.file_contribution)
            //       quest[this.getQuestionbyKey(quest, 'permanece6')]['visible'] = true;
            //   }
            // }
          }

          if(data.details_company_vacancy){
            this.empresa = data.details_company_vacancy[0] ?  data.details_company_vacancy[0] : null;
          }

          if(data.details_jobplacment){
            this.colocacion = data.details_jobplacment[0] ? data.details_jobplacment[0] : null;
            
            if(this.colocacion){
              this.contract_time = Utilities.dateDiff.getDateDifferenceInMonths(new Date(this.colocacion.date_start), new Date(this.colocacion.date_finish));
              //this.contract_days = Utilities.dateDiff.inDays(new Date(this.colocacion.date_start), new Date());
              this.contract_days = this.beneficiario.total_contrato

              this.showForm(quest, 1);                

              if(this.contract_days >= 30){
                this.showForm(quest, 2);                
              }

              if(this.contract_days >= 60){
                this.showForm(quest, 3);                
              }

              
            }
          }

          if(data.month1){
            if(data.month1[0]){
              this.permanencia_id_1 = data.month1[0].perm_id;
              for(let item in quest){ 

                let key = quest[item].key.split('_1')[0]


                if(data.month1[0][key] != null){

                  if(data.month1[0][key] === 1 && quest[item]['source'] == 'booleanSource'){
                    quest[item].value = 'true'; 
                  }

                  else if(data.month1[0][key] === 0 && quest[item]['source'] == 'booleanSource') {
                    quest[item].value = 'false'; 
                  }

                  else
                    quest[item].value = data.month1[0][key] + "";
                }

              }

              if(data.month1[0].type_accompaniment){
                let type_accompaniment = data.month1[0].type_accompaniment.split('/-/');

                for(let type of type_accompaniment){
                  let t = type.split('*'),
                  firts = t[0], last = t[t.length - 1];

                  quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id'+firts+'_1')]['value'] = 1
                  if(last.length > 0){
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_1')]['value'] = last;
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_1')]['disabled'] = false;
                  }
                }
              }
            }
          }

          if(data.month2){
            if(data.month2[0]){
              this.permanencia_id_2 = data.month2[0].perm_id;
              for(let item in quest){

                let key = quest[item].key.split('_2')[0]
                if(data.month2[0][key] != null){
                  if(data.month2[0][key] === 1 && quest[item]['source'] == 'booleanSource'){

                    quest[item].value = 'true'; 
                  }

                  else if(data.month2[0][key] === 0 && quest[item]['source'] == 'booleanSource') {
                    quest[item].value = 'false'; 
                  }

                  else
                    quest[item].value = data.month2[0][key] + "";
                }

              }

              if(data.month2[0].type_accompaniment){
                let type_accompaniment = data.month2[0].type_accompaniment.split('/-/');

                for(let type of type_accompaniment){
                  let t = type.split('*'),
                  firts = t[0], last = t[t.length - 1];

                  quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id'+firts+'_2')]['value'] = 1
                  if(last.length > 0){
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_2')]['value'] = last;
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_2')]['disabled'] = false;
                  }
                }
              }
            }
          }

          if(data.month3){
            if(data.month3[0]){
              this.permanencia_id_3 = data.month3[0].perm_id;
              for(let item in quest){

                let key = quest[item].key.split('_3')[0]

                if(data.month3[0][key] != null){

                  if(data.month3[0][key] === 1 && quest[item]['source'] == 'booleanSource'){
                    quest[item].value = 'true'; 
                  }

                  else if(data.month3[0][key] === 0 && quest[item]['source'] == 'booleanSource'){
                    quest[item].value = 'false'; 
                  }


                  else
                    quest[item].value = data.month3[0][key] + "";
                }

              }

              if(data.month3[0].type_accompaniment){
                let type_accompaniment = data.month3[0].type_accompaniment.split('/-/');

                for(let type of type_accompaniment){
                  console.log(type)
                  let t = type.split('*'),

                  firts = t[0], last = t[t.length - 1];

                  console.log(t)

                  console.log('type_permanece_accomp_id'+firts+'_3', quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id'+firts+'_3')])

                  quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id'+firts+'_3')]['value'] = 1
                  //Observacion
                  if(last.length > 0){
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_3')]['value'] = last;
                    quest[this.getQuestionbyKey(quest, 'type_accomp_other_3')]['disabled'] = false;
                  }
                }
              }
              
            }
          }

          for(var i = 1; i <= 3; i++){
            quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id_'+i)]['hidden'] = true;
            
            if(quest[this.getQuestionbyKey(quest, 'continua_empleado_'+i)]['value'] === 'true'){

              quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id_'+i)]['options'].map( opt => {
                quest[this.getQuestionbyKey(quest, 'type_permanece_accomp_id'+opt.key+'_'+i)]['disabled'] = false;
              })  

              quest[this.getQuestionbyKey(quest, 'satisfaction_competitor_id_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest, 'satisfaction_company_id_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest, 'desertion_risk_id_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest, 'observation_'+i)]['disabled'] = false;
            }

            if(quest[this.getQuestionbyKey(quest, 'continua_empleado_'+i)]['value'] === 'false'){
              quest[this.getQuestionbyKey(quest,'completion_reason_id_'+i)]['disabled'] = false;
            }

            if(quest[this.getQuestionbyKey(quest, 'continua_empleado_'+i)]['value'] === 'false'){
              quest[this.getQuestionbyKey(quest,'expectation_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'schedules_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'subordination_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'other_company_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'satisfaced_company_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'salary_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'environment_working_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'other_competitor_'+i)]['disabled'] = false;
              quest[this.getQuestionbyKey(quest,'contract_end_date_'+i)]['disabled'] = false;
            }
          }

          if(data.month_history.length > 0){
            for(let month of data.month_history){
              let  h = JSON.parse(JSON.stringify(this.template_history));

              for(let key of h){
                if(month[key.value] !== undefined){


                  if(key.value == 'continua_empleado' ){
                    if(month[key.value] == true){
                      this.getItemHistory('contract_end_date', h)['hidden'] = true;
                      this.getItemHistory('expectation', h)['hidden'] = true;
                      this.getItemHistory('schedules', h)['hidden'] = true;
                      this.getItemHistory('subordination', h)['hidden'] = true;
                      this.getItemHistory('other_company', h)['hidden'] = true;
                      this.getItemHistory('satisfaced_company', h)['hidden'] = true;
                      this.getItemHistory('salary', h)['hidden'] = true;
                      this.getItemHistory('environment_working', h)['hidden'] = true;
                      this.getItemHistory('other_competitor', h)['hidden'] = true;
                    }else{
                      this.getItemHistory('satisfaction_competitor', h)['hidden'] = true;
                      this.getItemHistory('satisfaction_company', h)['hidden'] = true;
                      this.getItemHistory('desertion_risk', h)['hidden'] = true;
                      this.getItemHistory('observation', h)['hidden'] = true;
                    }
                  }
                  key.value = month[key.value];

                }

                if(key.value == 'mes' ){
                  console.log(month['permanece_month_id'])
                  key.value = 'MES ' + month['permanece_month_id'];
                }

                if(key.value == 'title' ){
                  key.value = 'EMPLEO ' + month['numero_empleo'];
                }
              }
              this.history.push(h)
            }
          }

          console.log(this.history)


          if(data.files_history.length > 0){

            for(let index in data.files_history){
              let file = data.files_history[index];
              
              let h = this.history[index]

              if(h){
                for(let key of h){
                  if(file[key.value] !== undefined){
                      key.value = file[key.value];
                   }
                }
                this.history[index] = h;  
              }

              
            }
          }else{
             for(let h of this.history){
               for(let key of h){
                 if(key.type == 'document'){
                   console.log(key)
                   key.value = '';
                 }
               }
               
             }
             
          }

         
          this.loadEnd.emit({ load:false })
          this.questions = quest;

          setTimeout(_ => {
            if(this.panel)    
              window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
          });
          
          this.isLoadModule = true;
        });

})

}

getItemHistory(field, history){
  for(let key of history){
    if(key.value == field)
      return key;
  }
}

showForm(questions, index){
  for(let item in questions){
    if(questions[item].key.indexOf('_'+index) > 0 ){
      questions[item].hidden = false; 
    }
  }
}

submitFile(url_source, name_file, type_file){

  this[name_file] = url_source.url
  console.log(type_file)
  this.beneficiarioService.addFileColocacion(url_source.url, name_file, this.beneficiario.user_xml_id, type_file,this.type_permanencia)
  .subscribe( data => {

    console.log(data);

    // if( this.file_certificate || this.file_contribution){
    //   this.permanencia_form.enabledInput('permanece3', true)  
    //   this.permanencia_form.enabledInput('permanece6', true)
     
    //   if(this.questions[this.getQuestionbyKey(this.questions, 'permanece3')])
    //     this.questions[this.getQuestionbyKey(this.questions, 'permanece3')].visible = true;

    //   if(this.questions[this.getQuestionbyKey(this.questions, 'permanece6')])
    //     this.questions[this.getQuestionbyKey(this.questions, 'permanece6')].visible = true;
    // }
    this.loadEnd.emit({loading:false}) 
    this.loadFile.emit({load:false})
  });
}

onSubmit(form){
  this.loadEnd.emit({loading:true})

  Object.keys(form.formData).forEach(key => {
    if(form.formData[key] === true)
      form.formData[key] = 1;
  });

  let type_permanece_accomp_id_1 = [], type_permanece_accomp_id_2 = [], type_permanece_accomp_id_3 = [];


  for(let index of Object.keys(form.formData)){
    if(index.indexOf('type_permanece_accomp_id') >= 0){
      if(form.formData[index] == 1){
        let idx = index.split('_id');
        idx = idx[idx.length - 1].split('_');


        if(index.indexOf('_1') >= 0){
          type_permanece_accomp_id_1.push(idx[0]) 
        }

        if(index.indexOf('_2') >= 0){
          type_permanece_accomp_id_2.push(idx[0]) 
        }

        if(index.indexOf('_3') >= 0){
          type_permanece_accomp_id_3.push(idx[0]) 
        }
      }
    }
  }

  form.formData['type_permanece_accomp_id_1'] = type_permanece_accomp_id_1.join(',')
  form.formData['type_permanece_accomp_id_2'] = type_permanece_accomp_id_2.join(',')
  form.formData['type_permanece_accomp_id_3'] = type_permanece_accomp_id_3.join(',')


  this.beneficiarioService.savePermanencia(this.beneficiario.user_xml_id, [this.permanencia_id_1,this.permanencia_id_2,this.permanencia_id_3], form.formData, this.type_permanencia)
  .subscribe( data => {
    if(!data.error){

      if(data.completa == 0){
        this.permanencia_form.highWarrningInputsOn();
        alert(data.msg + '\nPero hay información pendiente para completar la etapa \nRecuerde que esta etapa se completará apenas haya diligenciado los '+ ((this.type_permanencia == 1) ? 3 : 6 ) + ' meses')
      }else{
        this.permanencia_form.highWarrningInputsOff();
        alert(data.msg)
      }

      this.complete = data.completa;
      this.permanencia_id_1 = data.permanece_id_1;
      this.permanencia_id_2= data.permanece_id_2;
      this.permanencia_id_3 = data.permanece_id_3;

      if(this.complete == 1){
        if(this.questions[this.getQuestionbyKey(this.questions, 'permanece3')])
          this.questions[this.getQuestionbyKey(this.questions, 'permanece3')].visible = true;

        if(this.questions[this.getQuestionbyKey(this.questions, 'permanece6')])
          this.questions[this.getQuestionbyKey(this.questions, 'permanece6')].visible = true;  
      }

      

      this.fullForm.emit({isFill:(data.completa == 1)})
    }
    else
      alert("Hubo un error al enviar la información")

    
    this.loadEnd.emit({loading:false})
  })
  
}

loadingFile(){
  //this.loadEnd.emit({loading: true})
  this.loadFile.emit({load:true})
} 

getQuestionbyKey(quest, key:string){
  for(let item in quest){
    if(quest[item].key == key)
      return item;
  }

  return null;
}



}
