import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Ng2DeviceService } from 'ng2-device-detector';
import { Router, ActivatedRoute, NavigationStart, RoutesRecognized, NavigationEnd} from '@angular/router';
import { AppSettings } from './../../appSettings';
import { AuthenticationService, VerificadorService, AnalitycsService } from '../../services';
import { ChangesAlertService} from '../../shared/changes-alert.service';

import 'rxjs/add/operator/filter';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit{

	@Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() alert: EventEmitter<boolean> = new EventEmitter<boolean>();

	menuColapse = false;
  user_name:string;
  type_user:string;
  link_xml: string;
  link_xls: string;
  link_sabana:string;
  profile: string;
  deviceInfo:any = null;
  alert_notify: boolean = false;


  constructor(private deviceService:Ng2DeviceService, 
              private router: Router, 
              private route:ActivatedRoute, 
              private authService: AuthenticationService, 
              private verificadorService:VerificadorService,
              private alertService: ChangesAlertService,
              private analitycsService: AnalitycsService){
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  routerLink(url){
    console.log(url)
    this.router.navigate([url]);
    
    if(this.detectMobil()){
      window.scrollTo(0, 0);
      this.closeMenu();  
    }
    
  }


  ngOnInit(){
    
    this.user_name = localStorage.getItem('username');
    this.type_user = localStorage.getItem('user_type');
    this.profile = localStorage.getItem('profile');
    this.link_xml = AppSettings.URL_BACKEND + 'Kokan_online';
    this.link_xls = AppSettings.URL_BACKEND + 'RegisterKokanOnline.xlsx';
    this.link_sabana = AppSettings.URL_BACKEND + 'Sabana_excel.xlsx';

    if(this.detectMobil()){
      this.menuColapse = true;
      this.notify.emit(this.menuColapse);
    }

    this.verificadorService.getNotificationUser()
      .subscribe( data => {
        if(data.total > 0){
            this.alert_notify = true;
            this.alert.emit(this.alert_notify);
        }
        
      })


     this.alertService.changeAlert$.subscribe( data => {
        this.verificadorService.getNotificationUser()
        .subscribe( data => {
            this.alert_notify =data.total > 0;
            this.alert.emit(this.alert_notify);
          
        })
     })


  }

  detectMobil(){
    return this.deviceInfo.device.search(/iphone|ipod|android|ipad/) > -1;
  }


  clickMenu (){
    this.menuColapse = !this.menuColapse;
    this.notify.emit(this.menuColapse);
  }

  closeMenu(){
   this.menuColapse = true;
   this.notify.emit(this.menuColapse); 
  }

  toggleOpen(event: MouseEvent){
    event.preventDefault();

  }

  logout(){
    let init_time = parseInt(localStorage.getItem('time_init_sesion'));
    let curr_time = new Date().getTime();
    let time = (curr_time - init_time)/1000/60;

    this.analitycsService.eventLogout(localStorage.getItem('username'), time);
    
    this.authService.logout();
    this.routerLink('/login')
  }

}
