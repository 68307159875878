export const FORM_PREFORMACION = [
	{
		"key":"user_ability[0][skill_id]",
		"label":"NOMBRE PRUEBA LECTURA - ESCRITURA",
		"size":"7",
		"type":"hidden",
		"controlType":"textbox",
		"value":"1",
		"verify":true,
		"editable":true
		
	},
	{
		"key":"user_ability[0][name_ability]",
		"label":"NOMBRE PRUEBA LECTURA - ESCRITURA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[0][score_id]",
		"label":"HABILIDAD LECTO ESCRITURA ",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"puntaje",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[0][actual_result]",
		"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 0,
		"verify":true,
		"editable":true
	},

	{
		"key":"user_ability[1][skill_id]",
		"label":"NOMBRE PRUEBA MATEMATICAS",
		"size":"7",
		"type":"hidden",
		"controlType":"textbox",
		"value":"2",
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[1][name_ability]",
		"label":"NOMBRE PRUEBA MATEMATICAS",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 1,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[1][score_id]",
		"label":"HABILIDAD DE MATEMÁTICAS FRENTE AL MERCADO LABORAL  ",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"puntaje",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 1,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[1][actual_result]",
		"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 1,
		"verify":true,
		"editable":true
	},
	
	{
		"key":"user_ability[2][skill_id]",
		"label":"NOMBRE PRUEBA- TI",
		"size":"7",
		"type":"hidden",
		"controlType":"textbox",
		"value":"3",
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[2][name_ability]",
		"label":"NOMBRE PRUEBA- TI",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 2,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[2][score_id]",
		"label":"HABILIDAD EN TECNOLOGÍA DE INFORMACIÓN",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"puntaje",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 2,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[2][actual_result]",
		"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 2,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[3][skill_id]",
		"label":"NOMBRE PRUEBA-PSICOTÉCNICA",
		"size":"7",
		"type":"hidden",
		"controlType":"textbox",
		"value":"4",
		"verify":true,
		"editable":true
	},

	{
		"key":"user_ability[3][name_ability]",
		"label":"NOMBRE PRUEBA-PSICOTÉCNICA",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 3,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[3][score_id]",
		"label":"COMPETENCIAS PSICOTÉCNICAS",
		"size":"6",
		"type":"text",
		"controlType":"dropdown",
		"source":"puntaje",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 3,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[3][actual_result]",
		"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 3,
		"verify":true,
		"editable":true
	},

	{
		"key":"user_ability[4][skill_id]",
		"label":"NOMBRE PRUEBA-SOCIOLABORALES",
		"size":"7",
		"type":"hidden",
		"controlType":"textbox",
		"value":"5",
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[4][name_ability]",
		"label":"NOMBRE PRUEBA-SOCIOLABORALES",
		"size":"6",
		"type":"text",
		"controlType":"textbox",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 4,
		"verify":true,
		"editable":true
	},

	{
		"key":"user_ability[4][score_id]",
		"label":"HABILIDAD SOCIOLABORAL",
		"size":"6",
		"type":"date",
		"controlType":"dropdown",
		"source":"puntaje",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 4,
		"verify":true,
		"editable":true
	},
	{
		"key":"user_ability[4][actual_result]",
		"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verifyGroup":"user_ability",
		"verifyGroupIndex": 4,
		"verify":true,
		"editable":true
	},
	{
		"key":"date_implementation",
		"label":"FECHA REALIZACION",
		"size":"6",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":true
	},
	{
		"key":"result_cosolidated_id",
		"label":"RESULTADO CONSOLIDADO",
		"size":"6",
		"type":"date",
		"controlType":"dropdown",
		"source":"puntaje",
		"verify":true,
		"editable":true
	},
	{
		"key":"actual_result_consolidate",
		"label":"¿CUÁL ES EL RESULTADO REAL CONSOLIDADO DE LA PRUEBA APLICADA?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":true,
		"editable":true
	},
	// {
	// 	"key":"user_ability[1][actual_result]",
	// 	"label":"¿CUÁL ES EL RESULTADO REAL DE LA PRUEBA APLICADA?",
	// 	"size":"12",
	// 	"type":"text",
	// 	"controlType":"textarea",
	// },

]