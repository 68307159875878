import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { Operador } from './../../struct';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';

@Injectable()
export class OperadorDataService {
  lastId: number = 0;
  operadores: Operador[];

  constructor(private http: Http) { }

  addOperador(operador: Operador){

    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)

    for(var key in operador){
      data.append(key, operador[key])
    }

    var i = 0;
    for(var hito in operador.hitos){
      for(key in operador.hitos[hito]){
        data.append('hitosoperator[' + i +']['+ key +']', operador.hitos[hito][key])
      }
      i++;
    }
    
    return this.http.post(AppSettings.API_ENDPOINT + 'insert_Operator', data)
    .map((response: Response) => response.json());
  }

  deleteOperadorById(id: number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('delete_operator_id', id.toString())

    return this.http.post(AppSettings.API_ENDPOINT + 'delete_Operator', data)
    .map((response: Response) => response.json());
  }

  getAllOperadores(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'view_AllOperator', data)
    .map((response: Response) => response.json());
  }

  getOperadorById(id: number){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('operator_id', id.toString())

    return this.http.post(AppSettings.API_ENDPOINT + 'view_Operator_id', data)
    .map((response: Response) => response.json());

  }

  addFile(url_source, type_document, id_operador){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('operator_id', id_operador)
    data.append('imageoperator', url_source)

    return this.http.post(AppSettings.API_ENDPOINT + 'upload_picture_profile', data)
    .map((response: Response) => response.json());
  }

  delHito(id_operador, id_hito){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id)
    data.append('operator_id', id_operador)
    data.append('id_hito', id_hito)

    return this.http.post(AppSettings.API_ENDPOINT + 'delete_milestone', data)
    .map((response: Response) => response.json());

  }

  getFunnelData(param_path, operator_id = null, city_id = null, ampliacion = null, all_data = null){
    var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');
    var path;

    data.append('token', session_id)

    
     if(ampliacion){

      if(!(ampliacion.bis1a && ampliacion.bis1b)){
        if(ampliacion.bis1a){
          data.append('bis1a', ampliacion.bis1a)          
        }

        if(ampliacion.bis1b){
          data.append('bis1b', ampliacion.bis1b)          
        }
      }
      
    }

    switch (param_path) {
      case 1:
        path = 'getOperatorsMilestone';
        data.append('operator_id', operator_id)
        break;

      case 2:
        path = 'getCityMilestone';
        data.append('city_id', city_id)
        break;

      case 3:
        path = 'getMilestoneGeneral';
        break;
    
    }

   all_data = (all_data) ?  all_data : 0;
   data.append('all_ndata', all_data);

    

    return this.http.post(AppSettings.API_ENDPOINT + path, data)
    .map((response: Response) => response.json());
    
  }
}
