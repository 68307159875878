export const FILTER_NOVEDADES = [
	{
		"key":"graphic",
		"label":"ID_GRAFICA",
		"type":"hidden",
		"size":"12",
		"controlType":"textbox",
  	},
	{
		"key":"etapa",
		"label":"ETAPA",
		"source":"funnel_phases",
		"size":"6",
		"default_text": "Seleccione etapa",
		"controlType":"dropdown",
		"action": "fillPlainDrop",
		"outputInput": ["novedad"],
		"outputValue": "9,10",
		"serviceSource": [
			{ key:"1", value:"Despido"},
			{ key:"2", value:"Renuncia"},
			{ key:"3", value:"Finalización de contrato"}
		],
  	},
  	{
		"key":"novedad",
		"label":"NOVEDAD",
		"size":"6",
		"controlType":"dropdown",
		"default_text": "Seleccione novedad",
		"source":"rawSource",
		"plane_options":[
			{ key:"1", value:"Salio"},
			{ key:"2", value:"Pendiente"}
		],
  	},
   	{
		"key":"operador",
		"label":"OPERADOR",
		"source":"operator",
		"size":"6",
		"default_text": "Seleccione operador",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "ciudad"
  	},
  	{
		"key":"ciudad",
		"label":"CIUDAD",
		"size":"6",
		"source":"city",
		"default_text": "Seleccione ciudad",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "operador"
  	}

]


export const FILTER_CAIDAS = [
	{
		"key":"graphic",
		"label":"ID_GRAFICA",
		"type":"hidden",
		"size":"12",
		"controlType":"textbox",
  	},
	{
		"key":"mes",
		"label":"MES",
		"source":"rawSource",
		"plane_options":[
			{ key:"1", value:"MES 1"},
			{ key:"2", value:"MES 2"},
			{ key:"3", value:"MES 3"},
			{ key:"4", value:"MES 4"},
			{ key:"5", value:"MES 5"},
			{ key:"6", value:"MES 6"},
		],
		"size":"12",
		"default_text": "Seleccione mes",
		"controlType":"dropdown",
		"value": 1,
		"init_options": true
  	},
  	{
		"key":"operador",
		"label":"OPERADOR",
		"source":"operator",
		"size":"6",
		"default_text": "Seleccione operador",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "ciudad"
  	},
  	{
		"key":"ciudad",
		"label":"CIUDAD",
		"size":"6",
		"source":"city",
		"default_text": "Seleccione ciudad",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "operador"
  	}

]

export const FILTER_COLOCACION = [
	{
		"key":"graphic",
		"label":"ID_GRAFICA",
		"type":"hidden",
		"size":"12",
		"controlType":"textbox",
  	},
  	{
		"key":"operador",
		"label":"OPERADOR",
		"source":"operator",
		"size":"6",
		"default_text": "Seleccione operador",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "ciudad"
  	},
  	{
		"key":"ciudad",
		"label":"CIUDAD",
		"size":"6",
		"source":"city",
		"default_text": "Seleccione ciudad",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "operador"
  	},
	{
		"key":"tipo_contrato",
		"label":"TIPO DE CONTRATO",
		"size":"12",
		"source":"tipo_contrato",
		"default_text": "Seleccione tipo de contrato",
		"controlType":"dropdown"
		
  	}

]


export const FILTER_GRAPH = [
	{
		"key":"graphic",
		"label":"ID_GRAFICA",
		"type":"hidden",
		"size":"12",
		"controlType":"textbox",
  	},
  	{
		"key":"operador",
		"label":"OPERADOR",
		"source":"operator",
		"size":"6",
		"default_text": "Seleccione operador",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "ciudad"
  	},
  	{
		"key":"ciudad",
		"label":"CIUDAD",
		"size":"6",
		"source":"city",
		"default_text": "Seleccione ciudad",
		"controlType":"dropdown",
		"action":"cleanInputs",
		"cleanInput": "operador"
  	}
]
