export class QuestionBase<T> {
	value: T;
	key: string;
	label: string;
	required: boolean;
	verify: boolean;
	verifyGroup: string;
	verifyGroupIndex: number;
	editable: boolean;
	order: number;
	controlType: string;
	size:number;
	reflect: string;
	disabled:boolean;
	optional:boolean;
	warrning_input:boolean;
	no_warrning:boolean;
	visible:boolean;


	constructor(options:{
		value?: T,
		key?: string,
		label?:string,
		required?:boolean,
		verify?:boolean,
		verifyGroup?: string,
		verifyGroupIndex?: number,
		editable?:boolean,
		order?:number,
		controlType?: string,
		size?:number,
		reflect?: string,
		disabled?:boolean;
		optional?:boolean;
		warrning_input?:boolean;
		no_warrning?:boolean;

	} = {}){
		this.value = options.value;
		this.key = options.key || '';
		this.label = options.label || '';
		this.required = !!options.required;
		this.verify = !!options.verify;
		this.verifyGroup =  options.verifyGroup || '';
		this.verifyGroupIndex =  options.verifyGroupIndex;
		this.editable = !!options.editable;
		this.order = options.order === undefined ? 1 : options.order;
		this.controlType = options.controlType || '';
		this.size = options.size || 12;
		this.reflect = options['reflect'] || '';
		this.disabled = options['disabled'] || false;
		this.visible = options['visible'] === undefined ? true : options['visible'];
		this.optional = options['optional'] || false;
		this.warrning_input = options['warrning_input'] || false;
		this.no_warrning = options['noWarrning'] || false;
		
	}

	getQuestion(key){
		if(this.key == key)
			return this;

		return -1;
	}
}
