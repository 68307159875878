import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VerificadorService, AnalitycsService } from '../../services';

@Component({
	selector: 'app-notificacion',
	templateUrl: './notificacion.component.html',
	styleUrls: ['./notificacion.component.css']
})
export class NotificacionComponent implements OnInit {

	@Input() id_participante;
	@Output() loadEnd = new EventEmitter();
	@Input() disabled:any;
	@Input() message: string;
	@Input() type: any;

	constructor(private verificadorService:VerificadorService,
				private analytcisService: AnalitycsService ) { 

	}

	ngOnInit() {

	}

	onSubmit(){
		this.loadEnd.emit({loading:true})
		this.verificadorService.sendNotificacion(this.id_participante, this.message, this.type)
		.subscribe( data => {
			if(data.error)
				return 
			this.analytcisService.eventAction('Verificador', 'NotificarEvidencia');
			alert(data.mgs)
			this.loadEnd.emit({loading:false})
		})
	}


}
