import { Component, OnInit } from '@angular/core';
import { UtilService, BeneficiarioDataService } from '../../services';
import { Router, ActivatedRoute} from '@angular/router';
import { Beneficiario } from '../../struct';


import * as _ from 'underscore';

@Component({
	selector: 'app-complete-search',
	templateUrl: './complete-search.component.html',
	styleUrls: ['./complete-search.component.css']
})
export class CompleteSearchComponent implements OnInit {

	model:any = {};
	type_documents = [];
	id_form:number;
	beneficiarios:Beneficiario[]  = [];
	loadPage:boolean = true;

	constructor(private utilService: UtilService,
		private router: Router, 
		private route: ActivatedRoute,
		private beneficiariService:BeneficiarioDataService){}

	ngOnInit(){
		this.route.params.subscribe(params => {
			this.loadPage = true;
			this.id_form = this.route.params['value'].state;
			this.beneficiariService.getBeneficiarioByStep(this.id_form)
				.subscribe( data => {
					this.beneficiarios = data.beneficiary
					this.loadPage = false;
				});
		})

		this.utilService.getAllTypeDocument().subscribe(
			data => {
				this.type_documents = data.type_document;
			})

	}

	onAssing(){
		this.loadPage = true;
		this.beneficiariService.getBeneficiarioByDocument(this.model.tipo_documento,this.model.documento)
		.subscribe( data => {
			if(!data.error){
				console.log(data);
				let id_beneficiario = data.user.id;
				this.router.navigate(['participante/'+id_beneficiario+'/'+this.id_form]);
			}else{
				alert(data.msg);
			}
			this.loadPage = false;

		});
	}

}
