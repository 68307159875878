import { Pipe, PipeTransform } from '@angular/core';
import { Operador, 
  Edades,
  EstadosParticipantes, 
  RangosSisben,
  CriteriosPriorizacion,
  TypeReport,
  RazonesNoElegibilidad,
  Poblacion
} from './../struct';
import * as _ from 'underscore';

@Pipe({
  name: 'filterReport'
})
export class FilterReportPipe implements PipeTransform {

  participantes;

  transform(participantes: any, filters: any): any {

    this.participantes = participantes;
    let _participantes = participantes;

    console.log(filters)

    const { estados, ciudades, operadores, edades, estado_civil, generos, nivel_educativo, poblacion, priorizacion, sisben, jefe_hogar, ampliacion, variables } = filters;

    _participantes = this.getFilter(this.participantes, operadores, 'id_operador');
    _participantes = this.getFilterEstados(_participantes, estados);
    _participantes = this.getFilter(_participantes , ciudades, 'city_id');
    _participantes = this.getRangosEdad(_participantes, edades);
    _participantes = this.getFilter(_participantes, estado_civil, 'id_civil_status');
    _participantes = this.getFilter(_participantes, generos, 'id_gender');
    _participantes = this.getFilter(_participantes, nivel_educativo, 'education_level_id');
    _participantes = this.getCriteriosPoblacion(_participantes, poblacion);
    _participantes = this.getCriteriosPriorizacion(_participantes, priorizacion);
    _participantes = this.getRangosSisben(_participantes, sisben);
    _participantes = this.getFilter(_participantes, jefe_hogar, 'head_household');
    _participantes = this.getFilter(_participantes, ampliacion, 'ampliacion');
    
    _participantes = _participantes.filter( item => this.filterVariable(parseInt(variables[0].id), item));

    _participantes = _.uniq(_participantes.map( b => b));


    return _participantes;
  }


  getFilter(data, filter, field){

    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected === true){
        flag = true;
        acum.push(data.filter( item => {
          return Number(item[field]) == filter.id
        }))
      }
    });

    

    acum.map( item => {
      item.map( fill => result.push(fill) );
    });

    return flag ? result : data;
  }

  getRangosEdad(data, filter){

    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected){
        flag = true;
        let rango = Edades.getItem(filter.id);

        acum.push(data.filter( item => {
          let edad = Math.floor(parseFloat(item.age))
          return edad >= rango.min && edad <= rango.max;
        }));

      }
    });

    acum.map( item => {
      item.map( fill => result.push(fill) );
    });

    return flag ? result : data;
  }

  getCriteriosPoblacion(data, filter){
    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected){
        flag = true;

        let criterio = Poblacion.getItem(filter.id);

        acum.push(data.filter( item => {
          switch (criterio.id) {
            case 1:
            return item.exist_victim == 1 && (item.score_sisben == null || item.score_sisben == '') && item.exist_united == 0;
            case 2:
            return item.exist_united == 1;
            case 3:
            return item.score_sisben != null;
            case 4:
            return item.exist_united == 1 && item.exist_victim == 1 && (item.score_sisben == null || item.score_sisben == '');
            case 5:
            return item.exist_united == 1 && (item.score_sisben != null || item.score_sisben != '') && item.exist_victim == 0 ;
            case 6:
            return item.exist_victim == 1 && (item.score_sisben != null || item.score_sisben != '') && item.exist_united == 0;
            case 7:
            return item.exist_victim == 1 && item.exist_united == 1 &&  item.score_sisben != null;
            case 8:
            return item.gestor == 1 ;
            

            default:
            return false;
          }

        }));

      }
    });

    acum.map( item => {
      item.map( fill => {
        if(result.indexOf(fill) < 0)
          result.push(fill)
      } );
    });

    return flag ? result : data;
  }

  getCriteriosPriorizacion(data, filter){
    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected){
        flag = true;
        let criterio = CriteriosPriorizacion.getItem(filter.id);

        acum.push(data.filter( item => {
          switch (criterio.id) {
            case 1:
            return item.exist_jea == 1;
            case 2:
            return item.mfa_exist == 1;
            case 3:
            return item.exist_united == 1;
            case 4:
            return item.exist_victim == 1;

            default:
            return false;
          }

        }));

      }
    });

    acum.map( item => {
      item.map( fill => {
        if(result.indexOf(fill) < 0)
          result.push(fill)
      } );
    });

    return flag ? result : data;
  }


  getRangosSisben(data, filter){

    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected){
        flag = true;
        let sisben = RangosSisben.getItem(filter.id);

        acum.push(data.filter( item => {

          if(item.score_sisben){
            let score = parseFloat(item.score_sisben);
            return score >= sisben.min && score <= sisben.max;
          }
            

          return false;
        }));

      }
    });

    acum.map( item => {
      item.map( fill => result.push(fill) );
    });

    return flag ? result : data;
    
  }

  getFilterEstados(data, filter){
    let acum = [], result = [];
    let flag = false;

    filter.map((filter, index) => {
      if(filter.selected){
        flag = true;
        
        acum.push(data.filter( item => {
          return this.filterVariable(filter.id, item);
        }));

      }
    });

    acum.map( item => {
      item.map( fill => result.push(fill) );
    });

    return flag ? result : data;
  }

  filterVariable(variable, item){

    switch (variable) {
      case 1:
      return item.stage_id != 4
      case 2:
      return item.completa_inscripcion == 2;
      case 3:
      return item.completa_orientacion == 2;
      case 4:
      return item.completa_formacion == 2;
      case 5:
      return item.completa_intermediacion == 2;
      case 6:
      return item.completa_colocacion == 2;

      case 7:
      return item.completa_preinscripcion == 1;
      case 8:
      return item.completa_inscripcion == 1;
      case 9:
      return item.completa_orientacion == 1;
      case 10:
      return item.completa_formacion == 1;
      case 11:
      return item.completa_intermediacion == 1;
      case 12:
      return item.completa_colocacion == 1;
      case 22:
      return item.completa_permanecia1 == 2;
      case 35:
      return item.completa_permanecia1 == 1;
      case 23:
      return item.completa_permanecia2 == 2;
      case 36:
      return item.completa_permanecia2 == 1;

      case 13:
      return item.stage_id != 1 && item.stage_id != 4;

      case 38:

        item.completa_postform = (item.completa_postform == null) ? 0: item.completa_postform;

        return (item.stage_id == 2 || item.stage_id == 3) &&  (item.completa_inscripcion==2 && item.completa_perfilamiento==2 && item.completa_orientacion==2
             && item.completa_psocial==2 && item.completa_retencion==2 && item.completa_preform==2 && item.completa_formacion==1) && (item.completa_postform!=2);

      case 40:

      return ((item.stage_id == 5) &&  (item.completa_inscripcion==2 && item.completa_perfilamiento==2 && item.completa_orientacion==2
             && item.completa_psocial==2 && item.completa_retencion==2 && item.completa_preform==2 && item.completa_formacion==2 && item.completa_postform==2 && item.completa_intermediacion==1) 
               && ((item.completa_colocacion != 2) && (item.completa_permanecia1 != 2 ) && (item.completa_permanecia2 != 2 )))

      default:

      return true;
    }
  }


}
