import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, 
         CrearOperadorComponent,
         EditarOperadorComponent, 
         VerOperadoresComponent, 
         LoginComponent,
         VerBeneficiariosComponent,
         SubirBeneficiariosComponent,
         ExcelBeneficiarioComponent,
         DetalleBeneficiarioComponent,
         DetalleOperadorComponent,
         MenuComponent,
         VerUsuariosComponent,
         EditarUsuarioComponent,
         DescargarPilaComponent,
         DescargarBeneficiariosComponent,
         SubirNoIncluidosComponent,
         GraficaComponent,
         VerGruposComponent,
         CrearGrupoComponent,
         DetalleGrupoComponent,
         SubirDeloitteComponent,
         CompleteSearchComponent,
         VerEmpresaComponent,
         CrearempresaComponent,
         EditarEmpresaComponent,
         DetalleEmpresaComponent,
         CrearVacanteComponent,
         DetalleVacanteComponent,
         FiltrosExcelComponent,
         SubirPilaComponent,
         SubirFunelComponent,
         VerHistorialPilaComponent,
         HojaVidaComponent,
         PdfHojaVidaComponent,
         AlertaComponent,
         IntermediacionAlertaComponent,
         ColocacionAlertaComponent,
         ColocacionNoEmpleadosAlertaComponent,
         NovedadesComponent,
         SubirPermanenciaTresComponent,
         SubirPermanenciaSeisComponent,
         DescargarPermanenciaComponent,
         TiempoRutaComponent,
         IntermediacionReportComponent,
         ColocacionReportComponent,
         VerificarParticipantesComponent,
         SubirVerificarParticipantesComponent,
         IndicadoresComponent,
         DescargarDocumentosUsuariosComponent
} from './pages';

import { FilterPageComponent } from './components/filter-page/filter-page.component';

import { AuthGuard } from './guard';

export const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { 
  	path: '', 
  	component: HomeComponent, 
  	canActivate: [AuthGuard],
  	children: [
      { path: '', component: MenuComponent},
      { path: 'state/:type', component: MenuComponent},
  		{ path: 'operadores', component: VerOperadoresComponent },
  		{ path: 'operador/crear', component: CrearOperadorComponent },
      { path: 'operador/:id', component: DetalleOperadorComponent },
      { path: 'operador/editar/:id', component: EditarOperadorComponent },
      { path: 'participantes', component: VerBeneficiariosComponent },
      { path: 'participantes/verify/:stage', component: VerBeneficiariosComponent },
      { path: 'participantes/verify/:stage/:status', component: VerBeneficiariosComponent },
      { path: 'participantes/alertas/:alert', component: VerBeneficiariosComponent },
      { path: 'participantes/subir', component: SubirBeneficiariosComponent },
      { path: 'participantes/subir_kokan', component: ExcelBeneficiarioComponent },
      { path: 'participantes/subir_no_incluidos', component: SubirNoIncluidosComponent },
      { path: 'participantes/descaga_beneficiarios', component: DescargarBeneficiariosComponent },
      { path: 'participantes/descaga_pila', component: DescargarPilaComponent },
      { path: 'participantes/descaga_permanencia/:id', component: DescargarPermanenciaComponent },
      { path: 'participantes/subir_deloitte', component: SubirDeloitteComponent },
      { path: 'participantes/subir_pila', component: SubirPilaComponent },
      { path: 'participantes/subir_embudo', component: SubirFunelComponent },
      { path: 'participantes/subir_permanecia_tres', component: SubirPermanenciaTresComponent },
      { path: 'participantes/subir_permanecia_seis', component: SubirPermanenciaSeisComponent },
      { path: 'participante/:id/:state', component: DetalleBeneficiarioComponent},      
      { path: 'participante/:id/:state/:type_verify', component: DetalleBeneficiarioComponent},      
      { path: 'search/:state', component: CompleteSearchComponent},      
      { path: 'usuarios', component: VerUsuariosComponent },
      { path: 'usuario/editar/:id', component: EditarUsuarioComponent },
      { path: 'grupos', component: VerGruposComponent },
      { path: 'grupos/crear', component: CrearGrupoComponent },
      { path: 'grupos/:id', component: DetalleGrupoComponent },
      { path: 'empresas', component: VerEmpresaComponent },
      { path: 'empresas/crear', component: CrearempresaComponent },
      { path: 'empresas/editar/:id', component: EditarEmpresaComponent },
      { path: 'empresas/:id', component: DetalleEmpresaComponent },
      { path: 'vacante/:id/crear', component: CrearVacanteComponent },
      { path: 'vacante/:id', component: DetalleVacanteComponent },
      { path: 'verificador/historial', component: VerHistorialPilaComponent },

      { path: 'reportes', component: GraficaComponent },
      { path: 'filtros', component: FiltrosExcelComponent },
      { path: 'novedades/:type', component: NovedadesComponent },
      { path: 'reportes/hoja_vida/:id_participante', component: HojaVidaComponent},
      { path: 'reportes/hoja_vida/pdf/:id_participante', component: PdfHojaVidaComponent},

      { path: 'reportes/tiempo_ruta', component: TiempoRutaComponent},
      { path: 'reportes/intermediacion', component: IntermediacionReportComponent},
      { path: 'reportes/colocacion', component: ColocacionReportComponent},

      { path: 'reportes/indicadores', component: IndicadoresComponent},
      { path: 'reportes/documentos_usuario', component: DescargarDocumentosUsuariosComponent},

      { path: 'alertas/documentacion', component: AlertaComponent},
      { path: 'alertas/intermediacion', component: IntermediacionAlertaComponent},
      { path: 'alertas/colocacion', component: ColocacionAlertaComponent},
      { path: 'alertas/colocacion_noempleados', component: ColocacionNoEmpleadosAlertaComponent},

      { path: 'notifiacion/revisar_participantes', component: VerificarParticipantesComponent},
      { path: 'notifiacion/subir_participantes', component: SubirVerificarParticipantesComponent},

      



  	]
  },
  
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(routes);
