import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../appSettings';

@Component({
	selector: 'app-subir-verificar-participantes',
	templateUrl: './subir-verificar-participantes.component.html',
	styleUrls: ['./subir-verificar-participantes.component.css']
})
export class SubirVerificarParticipantesComponent implements OnInit {


	urlIframe: any;
	title_page: string;

	constructor() { }

	ngOnInit() {
		var token = localStorage.getItem('session_id')
		this.title_page = 'Subir novedades participantes';
		this.urlIframe = AppSettings.URL_BACKEND + 'upload_file_novedades/' + token;
	}

}
