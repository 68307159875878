import { Component, OnInit } from '@angular/core';
import { Vacante } from '../../../struct';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service';
import { VacanteService, BeneficiarioDataService, AnalitycsService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-crear-vacante',
  templateUrl: './crear-vacante.component.html',
  styleUrls: ['./crear-vacante.component.css']
})
export class CrearVacanteComponent implements OnInit {

	model: any = {};
	title_view: string;
  	loadPage: boolean = true;
  	company_id: any;
  	name_company: any;
  	occupational_areas: any = [];	
  	subgenres: any = [];
  	denominations: any = [];
  	salaries: any = [];


	constructor( private vacanteService: VacanteService, 
				private beneficiarioService: BeneficiarioDataService, 
				private router: Router,
				private route: ActivatedRoute,
				private dropService: DropsWrapperService,
				private analyticsService: AnalitycsService) { }

	ngOnInit() {
		this.title_view = 'CREAR VACANTE';
		this.company_id = this.route.params['value'].id;
		
		this.dropService.fullValidateDrop().subscribe( 
			data => {
				console.log(data);
			this.occupational_areas = [{id:'', name_area:''}].concat(data['areocupacional'])
			this.subgenres = [{id:'', name_area:''}].concat(data['subgeneros']);
			//this.denominations = data['dominacion'];
			this.salaries = [{id:'', name_area:''}].concat(data['saliovacante']);
			this.model.company_id = this.company_id;
			this.loadPage = false;
		})

	}

	onSubmitVacante(){
		var vacante = new Vacante(this.model);
		this.vacanteService.addVacante(vacante).subscribe( 
			data => {
	  			if(data.error){
	  				return;
	  			}
	  			this.analyticsService.eventAction('Vacantes', 'CrearVacante');
  				this.router.navigate(['/empresas', this.company_id]);
  		});
        
	}

	onChangeArea(event, id_sector){
		this.beneficiarioService.getAreaBySubgrupo(id_sector)
		.subscribe( data => {
			if(!data.error)
				this.subgenres = data.subgrupo		
		});
		
	}	

	onChangeSubGrupo(event, id_sector){
		this.beneficiarioService.getDominationBySubgenre(id_sector)
		.subscribe( data => {
			if(!data.error)
				this.denominations = data.domination		
		});
		
	}

	

}
