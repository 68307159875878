import { Component, OnInit, ViewChild } from '@angular/core';
import { OperadorDataService, VerificadorService, PagerServicesService } from '../../../services';
import { AppSettings } from './../../../appSettings';
import { UtilService, AnalitycsService } from '../../../services';

@Component({
  selector: 'app-descargar-pila',
  templateUrl: './descargar-pila.component.html',
  styleUrls: ['./descargar-pila.component.css']
})
export class DescargarPilaComponent implements OnInit {

  @ViewChild('link_zip') link_zip;
  model: any = {};
  historial: any;
  historial_paged: any;
  pager: any = {};
  loadPage:boolean = false;
  link_action: string;
  token:string;

  constructor(private operadorService: OperadorDataService,
              private utilService: UtilService,
              private verificadorService: VerificadorService,
              private pagerService: PagerServicesService,
              private analyticsService: AnalitycsService) { }

  ngOnInit() {

  	this.utilService.getHistoryDownloadPila()
  		.subscribe( data => {
  			if(data.error)
  				return;
        this.historial = data.download;
        this.setPage(1);
  			
  		})
  }

  setPage(page: number){
      if(page < 1 || page > this.pager.totalPages){
        return
      }
      this.pager = this.pagerService.getPager(this.historial.length, page, 10);
      this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  downloadPila(){
    this.loadPage = true;
    this.verificadorService.getExcelPreinscritosPila().subscribe( data => {
      if(data.error){
         alert(data.msg);
         this.loadPage = false;
         return;
      }

      this.analyticsService.eventAction('Participantes', 'DescargarArchivoPila')
      this.link_zip.nativeElement.href = data.link
      this.link_zip.nativeElement.click()
      this.loadPage = false;
    });
  }
}
