export class Utilities {

	static toUp(el, tagName){
		tagName = tagName.toLowerCase();

		while( el && el.parentNode ){
			el = el.parentNode;
			if(el.tagName && el.tagName.toLowerCase() == tagName){
				return el;
			}
		}

		return null;
	}


	static dateDiff = {

		inDays: function(d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return parseInt(String((t2-t1)/(24*3600*1000)));
		},

		inWeeks: function(d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return parseInt(String((t2-t1)/(24*3600*1000*7)));
		},

		inMonths: function(d1, d2) {
			var d1Y = d1.getFullYear();
			var d2Y = d2.getFullYear();
			var d1M = d1.getMonth();
			var d2M = d2.getMonth();

			return (d2M+12*d2Y)-(d1M+12*d1Y);
		},

		inYears: function(d1, d2) {
			return d2.getFullYear()-d1.getFullYear();
		},

		getDateDifferenceInMonths: function(firstDate: Date, secondDate: Date){
			var _MS_PER_DAY = 1000 * 60 * 60 * 24;
			var utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
			var utc2 = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());


			var totalDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
			var totalYears = Math.trunc(totalDays / 365);
			totalYears = totalYears * 12;
			var totalMonths = Math.trunc((totalDays % 365) / 30);
			var remainingDays = Math.trunc((totalDays % 365) % 30);


			if(utc1 > 0 && utc2 > 0){
				return ((totalMonths + totalYears) == 0) ? remainingDays + ' dias.' : (totalMonths + totalYears) + ' meses, ' + remainingDays + ' dias.'
			}
			
			return '';

		}
	}

	static range(n){
		return Array.apply(null,Array(n)).map((x,i) => i)
	}

	static chunk(array, n){
		return Utilities.range(Math.ceil(array.length/n)).map((x,i) => array.slice(i*n,i*n+n))
	}

	static fitImageOn(canvas, imageObj) {

		let context = canvas.getContext('2d')
		let imageAspectRatio = imageObj.width / imageObj.height;
		let canvasAspectRatio = canvas.width / canvas.height;
		let renderableHeight, renderableWidth, xStart, yStart;

		
		if(imageAspectRatio < canvasAspectRatio) {
			renderableHeight = canvas.height;
			renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
			xStart = (canvas.width - renderableWidth) / 2;
			yStart = 0;
		}

		
		else if(imageAspectRatio > canvasAspectRatio) {
			renderableWidth = canvas.width
			renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
			xStart = 0;
			yStart = (canvas.height - renderableHeight) / 2;
		}

		else {
			renderableHeight = canvas.height;
			renderableWidth = canvas.width;
			xStart = 0;
			yStart = 0;
		}
		context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
	};
}
