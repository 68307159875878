import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ChartSelectEvent } from 'ng2-google-charts';

@Component({
	selector: 'bis-columns-graph',
	templateUrl: './columns-graph.component.html',
	styleUrls: ['./columns-graph.component.css']
})
export class ColumnsGraphComponent implements OnInit, OnChanges {

	@Input() grafica:any;
	@Input() colors:any;
	@Input() title:any;
	@Input() nextAvailable:boolean;
	@Input() previousAvailable:boolean;

	@Output() notify = new EventEmitter();
	@ViewChild('chart') chart;
	data_graph:any;
	open_overlay:boolean;
	captions_overlay:any;
	array_overlay:any;
	value_overlay:any;
	index_overlay:any;
	color_overlay:any;
	name_overlay:any;


	constructor() { }

	ngOnInit() {
		console.log(this.grafica)
		this.GenerateGraph();
	}

	ngOnChanges(changes){

		if(changes.grafica){
			this.grafica = changes.grafica.currentValue;
			this.GenerateGraph();
		}
	}

	clickG(event){
		console.log(event)
	}

	round(value){
		return Math.round(value)
	}

	select(event:ChartSelectEvent){

		console.log(event)
			this.open_overlay = true;
			if(event.message == "select"){
				this.array_overlay = event.selectedRowFormattedValues;
				this.index_overlay = event.column;
				this.color_overlay = this.colors[this.index_overlay - 1]
				this.captions_overlay = this.grafica[0];
				this.name_overlay = this.grafica[event.row + 1][0];
				console.log(this.captions_overlay, this.grafica,this.name_overlay)

				if(isNaN(this.round(this.array_overlay[this.index_overlay]))){
					this.open_overlay = false;
				}
			}	
			
		

		

	}

	nextOverlay(){
		this.index_overlay++;
		this.color_overlay = this.colors[this.index_overlay - 1]
	}

	prevOverlay(){
		this.index_overlay--;
		this.color_overlay = this.colors[this.index_overlay - 1]
	}

	DataGraphPosition(position){
		this.notify.emit(position);
	}

	GenerateGraph(){
		this.data_graph =  {
			chartType: 'ColumnChart',
			dataTable: this.grafica,
			options: {
				sliceVisibilityThreshold: 0,
				title: this.title,
				isStacked: true,
				height: 500,
				legend: { 
					position: 'bottom', 
					maxLines: 8,
					textStyle: {color: '#5a5a5a', fontSize: 10}
				},
				bar: { groupWidth: '25%' },
				chartArea: {top: '10%', width: '90%', bottom: '10%'},
				hAxis: {
					scaleType: 'number',
					textStyle: {color: '#323232', fontSize: 10, fontName: 'Open Sans'},
					gridlines: {
						count: 10
					}
				},
				vAxis: {
					title: 'Participantes',
					scaleType: 'string',
					gridlines: {
						count: 10
					}
				},
				
				colors: this.colors,
				animation:{
					duration: 1000,
					easing: 'out'
				},
				tooltip : {
					trigger: 'none'
				}

			},
		};
	}

	ExportGraph(){
		let pom = document.createElement('a');
		let chart = this.chart.wrapper.getChart();

		let image = new Image();
		image.src = chart.getImageURI();

		image.onload = event => {
			pom.setAttribute('href', image.src);
				pom.setAttribute('download', "Export");
				pom.setAttribute('target', '_blank');


				if (document.createEvent) {
					let event = document.createEvent('MouseEvents');
					event.initEvent('click', true, true);
					pom.dispatchEvent(event);
				}
				else {
					pom.click();
				}
		}

		// image.onload = event => {
		// 	html2canvas(this.table.nativeElement, {
		// 		background: '#fff',
		// 		onrendered: canvas => {
		// 			end_canvas.width = image.width;
		// 			end_canvas.height = canvas.height + (image.height + 20);

		// 			let table_images = new Image();
		// 			table_images.onload = event => {
		// 				ctx.fillStyle = "#fff";
		// 				ctx.fillRect(0,0,end_canvas.width,end_canvas.height);
		// 				ctx.drawImage(image, 0, 0, image.width, image.height)
		// 				ctx.drawImage(table_images, ((end_canvas.width / 2) - (table_images.width / 2)), image.height + 10)	
		// 				download_image(end_canvas.toDataURL());
		// 			}
		// 			table_images.src = canvas.toDataURL();
		// 		}
		// 	});
		// }
	}

}
