import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { AssignGroupWrappedService } from '../../../shared/assign-group-wrapped.service' ;
import { BeneficiarioDataService, VacanteService } from '../../../services';
import { QuestionService } from '../question.service';

@Component({
	selector: 'app-intermediacion',
	templateUrl: './intermediacion.component.html',
	styleUrls: ['./intermediacion.component.css'],
	providers: [QuestionService]
})
export class IntermediacionComponent implements OnInit {

	model:any = {};
	type_user:any;
	questions:any;
	dropsOptions:any = [];
	vacantes:any = [];
	assign_popup:boolean;
	listaVacanteStatuses:any = []
	load_module:boolean;
	complete:boolean;
	user_contrato:boolean;

	@Input() beneficiario:any;
	@Input() enabled_module:boolean;
	@Input() disabled:boolean;
	@Output() fullForm = new EventEmitter();
	@ViewChild('panel') panel;

	constructor( 
		private questionsService:QuestionService,
		private dropService:DropsWrapperService,
		private beneficiarioService: BeneficiarioDataService,
		private assignGroupService: AssignGroupWrappedService,
		private vacantesService: VacanteService) { }

	ngOnInit() {
		this.type_user = localStorage.getItem('user_type');

		this.model = Object.assign(this.model, {
			last_job_id: this.beneficiario.last_job_id,
			activity: this.beneficiario.activity,
			duration_id: this.beneficiario.duration_id,
			salary_id: this.beneficiario.salary_id,
			currently_studying: (this.beneficiario.currently_studying === false) ? 'NO' : 'SI',
			education_level_id: this.beneficiario.education_level_id,
			profession: this.beneficiario.profession,
			year_graduation: this.beneficiario.year_graduation,
			training_id: this.beneficiario.training_id,
			program_name: this.beneficiario.program_name,
			institution: this.beneficiario.institution,
			duration_hours: this.beneficiario.duration_hours,
			certification_date: this.beneficiario.certification_date
		});

		this.beneficiarioService.getIntermediacion(this.beneficiario.user_xml_id)
		.subscribe( data => {
			if(!data.error){
				this.model = Object.assign(this.model, {
					perfil_laboral: (data.intermediacion)  && data.intermediacion.profile,
					areas_conocimiento: (data.area_conocimiento[0]) && data.area_conocimiento[0].areas_knowledge_id,
					sector: (data.sector[0]) && data.sector[0].id,
					subsector: (data.sector[0]) && data.sector[0].subsector_id,
					areas_ocupacion: (data.desempleno[0]) && data.desempleno[0].occupational_areas_id,
					subgrupo_vacante: (data.desempleno[0]) && data.desempleno[0].subgenre_id,
					denominzacion_ocupacion: (data.desempleno[0]) && data.desempleno[0].domination_id,
					
				});

				this.user_contrato = data.user_contratado;
				this.load_module = true;

				setTimeout(_ => {
		            if(this.panel)    
		              window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
	          	});
			}
		})

		this.dropsOptions = this.dropService.dropOptons[0]

		this.vacantesService.getVacantesByBeneficiario(this.beneficiario.user_xml_id)
		.subscribe( data => {
			if(!data.erro)
				this.vacantes = data.vacantes;
		}) ;

		
	}

	onChangeSubSector(event, id_sector){
		this.beneficiarioService.getSubSectorByIdSector(id_sector)
		.subscribe( data => {
			if(!data.error)
				this.dropsOptions['subsector'] = data.subsectores		
		});
		
	}

	onChangeSubGrupo(event, id_sector){
		this.beneficiarioService.getDominationBySubgenre(id_sector)
		.subscribe( data => {
			if(!data.error)
				this.dropsOptions['dominacion'] = data.domination		
		});
		
	}

	onChangeArea(event, id_sector){
		this.beneficiarioService.getAreaBySubgrupo(id_sector)
		.subscribe( data => {
			if(!data.error)
				this.dropsOptions['subgeneros'] = data.subgrupo		
		});
		
	}

	onSubmit(){
		let formData = [];
		
		formData.push({ 'profile' : this.model.perfil_laboral})
		formData.push({ ['area_know[0][areas_knowledge_id]'] : this.model.areas_conocimiento})
		formData.push({ ['sector[0][subsector_id]'] : this.model.subsector})
		formData.push({ ['desempeno_laboral[0][occupational_areas_id]'] : this.model.areas_ocupacion})
		formData.push({ ['desempeno_laboral[0][subgenre_id]'] : this.model.subgrupo_vacante})
		formData.push({ ['desempeno_laboral[0][domination_id]'] : this.model.denominzacion_ocupacion})

		this.beneficiarioService.saveIntermediacionForm(this.beneficiario.user_xml_id, formData)
		.subscribe( data => {
			if(!data.error){
				
				let list_inputs = document.querySelectorAll('.form_intermediacion input, .form_intermediacion select, .form_intermediacion textarea');
				if(data.completa == 0){
					this.highWarrningInputsOn();
					alert(data.msg + '\nPero hay información pendiente para completar la etapa')
				}else{
					this.highWarrningInputsOff();
					alert(data.msg)
				}
				this.complete = data.completa;
				this.fullForm.emit({isFill:(data.completa == 1)})
			}
			else
				alert("Hubo un error al enviar la información")
		});

	}

	

	deleleAssignacion(event, vacante){
		event.stopPropagation();
		if(confirm('Desea eliminar esta vacante')){
			this.vacantesService.deleteVacantesByBeneficiario(vacante.id).subscribe(
				data => {
					if(!data.error){
						for(let op in this.vacantes){

							if(vacante.id == this.vacantes[op].id){
								this.vacantes.splice(op, 1);
							}
						}
					}else{
						alert(data.msg);
					}
				})
		}

	}

	changeStatus(event, vacante, status_id){
		event.stopPropagation()
		if(confirm('¿Desea cambiar el estado de la vacante?')){
			this.vacantesService.changeVacanteStatus(vacante.id, status_id).subscribe(
				data => {
					if(!data.error){
						for(let vac in this.vacantes){
							if(vacante.id == this.vacantes[vac].id){
								this.vacantes[vac].status_select_id = status_id
								this.vacantes[vac].status = this.dropsOptions['estado_seleccionado'][status_id-1].name_select
							}
						}
					}else{
						alert(data.msg);
					}
				})
		}
	}

	openPopUpVacancy(){
		this.assignGroupService.addBeneficiaries([this.beneficiario]);
		this.assign_popup = true;
	}

	closePopUpAssign(event){
		this.assign_popup = event['open_popup'];
	}

	fillVacantes(event){
		this.vacantes = event.vacantes;
		this.fullForm.emit({isFill:(event.completa == 1)})
	}

	getQuestionbyKey(quest, key:string){
		for(let item in quest){
			if(quest[item].key == key)
				return item;
		}

		return null;
	}


	highWarrningInputsOn(){
		let list_inputs = document.querySelectorAll('.form_intermediacion input[data-verify], .form_intermediacion select[data-verify], .form_intermediacion textarea[data-verify]');
		for(let i = 0; i < list_inputs.length; i++){
			if(!list_inputs[i]['value']){
				var classString = list_inputs[i].className;
				var newClass = classString.concat(" warring-input");
				list_inputs[i].className = newClass;
			}else{
				var classString = list_inputs[i].className;
				while(classString.indexOf(' ' + 'warring-input' + ' ') !== -1){
					classString = classString.replace(' ' + 'warring-input'+ ' ', '');
				}
				list_inputs[i].className = classString;
			}
		}
	}

	highWarrningInputsOff(){
		let list_inputs = document.querySelectorAll('.form_intermediacion input, .form_intermediacion select, .form_intermediacion textarea');
		for(let i = 0; i < list_inputs.length; i++){
			var classString = list_inputs[i].className;
			while(classString.indexOf(' ' + 'warring-input' + ' ') !== -1){
				classString = classString.replace(' ' + 'warring-input'+ ' ', '');
			}
			list_inputs[i].className = classString;
		}
	}


}
