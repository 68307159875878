import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import  { CompressImage } from '../file-uploader-s3/CompressImage'; 
import { AppSettings } from '../../appSettings';

import * as AWS from 'aws-sdk'; 

@Component({
  selector: 'app-avatar-photo-upload',
  templateUrl: './avatar-photo-upload.component.html',
  styleUrls: ['./avatar-photo-upload.component.css']
})
export class AvatarPhotoUploadComponent implements OnInit {


  image_buffer:any = 'assets/img/avatar.png';
  loader_image:boolean = false;
  @Output() emiterSubmit = new EventEmitter();
  @Input() source:string;
  @Input() disabled:boolean;
  private END_POINT_S3 = 'https://s3.us-east-2.amazonaws.com/' + AppSettings.BUCKET_S3 + '/'



  constructor() { }

  ngOnInit() {
  	if(this.source){
  		this.image_buffer = this.source;
  	}
  }

  loadImage(event){

  	let image_file:File = event.target.files[0];
  	this.loader_image = true;
  	
  	Promise.all(Array.prototype.slice.call([image_file], 0).map(this.uploadImage, this))
  		.then(item => {
	  		this.image_buffer = item[0]['s3_link'];
	  		this.loader_image = false;
	  		this.emiterSubmit.emit({url:this.image_buffer});
  		})


  }

  uploadImage(image_file:File){
  	let self = this;

    var compressImaHandler = (file) => {
      var max_size_image = 1024;
      var compress = new CompressImage(image_file, max_size_image);
      return compress.getImage();
    }

    return new Promise((resolve, reject) => {
    	compressImaHandler(image_file).then( f => {
          var params = {
            Bucket: AppSettings.BUCKET_S3, 
            Key: Date.now() + f['name'] , 
            ContentType: f['type'], 
            Body: f, 
            ServerSideEncryption: 'AES256',
            ACL: 'public-read' 
          };


          var S3 = new AWS.S3();

          S3.putObject(params, (err, data) => {
            if(err){
              reject(err);
              return;
            }
            
			resolve({
                s3_link: self.END_POINT_S3 + params.Key,
                name: params.Key
             });
          })

        }).catch( err => console.log(err))
    })
  }

}
