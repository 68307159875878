import { QuestionBase } from './question-base';


export class ButtonQuestion extends QuestionBase<string>{
	controlType = 'button';
	text:string;
	inputs:string;
	readonly: boolean;
	action:string;
	index: boolean;
	toggleClass: boolean;
	onClickCallback: Function;

	constructor(options:{} = {}){
		super(options);
		this.toggleClass = options['toggleClass'] || '';
		this.inputs = options['inputs'] || '';
		this.index = options['index'] || '';
		this.text = options['text'] || '';
		this.action = options['action'] || '';
		this.readonly = options['readonly'] || false;
		this.onClickCallback = options['click'] || null;
	}
}
