import { Component, OnInit } from '@angular/core';
import { DropsWrapperService } from '../../shared/drops-wrapper.service' ;
import { Router, ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
	type_user:string;
	status_menu:number;

  constructor(private dropService:DropsWrapperService, private route:ActivatedRoute) { }

  ngOnInit() {
  	let options;
  	
  	this.type_user = localStorage.getItem('user_type');


  	this.route.params
    	.subscribe(p => {
      		this.status_menu = p.type;
    	});
  
  }

}
