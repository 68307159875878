import { Component, OnInit, Input} from '@angular/core';
import { BeneficiarioDataService } from '../../services';
import { DropsWrapperService } from '../../shared/drops-wrapper.service' ;

@Component({
	selector: 'app-form-funnel',
	templateUrl: './form-funnel.component.html',
	styleUrls: ['./form-funnel.component.css']
})
export class FormFunnelComponent implements OnInit {

	constructor(private beneficiarioService:BeneficiarioDataService,
		private dropService:DropsWrapperService) { 
		this.optionDrops = this.dropService.optionsDrops[0];

		this.options_criterios = this.optionDrops.funnel_types.filter((a,b) => a.id <= 6 );
		this.options_permanencia = this.optionDrops.funnel_types.filter((a,b) => a.id > 6 );

		this.options_subpermanencia_emp = this.optionDrops.funnel_options.filter((a,b) => a.id <=3 );
		this.options_subpermanencia_par = this.optionDrops.funnel_options.filter((a,b) => a.id > 3 );

	}

	@Input() id_participante:number;
	@Input() id_etapa:number;
	@Input() type_etapa:number;
	@Input() novedad:number;
	@Input() comentario:number;
	@Input() disabled:number;
	@Input() use_subcats:boolean = false;
	optionDrops:any;

	private options_criterios: any;
	private options_subpermanencia_emp: any;
	private options_subpermanencia_par: any;
	private options_permanencia: any;
	

	model:any = {}

	ngOnInit() {
		console.log()
		if(this.novedad){
			this.model.funnel_type_id = this.novedad['funnel_type_id'];

		if(this.novedad['funnel_phase_options']){
			if(this.novedad['funnel_phase_options'][0])
				this.model.select_colocacion = this.novedad['funnel_phase_options'][0].funnel_option_id;
		}

		if(this.novedad['atributos_inter']){
			this.novedad['atributos_inter'].map( attr => {
				if(attr.funnel_intermediation_type_id == 1)
					this.model.inter_horario = true;

				if(attr.funnel_intermediation_type_id == 2)
					this.model.inter_salario = true;

				if(attr.funnel_intermediation_type_id == 3)
					this.model.inter_ocupacion = true;

				if(attr.funnel_intermediation_type_id == 4){
					this.model.inter_otra_chk = true;
					this.model.inter_otra = attr.other[0].other;
				}
			})
		}
		}
		
	}

	onSubmit(form){
		form.inter_horario = (form.inter_horario) ? 1 :  null;
		form.inter_salario = (form.inter_salario) ?  1 : null;
		form.inter_ocupacion = (form.inter_ocupacion)  ? 1 : null;

		this.beneficiarioService.sendFunelNovedad(this.id_participante, form ,this.id_etapa)
		.subscribe( data => {
			alert(data.msg)
		});
	}

}
