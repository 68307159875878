import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';
import { HabilidadFormacion } from '../../../struct/habilidad-formacion';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;



@Component({
  selector: 'app-formacion',
  templateUrl: './formacion.component.html',
  styleUrls: ['./formacion.component.css'],
  providers: [QuestionService]
})
export class FormacionComponent implements OnInit {

  validator = {
    number: /^[-+]?\d*(?:[\.\,]\d+)?$/,
    phone: /^[-+]?\d*(?:[\.\,]\d+)?$/,
    email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
    currency: /^[-+]?\d*(?:[\.\,]\d+)?$/,

  };

  type_user:any;
  questions:any;
  dropsOptions:any;
  model:any = {};
  s3_certificado:string;
  habilidades_duras:any = [];
  habilidades_blandas:any = [];
  send_photo: boolean = false;
  message:string;
  complete:boolean;
  loadModule:boolean;
  @Output() fullForm = new EventEmitter();
  @Output() loadEnd = new EventEmitter();
  @Input() beneficiario:any;
  @Input() enabled_module:any;
  @Input() disabled:any;
  @ViewChild('panel') panel;

  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService,
    private dropService:DropsWrapperService) { 
  }

  ngOnInit() {

    this.beneficiarioService.getFormacion(this.beneficiario.user_xml_id)
    .subscribe( data => {

      if(data.formacion.length > 0){
        this.habilidades_duras = data.formacion.filter(d => d.type_competition_id == 1)
        .map( h => new HabilidadFormacion(h));

        this.habilidades_blandas = data.formacion.filter(d => d.type_competition_id == 2)
        .map( h => new HabilidadFormacion(h));  
      }else{
        this.habilidades_duras.push(new HabilidadFormacion({type_competition_id:1}));
        this.habilidades_blandas.push(new HabilidadFormacion({type_competition_id:2}));
      }

      if(data.milestone){
        this.model.type_miles_formation_id = data.milestone.type_miles_formation_id;
        this.model.retired_id = data.milestone.retired_id;
        this.model.mileston_other = data.milestone.other;
        this.model.fecha_formacion = data.milestone.date_formation_end;
        this.s3_certificado = data.milestone.s3certificated;  
      }

      this.model.horas_totales = this.refreshHoras();
      this.type_user = localStorage.getItem('user_type');
      this.dropsOptions = this.dropService.dropOptons[0]
      
      this.loadModule = true;

      setTimeout(_ => {
        if(this.panel)    
          window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
      });

      
    })


    
    
  }

  addHabilidadDura(event){
    event.preventDefault();
    this.habilidades_duras.push(new HabilidadFormacion({type_competition_id:1}));
  }

  delHabilidadDura(event, index){
    event.preventDefault();
    if (index > -1) {
      this.habilidades_duras.splice(index, 1);
    }
  }

  addHabilidadBlanda($event){
    event.preventDefault();
    this.habilidades_blandas.push(new HabilidadFormacion({type_competition_id:2}));
  }

  delHabilidadBlanda(event, index){
    event.preventDefault();
    if (index > -1) {
      this.habilidades_blandas.splice(index, 1);
    }
  }


  refreshHoras(){
    let total_time_hard:number = this.habilidades_duras.reduce((prev, current) => {
      if(current.assigned_hour)
        return Number(prev) + Number(current.assigned_hour);
    }, 0);


    let total_time_soft:number = this.habilidades_blandas.reduce((prev, current) => {
      if(current.assigned_hour)
        return Number(prev) + Number(current.assigned_hour);

    }, 0);

    total_time_hard = isNaN(total_time_hard) ? 0 : total_time_hard;
    total_time_soft = isNaN(total_time_soft) ? 0 : total_time_soft; 

    return Number(total_time_hard + total_time_soft).toFixed();
  }

  

  saveCertificado(url_source){
    this.loadEnd.emit({loading: true})
    this.beneficiarioService.addFileFormacion(url_source.url ,this.beneficiario.user_xml_id)
    .subscribe( data => {
      if(this.send_photo){
          this.loadEnd.emit({loading: false})
          this.send_photo = false;
          this.complete = data.completa;
          this.fullForm.emit({isFill:(data.completa == 1), status:data.status})
      }
         
    });
  }

  loadingFile(event){
    this.loadEnd.emit({loading: event})
  }

  onSubmit(){

    let formData = [];
    let index = 0;
    this.send_photo = false;

    for(let hard in this.habilidades_duras){
      for(let key in this.habilidades_duras[hard]){
        formData.push({ [`arr_habilidad[${hard}][${key}]`] : this.habilidades_duras[hard][key]})
      }

      index++;
    }



    for(let soft in this.habilidades_blandas){
      for(let key in this.habilidades_blandas[soft]){
        formData.push({ [`arr_habilidad[${parseInt(soft) + index}][${key}]`] : this.habilidades_blandas[soft][key]})
      }
    }

    formData.push({ 'type_miles_formation_id': this.model.type_miles_formation_id})
    formData.push({ 'retired_id': this.model.retired_id})
    formData.push({ 'other': this.model.mileston_other})
    formData.push({ 'date_formation_end': this.model.fecha_formacion})

    this.beneficiarioService.saveFormation(this.beneficiario.user_xml_id, formData)
    .subscribe( data => {
     
      if(!data.error){
        this.send_photo = (this.model.type_miles_formation_id == 2);
        let list_inputs = document.querySelectorAll('.form_formacion input:not(.no-verify), .form_formacion select:not(.no-verify),.form_formacion textarea:not(.no-verify)');

        console.log(list_inputs)

        if(data.completa == 0){
            for(let i = 0; i < list_inputs.length; i++){
              if(!list_inputs[i]['value']){
                var classString = list_inputs[i].className;
                var newClass = classString.concat(" warring-input");
                list_inputs[i].className = newClass;
              }else{
                var classString = list_inputs[i].className;
                while(classString.indexOf(' ' + 'warring-input' + ' ') !== -1){
                 classString = classString.replace(' ' + 'warring-input'+ ' ', '');
                }
                list_inputs[i].className = classString;
              }
            }
            alert(data.msg + '\nPero hay información pendiente para completar la etapa')
        }else{
          for(let i = 0; i < list_inputs.length; i++){
              var classString = list_inputs[i].className;
              while(classString.indexOf(' ' + 'warring-input' + ' ') !== -1){
               classString = classString.replace(' ' + 'warring-input'+ ' ', '');
              }
              list_inputs[i].className = classString;
          }
          alert(data.msg)
          
        }
        
        
        

        this.fullForm.emit({isFill:(data.completa == 1), status:data.status})
        
        this.message = data.msg
        //this.loadEnd.emit({loading: false})
      }
      else
        alert("Hubo un error al enviar la información")
    });

  }

  fillForm(event){
    this.fullForm.emit({isFill:event.isFill})
  }


}
