export const HISTORY_COLOCACION = [
	{
		size: "12",
		label: "EMPLEO",
		value: "empleo",
		type: 'text'
	},
	{
		size: "2",
		label: "NIT",
		value: "nit",
		type: 'text'
	},
	{
		size: "8",
		label: "DIRECCIÓN",
		value: "address",
		type: 'text'
	},
	{
		size: "2",
		label: "CIUDAD",
		value: "name_municipality",
		type: 'text'
	},
	{
		size: "10",
		label: "PERFIL REQUERIDO",
		value: "name_subgenre",
		type: 'text'
	},
	{
		size: "2",
		label: "NÚMERO DE VACANTES",
		value: "quantity",
		type: 'text'
	},
	{
		size: "12",
		label: "CARGO DE LA VACANTE",
		value: "position_vacancy",
		type: 'text'
	},
	{
		size: "12",
		label: "ÁREA DE LA VACANTE",
		value: "name_area",
		type: 'text'
	},
	{
		size: "6",
		label: "SUBGRUPO DE LA OCUPACION",
		value: "name_subgenre",
		type: 'text'
	},
	{
		size: "6",
		label: "DENOMINACIÓN DE LA OCUPACION",
		value: "name_domination",
		type: 'text'
	},

	{
		size: "4",
		label: "CONTRATO LABORAL",
		value: "file_contract",
		type: 'document'
	},
	{
		size: "4",
		label: "CERTIFICADO LABORAL",
		value: "file_certificate",
		type: 'document'
	},
	{
		size: "4",
		label: "CERTIFICADO APORTE EMPLEADO",
		value: "file_contribution",
		type: 'document'
	},
	{
		size: "6",
		label: "¿EL PARTICIPANTE FIRMÓ CONTRATO?",
		value: "contract",
		type: "boolean"
	},
	{
		size: "6",
		label: "TIPO DE CONTRATO FIRMADO",
		value: "name_contract",
		type: "text"
	},
	{
		size: "6",
		label: "¿CUÁL ES LA FECHA DEL CONTRATO FIRMADO?",
		value: "date_start",
		type: "text"
	},
	{
		size: "6",
		label: "¿CUÁL ES LA FECHA DE FINALIZACIÓN DEL CONTRATO?",
		value: "date_finish",
		type: "text"
	},
	{
		size: "4",
		label: "¿CUÁL ES EL SALARIO DEL CONTRATO?",
		value: "salary_contract",
		type: "text"
	},
	{
		size: "4",
		label: "DURACIÓN TOTAL DEL CONTRATO",
		value: "duration_contract",
		type: "text"
	},
	{
		size: "4",
		label: "RANGO DURACIÓN TOTAL DEL CONTRATO",
		value: "range_duration_contract",
		type: "text"
	},
	{
		size: "4",
		label: "FINALIZAR CONTRATO",
		value: "contract_end",
		type: "boolean"
	},
	{
		size: "8",
		label: "NOMBRE DE LA PERSONA DE CONTACTO EN LA EMPRESA",
		value: "person_charge",
		type: "text"
	},
	{
		size: "6",
		label: "CARGO DE LA PERSONA DE CONTACTO",
		value: "person_position",
		type: "text"
	},
		{
		size: "6",
		label: "TELÉFONO DE LA PERSONA DE CONTACTO ",
		value: "phone_person",
		type: "text"
	}
]