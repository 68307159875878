import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';

@Component({
  selector: 'app-preformacion',
  templateUrl: './preformacion.component.html',
  styleUrls: ['./preformacion.component.css'],
  providers: [QuestionService]
})
export class PreformacionComponent implements OnInit, OnChanges {

  questions:any;
  questions_base:any;
  type_user:any;
  _refresh_module:boolean;
  complete:boolean;

  @ViewChild('preformacion_form') preformacion_form;
  @ViewChild('panel') panel;
  @Output() fullForm = new EventEmitter();
  @Output() loadEnd = new EventEmitter();
  @Output() onSubmitForm = new EventEmitter();
  @Output() loadFile = new EventEmitter();

  @Input() beneficiario:any;
  @Input() type_form:any;
  @Input() enabled_module:any;
  @Input() upadting_form:boolean;
  @Input() disabled:any;



  @Input()
  set refresh_module(value: boolean) {
    this._refresh_module = value;
  }
  get refresh_module() { return this._refresh_module; }

  s3_prueba:string;
  s3_resultado:string;

  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService) { 
  }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');
    this.loadDataForm();
  }

  ngOnChanges(changes:any){
    if(changes.refresh_module){
      if(changes.refresh_module.currentValue === true){
        this.loadDataForm();
      }
    }
  }


  loadDataForm(){
    this.questions = null;
    if(this.questions_base){
      this.loadQuestions(this.questions_base);
    }else{
      this.questionsService.getQuestionsPreformacion().map( quest => {
        this.questions_base = quest;
        this.loadQuestions(quest);
        
      });  
    }
  }


  loadQuestions(quest:any){

    this.beneficiarioService.getPreformacion(this.beneficiario.user_xml_id, this.type_form)
    .subscribe( data  => {
      if(!data.error){
        if(data.preformacion && data.preformacion.length > 0){
          let counter = 0;
          for(let p in data.preformacion){
            let preformacion = data.preformacion[p];

            if(preformacion.name_ability && preformacion.name_ability.length > 0 ){
              quest[counter].value = preformacion.skill_id
              quest[counter + 1].value = preformacion.name_ability
              quest[counter + 2].value = preformacion.score_id  
              quest[counter + 3].value = preformacion.actual_result   

              if(this.type_form == 2){
                quest[counter + 1]['readonly'] = true;
              }

            }else{
              if(this.type_form == 2){
                quest[counter].disabled = false;
                quest[counter + 1].disabled = true;
                quest[counter + 2].disabled = true;
                quest[counter + 3].disabled = true;  
              }

            }
            counter+=4;  
          }
        }

        if(data.consolidado[0]){
          quest[20].value = data.consolidado[0].fecha_implementacion
          quest[21].value = data.consolidado[0].id_result  
          quest[22].value = data.consolidado[0].actual_result_consolidate
          this.s3_prueba =  data.consolidado[0].file_preformation
          this.s3_resultado =  data.consolidado[0].file_result
        }         
      }
      this.questions =  quest;
      this.onSubmitForm.emit({refresh:false, type_form:this.type_form});

       setTimeout(_ => {
          if(this.panel)    
            window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
        });
    })
  }

  onSubmit(form){
    this.loadEnd.emit({loading:true})

    this.beneficiarioService.updateForm(this.beneficiario.user_xml_id, form.formData, 4, this.type_form)
    .subscribe( data => {
      if(!data.error){

        
        if(data.completa == 0){
          this.preformacion_form.highWarrningInputsOff();
          this.preformacion_form.highWarrningInputsOn();
          alert(data.msg + '\nPero hay información pendiente para completar la etapa')
        }else{
          this.preformacion_form.highWarrningInputsOff();
          alert(data.msg)
        }
        
        this.complete = data.completa;
        console.log(this.complete)

        this.fullForm.emit({isFill:(data.completa == 1), status:data.status.name_stage})
       
       if(this.type_form == 1)
          this.onSubmitForm.emit({refresh:true, type_form:this.type_form});
      }
      else
        alert("Hubo un error al enviar la información")

      this.loadEnd.emit({loading:false}) 

    })
    
  }

  fillForm(event){
    this.fullForm.emit({isFill:event.isFill})
  }

  submitFilePreformacion(url_source, type_document, type_form){
    this.beneficiarioService.addFilePreformacion(url_source.url, type_document, type_form,this.beneficiario.user_xml_id)
    .subscribe( data => {
      console.log(data);
      this.loadEnd.emit({loading:false}) 
       this.loadFile.emit({load:false})
      
    });
  }



  loadingFile(){
    this.loadFile.emit({load:true})
  } 

}
