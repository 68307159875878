export class Vacante {

	idvacancy: number;
	description: string;
	quantity: number;
	position_vacancy: string;
	occupational_areas_id: number;
	subgenre_id: number;
	domination_id: number;
	salary_vacancy_id: number;
	company_id: number;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}
}
