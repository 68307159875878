import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'explode'
})
export class ExplodePipe implements PipeTransform {

  transform(value: any, separator:string): any {
  	if(value)
    	return value.join(separator)

    return null
  }

}
