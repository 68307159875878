import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';

@Component({
  selector: 'app-orientacion-ocupacional',
  templateUrl: './orientacion-ocupacional.component.html',
  styleUrls: ['./orientacion-ocupacional.component.css'],
})
export class OrientacionOcupacionalComponent implements OnInit {

  type_user:any;
  questions:any;
  complete:boolean;
  @ViewChild('orientacion_ocupacion') orientacion_ocupacion;
  @ViewChild('panel') panel;
  @Output() fullForm = new EventEmitter();
  @Output() loadEnd = new EventEmitter();
  @Input() beneficiario:any;
  @Input() enabled_module:any;
  @Input() disabled:any;

  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService) { 
  }


  ngOnInit() {

    this.type_user = localStorage.getItem('user_type');
    this.questionsService.getQuestionsOrientacion().map( quest => {
      this.beneficiarioService.getOrientacion(this.beneficiario.user_xml_id)
      .subscribe( data  => {
        if(!data.error){

          for(let item in quest){
            if(data.beneficiary[0][quest[item].key] != null)
              quest[item].value = String(data.beneficiary[0][quest[item].key]);


            if(quest[item].key == 'training_id' && quest[item].value != 6){
              quest[this.getQuestionbyKey(quest, 'program_name')].disabled =  false
              quest[this.getQuestionbyKey(quest, 'institution')].disabled =  false
              quest[this.getQuestionbyKey(quest, 'duration_hours')].disabled =  false
              quest[this.getQuestionbyKey(quest, 'certification_date')].disabled =  false
            }

            if(quest[item].key == 'last_job_id' && quest[item].value != 3){
              quest[this.getQuestionbyKey(quest, 'activity')].disabled =  false
              quest[this.getQuestionbyKey(quest, 'duration_id')].disabled =  false
              quest[this.getQuestionbyKey(quest, 'salary_id')].disabled =  false
            }

          }



          if(data.interesocupacional){
            quest[this.getQuestionbyKey(quest, 'occupational_areas_id')].value =   data.interesocupacional.occupational_areas_id
            quest[this.getQuestionbyKey(quest, 'domination_id')].value =   data.interesocupacional.domination_id
          }

          if(data.oso_beneficiary){
            for(let i in data.oso_beneficiary){


              let oso = data.oso_beneficiary[i];

              if(oso.description){
                if(quest[this.getQuestionbyKey(quest, `arr_oso[${i}][check]`)])
                  quest[this.getQuestionbyKey(quest, `arr_oso[${i}][check]`)].value = true; 
                
                if(quest[this.getQuestionbyKey(quest, `arr_oso[${i}][description]`)]){
                  quest[this.getQuestionbyKey(quest, `arr_oso[${i}][description]`)].disabled = false;  
                  quest[this.getQuestionbyKey(quest, `arr_oso[${i}][description]`)].value = oso.description;  
                }
                
              }

              

              if(this.getQuestionbyKey(quest, `arr_oso[${i}][other]`)){
                quest[this.getQuestionbyKey(quest, `arr_oso[${i}][other]`)].value = oso.other;
                quest[this.getQuestionbyKey(quest, `arr_oso[${i}][other]`)].disabled = !(oso.other);  
              }
            }
          }

          
          this.questions =  quest;

          setTimeout(_ => {
              if(this.panel)    
                window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
            });
          
        }

        
      })


      
    });
  }

  onSubmit(form){
    this.loadEnd.emit({load:true})
    this.beneficiarioService.updateForm(this.beneficiario.user_xml_id, form.formData, 2)
    .subscribe( data => {
      if(!data.error){
        
        if(data.completa == 0){
          this.orientacion_ocupacion.highWarrningInputsOn();
          alert(data.msg + '\nPero hay información pendiente para completar la etapa')
        }else{
          this.orientacion_ocupacion.highWarrningInputsOff();
          alert(data.msg)
        }

        this.complete = data.completa;
        this.fullForm.emit({isFill:(data.completa == 1)})
      }
      else
        alert("Hubo un error al enviar la información")

      this.loadEnd.emit({load:false})   
    })
    
  }

  fillForm(event){
    this.fullForm.emit({isFill:event.isFill})
  }

  getQuestionbyKey(quest, key:string){
    for(let item in quest){
      if(quest[item].key == key)
        return item;
    }

    return null;
  }

}
