export { HomeComponent } from './home/home.component';
export { CrearOperadorComponent } from './operador/crear-operador/crear-operador.component';
export { EditarOperadorComponent } from './operador/crear-operador/editar-operador.component';
export { VerOperadoresComponent } from './operador/ver-operadores/ver-operadores.component';
export { DetalleOperadorComponent } from './operador/detalle-operador/detalle-operador.component';
export { VerBeneficiariosComponent } from './beneficiario/ver-beneficiarios/ver-beneficiarios.component';
export { SubirBeneficiariosComponent } from './beneficiario/subir-beneficiarios/subir-beneficiarios.component';
export { ExcelBeneficiarioComponent } from './beneficiario/subir-beneficiarios/excel-beneficiario.component';
export { SubirNoIncluidosComponent } from './beneficiario/subir-beneficiarios/subir-no-incluidos.component';
export { SubirDeloitteComponent } from './beneficiario/subir-beneficiarios/subir-deloitte.component';
export { SubirPilaComponent } from './beneficiario/subir-beneficiarios/subir-pila.component';
export { SubirFunelComponent } from './beneficiario/subir-beneficiarios/subir-funel.component';
export { DetalleBeneficiarioComponent } from './beneficiario/detalle-beneficiario/detalle-beneficiario.component';
export { DescargarBeneficiariosComponent } from './beneficiario/descargar-beneficiarios/descargar-beneficiarios.component';
export { DescargarPilaComponent } from './beneficiario/descargar-pila/descargar-pila.component';
export { VerHistorialPilaComponent } from './beneficiario/ver-historial-pila/ver-historial-pila.component';

export { VerUsuariosComponent } from './usuarios/ver-usuarios/ver-usuarios.component';
export { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';

export { CrearGrupoComponent } from './perfilamiento/crear-grupo/crear-grupo.component';
export { VerGruposComponent } from './perfilamiento/ver-grupos/ver-grupos.component';
export { DetalleGrupoComponent } from './perfilamiento/detalle-grupo/detalle-grupo.component';
export { GraficaComponent } from './reportes/grafica/grafica.component';
export { FiltrosExcelComponent } from './reportes/filtros/filtros-excel/filtros-excel.component';
export { NovedadesComponent } from './reportes/novedades/novedades.component';

export { DescargarDocumentosUsuariosComponent } from './reportes/descargar-documentos-usuarios/descargar-documentos-usuarios.component';

export { CompleteSearchComponent } from './complete-search/complete-search.component';
export { VerEmpresaComponent } from './empresas/ver-empresa/ver-empresa.component';
export { CrearempresaComponent } from './empresas/crearempresa/crearempresa.component';
export { EditarEmpresaComponent } from './empresas/editar-empresa/editar-empresa.component';
export { DetalleEmpresaComponent } from './empresas/detalle-empresa/detalle-empresa.component';
export { CrearVacanteComponent } from './empresas/crear-vacante/crear-vacante.component';
export { DetalleVacanteComponent } from './empresas/detalle-vacante/detalle-vacante.component';

export { HojaVidaComponent } from './reportes/hoja-vida/hoja-vida.component';
export { PdfHojaVidaComponent } from './reportes/hoja-vida/pdf-hoja-vida/pdf-hoja-vida.component';

export { TiempoRutaComponent } from './reportes/tiempo-ruta/tiempo-ruta.component';
export { IntermediacionReportComponent } from './reportes/tiempo-ruta/intermediacion-report.component';
export { ColocacionReportComponent } from './reportes/tiempo-ruta/colocacion-report.component';

export { IndicadoresComponent } from './reportes/indicadores/indicadores.component';


export { AlertaComponent } from './alertas/alerta/alerta.component';
export { IntermediacionAlertaComponent } from './alertas/alerta/intermediacion_alerta.component';
export { ColocacionNoEmpleadosAlertaComponent } from './alertas/alerta/colocacion_noempleados_alerta.component';
export { ColocacionAlertaComponent } from './alertas/alerta/colocacion_alerta.component';

export { SubirPermanenciaTresComponent } from './beneficiario/subir-beneficiarios/subir-permanencia-tres.component';
export { SubirPermanenciaSeisComponent } from './beneficiario/subir-beneficiarios/subir-permanencia-seis.component';
export { DescargarPermanenciaComponent } from './beneficiario/descargar-permanencia/descargar-permanencia.component';

export { VerificarParticipantesComponent } from './alertas/verificar-participantes/verificar-participantes.component';
export { SubirVerificarParticipantesComponent } from './alertas/subir-verificar-participantes/subir-verificar-participantes.component';

export { MenuComponent } from './menu/menu.component';
export { LoginComponent } from './login/login.component';
