import { Component, OnInit } from '@angular/core';
import { AlertasService, AnalitycsService } from '../../../services';

@Component({
  selector: 'app-colocacion-noempleados-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
  providers: [AlertasService]
})
export class ColocacionNoEmpleadosAlertaComponent implements OnInit {


  alert_data: any = [];
  date_interval: any = [];
  alertas:any = [];
  link_form:any;

  constructor(private alertService:AlertasService,
    private analyticsService: AnalitycsService) { }

  ngOnInit() {
    this.link_form = 'upload_alert_coloc_no_empl.xlsx';
    this.date_interval = [
    { label: 'Mes 1', level: 0, value: 1},
    { label: 'Mes 2', level: 0, value: 2},
    { label: 'Mes 3', level: 0, value: 3},
    { label: 'Mes 4', level: 0, value: 4},
    { label: 'Mes 5', level: 0, value: 5},
    { label: 'Mes 6', level: 0, value: 6},
    { label: 'Total', level: 0, value: null},
    ]

    this.alertService.getAlertColocacionNoEmpleado()
    .subscribe( data => {
      let alert_data_1 = data.operator.map( op => {
        return{
          id_operador: op.id_operador,
          operador: op.name_operator,
          alertas: this.date_interval.map( (item, index) =>  op['mes' + (index + 1)]).filter( o => o != undefined)
        }  
      })

      this.alertas = [
      {
        nombre: ' Participantes colocados que han perdido su empleo',
        table: alert_data_1
      },
      ]

    });

  }

  onExportAlert(name){
    this.analyticsService.eventAction("Alertas", "Descargar:ColocacionNoEmpleados");
  }

}
