import { Component, OnInit } from '@angular/core';
import { Empresa, Vacante } from '../../../struct';
import { EmpresaService, VacanteService, AnalitycsService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-detalle-empresa',
	templateUrl: './detalle-empresa.component.html',
	styleUrls: ['./detalle-empresa.component.css']
})
export class DetalleEmpresaComponent implements OnInit {

	vacantes: any = [];	
	model: any = {};
	loadPage: boolean = false;
	idcompany: number;
	empresa: Empresa;
	type_user:any;

	constructor( private empresaService: EmpresaService,
		private vacanteService: VacanteService,
		private router: Router,
		private route: ActivatedRoute,
		private analytcisService: AnalitycsService ) { }

	ngOnInit() {
		this.idcompany = this.route.params['value'].id;
		this.type_user = localStorage.getItem('user_type');

		if(this.idcompany) {
			this.empresaService.getEmpresasById(this.idcompany).subscribe(
				data => {
					if(data.detailcompany) {
						this.empresa = new Empresa(data.detailcompany[0]);
						this.model = this.empresa;
					}
				});
			this.vacanteService.getVacantesByCompanyId(this.idcompany).subscribe(
				data => {
					if(data.veneficiary_by_company) {
						this.vacantes = data.veneficiary_by_company.map(vacante => new Vacante(vacante));
						this.model.vacantes = this.vacantes;
					}
				});
		}
	}

	// La Vacante no se puede eliminar
	//
	// deleteVacante(event, vacante){
	// 	event.stopPropagation();
	// 	if(confirm('Desea eliminar la vacante ' + vacante['description'])){
	// 		this.vacanteService.deleteVacanteById(vacante.id).subscribe(
	// 			data => {
	// 				for(let vac in this.vacantes){
	// 					if(vacante.id == this.vacantes[vac].id){
	// 						this.vacantes.splice(vac, 1);
	// 					}
	// 				}
	// 				this.loadPage = false;
	// 			});
	// 	}
	// }

	nullifyVacante(event, vacante){
		event.stopPropagation();
		if(confirm('Desea deshabilitar la vacante ' + vacante['description'])){
			this.loadPage = true
			this.vacanteService.nullifyVacanteById(vacante.id).subscribe(
				data => {
					if(!data.error){
						for(let vac in this.vacantes){
							if(vacante.id == this.vacantes[vac].id){
								this.vacantes[vac].name_status_vacancy = 'Anulada'
							}
						}
					}
					
					alert(data.msg)
					this.analytcisService.eventAction('Vacantes', 'DeshabilitarVacantes');
					this.loadPage = false
				});
		}
	}

}
