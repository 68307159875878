import { Directive, HostListener, ElementRef, OnInit  } from '@angular/core';
import { CustomCurrencyPipe } from '../pipes/custom-currency.pipe';

@Directive({
  selector: '[currencyFormatter]'
})
export class CurrencyFormatterDirective {

 el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
  	
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = new CustomCurrencyPipe().transform(this.el.value);
  }

  @HostListener("keyup", ["$event.target.value"])
  onKeyUp(value) {
  	var clean_text = new CustomCurrencyPipe().parse(value)
  	console.log(clean_text);
    this.el.value = new CustomCurrencyPipe().transform(clean_text); // opossite of transform
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    //this.el.value = new CustomCurrencyPipe().parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = new CustomCurrencyPipe().transform(value);
  }

}
