import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AssignGroupWrappedService } from '../../shared/assign-group-wrapped.service' ;
import { BeneficiarioDataService, PerfilacionService } from '../../services';

@Component({
  selector: 'assign-group',
  templateUrl: './assign-group.component.html',
  styleUrls: ['./assign-group.component.css']
})
export class AssignGroupComponent implements OnInit {


  model:any = {};
  finish_assign:boolean = false; 
  finish_an_user:boolean = false; 
  open_popup:boolean = false;
  beneficiaries:any;
  beneficiariesOK:any = [];
  beneficiaresFail:any = [];
  name_group:any;
  groups_perfilacion:any = [];
  full_height_component:boolean = false;

  @ViewChild('containForm') containForm: ElementRef;

  @Output() togglePopup = new EventEmitter();
  @Output() addBeneficiarios = new EventEmitter();

  @Input() open_assign:boolean =  false;


  constructor(private assignGroupService: AssignGroupWrappedService,
    private perfilacionService: PerfilacionService,
    private beneficiaryDataService: BeneficiarioDataService) { }

  ngOnInit() {

  	this.model.grupo = '';


    this.groups_perfilacion 

    this.perfilacionService.getAllGroups()
    .subscribe( data => this.groups_perfilacion = data.allgroup);

    this.assignGroupService.addBeneficiaries$.subscribe( beneficiares => {
      this.open_popup = true;

       for(let beneficiary in beneficiares){
         if(beneficiares[beneficiary]['stage_id'] > 1){
           this.beneficiariesOK.push(beneficiares[beneficiary]);
         }else{
           this.beneficiaresFail.push(beneficiares[beneficiary]);
         }
         
       }
    });
  }



  

  onClose(event){
  	this.open_popup = false;
    this.finish_assign = false;
    this.model = {};
    this.beneficiariesOK = [];
    this.beneficiaresFail = [];
    this.togglePopup.emit({open_popup: false});
  }


  onAssign(event){
    console.log(this.beneficiariesOK)
    this.model.beneficiaries = this.beneficiariesOK.map( b => {
      return b.id_participante || b.user_xml_id
    });

    this.perfilacionService.assingBeneficiariosToGroup(this.model.grupo, this.model.nombre_grupo, this.model.descripcion, JSON.stringify(this.model.beneficiaries))
    .subscribe( data => {

      this.finish_assign = true;
      if(this.model.beneficiaries.length == 1 && data.is_asgin === true){
        console.log('un asignado')
        this.finish_an_user = true;
      }else{
        console.log('asignar')
      }

      this.name_group = data.group[0].name_group;
      this.addBeneficiarios.emit({group: data.group, is_asgin:data.is_asgin});

    })
  }

}
