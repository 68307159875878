import { Component, Input, OnInit, forwardRef, SimpleChanges } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';

const noop = () => {
};

@Component({
	selector: 'app-check-list',
	templateUrl: './check-list.component.html',
	styleUrls: ['./check-list.component.css'],
	providers: [
	{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => CheckListComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => CheckListComponent),
		multi: true,
	}
	]
})

export class CheckListComponent implements OnInit, ControlValueAccessor, Validator {

	@Input() listData;
	@Input() listChecks;
	@Input() listCondition;
	@Input() name:string;
	@Input() current_criterio:number;
	result:any;
	group_radios:any = {};

	private onChangeCallback: (_: any) => void = noop;
	private onTouchedCallback: () => void = noop;

	constructor() { }

	ngOnInit() {
		for(let item of this.listChecks){
			if(item['value'])
				this.group_radios[this.name + '_' + item['id']] = item['value'];
		}

		setTimeout(_ => {
			this.result = this.getResult();
			this.onChangeCallback({list:this.group_radios, result:this.result});
		}, 1000)


	}

	get value(){
		return this.result;
	}

	set value(v:any){
		console.log(v)
		if(v !== this.result){
			this.result = v;
			this.onChangeCallback({list:this.group_radios, result:this.result});
		}
	}

	public writeValue(value: any) {
		if (value !== this.result) {
			this.result = value;
		}
	}

	onChange(event){
		console.log('verde')
		this.result = this.getResult();
		this.onChangeCallback({list:this.group_radios, result:this.result});
	}

	onChangeModel(event){
		console.log(event)
	}


	getResult(){
		let valueDefault;
		console.log(this.group_radios)
		for(let radio in this.group_radios){
				console.log(radio)
			for(let condition in this.listCondition){

				if(this.group_radios[radio] == this.listCondition[condition].value){

					if(this.listCondition[condition].rule == 'default'){
						console.log(this.group_radios[radio])
						valueDefault = this.group_radios[radio]
					}
					if(this.listCondition[condition].rule == 'first')
						return this.group_radios[radio];
				}

			}
		}

		if(valueDefault)
			return valueDefault

		return -1;
	}

	public registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: FormControl){
		return (this.result) ? null : {
			inputError: {
				valid: false,
			},
		};
	}


}
