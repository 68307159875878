export const FORM_FORMACION = [
	{
		"key":"competencia_dura",
		"label":"NOMBRE PRUEBA LECTURA - ESCRITURA",
		"size":"7",
		"type":"text",
		"controlType":"textbox",
	},
	{
		"key":"competencia_dura",
		"size":"5",
		"text":"AGREAGAR COMPETENCIA",
		"controlType":"button",
		"click": "addM"
	}
	
]