export const FORM_PERMANENCIA = [
	{
		"key":"button_mes",
		"text":"Hasta mes",
		"size":"12",
		"index": "",
		"controlType":"switchtoggle",
		"click": "switchToggle",
		"action": "toggleForm",
		"toggleClass": false,
		"inputs":[
			'type_permanece_accomp_id'].join(',')

	},
	{
		"key":"title_mes",
		"label":"Hasta mes",
		"size":"12",
		"controlType":"label"
	},
	{
		"key":"continua_empleado",
		"label":"¿Continua empleado?",
		"size":"12",
		"controlType":"dropdown",
		"source":"booleanSource",
		"inputInput": [
			'expectation',
			'schedules',
			'subordination',
			'other_company',
			'completion_reason_id',
			'satisfaced_company',
			'salary',
			'environment_working',
			'other_competitor',
			'contract_end_date'

		].join(','),
		"outputInput":[
			'type_permanece_accomp_id',
			'satisfaction_competitor_id',
			'satisfaction_company_id',
			'desertion_risk_id',
			'observation'
		].join(','),
		"action":"enabledOnValue",
		"valueToEnabled": "false",
		"verify":true,
		"editable":true
	},


	{
		"key":"completion_reason_id",
		"label":"Motivo",
		"size":"12",
		"controlType":"dropdown",
		"source":"completion_reason",
		"neddle_name":"reason",
		"verify":true,
		"editable":true,
		"disabled":true
	},

	// {
	// 	"key":"dismissed",
	// 	"label":"¿El participante fue despedido?",
	// 	"size":"12",
	// 	"controlType":"dropdown",
	// 	"source":"booleanSource",
	// 	"action":"onlyOption",
	// 	"outputInput":"give_up",
	// 	"valueToEnabled": "true",
	// 	"verify":true,
	// 	"editable":true,
	// 	"disabled":true
	// },

	// {
	// 	"key":"give_up",
	// 	"label":"¿El participante renunció?",
	// 	"size":"12",
	// 	"controlType":"dropdown",
	// 	"source":"booleanSource",
	// 	"action":"onlyOption",
	// 	"outputInput":"dismissed",
	// 	"valueToEnabled": "true",
	// 	"verify":true,
	// 	"editable":true,
	// 	"disabled":true
	// },

	{
		"key":"contract_end_date",
		"label":"Fecha de finalización contrato/despido/renuncia",
		"size":"12",
		"type":"date",
		"controlType":"textbox",
		"verify":true,
		"editable":true,
		"disabled":true
	},

	{
		"key":"title_empresa",
		"label":"Según la perspectiva de la empresa el participante:",
		"size":"12",
		"controlType":"label",
		"verify":false,
	},

	{
		"key":"expectation",
		"label":"Cumplió con las expectitivas esperadas en terminos de productividad",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},
	{
		"key":"schedules",
		"label":"Cumplió con los horarios establecidos",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},

	{
		"key":"subordination",
		"label":"Su comportamiento era adecuado en terminos de subordinación",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},

	{
		"key":"other_company",
		"label":"Otro",
		"size":"12",
		"controlType":"checkbox",
		"action":"enabledInput",
		"outputInput": '',
		"inputInput":"cual_other_company",
		"verify":false,
		"editable":true,
		"disabled":true
	},


	{
		"key":"cual_other_company",
		"label":"¿Cuál?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":false,
		"editable":true,
		"disabled":true
	},
	

	{
		"key":"title_participante",
		"label":"Según la perspectiva del participante:",
		"size":"12",
		"controlType":"label"
	},

	{
		"key":"satisfaced_company",
		"label":"Estaba a gusto con la Empresa",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},
	{
		"key":"salary",
		"label":"El horario y salario compensaban el trabajo",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},

	{
		"key":"environment_working",
		"label":"Le gusto el ambiente de trabajo",
		"size":"12",
		"controlType":"checkbox",
		"verify":false,
		"editable":true,
		"disabled":true
	},
	{
		"key":"other_competitor",
		"label":"Otro",
		"size":"12",
		"controlType":"checkbox",
		"action":"enabledInput",
		"outputInput": '',
		"inputInput":"cual_other_competitor",
		"verify":false,
		"editable":true,
		"disabled":true
	},
	{
		"key":"cual_other_competitor",
		"label":"¿Cuál?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":false,
		"editable":true,
		"disabled":true
	},

	
	// {
	// 	"key":"title_empresa",
	// 	"label":"",
	// 	"size":"12",
	// 	"controlType":"label"
	// },

	{
		"key":"type_permanece_accomp_id",
		"label":"Tipo de acompañamiento",
		"size":"12",
		"type":"groupcheckbox",
		"controlType":"groupcheckbox",
		"source":"acompanamiento",
		"action":"enabledOnValue",
		"valueToEnabled": "4",
		"outputInput": '',
		"inputInput":"type_accomp_other",
		"cleanInput":"",
		"verify":true,
		"editable":false,
		"disabled":true
	},
	{
		"key":"type_accomp_other",
		"label":"¿Cuál?",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":true,
		"editable":true,
		"disabled": true,
	},

	{
		"key":"satisfaction_competitor_id",
		"label":"Nivel de satisfacción del participante",
		"size":"12",
		"controlType":"dropdown",
		"source":"nivel_satisfaccion",
		"verify":true,
		"editable":true,
		"disabled":true
	},

	{
		"key":"satisfaction_company_id",
		"label":"Nivel de satisfacción de la empresa",
		"size":"12",
		"controlType":"dropdown",
		"source":"nivel_satisfaccion",
		"verify":true,
		"editable":true,
		"disabled":true

	},

	{
		"key":"desertion_risk_id",
		"label":"Riesgo de deserción laboral ",
		"size":"12",
		"controlType":"dropdown",
		"source":"nivel_satisfaccion",
		"verify":true,
		"editable":true,
		"disabled":true
	},

	{
		"key":"observation",
		"label":"Observaciones",
		"size":"12",
		"type":"text",
		"controlType":"textarea",
		"verify":true,
		"editable":true,
		"disabled":true
	},

	// {
	// 	"key":"ajuste_verificador",
	// 	"label":"¿Señor Operador usted confirma que los ajustes solicitados por el verificador quedaron resueltos de manera satsifactoria?",
	// 	"size":"12",
	// 	"controlType":"checkbox",
	// 	"optional":"true",
	// },

	/*{
		"key":"permanece3",
		"label":"Señor Operador, ¿usted certifica que el participante lleva tres (3) meses vinculado laboralmente?",
		"size":"12",
		"controlType":"checkbox",
		"optional":"true",
		"editable":false,
		"verify":false,
		"visible":false,
		"hidden":"true"
	},

	{
		"key":"permanece6",
		"label":"Señor Operador, ¿usted certifica que el participante lleva seis (6) meses vinculado laboralmente?",
		"size":"12",
		"controlType":"checkbox",
		"optional":"true",
		"editable":false,
		"verify":false,
		"visible":false,
		"hidden":"true"
	},*/


	

];