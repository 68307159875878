import { Component, OnInit } from '@angular/core';
import { Vacante, Empresa, Beneficiario } from '../../../struct';
import { VacanteService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-detalle-vacante',
  templateUrl: './detalle-vacante.component.html',
  styleUrls: ['./detalle-vacante.component.css']
})
export class DetalleVacanteComponent implements OnInit {

	model: any = {};
	idvacancy: number;
	vacante: Vacante;
	company: any = {};
	beneficiarios : any = []

	constructor(private vacanteService: VacanteService,
				private router: Router,
				private route: ActivatedRoute ) { }

	ngOnInit() {
		this.idvacancy = this.route.params['value'].id;
		if (this.idvacancy) {
			this.vacanteService.getVacantesById(this.idvacancy).subscribe(
				data => {
					if(data.detailvacancy) {
						this.vacante = new Vacante(data.detailvacancy[0]);
						this.model = this.vacante;
					}
					if(data.company) {
						this.company = new Empresa(data.company[0]);
					}
					if(data.beneficiarios) {
						this.beneficiarios = data.beneficiarios.map( 
							beneficiario => new Beneficiario(beneficiario));
					}
			});
		}
	}
}
