import { Pipe, PipeTransform } from '@angular/core';
import { Operador, 
	Edades,
	EstadosParticipantes, 
	RangosSisben,
	CriteriosPriorizacion,
	TypeReport,
	RazonesNoElegibilidad 
} from './../struct';
import * as _ from 'underscore';

@Pipe({
	name: 'filterExcel'
})
export class FilterExcelPipe implements PipeTransform {

	participantes;

	transform(participantes: any, filtros: any): any {

		this.participantes = participantes;
		let _participantes = participantes;

		console.log(filtros)

		const {
			operadores, ciudades, jefe_hogar, estudia_actual, criterios, generos, 
			estadosciviles, edad, sisben, razonNoElegibilidad, 
			niveleducativo, resultadopre, resultadopost, formacion_dura, 
			modalidad, formacion_blanda, tipoacompanamiento, remision, 
			cuidadopsicosocial, cierrepsicosocial, tipo_contrato, areaocupacional, 
			horas_desde_asignadas, horas_hasta_asignadas, min_salario, max_salario

		} = filtros;
		_participantes = this.getFilter(_participantes, operadores, 'id_operador');
		_participantes = this.getFilter(_participantes, ciudades, 'city_id');
		_participantes = this.getFilter(_participantes, jefe_hogar, 'head_household');
		_participantes = this.getFilter(_participantes, estudia_actual, 'currently_studying');
		_participantes = this.getCriteriosPriorizacion(_participantes, criterios);
		_participantes = this.getFilter(_participantes, generos, 'id_gender');
		_participantes = this.getFilter(_participantes, estadosciviles, 'id_civil_status');
		_participantes = this.getRangosEdad(_participantes, edad);
		_participantes = this.getRangosSisben(_participantes, sisben);

		_participantes = this.getFilter(_participantes, niveleducativo, 'education_level_id');
		_participantes = this.getFilter(_participantes, resultadopre, 'id_result_consolidate_preformation');
		_participantes = this.getFilter(_participantes, resultadopost, 'id_result_consolidate_postformation');
		

		_participantes = this.getFilterArray(_participantes, formacion_dura, 'id_program_formation_hab_duras');
		_participantes = this.getFilterArray(_participantes, formacion_blanda, 'id_program_formation_hab_blandas');
		_participantes = this.getFilterArray(_participantes, modalidad, 'id_modadliad_hab_blandas')
								.concat(this.getFilterArray(_participantes, modalidad, 'id_modadliad_hab_duras'));

		_participantes = this.getFilterArray(_participantes, tipoacompanamiento, 'id_tipo_acompanamiento');
		_participantes = this.getFilter(_participantes, remision, 'remission_id');
		_participantes = this.getFilterArray(_participantes, cuidadopsicosocial, 'id_cuidadopsicosocial');
		_participantes = this.getFilter(_participantes, cierrepsicosocial, 'psocial_close_id');

		_participantes = this.getFilter(_participantes, areaocupacional, 'occupational_areas_id');
		_participantes = this.getFilter(_participantes, tipo_contrato, 'type_contract_id');

		_participantes = this.getRangeNumber(_participantes, horas_desde_asignadas, horas_hasta_asignadas, 'horas_total_asignadas');
		_participantes = this.getRangeNumber(_participantes, min_salario, max_salario, 'salary_contract');

		

		return _.uniq(_participantes.map( b => b.id_participante));


	}


	getFilter(data, filter, field){
		let acum = [], result = [];
		let flag = false;

		filter.map((filter, index) => {
			if(filter.selected){
				flag = true;
				acum.push(data.filter( item => item[field] == filter.id))
			}
		});

		acum.map( item => {
			item.map( fill => result.push(fill) );
		});
		return flag ? result : data;
	}

	getFilterArray(data, filter, field){
		let acum = [], result = [];
		let flag = false;

		filter.map((filter, index) => {
			if(filter.selected){
				flag = true;
				acum.push(data.filter( item => {
					let items = [];

					if(item[field]){
						items = item[field].split(',').filter( f =>  parseInt(f) == filter.id)
						console.log(items, item.id_participante)
						return items.length > 0;
					}
					
				}));
			}
		});

		acum.map( item => {
			item.map( fill => result.push(fill) );
		});

		return flag ? result : data;

	}

	getRangosEdad(data, filter){
		let acum = [], result = [];
		let flag = false;

		filter.map((filter, index) => {
			if(filter.selected){
				flag = true;
				let rango = Edades.getItem(filter.id);

				acum.push(data.filter( item => {
					let edad = Math.floor(parseFloat(item.age))
					return edad >= rango.min && edad <= rango.max;
				}));

			}
		});

		acum.map( item => {
			item.map( fill => result.push(fill) );
		});

		return flag ? result : data;
	}

	getRangosSisben(data, filter){

		let acum = [], result = [];
		let flag = false;

		filter.map((filter, index) => {
			if(filter.selected){
				flag = true;
				let sisben = RangosSisben.getItem(filter.id);

				acum.push(data.filter( item => {
					let score = parseFloat(item.score_sisben);
					return score >= sisben.min && score <= sisben.max;
				}));

			}
		});

		acum.map( item => {
			item.map( fill => result.push(fill) );
		});

		return flag ? result : data;
		
	}

	getCriteriosPriorizacion(data, filter){
		let acum = [], result = [];
		let flag = false;

		filter.map((filter, index) => {
			if(filter.selected){
				flag = true;
				let criterio = CriteriosPriorizacion.getItem(filter.id);

				acum.push(data.filter( item => {
					switch (criterio.id) {
						case 1:
						return item.exist_jea == 1;
						case 2:
						return item.mfa_exist == 1;
						case 3:
						return item.exist_united == 1;
						case 4:
						return item.exist_victim == 1;

						default:
						return false;
					}

				}));

			}
		});

		acum.map( item => {
			item.map( fill => {
				if(result.indexOf(fill) < 0)
					result.push(fill)
			} );
		});

		return flag ? result : data;
	}

	getRangeNumber(data, min, max, field){
		
		if(min > 0 && max > 0){
			console.log(min, max, field)	

			return data.filter( item => {
				console.log(item[field])
				return item[field] >= min && item[field] <= max
			});
		}

		return data;
	}

}

