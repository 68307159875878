import { Component, OnInit, Input } from '@angular/core';
import { VerificadorService, PagerServicesService } from '../../services';

@Component({
  selector: 'verify-history',
  templateUrl: './verify-history.component.html',
  styleUrls: ['./verify-history.component.css']
})
export class VerifyHistoryComponent implements OnInit {

  @Input() id_participante;
  history: any = [];
  pager: any = {};
  historial_paged:any = [];

  constructor(private verificadorService:VerificadorService,
              private pagerService: PagerServicesService) { }

  ngOnInit() {
  	this.verificadorService.getHistorialVerificacion(this.id_participante)
  		.subscribe( data => {
  			this.history = data.history;
        this.setPage(1);
  		})
  }


  setPage(page: number){
      if(page < 1 || page > this.pager.totalPages){
        return
      }
      this.pager = this.pagerService.getPager(this.history.length, page, 10);
      this.historial_paged = this.history.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

}
