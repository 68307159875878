import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string>{
	controlType = 'textbox';
	type: string;
	pattern: string;
	typeData:string;
	maxlength:number;
	readonly:boolean;
	max:string;
	typeMask:string;
	
	validator = {
		number: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		phone: /^[-+]?\d*(?:[\.\,]\d+)?$/,
		email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
		currency: /^[-+]?\d*(?:[\.\,]\d+)?$/,

	};
	

	constructor(options: {} = {}){
		super(options);
		this.pattern = this.validator[options['type']]  || '';
		this.typeMask = options['pattern']  || '';
		this.typeData = options['type'];
		this.type = (options['type'] == 'number' || options['type'] == 'phone' || options['type'] == 'email' || options['type'] == 'currency') ? 'text' : options['type'] ;
		this.readonly = options['readonly'] || false;
		this.maxlength = (options['type'] == 'text') && 300;
		this.max = (options['type'] == 'date') && '2200-12-31';
	}
}


/*
number
phone
date
email
currency
*/