import { 
	Directive,
	Input,
	Output,
	EventEmitter,
	HostListener,
	HostBinding,
} from '@angular/core';


@Directive({
  selector: '[appDragNdrop]'
})
export class DragNdropDirective {

	@Input() private allowed_extensions: Array<string> = [];
	@Output() private filesChangeEmiter : EventEmitter<File[]> = new EventEmitter();
  @Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
	@HostBinding('style.background') private background = 'transparent';

  	constructor() { }

  	@HostListener('dragover', ['$event']) public onDragOver(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.background = '#192c4b';
  	} 

  	@HostListener('dragleave', ['$event']) public onDragLeave(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.background = 'transparent';
  	}

  	@HostListener('drop', ['$event']) public onDrop(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.background = 'transparent';

  		var files = evt.dataTransfer.files;
  		var valid_files : Array<File> = [];
    	var invalid_files : Array<File> = [];

    	for(var file of files){
			var ext = file.name.split('.')[file.name.split('.').length - 1];
  			if(this.allowed_extensions.lastIndexOf(ext) != -1){
  				valid_files.push(file);
  			}else{
  				invalid_files.push(file);
  			}
    	}

  		this.filesChangeEmiter.emit(valid_files);
  		this.filesInvalidEmiter.emit(invalid_files);
  	}

}
