import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { AppSettings } from '../../../../appSettings';

@Component({
  selector: 'app-pdf-hoja-vida',
  templateUrl: './pdf-hoja-vida.component.html',
  styleUrls: ['./pdf-hoja-vida.component.css']
})
export class PdfHojaVidaComponent implements OnInit {

  linkPDF:string;
  id_participante:any;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  	this.id_participante = this.route.params['value'].id_participante;
  	this.linkPDF = AppSettings.URL_BACKEND + 'pdf/hoja_vida.pdf?user_id=' + this.id_participante;
  }

}
