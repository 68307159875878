export class HabilidadFormacion {
	type_competition_id:any;
	other:string;
	modality_id:number;
	assigned_hour:number;
	working_day_id:number;
	date_start:Date;
	date_finish:Date;
	program_formation_id:number;

	constructor(values: any = {}){
		Object.assign(this, values);
	}
}
