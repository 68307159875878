import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-beneficiary-box-state-traffic',
  templateUrl: './beneficiary-box-state-traffic.component.html',
  styleUrls: ['./beneficiary-box-state-traffic.component.css']
})
export class BeneficiaryBoxStateTrafficComponent implements OnInit {

  states:any = {}
  @Input() cur_state:number;
  @Input() beneficiario;
  @Input() type_verify:number;


  constructor() { }

  ngOnInit() {
    this.states = [
    {id:'-1', name:'PREINSCRITO INCOMPLETO', checked:true, color:' #717171'},
    {id:'0', name:'PREINSCRIPCIÓN', checked:(this.cur_state != 4), color:' #f1c154'},
    {id:'1', name:'INSCRIPCIÓN', checked:this.beneficiario['completa_inscripcion'] == 2, color:'#ef893a'},
    {id:'12', name:'CARACTERIZACIÓN Y PERFILAMIENTO', checked:this.beneficiario['completa_perfilamiento'] == 2, color:'#f55d52'},
    {id:'2', name:'ORIENTACIÓN SOCIO-OCUPACIONAL', checked:this.beneficiario['completa_orientacion'] == 2, color:'#d53e33'},
    {id:'3', name:'ACOMPAÑAMIENTO PSICOSOCIAL', checked:this.beneficiario['completa_psocial'] == 2, color:'#5d1b7b'},
    {id:'7', name:'ESTRATEGIAS DE RETENCIÓN', checked:this.beneficiario['completa_retencion'] == 2, color:'#9a5cb4'},
    {id:'4', name:'PRUEBAS PREFORMACIÓN', checked:this.beneficiario['completa_preform'] == 2, color:'#2ea8ed'},
    {id:'6', name:'FORMACIÓN', checked:this.beneficiario['completa_formacion'] == 2, color:'#0e69ad'},
    {id:'5', name:'PRUEBAS POST-FORMACIÓN', checked:this.beneficiario['completa_postform'] == 2, color:'#122a6e'},
    {id:'10', name:'INTERMEDIACIÓN', checked:this.beneficiario['completa_intermediacion'] == 2, color:'#0b6b19'},
    {id:'8', name:'COLOCACIÓN LABORAL', checked:this.beneficiario['completa_colocacion'] == 2, color:'#179a8f'},
    {id:'13', name:'PERMANENCIA LABORAL 3 MESES', checked:this.beneficiario['completa_permanecia1'] == 2, color:'#39ca74'},
    {id:'14', name:'PERMANENCIA LABORAL 6 MESES', checked:this.beneficiario['completa_permanecia2'] == 2, color:'#89e0ac'}
    
    ]
  }

  getColor(state){
  	if (state.checked)
  		return state.color
  }

}
