import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
//import { GlobalService } from '../../../services/global.service';
import { ExportGraph } from './ExportGraph';


@Component({
	selector: 'acc-bars-graph',
	templateUrl: './acc-barsgraph.component.html',
	styleUrls: ['./acc-barsgraph.component.css'],
	changeDetection:ChangeDetectionStrategy.Default,
})

export class AccBarsgraphComponent {
	@Input() totals:string;
	@Input() type:string;
	@Input() list:any;
	@Input() xaxis:any;
	@Input() yaxis:any;
	@Input() xlabel:string;
	@Input() ylabel:string;
	@Input() yaxisElements:any;
	@Input() yaxisSubElements:any;
	@Input() nextAvailable:any;
	@Input() previousAvailable:any;
	@Input() multiple:string;
	@Input() listobject:string;
	@Input() yaxisValues:any;
	@Input() participation:string;
	@Input() percent:boolean;

	@ViewChild('svg_graph') svg_graph;

	@Output() notify: EventEmitter<any> = new EventEmitter<any>();
	
	range:any;
	detail:any;
	max:number;
	filter:string;
	viewBoxSize:string ="0 0 1024 550"
	in_export:boolean = false;

	viewDetail = function(item, type, index, percent = null){
		console.log(item.name_operator, type, index);
		this.detail = [];
		this.detail.item = item;
		
		this.detail.type = type.value;
		this.detail.name = type.name;
		this.detail.color = type.color;
		this.detail.value = eval("item." + type.value);
		this.detail.percent = !isNaN(Math.round(eval("item." + type.percent))) ? Math.round(eval("item." + type.percent)) + '%' : null;
		this.detail.index = index;
		this.detail.xlabel = item.name_operator
	}

	DataGraphPosition(ev){
		this.notify.emit(ev);
	}

	ShowGraphPosition(ev){
		if(ev == 'Prev'){
			return this.previousAvailable;
		} else if(ev == 'Next'){
			return this.nextAvailable;
		}
	}
	nextPrevDetail(ev){
		let totalElements = JSON.parse(JSON.stringify(this.yaxisSubElements))

		this.yaxisElements.map( p => {
			totalElements.push(p)
		});

		var axisSize = totalElements.length;
		var CurrentPos = this.detail.index;
		var newPos = 0;
		if (ev == 'next'){
			if (CurrentPos + 1 < axisSize){
				newPos = CurrentPos + 1
			} else {
				newPos = 0;
			}
		} else {
			if (CurrentPos - 1 >= 0){
				newPos = CurrentPos - 1
			} else {
				newPos = axisSize - 1;
			}
		}


		this.viewDetail(this.detail.item, totalElements[newPos], newPos)

	}

	DeleteDetail(){
		this.detail = [];
		this.detail = null;
	}

	getRotation(ev){
		return "rotate(-40," + ev + ",530)"
	}


	graphPosition(ev, parent, list){
		var barw = 0;
		var c = 0;
		var a = 0;
		if (this.type == 'Talent' ||  this.type == 'Regular' || this.type == 'Elements'){
			barw = 450 / (this.list.length + 1) / (this.yaxisElements.length * 2  + 1)
		} else if (this.type == 'Percent') {
			barw = 450 / (this.list.length + 1) * 0.5;
		}
		
		if (this.type == 'Percent'){
			c = this.labelPosition(parent) + barw/2;
		} else if (this.type == 'Dots'){
			c = this.labelPosition(parent)
		} else {
			var SegSize = 380 / (this.list.length + 1);
			//a = (this.labelPosition(parent) - (900 / (this.list.length + 1) / 2) + barw)
			//a = this.labelPosition(parent) - SegSize /2 + barw/2
			a = this.labelPosition(parent) - SegSize/2
			//c = a + barw * ev
			c = a + barw * ((ev+1)*2)
		}

		return c
	}

	getBarheight(item, type, special){
		var a = 0;
		if(this.type == 'Elements'){
			var listObj = eval('item.' + this.multiple);
			var pos = 0;
			for(let element of listObj){
				if (element.id === type.id){
					if(special){
						a = eval('element.' + this.yaxisValues[1]);
					} else {
						a = eval('element.' + this.yaxisValues[0]);
					}
					
				}
			}
		} else {
			a = eval("item." + type.value);
		}
		var h = a / this.max * 500;

		return !isNaN(h) ? h : 0;
	}

	getLabelAxis(ev){
		var a = eval("ev." + this.xaxis);
		return a;
	}
	checkDivisible(ev){
		var resp = false;
		if (this.list.length > 29 && this.list.length < 40){
			if(ev % 3 == 0){
				resp = true;
			}

		} else if (this.list.length > 39 && this.list.length < 50){
			if(ev % 4 == 0){
				resp = true;
			}
		} else if (this.list.length > 49){
			if(ev % 5 == 0){
				resp = true;
			}
		} else if (this.list.length > 99){
			if(ev % 10 == 0){
				resp = true;
			}
		} else {
			resp = true;
		}
		return resp;
	}
	labelPosition(pos){
		var a = 62 + (900 / (this.list.length + 1) * (pos + 1));
		return a
	}
	setMax(){
		if(this.type != "Percent"){
			var m = 0;
			for(let item of this.list){
				for(let val of this.yaxisElements){
					if(!this.multiple){
						var a = eval('item.' + val.value);
						if(parseInt(a) > m){
							m = parseInt(a);
						}
					} else {
						var listObj = eval('item.' + this.multiple);
						for (let obj of listObj){
							if(val.id == obj.id){
								var b = eval('obj.' + this.yaxisValues[0])
								if(parseInt(b) > m){
									m = parseInt(b);
								}
							}
						}
					}
					
				}

			}
			if ( m > 50){
				this.max = m + 5- m % 5;
				console.log("MAX 5:", this.max)
			} else {
				this.max = Math.ceil(m / 10) * 10;
				console.log("MAX:",this.max);
			}
			
		}
	}
	
	textString(ev){
		if(ev.length > 15){
			return ev.slice(0,15) + "..."
		} 
		else{
			return ev
		}
	}
	ngOnChanges(ev){
		
		if(ev.list || ev.yaxisElements){
			this.detail = [];
			this.detail = null;
		}
		this.setMax();
	}
	ngOnInit() {
		if(this.totals){
			this.viewBoxSize ="0 0 1024 600"
		}
		if(this.type == "Talent"){
			this.viewBoxSize ="0 0 1024 620"
		}
		this.range = new Array(10);
		this.setMax();
	}

	exportGraph(){
		this.in_export = true;
		setTimeout( _ => {
			new ExportGraph(this.svg_graph.nativeElement, 1040, 570, 'BIS').exportGraph()
		 		.then( data => {
		 			this.in_export = false;
		 		});	
		})
	 	
	}

	constructor(){

	}
}
