import { Component, OnInit } from '@angular/core';
import { BeneficiarioDataService } from '../../../services/'


@Component({
	selector: 'app-descargar-documentos-usuarios',
	templateUrl: './descargar-documentos-usuarios.component.html',
	styleUrls: ['./descargar-documentos-usuarios.component.css']
})
export class DescargarDocumentosUsuariosComponent implements OnInit {

	model: any = {}
	loadPage:boolean;
	constructor(private beneficiaryService: BeneficiarioDataService) { }

	ngOnInit() {
	}


	sendForm(form){
		this.loadPage = true;
		this.beneficiaryService.sendDownloadFiles(form.email)
			.subscribe( data => {
				this.loadPage = true;
				alert('Se enviara la información via correo electrónico, una vez el proceso sea completado. \n Por favor revise su bandeja de entrada')
				console.log(data);
			});
	}
}
