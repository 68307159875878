export class Beneficio {
	
	id: number;
	totalValor: number;
	document: any;
	descripcionSoporte: any; 

	constructor(values: Object = {}){
		Object.assign(this, values);
	}
}
