import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBeneficiarios'
})
export class OrderBeneficiariosPipe implements PipeTransform {

  transform(value: Array<any>, field: string): any {

     if(value == null) {
      return null;
    }

    if (field.startsWith("-")) {
      field = field.substring(1);
      if (typeof value[0][field] === 'string' || value[0][field] instanceof String) {
        return [...value].sort((a, b) => b[field].localeCompare(a[field]));
      }
      return [...value].sort((a, b) => b[field] - a[field]);
    }
    else {
      if (typeof value[0][field] === 'string' || value[0][field] instanceof String) {
        return [...value].sort((a, b) => -b[field].localeCompare(a[field]));
      }
      return [...value].sort((a, b) => a[field] - b[field]);
    }
  }

}
