import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../appSettings';
import { AnalitycsService } from '../../../services';


@Component({
  selector: 'app-subir-beneficiarios',
  templateUrl: './subir-beneficiarios-dos.component.html',
  styleUrls: ['./subir-beneficiarios.component.css']
})
export class SubirBeneficiariosComponent implements OnInit {

  urlIframe: any;
  urlIframeOrientacion: any;
  urlIframepsicosocial:any;
  urlIframeIncripciones:any;
  urlIframePreformacion:any;
  urlIframeFormacion:any;


  title_page: string;
  selectedFIles:string="1";

  sendEvent:boolean = false;
  timeEvent:any;
  options = ['Subir Kokan Offline', 'Subir Inscripciones', 'Subir Orientación Socio-ocupacional', 'Subir Apoyo Psicosocial', 'Subir Formación'];

  constructor(private analitycService:AnalitycsService) { }

  ngOnInit() {
  	var token = localStorage.getItem('session_id')
  	this.title_page = 'Subir información participantes';
  	this.urlIframe = AppSettings.URL_BACKEND + 'upload_file/' + token;
    this.urlIframeOrientacion = AppSettings.URL_BACKEND + 'upload_file_orientacion_ocupacional/';
    this.urlIframepsicosocial = AppSettings.URL_BACKEND + 'upload_file_apoyo_psicosocial/';
    this.urlIframeIncripciones = AppSettings.URL_BACKEND + 'upload_file_inscripcion/';
    this.urlIframePreformacion = AppSettings.URL_BACKEND + 'upload_file_formation/';
    this.urlIframeFormacion = AppSettings.URL_BACKEND + 'upload_file_module_formation/';
  }

  onChangeModel(value){

    console.log(value)
    
    if(this.timeEvent){
      clearTimeout(this.timeEvent);
    }
    
    this.timeEvent = setTimeout( _ => {
      this.analitycService.eventAction('Paricipantes', 'SubirBeneficiarios:' + this.options[value - 1])
    }, 8000);
  }

}
