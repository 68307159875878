import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Beneficiario } from '../struct/';


@Injectable()
export class AssignGroupWrappedService {

  private beneficiaries = new Subject<Beneficiario>();
  addBeneficiaries$ = this.beneficiaries.asObservable();
  

  constructor() { }

  addBeneficiaries(item:any){
  	this.beneficiaries.next(item);
  }

}
