import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Subscription }   from 'rxjs/Subscription';
import { Beneficiario } from '../../../struct';
import { BeneficiarioDataService, PagerServicesService, VerificadorService } from '../../../services';
import { FilterBeneficiariosPipe } from '../../../pipes/filter-beneficiarios.pipe';
import { OrderBeneficiariosPipe } from '../../../pipes/order-beneficiarios.pipe';
import { FilterWrappedService } from '../../../shared/filter-wrapped.service' ;
import { AssignGroupWrappedService } from '../../../shared/assign-group-wrapped.service' ;
import { Utilities } from '../../../shared/utilities' ;
import { AppSettings } from './../../../appSettings';
import * as _ from 'underscore';

@Component({
  selector: 'app-ver-beneficiarios',
  templateUrl: './ver-beneficiarios.component.html',
  styleUrls: ['./ver-beneficiarios.component.css']
})
export class VerBeneficiariosComponent implements OnInit {


  @ViewChild('link_zip') link_zip;

  subsciption: Subscription;
  beneficiarios: any = [];
  beneficiarios_total: any = [];
  beneficiarios_total_filtro: any = [];
  pager: any = {};
  beneficiarios_paged = [];
  model: any = {};
  data: any = [];
  open_popup = false;
  assign_popup = false;
  filters = {};
  order_sorter = false;
  order_class  = {};
  cur_sorter = '';
  header_sorters = [];
  swipe:number = 0;
  loadPage:boolean = false;
  stage_verify:any;
  state_verify:any;
  filter_verify:any = [1, 2, 3, 4];
  verifyToGo:number = 0;
  counters:any = {}; 
  loadData:boolean =  false;
  currpage:number = 1;
  isFilter:boolean = false;
  waitFilters:any;
  numberPages:number = 15;
  pagerFlag:boolean = false;
  dataTotales:any = {};
  dataLabels:any = {};
  type_user:any;
  url_zip:string;
  url_reportre_verificado:string;
  text_reporte_verificado:string;
  all_participantes:any;
  download_all:boolean;

  select_all_participante:boolean = false;
  selected_participante:any = [];



  constructor(private beneficiarioService: BeneficiarioDataService,
    private pagerService: PagerServicesService,
    private verificadorService: VerificadorService,
    private filter: FilterBeneficiariosPipe,
    private fillService: FilterWrappedService,
    private orderService: OrderBeneficiariosPipe,
    private router: Router, 
    private route: ActivatedRoute,
    private assignGroupService: AssignGroupWrappedService) { }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');

    this.header_sorters.map( (h, i) => Object.assign(h, {index: (i+1)}));
    this.loadPage = true;

    this.route.params
    .subscribe(p => {
      console.log(p)
      if(p.buscar){
        this.filters = {buscar:p.buscar};
      }else {
        if(JSON.parse(localStorage.getItem('filters'))){

          this.filters =  JSON.parse(localStorage.getItem('filters'));
        }
      }

      
      if(p.stage){
        this.stage_verify = Number(p.stage)
        this.state_verify = Number(p.status)
        console.log(this.state_verify)
        this.verifyToGo = (this.stage_verify == 2) && 11;

        if(this.stage_verify == 2){
          this.url_reportre_verificado = AppSettings.URL_BACKEND + 'user_elebibilidad.xlsx?type=' + this.state_verify;
          this.text_reporte_verificado = (this.state_verify ==1) ? 'REPORTE DE COLOCADOS VERIFICADOS' : 'REPORTE DE COLOCADOS NO VERIFICADOS';
        }else if(this.stage_verify == 3 ){
            this.url_reportre_verificado = AppSettings.URL_BACKEND + 'user_elebibilidad_perm.xlsx?type=1&verify_id=' + this.state_verify;
            this.text_reporte_verificado = (this.state_verify ==1) ? 'REPORTE PERMANENCIA 3 MESES VERIFICADOS' : 'REPORTE PERMANENCIA 3 MESES NO VERIFICADOS';
        }else if(this.stage_verify == 4 ){
             this.url_reportre_verificado = AppSettings.URL_BACKEND + 'user_elebibilidad_perm.xlsx?type=2&verify_id=' + this.state_verify;
             this.text_reporte_verificado = (this.state_verify ==1) ? 'REPORTE PERMANENCIA 6 MESES VERIFICADOS' : 'REPORTE PERMANENCIA 6 MESES NO VERIFICADOS';
        }
          
      }

      if(p.alert){
        console.log('Listado alertas')
      }

      this.loadPage = true;
      this.beneficiarioService.getAllBeneficiarios(this.stage_verify, this.state_verify, this.filters).subscribe(this.loadBeneficiaries.bind(this));
    })

    this.fillService.addFilters$.subscribe( filter_items => {
      localStorage.setItem('filters', JSON.stringify(filter_items));
      this.loadPage = true;

      for(let item of Object.keys(filter_items)){
        if(filter_items[item] === false){
          delete filter_items[item];
        }
      }

      
      console.log(!(this.filters['con_soporte'] && this.filters['sin_soporte']));

     


      this.filters = filter_items;
      console.log(this.filters)

     

      this.pagerFlag = false;
      this.beneficiarioService.getAllBeneficiarios(this.stage_verify, this.state_verify, filter_items).subscribe(this.loadBeneficiaries.bind(this),);
    })

  }

  defaultPage(){
    this.currpage = 1;
    this.pager = this.pagerService.getPager(this.beneficiarios_total_filtro, 1, this.numberPages);
    this.loadData = true;
    
  }  

  setPage(page: number){
    if(page < 1 || page > this.pager.totalPages){
      return
    }

    this.loadPage = true;
    this.pagerFlag = true;
    this.beneficiarioService.getAllBeneficiarios(this.stage_verify, this.state_verify, this.filters, (page - 1) * this.numberPages).subscribe(this.loadBeneficiaries.bind(this));
    this.pager = this.pagerService.getPager(this.beneficiarios_total_filtro, page, this.numberPages);
    
  }

  closePopUp(event){
    this.open_popup = event['open_popup'];
  }

  closePopUpAssign($event){
    this.assign_popup = event['open_popup'];
    document.body.style.overflow = '';
  }

  openPopUpAssign(){
    this.assignGroupService.addBeneficiaries(this.beneficiarios);
    this.assign_popup = true;
    document.body.style.overflow = 'hidden';
  }

  emptyObject(obj){
    return _.isEmpty(obj);
  }

  specificFilter(filters, field, value){
    return filters[field] == value;
  }

  getKeys(obj){
    return _.keys(obj);
  }

  quitFilter(event, key){
    event.preventDefault();
    this.loadPage = true;
    delete this.filters[key]
    this.fillService.addFilters(this.filters);
    this.defaultPage();
  }

  sendMailPreIncompleto(){
    this.beneficiarioService.sendMailPreIncompleto()
    .subscribe( data => {
      alert('Se envio un alerta con los beneficiarios en estado PREINSCRITO INCOMPLETO.');
    })
  }

  selectedBoxAll(event){
    let selected = event.target.checked;

    this.beneficiarios = this.beneficiarios.map( b => {

      let index = this.selected_participante.indexOf(b.id);
      
      if(selected){
        if(index == -1)
          this.selected_participante.push(b.id);
      }else{
        if(index != -1)
          this.selected_participante.splice(index, 1);
      }
      
      b.selected = selected
      return b;
    });

    console.log(this.selected_participante);
  }

  selectBox(checked, id_participante){
    
    let index = this.selected_participante.indexOf(id_participante);

    if(index > -1 && !checked){
      this.selected_participante.splice(index, 1);
      this.select_all_participante = false;
    }else{
      if(checked){
        this.selected_participante.push(id_participante);
      }
    }
  }

  downloadExcelPila(){
    this.loadPage = true;
    this.verificadorService.getExcelPila(this.selected_participante).subscribe( data => {
      if(data.error){
         alert(data.msg);
         this.loadPage = false;

       

         return;
      }

      console.log(this.download_all)
       if(this.download_all){
         this.selected_participante = [];
         this.download_all = false;
       }

      this.link_zip.nativeElement.href = data.link
      this.link_zip.nativeElement.click()
      this.loadPage = false;
    });
  }

  downloadExcelPermanencia(){
    this.loadPage = true;
    this.verificadorService.getExcelPermanencia((this.stage_verify - 2),this.selected_participante).subscribe( data => {
      if(data.error){
         alert(data.msg);
         this.loadPage = false;

         return;
      }

      
     console.log(this.download_all)
     if(this.download_all){
       this.selected_participante = [];
       this.download_all = false;
     }


      this.link_zip.nativeElement.href = data.link
      this.link_zip.nativeElement.click()
      this.loadPage = false;
    });
  }


  loadBeneficiaries(data){
    this.loadPage = true;
    if(data.beneficiary){  

      if(this.stage_verify){
        if(this.filter_verify.indexOf(this.stage_verify) > -1) {
          data.beneficiary = data.beneficiary;
        }else{
          this.router.navigate(['/']);
        }
      }
      


      this.data = data.beneficiary.map( b => {

        b.selected = (this.selected_participante.indexOf(b.id) > -1);
        return new Beneficiario(b);
      });

      this.beneficiarios = this.data;
      this.beneficiarios_total_filtro = data.total_filtro;
      this.beneficiarios_total = data.total;
      this.dataTotales['buscar'] = data.total_filtro;
      this.dataTotales['etnia'] = data.total_exits_etnia;
      this.dataTotales['genero'] = data.total_genero;
      this.dataTotales['edad'] = data.total_edad;
      this.dataTotales['estado'] = data.total_estado;
      this.dataTotales['numero_envio'] = data.total_numero_envio;
      this.dataTotales['operador'] = data.total_operador;
      this.dataTotales['mfa_exist'] = data.total_mfa_exist;
      this.dataTotales['exist_jea'] = data.total_exist_jea;
      this.dataTotales['sisben'] = data.total_sisben;
      this.dataTotales['exits_sisben'] = data.total_exits_sisben;
      this.dataTotales['exist_united'] = data.total_exist_united;
      this.dataTotales['exist_victim'] = data.total_exist_victim;
      this.dataTotales['grupor_perfilamiento'] = data.total_perfilamiento;
      this.dataTotales['bis1a'] = data.total_bis1a;
      this.dataTotales['bis1b'] = data.total_bis1b;

      this.dataLabels['bis1a'] = "BIS 1A";
      this.dataLabels['bis1b'] = "BIS 1B";
      this.dataLabels['buscar'] = "Buscar";
      this.dataLabels['etnia'] = "Etnia";
      this.dataLabels['genero'] = "Genero";
      this.dataLabels['edad'] = "Edad";
      this.dataLabels['estado'] = "Estado";
      this.dataLabels['numero_envio'] = "Numero de envio";
      this.dataLabels['operador'] = "Operador";
      this.dataLabels['mfa_exist'] = "Familias en acción";
      this.dataLabels['exist_jea'] = "Jovenes en acción";
      this.dataLabels['sisben'] = "Rango de sisbén";
      this.dataLabels['exits_sisben'] = "Población sisbén";
      this.dataLabels['exist_united'] = "Población unidos";
      this.dataLabels['exist_victim'] = "Población victimas";
      this.dataLabels['grupor_perfilamiento'] = "Grupos de perfiliamiento";
      
      this.dataLabels['documento_colocacion'] = "Con documentos";
      this.dataLabels['documento_permanecia_3'] = "Con documentos";
      this.dataLabels['documento_permanecia_6'] = "Con documentos";
      this.dataLabels['documento_colocacion_sin'] = "Sin Documentos";
      this.dataLabels['documento_permanecia_3_sin'] = "Sin Documentos";
      this.dataLabels['documento_permanecia_6_sin'] = "Sin Documentos";

      this.all_participantes = data.users_id;

      


      if(!this.pagerFlag){
        this.defaultPage();
        this.pagerFlag = false;
      }
      

    }else{
      this.data = [];
      this.beneficiarios = this.data;
      this.beneficiarios_total = this.data;
      this.pager = null;
      this. defaultPage();
    }
    this.loadPage = false;
  }

  sendExcelParticipantes(){

      if(this.stage_verify == 2){
        this.downloadExcelPila();
      }

      if(this.stage_verify == 3 || this.stage_verify == 4){
        this.downloadExcelPermanencia();
      }
      
  }

   sendAllExcelParticipantes(){

      this.selected_participante = this.all_participantes.split(',');
      this.download_all = true;
      

      if(this.stage_verify == 2){

        this.downloadExcelPila();
      }

      if(this.stage_verify == 3 || this.stage_verify == 4){
        this.downloadExcelPermanencia();
      }
      
  }

}
