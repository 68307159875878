import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../../struct';
import { EmpresaService, UtilService } from '../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '../../../app.validators';

@Component({
	selector: 'app-editar-empresa',
	templateUrl: './editar-empresa.component.html',
	styleUrls: ['./editar-empresa.component.css']
})
export class EditarEmpresaComponent implements OnInit {
	
	model: any = {}
	empresa: Empresa;
  	title_view: string;
  	loadPage: boolean = true;
  	idcompany: any;
  	name_company: any;
  	ciudades: any = [];
  	validators = Validators;
  
  	constructor(private empresaService: EmpresaService,
  		private router: Router,
  		private route: ActivatedRoute,
  		private utilService: UtilService) { }

	ngOnInit() {
		this.title_view = 'EDITAR EMPRESA';
		this.idcompany = this.route.params['value'].id;

		if(this.idcompany){
	  		this.empresaService.getEmpresasById(this.idcompany).subscribe(
	  			data => {
		        if(data){
		        	console.log(data);
			          this.empresa = new Empresa(data.detailcompany[0]);
			          this.model = this.empresa;
			          this.model.idcompany = data.detailcompany[0].id;
			          console.log(this.model.idcompany);
			          this.name_company = data.detailcompany[0].name_company;
			          this.title_view = 'EDITAR ' + this.name_company;
		        }})

			  	this.utilService.getAllMunicipally().subscribe( 
					data => {
					this.ciudades = data.municipality;
					this.loadPage = false;
				})
      	}


	}

	onSubmitEmpresa(){
		console.log(this.model);
		var empresa = new Empresa(this.model);
		
		this.loadPage = true;
		this.empresaService.addEmpresa(empresa).subscribe( 
			data => {
	  			if(data.error){
	  				console.log(data.msg);
	  				return;
	  			}
  				this.router.navigate(['/empresas']);
		        this.loadPage = false;
  		});
	}

}