import { Component, OnInit } from '@angular/core';
import { PerfilacionService } from '../../../services';
import { GrupoPerfilacion } from '../../../struct';


@Component({
	selector: 'app-ver-grupos',
	templateUrl: './ver-grupos.component.html',
	styleUrls: ['./ver-grupos.component.css']
})
export class VerGruposComponent implements OnInit {

	groupsPerliacion:any = [];
	type_user:any;

	constructor(private perfilacionService:PerfilacionService) { }

	ngOnInit() {
		this.type_user = localStorage.getItem('user_type');
		this.perfilacionService.getAllGroups()
		.subscribe( data => {
			this.groupsPerliacion = data.allgroup;
		});
	}

	deleteGroup(event, group:GrupoPerfilacion){
		event.stopPropagation();
		if(confirm('Desea eliminar el grupo ' + group.name_group)){
			this.perfilacionService.deleteGroupById(group.id).subscribe(
				data => {
					if(!data.error){
						for(let op in this.groupsPerliacion){
							if(data.delgroup.id == this.groupsPerliacion[op].id){
								this.groupsPerliacion.splice(op, 1);
							}
						}
					}else{
						alert('No se puede eliminar el grupo: ' + data.msg);
					}
					
					
				})
		}
	}

}
