export class RazonesNoElegibilidad {
	id:number;
	rango:string;
	type: TypeReport;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getData(type: TypeReport){
		switch (type) {
			case TypeReport.KOKAN:
			return [
			{ id:1, razon:'NO CUMPLE POR POBLACIÓN', type: TypeReport.KOKAN},
			{ id:2, razon:'EDAD', type: TypeReport.KOKAN},
			{ id:3, razon:'PROGRAMAS DPS', type: TypeReport.KOKAN},
			].map( razon => new RazonesNoElegibilidad(razon));

			case TypeReport.DELOITTE:
			return [
			{ id:1, razon:'BACHILLER', type: TypeReport.DELOITTE},
			{ id:2, razon:'PILA', type: TypeReport.DELOITTE},
			].map( razon => new RazonesNoElegibilidad(razon));

			default:
			return null;
		}
	}

	static getItem(id, type){
		return RazonesNoElegibilidad.getData(type).filter( razon => razon.id == id)[0];
	}
}

export enum TypeReport{
	KOKAN,
	DELOITTE
}
