import { Component, OnInit } from '@angular/core';
import { OperadorDataService } from '../../../services';

@Component({
  selector: 'app-ver-operadores',
  templateUrl: './ver-operadores.component.html',
  styleUrls: ['./ver-operadores.component.css']
})
export class VerOperadoresComponent implements OnInit {

  operadores: any = [];
  type_user:string;

  constructor(private operadorService: OperadorDataService) { }

  ngOnInit() {
    this.type_user = localStorage.getItem('user_type');
  	this.operadorService.getAllOperadores().subscribe(
  		data => {
  			this.operadores = data.operator;
  		})
  }

  deleteOperador(event, operador){
    event.stopPropagation();
    if(confirm('Desea eliminar el operador ' + operador['name_operator'])){
      this.operadorService.deleteOperadorById(operador.id).subscribe(
        data => {
          
          for(let op in this.operadores){
              if(data.operator.id == this.operadores[op].id){
                this.operadores.splice(op, 1);
              }
           }
       })
    }
  }

 
}
