import { QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<string>{
	controlType = 'checkbox';
	outputInput:string;
	inputInput:string;
	onlyDisabled:string;
	action:string;
	

	constructor(options: {} = {}){
		super(options);
		this.action = options['action'] || '';
		this.outputInput = options['outputInput'] || '';
		this.inputInput = options['inputInput'] || '';
		this.onlyDisabled = options['onlyDisabled'] || '';
	}


}
