import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { VerificadorService, AnalitycsService  } from '../../services';
import * as _ from 'underscore';

@Component({
  selector: 'app-criterios',
  templateUrl: './criterios.component.html',
  styleUrls: ['./criterios.component.css']
})
export class CriteriosComponent implements OnInit {

  @Input() id_beneficiario:any;
  criterios:any = [];
  check_list
  value_criterios:any = 1;
  model:any = {};
  observacion:string = '';
  status:number ;
  message:string = '¡El participante cambiará a estado';
  currents_values:any = {};
  can_save:boolean = true;
  loader:boolean = true;
  @Output() out_status = new EventEmitter();


  constructor(private verificadorService:VerificadorService,
              private analyticsSercice: AnalitycsService) { }

  ngOnInit() {
  	this.criterios = [
    {id: 1, description: 'Cumple'},
    {id: 2, description: 'No Cumple'},
    {id: 3, description: 'Pendiente'}
    ]

    this.check_list = [
    {id:1, description:'SISBÉN de 0 hasta 41.74'},
    {id:2, description:'Pertenece red unidos'},
    {id:3, description:'Pertenecer unidad de víctimas'},
    ];

    this.verificadorService.getVerificacionInscripcion(this.id_beneficiario)
    .subscribe(data => {

      if(!data.error){

        if(data.empleo.length > 0){
          this.currents_values.empleo = data.empleo[0].type_fulfill_id
        }

        if(data.edad.length > 0){
          this.currents_values.edad = data.edad[0].type_fulfill_id
        }

        if(data.bachiller.length > 0){
          this.currents_values.bachiller = data.bachiller[0].type_fulfill_id
        }

        if(data.no_inclusion.length > 0){
          this.currents_values.no_inclusion = data.no_inclusion[0].type_fulfill_id
        }

        if(data.sisben.length > 0){
          this.check_list[0].value = data.sisben[0].type_fulfill_id
        }

        if(data.red_unidos.length > 0){
          this.check_list[1].value = data.red_unidos[0].type_fulfill_id
        }

        if(data.victimas.length > 0){
          this.check_list[2].value = data.victimas[0].type_fulfill_id
        }

        if(data.status_deliotte.length > 0){
          this.model.observacion = data.status_deliotte[0].observation
          this.model.comentario = data.status_deliotte[0].comment;
          this.status = data.status_deliotte[0].type_fulfill_id
          this.can_save = !(this.status == 2 || this.status == 5);
          this.out_status.emit({status:data.status_deliotte[0].name_type_fulfill})

        }


      }

      this.loader = false;

    })
  }

  getOutPutRadio(event){
  	console.log(this.model)
  }

  onSubmitList(event){

    console.log(this.status)

    let message;
    switch (this.status) {
      case 1:
      case 5:
      message = this.message + ' Elegible!'
      break;
      case 2:
      message = this.message + ' No elegible!'
      break;
      case 3:
      case 4:
      message = this.message + ' Pendiente!'
      break;
      
      default:
      break;
    }


    if(confirm(message)){
      this.analyticsSercice.eventAction('Verificador', 'VerificarCriterios')
      this.verificadorService.sendVerificacionInscripcion(this.id_beneficiario, this.model)
      .subscribe( data => {
        if(data.status_deliotte.length > 0){
          this.status = data.status_deliotte[0].type_fulfill_id
          this.can_save = !(this.status == 2 || this.status == 5);
          this.out_status.emit({status:data.status_deliotte[0].name_type_fulfill})
        }
      })
    }

    
    
  }

  getStatus(){
    let statuses = [];

    for(let d in this.model){

      if(this.model[d] != null){
        if(typeof(this.model[d]) == 'object'){

          if(this.model[d].result < 0)
            statuses.push(2)
          else
            statuses.push(this.model[d].result)
        }else{
          if(typeof(this.model[d]) != 'string'){
            statuses.push(this.model[d])  
          }

        }
      }

      
    }

    let _statuses = _.uniq(statuses)

    console.log(_statuses)

    if(_statuses.indexOf(2) > -1){
      this.status = 2
    }else if (_statuses.indexOf(3) > -1){
      this.status = 3
    }else{
      this.status = 1;
    }

    console.log(this.model)
  }

  onChange(event){
    this.getStatus();
  }

}
