import { Component, OnInit } from '@angular/core';
import { Operador, Hito } from '../../../struct';
import { OperadorDataService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';
import { UtilService } from '../../../services/util.service' ;

@Component({
	selector: 'app-detalle-operador',
	templateUrl: './detalle-operador.component.html',
	styleUrls: ['./detalle-operador.component.css']
})
export class DetalleOperadorComponent implements OnInit {


	model: any = {}
	hitos: Array<Hito> = new Array<Hito>();
	id_operador:number;
	operador: Operador;
	milestones: any;
	constructor(
		private operadorService: OperadorDataService,
		private router: Router, 
		private route: ActivatedRoute,
		private utilService: UtilService) { }

	ngOnInit() {
		this.id_operador = this.route.params['value'].id;

		if(this.id_operador){
			this.operadorService.getOperadorById(this.id_operador)
			.subscribe(data => {
				if(data.operator){
					this.hitos = data.hitos.map(hito => {
						return new Hito({
							capacity: hito.capacity,
							capacity_bis1b: hito.capacity_bis1b,
							date: hito.date,
							name: hito.name
						});
					});
					this.operador = new Operador(data.operator)
					this.operador.hitos = this.hitos;
					this.model = this.operador;
				}

			});
		}
	}

}
