import { Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import { OperadorDataService, UtilService, PagerServicesService, AnalitycsService} from '../../../services';
import { FormControl, FormGroup, FormArray, Validators} from '@angular/forms';
import { DropsWrapperService } from '../../../shared/drops-wrapper.service' ;
import { GraphComponent } from '../../../components/graph/graph.component';
import { AppSettings } from '../../../appSettings'
import { Utilities } from '../../../shared/utilities';
import { QuestionService } from '../../../components/forms/question.service';

@Component({
	selector: 'app-tiempo-ruta',
	templateUrl: './tiempo-ruta.component.html',
	styleUrls: ['./tiempo-ruta.component.css']
})
export class TiempoRutaComponent implements OnInit {

	@ViewChild('graph_preinscrito') graph_preinscrito;
	@ViewChild('name_pdf') name_pdf;
	@ViewChild('comentario_pdf') comentario_pdf;
	@ViewChildren('graphs') components:QueryList<GraphComponent>;

	loadPage:boolean = true;
	color_general:any[];
	export_data:any = {};
	dataSets:any = [];
	dataSetsTablesMirrors:any = [];
	dataSetsDemografico:any = [];
	title_graph = 'TIEMPO RUTA';
	form: FormGroup;
	dropsOptions: any;
	operadores: any;
	ciudadesOperador: any;
	model:any = {};
	url_form:string =  AppSettings.URL_BACKEND + 'pdf/tiempo_ruta.pdf';
	url_excel: string = AppSettings.URL_BACKEND + 'tiempo_ruta.xlsx';
	url_pdf:string = AppSettings.URL_BACKEND;
	graphs = [];
	validInput:boolean;
	token:string;
	cut_dataset:any = [];

	historial: any;
	historial_paged:any;
	pager: any = {};
	form_filter:any;
	name_series:any;
	sub_caption = ['Criterio', 'Participantes', 'Porcentaje']

	graphics = ['DE PREINSCRITO A INSCRITO','DE INSCRITO A INTERMEDIACIÓN','DE INTERMEDIACIÓN A COLOCACIÓN','DE COLOCACIÓN A CERTIFICADO','DE CERTIFICADO A CUMPLE EMPLEABILIDAD','COLOCADOS ACTIVOS','PARTICIPANTES CAÍDOS'];

	type_user:any;
	etapas:any;
	with_mirror = true;

	data_load:any;

	amplacion_data = {
		left: {bis1a:true, bis1b:true},
		right: {bis1a:true, bis1b:true}
	}
	


	constructor(private utilService:UtilService,
		private operadorService: OperadorDataService,
		private dropService:DropsWrapperService,
		private pagerService: PagerServicesService,
		private qService:QuestionService,
		private analyticsService: AnalitycsService) { }

	get InputOperadores(): FormArray{
		return this.form.get('operadores') as FormArray;
	}

	get InputCiudades(): FormArray{
		return this.form.get('ciudades') as FormArray;
	}

	ngOnInit() {
		//this.dropService.minValidateDrop().subscribe( d => this.dropService.addOptions(d));
		this.token = localStorage.getItem('session_id')
		this.type_user = localStorage.getItem('user_type')

		this.dropService.minValidateDrop().subscribe( d => {
			this.dropService.addOptions(d)
			this.qService.getQuestionsFilter(4)
				.map( form => {
					this.form_filter = (this.type_user == 2) ? 
						form.filter( f => f.key != 'operador' && f.key != 'ciudad' ) : form
					//this.form_filter = form;
				})
		});

		this.model.operador_gnrl = "";
		this.model.ciudad_gnrl = "";
		this.model.operador = "";
		this.model.ciudad = "";
		this.model.etapa = "";

		this.color_general = [
		['#39ca74','#2ea8ed','#f1c154','#e96637','#9a5cb4','#0e69ad','#599bf0','#f1c154'], //'graph_operador':
		['#d53e33','#0e69ad','#179a8f'], //'graph#_ciudades':
		['#d53e33','#2ea8ed','#9a5cb4','#f1c154'],//'graph_generos':
		['#39ca74','#f55d52','#0e69ad','#2ea8ed'],//'graph_edades':
		['#0e69ad','#599bf0','#f1c154','#e96637','#5d1b7b','#9a5cb4','#39ca74'], //'graph_poblacion':
		['#0e69ad','#179a8f','#5d1b7b','#d53e33'],//'graph_priorizacion':
		['#2ea8ed','#9a5cb4','#599bf0'],//'graph#_sisben':
		['#179a8f','#5d1b7b'],//'graph_jefehoga#r':
		['#9a5cb4','#d53e33','#0b6b19','#e96637','#599bf0','#0e69ad','#f55d52','#32a69c','#ef893a','#122a6e','#39ca74','#f97e4a','#2ea8ed','#5d1b7b'], //'graph_nivel_educativo':
		['#ef893a','#d53e33', '#39ca74', '#5d1b7b','#9a5cb4','#2ea8ed','#122a6f', '#f55d52'] //'graph_estado_civil':
		]

		this.etapas = [
	        {id:'37', name_stage:'Elegible'},
	        {id:11, name_stage:'Inscripción'},
	        {id:38, name_stage:'En formación'},
	        {id:40, name_stage:'En Intermediación'},
	        {id:19, name_stage:'Intermediación'},
	        {id:20, name_stage:'Colocación'},
	        {id:22, name_stage:'Permanencia 3 meses'},
	        {id:23, name_stage:'Permanencia 6 meses'},
    	]


		this.operadorService.getAllOperadores()
		.subscribe( data => {
			this.operadores = data.operator;
			this.dropService.addOptions$.subscribe( options => {
				this.dropsOptions = options;
				this.form = this.buildForm();	

				this.utilService.getTiempoRuta()
				.subscribe( data => {
					this.drawData(data);
					this.drawDemografico(data);
					this.historial = data.history;
					this.loadPage = false;
					this.setPage(1)
				})
			})
		}) 
		
	}

	drawData(data){

		this.dataSets = [];

		this.name_series = {
			'mes_periodo' : ['Mes 1', 'Mes 2', 'Mes 3', 'Mes 4', 'Mes 5', 'Mes 6'],
			'meses' : ['Menos de un mes','Entre 1 y 2 meses','Entre 2 y 3 meses','Entre 4 y 5 meses ','Más de 5 meses'],
			'semanas' : ['Menos de una semana ','Entre 1 y 2 semanas','Entre 2 y 3 semanas','Entre 4 y 5 semanas','Más de 5 semanas'],
			'poblacion' : ['Víctimas','Unidos','Sisben','Unidos + víctimas','Unidos + sisben','Víctimas + sisben','Unidos + víctimas + sisben'],
			'edad' : ['18 a 24 años','25 a 30 años','31 a 36 años','Mayores de 37 años'],
			'genero' : ['Femenino','Masculino','No reporta','LGBTI']
		};



		this.dataSets.push({
			title: 'DE PREINSCRITO A INSCRITO',
			graph: 'preinscritos',
			header: 'meses',
			id: '1',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.meses , data.preinscritos),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})


		this.dataSets.push({
			title: 'DE INSCRITO A INTERMEDIACIÓN',
			graph: 'inscritos',
			header: 'meses',
			id: '2',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.meses , data.inscritos),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})

		this.dataSets.push({
			title: 'DE INTERMEDIACIÓN A COLOCACIÓN',
			graph: 'intermediacion',
			header: 'meses',
			id: '3',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.meses , data.intermediacion),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})

		this.dataSets.push({
			title: 'DE COLOCACIÓN A CERTIFICADO',
			graph: 'colocacion',
			header: 'meses',
			id: '4',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.meses , data.colocacion),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})

		this.dataSets.push({
			title: 'DE CERTIFICADO A CUMPLE EMPLEABILIDAD',
			graph: 'certificado',
			header: 'semanas',
			id: '5',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.semanas , data.certificado),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})

		this.dataSets.push({
			title: 'COLOCADOS ACTIVOS',
			graph: 'colocados_activos',
			header: 'mes_periodo',
			id: '6',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.mes_periodo , data.colocados_activos),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})

		this.dataSets.push({
			title: 'PARTICIPANTES CAÍDOS',
			graph: 'colocados_caidos',
			header: 'mes_periodo',
			id: '7',
			size: 6,
			type:'ColumnChart',
			data: this.getSeriesGraph(this.name_series.mes_periodo , data.colocados_caidos),
			sub_caption: ['criterio', 'participantes', 'porcentaje'],
			download_row: true,
			with_filters: false,
		})





		this.dataSets.map( (data_set, i) => {

			let index_excel = (data_set.id_excel) ? data_set.id_excel : null

			this.cut_dataset.push([
				{
					id: 1,
					data_set: data_set.data,
					title: data_set.title,
					color:  this.color_general[0],
					type: data_set.type,
					type_data: (i+1),
					source: data_set.graph,
					header: data_set.header,
					graph_id: data_set.id,
					download_row: data_set.download_row,
					with_filters: data_set.with_filters,
					sub_caption: data_set.sub_caption,
					id_excel: index_excel,
					series: data_set.series
				},
				{
					id: 2,
					data_set: data_set.data,
					title: data_set.title,
					color:  this.color_general[0],
					type: data_set.type,
					type_data: (i+1),
					source: data_set.graph,
					header: data_set.header,
					graph_id: data_set.id,
					download_row: data_set.download_row,
					with_filters: data_set.with_filters,
					sub_caption: data_set.sub_caption,
					id_excel: index_excel,
					series: data_set.series
				},
			]);
		})

		//this.cut_dataset = Utilities.chunk(JSON.parse(JSON.stringify(this.dataSets)), 2);

		// this.dataSetsDemografico['poblacion'] = this.getSeriesGraph(name_series.poblacion , data.poblacion);
		// this.dataSetsDemografico['edad'] = this.getSeriesGraph(name_series.edad , data.edad);
		// this.dataSetsDemografico['genero'] = this.getSeriesGraph(name_series.genero , data.genero);

		
	}

	getSeriesGraph(name_serie, data, complemento = '', index_obj = null){

		if(index_obj != null){
			data = data.map( d => d[index_obj]);
		}

		return Object.keys(data).map( (serie, index) => {
			return [name_serie[index] + ' ' + complemento, data[serie]]
		});
		
		
	}

	getExportGraph(event, graph){
		this.export_data[graph] = {
			grafica: graph,
			img: event.graph,
			table: event.table
		}

	}


	getKeys(object){
		return Object.keys(object);
	}


	exportData(){

		this.components.map( g => {
			g.exportData();	
		});

		if(this.name_pdf.nativeElement.value.length <= 0){
			this.validInput = false;
		}else{
			this.validInput = true;
			this.loadPage = true;
			setTimeout( _ => {
				let form = <HTMLFormElement> document.getElementById('form-reporte');
				this.name_pdf.nativeElement.value = this.name_pdf.nativeElement.value.replace(/ /g,"_");
				form.submit();
				this.loadPage = false;
				this.name_pdf.nativeElement.value = ''
				this.comentario_pdf.nativeElement.value = ''
			}, 3000)
		}
	}

	buildForm(){
		let arr = {};
		arr['ciudades'] = new FormArray(this.createArrayInput(this.dropsOptions.city));
		arr['operadores'] = new FormArray(this.createArrayInput(this.operadores));

		return new FormGroup(arr);
	}

	fillDataMirror(formData, index, source, header){

		this.loadPage = true;
		if(formData.mirror_filter){
			this.utilService.getTiempoRuta(formData.operador, formData.ciudad, formData)
				.subscribe( d => {
					this.loadFilter(d, index, formData, source, header);
				});
		}

		
		
	}

	loadFilter(d,index, formData, source, header){
		this.data_load = d;
				let data_set = this.getSeriesGraph(this.name_series[header], d[source]);
				let color_set = this.color_general[0];

				if(source == 'operadors'){
					let headers = d[source].map( op => op.name_operator);
					let id_operador = d[source].map( op => op.id_operador);
					let index_color = this.name_series[header].indexOf(headers[0]);
					
					data_set = this.getSeriesGraph(d[source].map( op => op.name_operator), d[source], '', 'empresas')

					if(data_set.length == 1)
						color_set = [this.color_general[0][index_color]]

					this.cut_dataset[index][formData.graphic - 1].id_excel = id_operador;
				}

				if(formData.graphic){
					this.cut_dataset[index][formData.graphic - 1].data_set = data_set;
					this.cut_dataset[index][formData.graphic - 1].color = color_set;
				}else{
					this.cut_dataset[index][0].data_set = data_set;
					this.cut_dataset[index][0].color = color_set;
				}
				
				
				if(formData.mirror_filter){
					console.log("MIRROR")
					console.log(this.cut_dataset.length, index)
					console.log(index >= (this.cut_dataset.length-1))
					if(index >= (this.cut_dataset.length-1)){
						this.loadPage = false;	
					}	
				}else{
					this.loadPage = false;	
				}
		}


	createArrayInput(obj: Array<any>){
		let array = [];

		if(obj)
			obj.map( (o, index) => {
				array.push(new FormControl())
			}) 

		return array;
	}


	fillDataGraph(){
		this.model.operador_gnrl = (this.model.operador_gnrl != "all" ) ? this.model.operador_gnrl : null
		this.model.ciudad_gnrl = (this.model.ciudad_gnrl != "all" ) ? this.model.ciudad_gnrl : null

			this.loadPage = true;
			this.utilService.getTiempoRuta(this.model.operador_gnrl, this.model.ciudad_gnrl)
			.subscribe( data => {
				this.drawData(data);
				this.loadPage = false;
			})
	}

	fillData(){
		this.loadPage = true;
		this.utilService.getDemograficReport(this.model.operador, this.model.ciudad, this.model.etapa, this.model.bis1a, this.model.bis1b)
			.subscribe( data => {
				this.drawDemografico(data);
				this.loadPage = false;
			})
	}

	changeExternalFilter(side, field){
		this.loadPage = true;
		let ampliacion = Object.assign({}, this.amplacion_data);
		ampliacion[side][field] = !ampliacion[side][field];
		console.log(this.amplacion_data)
		this.amplacion_data = ampliacion;
	}

	drawDemografico(data){
		const name_series = {
			'poblacion' : ['Sisben','Unidos','Víctimas','Unidos + sisben','Unidos + víctimas','Víctimas + sisben','Unidos + víctimas + sisben'],
			'edad' : ['18 a 24 años','25 a 30 años','31 a 36 años','Mayores de 37 años'],
			'genero' : ['Femenino','Masculino','No reporta','LGBTI']
		};

		this.dataSetsDemografico['poblacion'] = this.getSeriesGraph(name_series.poblacion , data.poblacion);
		this.dataSetsDemografico['edad'] = this.getSeriesGraph(name_series.edad , data.edad);
		this.dataSetsDemografico['genero'] = this.getSeriesGraph(name_series.genero , data.genero);
	}

	cleanFill(input){
		switch (input) {
			case 1:
				this.model.ciudad = null
				break;

			case 2:
				this.model.operador = null
				break;
			
			default:
				// code...
				break;
		}
	}

	setPage(page: number){
	    if(page < 1 || page > this.pager.totalPages){
	      return
	    }
	    this.pager = this.pagerService.getPager(this.historial.length, page, 10);
	    this.historial_paged = this.historial.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	getExportExcel(evt, graph, id_ext){

		console.log(evt)

		if(id_ext[0])
			this.model.intervalo = id_ext[evt.graphic - 1][evt.index];
		else
			this.model.intervalo = evt.interval;

		this.model.grafica = graph;
		this.model.operador = evt.operador
		this.model.ciudad = evt.ciudad


		this.model.bis1a = (evt.bis1a) ? evt.bis1a : null;
		this.model.bis1b =(evt.bis1b) ?  evt.bis1b : null;


		let form = <HTMLFormElement> document.getElementById('form-excel');
		this.analyticsService.eventAction('DhTiempoRuta', 'Descargar:' + this.graphics[graph - 1] );
		setTimeout(_ => {form.submit();}, 100)
	}

	sendColumnFilter(evt, value, column){
		console.log(this.amplacion_data);
	}

}
