import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Utilities } from '../../shared/utilities';

import * as html2canvas from "html2canvas"

import * as _ from 'underscore';

@Component({
	selector: 'app-graph',
	templateUrl: './graph.component.html',
	styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit, OnChanges {

	@ViewChild('chart') chart;
	@ViewChild('overlay') overlay;

	@ViewChild('table') table;
	@ViewChild('contentGraph') contentGraph;

	grafica:any;
	tabla_grafica;
	porcentajes_grafica;
	options;
	color_default;

	total_data;
	total_dataSet;
	fillOff;
	hideGraph;
	header;
	order_dataset;
	fill_dataset;
	zoomx2;

	loadBullets:boolean = false;
	colspanHeader:number;
	fillter:any = {};

	@Input() with_filters;
	@Input() data_set;
	@Input() title;
	@Input() hide_slice_text;
	@Input() colors_dataSet;
	@Input() color_convention;
	@Input() filter;
	@Input() type_chart;
	@Input() sub_caption;
	@Input() download_row;
	@Input() no_header;
	@Input() order_value;
	@Input() series;
	@Input() label_total;

	_type_chart;

	@Output() exportGraph = new EventEmitter();
	@Output() exportLink = new EventEmitter();


	constructor() { 
		this.color_default = ['#dcdcdc'];

	}

	readyChart(event){
		if (this.type_chart == 'FunnelChart'){
			let arr_text = [].slice.call(this.chart.wrapper.getChart().container.querySelectorAll('text[text-anchor=end]'));
			let longest_width = arr_text.reduce(function (a, b) { return a.getBBox().width > b.getBBox().width ? a : b; }, arr_text.slice(0, 1)[0]).getBBox().width;

			for(let i = 0; i < arr_text.length; i++){
				let text = arr_text[i];
				text.setAttribute('x', longest_width - 50);
				text.setAttribute('text-anchor', 'front');
			}
			
			this.overlay.nativeElement.style.top = (this.chart.wrapper.getChart().getChartLayoutInterface().getYLocation(0, 0) - 10) + 'px'
			this.overlay.nativeElement.style.left = longest_width - 70 + 'px';
			this.loadBullets = true;
		}
	}

	ngOnInit() {
		this.colspanHeader = (this.sub_caption) ? this.sub_caption.length : 5;

		if(this.download_row)
			this.colspanHeader++;


		this._type_chart = (this.type_chart == 'FunnelChart') ? 'BarChart' : this.type_chart;

		if(this.order_value){

			this.order_dataset = (this.type_chart == 'FunnelChart') ? this.data_set : this.data_set.slice(0).sort( (a, b) => b[1] - a[1]);
		}else{
			this.order_dataset = this.data_set;
		}


		this.order_dataset.map( (item, index) => {
			this.fillter[index] = {
				selected: true,
				item: item[0],
				disabled: false
			}
		})

		this.setOptions();
		this.drawData();

	}

	ngOnChanges(changes){
		if(changes.data_set){
			if(!changes.data_set.firstChange){

				this.data_set = changes.data_set.currentValue;


				if (this.type_chart == 'BarChart' || this.type_chart == 'ColumnChart'){
					this.data_set = changes.data_set.currentValue.map( ( item, i) => {
						item[2] = this.colors_dataSet[i];
						return item;
					});
				}

				
				//this.order_dataset = this.data_set.slice(0).sort( (a, b) => b[1] - a[1]);
				if(this.order_value){
					this.order_dataset = (this.type_chart == 'FunnelChart') ? this.data_set : this.data_set.slice(0).sort( (a, b) => b[1] - a[1]);
				}else{
					this.order_dataset = this.data_set;
				}
				this.drawData();

				if(this.chart){
					
					// setTimeout( _ => {
					// 	this.exportData();
					// 	this.exportGraph.emit({
					// 		graph: this.chart.wrapper.getChart().getImageURI(),
					// 		table: JSON.stringify(this.tabla_grafica)
					// 	})
					// }, 1000)	
				}

			}
		}
		
	}

	onExportLink(item){
		let index = null;

		this.data_set.map( (data, i ) => {

			if(data[0] === item.label)
				index = i;
		})

		this.exportLink.emit({interval: (index + 1)});
	}

	onExportLinkMultiple(state, inteval){
		this.exportLink.emit({interval: (inteval + 1), state:state});
	}

	exportData(custom_dataset = null){

		let _self = this;

		let data_set = JSON.parse(JSON.stringify(this.order_dataset));
		let colors_dataSet = this.order_dataset.map( (o, i) =>  _self.colors_dataSet[i] );

		if(custom_dataset){
			data_set = custom_dataset;
			this.fill_dataset = data_set;
		}

		this.hideGraph = true;
		if(this.total_dataSet > 0){

			if(this.type_chart == 'PieChart'){
				this.grafica = {
					chartType: this.type_chart,
					dataTable: this.header.concat(data_set),
					options: Object.assign({}, this.options, {
						'colors': colors_dataSet , 
						'tooltip': {trigger:'none'},
						'width': 250, 'height':250,
					})
				};		
			}

			if(this.type_chart == 'FunnelChart'){
				this.grafica = {
					chartType: this.type_chart,
					dataTable: this.header.concat(data_set),
					options: Object.assign({}, this.options, {
						'colors': colors_dataSet , 
						'tooltip': {trigger:'none'},
						'width': 800, 'height':400,
					})
				};		
			}
			
		}else{
			if(this.type_chart == 'PieChart'){
				this.grafica = {
					chartType: this.type_chart,
					dataTable: this.header.concat([['No hay datos', 1]]),
					options: Object.assign({}, this.options, {
						pieSliceText: 'label',
						pieSliceTextStyle: {color: '#999'},
						tooltip:{trigger:'none'},
						'width': 250, 'height':250,
					})
				};		
			}
		}



		//setTimeout( _ => {

			if(this.type_chart != 'TableChart' && this.type_chart != 'GroupTableChart'){
					if(this.type_chart == 'FunnelChart'){
						this.exportGraph.emit({
							graph: this.chart.wrapper.getChart().getImageURI(), 
							table: JSON.stringify(this.tabla_grafica)
						})	
					}else{
						if(this.chart.wrapper){
							this.exportGraph.emit({
								graph: this.chart.wrapper.getChart().getImageURI(),
								table: JSON.stringify(this.tabla_grafica.filter( t => t.value > 0))
							})		
						}
						
					}	
			}else{
				this.exportGraph.emit({
					graph: null,
					table: JSON.stringify(this.tabla_grafica)
				})	
			}
			
			this.hideGraph = false;
		//}, 500)
	}

	setOptions(){
		if (this.type_chart == 'PieChart'){
			this.header = (this.series) ? [this.series] :[['Operador', 'Participantes']];

			this.options = {
				'colors': this.color_default,
				'legend': {position: 'none'},
				'chartArea': {'width': '90%', 'height': '90%'},
				'fontSize': 12,
				'width': 400, 'height':250,
				tooltip: { hideDelay: 0}
			};

			if(this.hide_slice_text){
				this.options['pieSliceText'] = 'none';
				this.options['tooltip'] = { hideDelay: 0, textStyle: {fontSize: 10} }
			}
		}

		if (this.type_chart == 'FunnelChart'){
			this.header = [['Etapa', 'Meta', 'Activos', {role:'style'}, 'Salio', {role:'style'}, 'Pendientes', {role:'style'}]];
			
			this.options = {
				title: this.title, 
				height: 400,
				chartArea: {width: '30%'},
				isStacked: true, 
				hAxis: {   format: ';', gridlines:{count: 3}, viewWindow:'pretty'},
				yAxis: {},
				bar: { groupWidth: '100%' }
			}
		}

		if (this.type_chart == 'BarChart'){
			//this.header = [['Operador', 'Participantes', { role: 'style' }]];
			//this.header = (this.series) ? [this.series.push({ role: 'style' })] :[['Operador', 'Participantes', { role: 'style' }]];

			if(this.series){
				let series = JSON.parse(JSON.stringify(this.series))
				series.push({ role: 'style' })	
				console.log("BAR: " + series)
				
				this.header = [series];
			}else{
				this.header = [['Operador', 'Participantes', { role: 'style' }]];	
			}
			
			this.order_dataset.map( ( item, i) => {
				return item[2] = this.colors_dataSet[i];
			});

			this.options = {
				'width': 880, 'height':200,
				chartArea: {left:'25%', width: '60%'},
				legend: {position: 'none'},
				annotations: {
					alwaysOutside: true,
					textStyle: {
						fontName: 'Times-Roman',
						fontSize: 11,
						auraColor: 'none',
						color: '#555'
					},
					boxStyle: {
						stroke: '#ccc',
						strokeWidth: 1,
						gradient: {
							color1: '#f3e5f5',
							color2: '#f3e5f5',
							x1: '0%', y1: '0%',
							x2: '100%', y2: '100%'
						}
					}
				},
				hAxis: {
					title: 'Participantes',
					minValue: 0,
					format: '0',

				},
				vAxis: { 
					format: '0',
					gridlines: { count: 1 },
					textStyle: {
						color: '#484C4F'
					}
				}
			}
		}

		if (this.type_chart == 'ColumnChart'){
			
			if(this.series){
				let series = JSON.parse(JSON.stringify(this.series))
				series.push({ role: 'style' })	
				
				this.header = [series];
			}else{
				this.header = [['Operador', 'Participantes', { role: 'style' }]];	
			}
			
			
			this.order_dataset.map( ( item, i) => {
				return item[2] = this.colors_dataSet[i];
			});

			this.options = {
				'width': 400, 'height':400,
				chartArea: {top: '10%', left: '15%', width: '85%', height: '75%'},
				legend: {position: 'none'},
				annotations: {
					alwaysOutside: true,
					textStyle: {
						fontName: 'Times-Roman',
						fontSize: 11,
						auraColor: 'none',
						color: '#555'
					},
					boxStyle: {
						stroke: '#ccc',
						strokeWidth: 1,
						gradient: {
							color1: '#f3e5f5',
							color2: '#f3e5f5',
							x1: '0%', y1: '0%',
							x2: '100%', y2: '100%'
						}
					}
				},
				hAxis: {
					minValue: 0,
					maxValue: 0,
					format: '#.##',

				},
				vAxis: { 

					title: this.header[0][1],
					minValue: 0,
					maxValue: 0,
					format: '#.##',
					textStyle: {
						color: '#484C4F'
					},
					gridlines: {
						count: -1
					}
				}
			}
		}

		if (this.type_chart == 'TableChart'){
			this.header = []	
		}

		
	}


	drawData(custom_dataset = null){


		let _options = {};
		
		let data_set = JSON.parse(JSON.stringify(this.order_dataset));

		if(custom_dataset){
			data_set = custom_dataset;
			this.fill_dataset = data_set;
		}

		

		if(this.type_chart == 'FunnelChart'){
			this.total_dataSet = 1;	
			this.porcentajes_grafica = data_set.map( item =>  {

				let a = (Math.abs(item[1].v) >= 1 && item[1].f != 1 ) ? Math.abs(item[1].v) : 0;
				let b = (Math.abs(item[2].v) >= 1) ? Math.abs(item[2].v) : 0;


				let p = Number(Math.abs(b) / Math.abs(a)) * 100

				return !isNaN(p) && isFinite(p) ? p.toFixed(0) : 0;
			}) 
		}else if(this.type_chart == 'TableChart' || this.type_chart == 'GroupTableChart'){
			this.total_dataSet = 1;	
		}else{
			this.total_dataSet = data_set.reduce((a,b)=>{ return ['total', a[1] + b[1]]}, ['inicial',0])[1];
			this.porcentajes_grafica = data_set.map( item => (item <= 0) ? 0 : Number(item[1] * 100 / this.total_dataSet).toFixed(1)) 
		}
		
		
		this.fillOff = this.filter ? this.filter.filter(item => { 
			if(item != null) 
				return item.selected == true

			return false;
		}).length <= 0 : false;


		let _self = this;
		let colors_dataSet = data_set.map( (o, i) =>  _self.colors_dataSet[i] );

		if(this.total_dataSet > 0){
			_options = Object.assign({}, this.options, {
				'colors': colors_dataSet 
			});

			if (this.type_chart == 'BarChart' || this.type_chart == 'ColumnChart'){
				data_set.map( ( item, i) => {
					return item[2] = this.colors_dataSet[i];
				});
			}
		}else{
			_options = Object.assign({}, this.options, {
				pieSliceText: 'label',
				pieSliceTextStyle: {color: '#999'},
				tooltip:{trigger:'none'}
			});

			if (this.type_chart == 'PieChart' ){
				data_set = [['No hay datos', 1]];	
			}
			if (this.type_chart == 'BarChart' || this.type_chart == 'FunnelChart' || this.type_chart == 'ColumnChart'){
				data_set = [['No hay datos', 1, this.color_default[0]]];
			}

		}

		

		if(this.type_chart == 'FunnelChart'){
			this.tabla_grafica = data_set.map( (data, index) => {

				data[1].f = (data[1].f >= 1 && data[1].f != 1) ? data[1].f : 0;
				data[2].f = (data[2].f >= 1) ? data[2].f : 0;
				data[4].f = (data[4].f >= 1) ? data[4].f : 0;
				data[6].f = (data[6].f >= 1) ? data[6].f : 0;

				return {
					color:this.color_convention[index],
					item:data[0],
					value: [
						{value:data[1].f, download:false},
						{value:data[2].f, download:true, id:1},
						{value:data[4].f, download:true, id:2},
						{value:data[6].f, download:true, id:3}
					],
					percentage: this.porcentajes_grafica[index],
					selected: this.filter[index] != null ? this.filter[index].selected : true,
				}
			});
		}else if(this.type_chart == 'TableChart'){
			this.tabla_grafica = data_set.map( (data, index) => {
				return {
					color:colors_dataSet[index],
					row: Object.keys(data).map( key => data[key]),
				}
			});		

		}else if(this.type_chart == 'GroupTableChart'){
			this.tabla_grafica = data_set.map( (data, index) => {
				return {
					color:colors_dataSet[index],
					row: Object.keys(data).map( key => data[key]),
				}
			});		
		}else{
			this.tabla_grafica = data_set.map( (data, index) => {


				let desc = (data[0].search(/[\(\:\*]/) > 0) ? data[0].slice(0, data[0].search(/[\(\:\*]/)): data[0];

				let ext_data = (data[0].search(/[\*]/) > 0 ) ? data[0].split('*')[data[0].split('*').length - 1] : null;

				return {
					color:colors_dataSet[index],
					label: data[0],
					ext_data: ext_data,
					item:desc,
					value:data[1],
					percentage: this.porcentajes_grafica[index],
					selected: this.filter[index] != null ? this.filter[index].selected : true,
				}
			});	

		}

		
		if(this.type_chart != 'TableChart' && this.type_chart != 'GroupTableChart'){
			this.grafica =  {
				chartType: this._type_chart,
				dataTable: this.header.concat(data_set),
				options: _options,
			};

		}

		if (!this.fillOff)
			this.tabla_grafica = this.tabla_grafica.filter( item => item.selected)

		if(!custom_dataset)
			setTimeout(_ => {this.exportData()}, 2000)
		//;
	}

	download(filename, graph, custom_dataset = null) {

		let data_set = JSON.parse(JSON.stringify(this.order_dataset));

		console.log(data_set, custom_dataset);
		if(custom_dataset && this.with_filters)
			data_set = custom_dataset; 


		console.log(data_set)


		this.hideGraph = true;
		this.zoomx2 = true;

		let options;


		let _self = this;
		let colors_dataSet = data_set.map( (o, i) =>  _self.colors_dataSet[i] )

		if(this.type_chart == 'PieChart'){
			options = Object.assign({}, this.options, {
				'colors': colors_dataSet , 
				'tooltip': {trigger:'none'},
				'width': 800, 'height': 800,
				'fontSize':24,
				'pieSliceText': 'percentage'
			})
		}

		if(this.type_chart == 'ColumnChart'){
			options = Object.assign({}, this.options, {
				'colors': colors_dataSet , 
				'tooltip': {trigger:'none'},
				'width': 1000, 'height': 800,
				'fontSize':24,
				'pieSliceText': 'percentage'
			})
		}

		if(this.type_chart == 'BarChart'){
			options = Object.assign({}, this.options, {
				'width': 2060, 'height': 400,
			})
		}

		if(this.type_chart == 'FunnelChart'){
			options = Object.assign({}, this.options, {
				colors: this.colors_dataSet,
				title: this.title, 
				height: 600,
				width: 1200,
				chartArea: {width: '40%'},
				isStacked: true, 
				hAxis: {   format: ';', gridlines:{count: 3}, viewWindow:'pretty'},
				yAxis: {},
				bar: { groupWidth: '100%' }
			})
		}


		if(this.total_dataSet > 0 && this.type_chart != 'TableChart' && this.type_chart != 'GroupTableChart'){
			this.grafica = {
				chartType: this._type_chart,
				dataTable: this.header.concat(data_set),
				options: options
			};	
		}
		

		setTimeout( _ => {

			let pom = document.createElement('a');
			let end_canvas = document.createElement('canvas');
			let ctx = end_canvas.getContext('2d')


			if(this.type_chart == 'TableChart' || this.type_chart == 'GroupTableChart'){

				html2canvas(this.table.nativeElement, {
					background: '#fff',
					onrendered: canvas => {
						download_image(canvas.toDataURL());
					}
				});


			}else{
				let chart = this.chart.wrapper.getChart();

				let image = new Image();
				image.src = chart.getImageURI();

				image.onload = event => {
					html2canvas(this.table.nativeElement, {
						background: '#fff',
						onrendered: canvas => {
							end_canvas.width = image.width;
							end_canvas.height = canvas.height + (image.height + 20);

							let table_images = new Image();
							table_images.onload = event => {
								ctx.fillStyle = "#fff";
								ctx.fillRect(0,0,end_canvas.width,end_canvas.height);
								ctx.drawImage(image, 0, 0, image.width, image.height)
								ctx.drawImage(table_images, ((end_canvas.width / 2) - (table_images.width / 2)), image.height + 10)	
								download_image(end_canvas.toDataURL());
							}
							table_images.src = canvas.toDataURL();
						}
					});
				}
			}
			
			function download_image(image){
				pom.setAttribute('href', image);
				pom.setAttribute('download', filename);
				pom.setAttribute('target', '_blank');


				if (document.createEvent) {
					let event = document.createEvent('MouseEvents');
					event.initEvent('click', true, true);
					pom.dispatchEvent(event);
				}
				else {
					pom.click();
				}

				//if(_self.type_chart != 'TableChart'){
					_self.drawData(data_set);	
				//}
				
				_self.hideGraph = false;
				_self.zoomx2 = false;
			}
			
		});
	}

	getKeys(obj){
		return Object.keys(obj);
	}

	centerNumber(value){
		return Number.isInteger(value);
	}


	fill_series(filter){
		let fill_dataset = [];

		for(let fill of Object.keys(this.fillter)){
			if(this.fillter[fill].selected === true){
				fill_dataset.push(this.order_dataset[fill])
			}
		}

		this.setOptions()
		this.drawData(fill_dataset);	
	}





}
