export class Edades {
	id:number;
	rango:string;
	min:number;
	max:number;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}

	static getData(){
		return [
		{id:1, rango:'18 A 24', min:18, max:24},
		{id:2, rango:'25 A 30', min:25, max:30},
		{id:3, rango:'31 A 36', min:31, max:36},
		{id:4, rango:'Mayores de 37', min:37, max: 200},
		].map( edad => new Edades(edad));
	}

	static getItem(id){
		return Edades.getData().filter( edad => edad.id == id)[0];
	}


}
