import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { Empresa } from './../../struct';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';

@Injectable()
export class EmpresaService {

  constructor(private http: Http) { }

  addEmpresa(empresa: Empresa) {
  	var data = new URLSearchParams();
    var session_id = localStorage.getItem('session_id');

    data.append('token', session_id);
    data.append('idcompany', String(empresa.idcompany));
    data.append('name_company', String(empresa.name_company));
    data.append('nit', empresa.nit);
    data.append('address', String(empresa.address));
    data.append('municipality_id', String(empresa.municipality_id));

    return this.http.post(AppSettings.API_ENDPOINT + 'insert_company', data)
    .map((response: Response) => response.json());
  }

  deleteEmpresaById(id:number) {
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('idcompany', id.toString())

    return this.http.post(AppSettings.API_ENDPOINT + 'delete_company', data)
    .map((response: Response) => response.json());
  }

  getAllEmpresas() {
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id);

    return this.http.post(AppSettings.API_ENDPOINT + 'view_all_company', data)
      .map((response: Response) => response.json());
  }

  getEmpresasById(id:number) {
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id);
    data.append('id_company', String(id));

   	return this.http.post(AppSettings.API_ENDPOINT + 'view_detail_company', data)
      .map((response: Response) => response.json());
  }

  getExcelEmpresas(){
    return this.http.get(AppSettings.URL_BACKEND + 'excelEmpresasParticipantes', {})
    .map((response: Response) => response.json());
  }
}
