import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'keyspipe'
})
export class KeyspipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return _.keys(value);
  }

}
