export class Empresa {
	idcompany: number;
	name_company: string;
	nit: string;
	address: string;
	municipality_id: number;

	constructor(values: Object = {}){
		Object.assign(this, values);
	}
}