import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../appSettings';

@Component({
  selector: 'app-subir-no-incluidos',
  templateUrl: './subir-beneficiarios.component.html',
  styleUrls: ['./subir-beneficiarios.component.css']
})
export class SubirNoIncluidosComponent implements OnInit {

  urlIframe: any;
  title_page: string;
  constructor() { }

  ngOnInit() {
  	var token = localStorage.getItem('session_id')
  	this.title_page = 'Subir KOKAN No Incluidos';
  	this.urlIframe = AppSettings.URL_BACKEND + 'upload_file_excel_noincluidos/';
  }

}