import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.css']
})
export class HistoryTableComponent implements OnInit {

 
  @Input() template_history;
  @Input() history;
  openTab = false;
  outside:any = [];
  title:any = [];


  constructor() { }

  ngOnInit() {
    if(this.history[0]){
      this.title = this.history[0].filter( o => o.title)[0];
      this.outside = this.history[0].filter( o => o.outside);
    }
  }

  toggleHistory(){
  	this.openTab = !this.openTab;
  }

}
