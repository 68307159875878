import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { Usuario } from './../../struct';
import { AppSettings } from './../../appSettings';
import 'rxjs/add/operator/map';

@Injectable()
export class UsuariosService {

 
  usuarios: Usuario[];

  constructor(private http: Http) { }

  getAllUsers(){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    return this.http.post(AppSettings.API_ENDPOINT + 'view_userxoperator', data)
      .map((response: Response) => response.json());
  }

  getNameUsers(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    return this.http.post(AppSettings.API_ENDPOINT + 'user_names', data)
      .map((response: Response) => response.json()); 
  }

  getUserById(id){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('id_userxope', id)

    return this.http.post(AppSettings.API_ENDPOINT + 'view_userxoperator_id', data)
      .map((response: Response) => response.json());
  }

  addUsuario(usuario: Usuario){
  	var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    for(var key in usuario){
      data.append(key, usuario[key])
    }

    return this.http.post(AppSettings.API_ENDPOINT + 'edit_userxoperator', data)
      .map((response: Response) => response.json());
  }



}
