import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UtilService, AnalitycsService } from '../../../services';
import { AppSettings } from './../../../appSettings';

@Component({
  selector: 'app-descargar-beneficiarios',
  templateUrl: './descargar-beneficiarios.component.html',
  styleUrls: ['./descargar-beneficiarios.component.css']
})
export class DescargarBeneficiariosComponent implements OnInit {


  model: any = {};
  link_action: string;
  error_msg:string = 'Campo requerido';
  error_desde:boolean;
  error_hasta:boolean;


  constructor(private utilService: UtilService,
              private analyticsService: AnalitycsService) {
   }

  ngOnInit() {
    this.link_action = AppSettings.URL_BACKEND + 'Kokan_online';

    document.getElementById('desde').addEventListener('change', evt => {
      if(evt.target['value'].length > 0){
        this.error_desde = false;
      }
    }, false);

    document.getElementById('hasta').addEventListener('change', evt => {
      if(evt.target['value'].length > 0){
        this.error_hasta = false;
      }
    }, false);

  
  }

  onSubmit(event){
    event.preventDefault();
    var desde = document.getElementById('desde');
    var hasta = document.getElementById('hasta');
    var form = <HTMLFormElement> document.getElementById('form_rangos');


    if(desde['value'].length > 0 && hasta['value'].length > 0){
      return this.utilService.validateDates(desde['value'], hasta['value'])
        .subscribe( data => {
          if(data.error){
            this.error_desde = true;
            this.error_hasta = true;
            this.error_msg = data.msg;
            return false;      
          }

          this.analyticsService.eventAction('Participantes', 'DescargarXmlParticipantes')
          form.submit();
          this.error_desde = false;
          this.error_hasta = false;
          this.error_msg = '';
          return true;
        });
        
    }else{
     this.error_desde = true;
     this.error_hasta = true;
     return false;
    }
  	
  }

  validateForm(g: FormGroup){
    return new Promise( resolve => {
       this.utilService.validateDates(g.get('desde'), g.get('hasta'))
        .subscribe( data => {
          resolve(data.error);
        });
    });
  }




}
