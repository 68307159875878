import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FilterWrappedService } from '../../shared/filter-wrapped.service' ;
import { OperadorDataService, UtilService, PerfilacionService } from '../../services';
import { DropsWrapperService,  } from '../../shared/drops-wrapper.service' ;
import { Operador, 
  Edades,
  EstadosParticipantes, 
  RangosSisben,
  CriteriosPriorizacion,
  TypeReport,
  RazonesNoElegibilidad 
} from './../../struct';


@Component({
  selector: 'beneficiario-filter-page',
  templateUrl: './filter-page.component.html',
  styleUrls: ['./filter-page.component.css']
})
export class FilterPageComponent implements OnInit , OnChanges{

  model: any = {};
  departaments = [];
  municipality = [];
  zones = [];
  genders = [];
  type_documents = [];
  stages = [];
  edades: Edades[];
  rangosSisben: RangosSisben[];
  operadores: any;
  open_popup = false;
  open_convension = false;
  type_user: any
  dropsOptions: any;
  grupos_perfilamiento: any;

  stage:any;
  status:any;

  @Input() showConvention:boolean = true;
  @Input() showFilter:boolean = true;
  @Input() showFilterText:boolean = true;
  @Input() redirectList:boolean = false;
  @Input() loadDrops:boolean = true;

  @Output() togglePopup = new EventEmitter();
  @Output() toggleConvension = new EventEmitter();


  constructor(private filterService: FilterWrappedService,
    private utilService: UtilService,
    private perfilacionService: PerfilacionService,
    private operadorService: OperadorDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dropService:DropsWrapperService) { }



  ngOnInit() {

    this.type_user = localStorage.getItem('user_type');

    if(!this.loadDrops === false)
      this.dropService.minValidateDrop().subscribe( d => this.dropService.addOptions(d));

    this.dropService.addOptions$.subscribe( options => this.loadDropsService(options));

    this.utilService.getAllStages()
    .subscribe( data => {
      this.stages = data.stage;

      this.stages = this.stages.map( state => {
        if(state.id == 5)
          state.name_stage = 'Certificado (formación)'
       

        if(state.id == 2)
          state.name_stage = 'Elegible (estado)'

        return state
      })



      this.stages = this.stages.concat([
        {id:'6', name_stage:'Pendiente'},
        {id:'37', name_stage:'Elegible en adelante'},
        {id:'7', name_stage:'No elegible'},
        {id:'8', name_stage:'KOKAN Incluidos'},
        {id:'9', name_stage:'KOKAN No Incluidos'},
        {id:'21', name_stage:'certificado (colocación)'},
        {id:'10', name_stage:'preinscripción'  },
        {id:'24', name_stage:'preinscripción (Incompleto)'  },
        {id:'11', name_stage:'inscripción'},
        {id:'25', name_stage:'inscripción (Incompleto)'},
        {id:'12', name_stage:'caracterización y perfilamiento'},
        {id:'13', name_stage:'orientación ocupacional'},
        {id:'27', name_stage:'orientación ocupacional (Incompleto)'},
        {id:'14', name_stage:'acompañamiento psicosocial'},
        {id:'28', name_stage:'acompañamiento psicosocial (Incompleto)'},
        {id:'15', name_stage:'estrategias de retención'},
        {id:'29', name_stage:'estrategias de retención (Incompleto)'},
        {id:'16', name_stage:'pruebas pre-formación'},
        {id:'30', name_stage:'pruebas pre-formación (Incompleto)'},
        {id:'38', name_stage:'En formación'},
        {id:'17', name_stage:'formación'},
        {id:'31', name_stage:'formación (Incompleto)'},
        {id:'18', name_stage:'pruebas post-formación'},
        {id:'32', name_stage:'pruebas post-formación (Incompleto)'},
        {id:'40', name_stage:'en intermediación'},
        {id:'19', name_stage:'intermediación'},
        {id:'33', name_stage:'intermediación (Incompleto)'},
        {id:'20', name_stage:'colocación laboral'},
        {id:'34', name_stage:'colocación laboral (Incompleto)'},
        {id:'22', name_stage:'permanencia 3 meses'},
        {id:'35', name_stage:'permanencia 3 meses (Incompleto)'},
        {id:'23', name_stage:'permanencia 6 meses'},
        {id:'36', name_stage:'permanencia 6 meses (Incompleto)'},
        {id:'39', name_stage:'Recolocado'},
        ]);

       this.route.params.subscribe(
        param => {
          console.log(param['stage']);
          if(param['stage'] == 2 && param['stage']){
            this.stages =  [ 
              {id:'20', name_stage:'colocación laboral'},
              {id:'34', name_stage:'colocación laboral (Incompleto)'}]          
          }

          if(param['stage'] == 3 && param['stage']){
            this.stages =  [ 
                {id:'22', name_stage:'permanencia 3 meses'},
                {id:'35', name_stage:'permanencia 3 meses (Incompleto)'}]
          }

          if(param['stage'] == 4 && param['stage']){
            this.stages =  [ 
              {id:'23', name_stage:'permanencia 6 meses'},
              {id:'36', name_stage:'permanencia 6 meses (Incompleto)'}]          
          }

          console.log(param);
          this.stage = param.stage;
          this.status = param.status;
      })
    })

   

    this.edades = this.utilService.getRangosEdades();
    this.rangosSisben = this.utilService.getRangosSisben();

    if(JSON.parse(localStorage.getItem('filters'))){
      this.model =  JSON.parse(localStorage.getItem('filters'));
    }
    
  }

  loadDropsService(options){
    this.dropsOptions = options
    this.departaments = options.department;
    this.municipality = options.municipality;
    this.zones = options.zone;
    this.genders = options.gender;
    this.type_documents = options.type_document;
    this.operadores = (options.operator) ?  options.operator.map( o => new Operador(o)) : null;
    this.grupos_perfilamiento = options.allgroup;
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onOpenFilters(){
    if(JSON.parse(localStorage.getItem('filters'))){
      this.model =  JSON.parse(localStorage.getItem('filters'));
    }
    this.open_popup = true;
  }

  onClose(){
    this.open_popup = false;
    this.togglePopup.emit({open_popup: false});
  }

  onFilter(){
    let filters = Object.assign({}, this.model)

   console.log(filters)

    this.model = {};
    if(parseInt(filters.estado) < 0){
      delete filters.estado
    }else{
      delete filters.atributo
    }

    

    this.filterService.addFilters(filters);
    this.open_popup = false;
    this.togglePopup.emit({open_popup: false});


    if(this.redirectList)
      this.router.navigate(['/participantes', { buscar: filters.buscar} ]);
  }

  onSubmitFilter(event){
    this.model.buscar = event.text_search;
    this.onFilter();
  }

  openConvension(){
    this.open_convension = !this.open_convension;
    this.toggleConvension.emit({open_convension:this.open_convension});
  }

  getAttribute(event){
    let estado = this.model.estado;
    if(estado < 0){
      this.model.atributo = -estado;
    }
  }

}
