import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';
import { AppSettings } from '../../../appSettings';

@Component({
	selector: 'app-preinscripcion',
	templateUrl: './preinscripcion.component.html',
	styleUrls: ['./preinscripcion.component.css']
})
export class PreinscripcionComponent implements OnInit {

	questions:any;
	type_user:any;
	complete:boolean;

	@ViewChild('form_inscription') form_inscription;
	@ViewChild('panel') panel;
	@Input() beneficiario:any;
	@Input() disabled:any;
	@Output() loadEnd = new EventEmitter();
	@Output() loadFile = new EventEmitter();
	@Output() fullForm = new EventEmitter();

	constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService) { }

	ngOnInit() {
		this.type_user = localStorage.getItem('user_type');
		this.questionsService.getQuestionsPreInscription().map( quest => {
			 for(let item in quest){
	          if(this.beneficiario[quest[item].key] != null){
	            quest[item].value = this.beneficiario[quest[item].key] + "";
	          }
	      	}
			this.questions = quest;
		})
	}

	onSubmit(form){
		this.loadEnd.emit({load:true})
		this.loadEnd.emit({load:true})
		this.loadEnd.emit({load:false})
    
	    this.beneficiarioService.updateUserData(this.beneficiario.user_xml_id, form.formData)
	    .subscribe( data => {
	        //this.fullForm.emit({isFill:(data.completa == 1), status:data.status})
	        alert(data.msg)
	        this.loadEnd.emit({load:false, dataUpdate: form.formData})
	    });
	}

}
