import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { QuestionBase } from './question-base';
import { DropdownQuestion } from './dropdown-question';
import { CheckboxGroupQuestion } from './checkboxgroup-question';
import { TextboxQuestion} from './textbox-question';
import { TextAreaQuestion} from './textarea-question';
import { CheckboxQuestion} from './checkbox-question';
import { ButtonQuestion} from './button-question';
import { AppSettings } from '../../appSettings';
import { FORM_INSCRIPCION } from '../../data/form_inscripcion';
import { FORM_PREINSCRIPCION } from '../../data/form_preinscripcion';
import { FORM_ORIENTACION } from '../../data/form_orientacion';
import { FORM_APOYO_PSICOSOCIAL } from '../../data/form_apoyo_psicosocial';
import { FORM_PREFORMACION } from '../../data/form_preformacion';
import { FORM_FORMACION } from '../../data/form_formacion';
import { FORM_INTERMEDIACION } from '../../data/form_intermediacion';
import { FORM_COLOCACION } from '../../data/form_colocacion';
import { FORM_PERMANENCIA } from '../../data/form_permanencia';
import { FILTER_NOVEDADES, FILTER_CAIDAS, FILTER_COLOCACION, FILTER_GRAPH } from '../../data/filters';
import { DropsWrapperService } from '../../shared/drops-wrapper.service' ;


@Injectable()
export class QuestionService {

	constructor(private http: Http,
			private dropService: DropsWrapperService) { }
    
    getQuestionsPreInscription(){
    	return this.getQuestions2(FORM_PREINSCRIPCION);
    }

    getQuestionsInscription(){
    	return this.getQuestions2(FORM_INSCRIPCION);
    }

    getQuestionsOrientacion(){
    	return this.getQuestions2(FORM_ORIENTACION);	
    }

     getQuestionsApoyoPsicosocial(){
    	return this.getQuestions2(FORM_APOYO_PSICOSOCIAL);	
    }

    getQuestionsPreformacion(){
    	return this.getQuestions2(FORM_PREFORMACION);	
    }

    getQuestionsFormacion(){
    	return this.getQuestions2(FORM_FORMACION);	
    }

    getQuestionsIntermendiacion(){
    	return this.getQuestions2(FORM_INTERMEDIACION);
    }

    getQuestionsColocacion(){
    	return this.getQuestions2(FORM_COLOCACION);
    }

    getQuestionsPermanencia(){
    	return this.getQuestions2(FORM_PERMANENCIA);
    }

    getQuestionsFilter(type){

    	switch (type) {
    		case 1:
    			return this.getQuestions2(FILTER_NOVEDADES);
    		
    		case 2:
    			return this.getQuestions2(FILTER_CAIDAS);

    		case 3:
    			return this.getQuestions2(FILTER_COLOCACION);

    		case 4:
    			return this.getQuestions2(FILTER_GRAPH);

    		default:
    			return null;
    	}
    }


    //OBSOLETA
	getQuestions(q_json){


		let data = Object.assign({}, q_json); 
		console.log(data)

		return this.dropService.addOptions$.map( options => {
			let questions: QuestionBase<any>[] = [];
			
			for(let item in data){

				if(data[item].controlType == 'label'){
					questions.push(new QuestionBase(data[item]));
				}

				if(data[item].controlType == 'textarea'){
					questions.push(new TextAreaQuestion(data[item]));
				}

				if(data[item].controlType == 'textbox'){
					questions.push(new TextboxQuestion(data[item]));
				}
				
				if(data[item].controlType == 'checkbox'){
					questions.push(new CheckboxQuestion(data[item]));
				}

				if(data[item].controlType == 'button'){
					questions.push(new ButtonQuestion(data[item]));
				}

				if(data[item].controlType == 'switchtoggle'){
					questions.push(new ButtonQuestion(data[item]));
				}


				

				if(data[item].controlType == 'dropdown' || data[item].controlType == 'groupcheckbox'){
					
					let itemOptions = options[data[item]['source']];

					if(data[item]['plane_options'])
						data[item]['options'] = data[item]['plane_options'];
					else
						data[item]['options'] = []
					
					if(data[item]['source'] == 'boolSource'){
						data[item]['options'].push(
							{key: 1, value: 'SI'},
							{key: 0, value: 'NO'})
					}

					if(data[item]['source'] == 'booleanSource'){
						data[item]['options'].push(
							{key: true, value: 'SI'},
							{key: false, value: 'NO'});
					}else{
						for(let option in itemOptions){
							data[item]['options'].push({
								key: itemOptions[option].id,
								value: this.getSameIndex(itemOptions[option], 'name')
							})
						}
					}


					questions.push(new DropdownQuestion(data[item]));

					if(data[item].controlType == 'groupcheckbox'){

						//let clone_title = Object.assign({}, data[item])
						//clone_title.key = clone_title.key + '_title'; 
						//clone_title.controlType = 'label';
						//questions.push(new QuestionBase(clone_title));


						data[item]['options'].map( opt => {
							
							let clone = Object.assign({}, data[item])
							clone.key = clone.key + '' + opt.key; 
							clone.controlType = 'checkbox';
							clone.label = opt.value;

							if(clone.action == 'enabledOnValue'){
								if(clone.valueToEnabled == opt.key){
									clone.action = 'enabledInput';
								}
							}


							for(let i in questions){
								if(questions[i]['outputInput']){
									if(questions[i]['outputInput'].indexOf(data[item].key) > -1){
										let arr_output = questions[i]['outputInput'].split(',')
										arr_output.push(clone.key);
										questions[i]['outputInput'] = arr_output.join(',')

									}	
								}

								if(questions[i]['inputInput']){
									if(questions[i]['inputInput'].indexOf(data[item].key) > -1){
										let arr_output = questions[i]['inputInput'].split(',')
										arr_output.push(clone.key);
										questions[i]['inputInput'] = arr_output.join(',')

									}	
								}
							}

							questions.push(new CheckboxQuestion(clone));
						})
						
					}else{
						
					}
					
				}


			}

			console.log(questions)

			return questions;
		})
	}

	getQuestions2(q_json){

		let data = Object.assign({}, q_json); 

		return this.dropService.optionsDrops.map( options => {
			let questions: QuestionBase<any>[] = [];
			
			for(let item in data){

				if(data[item].controlType == 'label'){
					questions.push(new QuestionBase(data[item]));
				}

				if(data[item].controlType == 'textarea'){
					questions.push(new TextAreaQuestion(data[item]));
				}

				if(data[item].controlType == 'textbox'){
					questions.push(new TextboxQuestion(data[item]));
				}
				
				if(data[item].controlType == 'checkbox'){
					questions.push(new CheckboxQuestion(data[item]));
				}

				if(data[item].controlType == 'button'){
					questions.push(new ButtonQuestion(data[item]));
				}

				if(data[item].controlType == 'switchtoggle'){
					questions.push(new ButtonQuestion(data[item]));
				}

				

				if(data[item].controlType == 'dropdown' || data[item].controlType == 'groupcheckbox'){
					
					let itemOptions = options[data[item]['source']];

					if(data[item]['plane_options'])
						data[item]['options'] = data[item]['plane_options'];
					else
						data[item]['options'] = []
					
					if(data[item]['source'] == 'boolSource'){
						data[item]['options'].push(
							{key: 1, value: 'SI'},
							{key: 0, value: 'NO'})
					}

					if(data[item]['source'] == 'booleanSource'){
						data[item]['options'].push(
							{key: true, value: 'SI'},
							{key: false, value: 'NO'});
					}else{
						for(let option in itemOptions){
							let name = (data[item]['neddle_name']) ? data[item]['neddle_name'] : 'name'
							data[item]['options'].push({
								key: itemOptions[option].id,
								value: this.getSameIndex(itemOptions[option], name)
							})
						}
					}


					questions.push(new DropdownQuestion(data[item]));

					if(data[item].controlType == 'groupcheckbox'){

						let clone_title = Object.assign({}, data[item])
						clone_title.key = clone_title.key + '_title'; 
						clone_title.controlType = 'label';
						questions.push(new QuestionBase(clone_title));


						data[item]['options'].map( opt => {
							
							let clone = Object.assign({}, data[item])
							clone.key = clone.key + '' + opt.key; 
							clone.controlType = 'checkbox';
							clone.label = opt.value;

							if(clone.action == 'enabledOnValue'){
								if(clone.valueToEnabled == opt.key){
									clone.action = 'enabledInput';
								}
							}


							for(let i in questions){
								if(questions[i]['outputInput']){
									if(questions[i]['outputInput'].indexOf(data[item].key) > -1){
										let arr_output = questions[i]['outputInput'].split(',')
										arr_output.push(clone.key);
										questions[i]['outputInput'] = arr_output.join(',')

									}	
								}

								if(questions[i]['inputInput']){
									if(questions[i]['inputInput'].indexOf(data[item].key) > -1){
										let arr_output = questions[i]['inputInput'].split(',')
										arr_output.push(clone.key);
										questions[i]['inputInput'] = arr_output.join(',')

									}	
								}
							}

							questions.push(new CheckboxQuestion(clone));
						})
						
					}
					
				}


			}

			return questions;
		})
	}

	getSameIndex(obj, neddle) {
		for(let o in obj){
			if(o.indexOf(neddle) > -1)
				return obj[o];
		}
	}

	fullValidateDrop(){
		var session_id = localStorage.getItem('session_id');
		var data = new URLSearchParams();
		data.append('token', session_id)
		
		return this.http.post(AppSettings.API_ENDPOINT + 'fullservices', data)
		.map((response: Response) => response.json());
	}


}
