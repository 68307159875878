import { Pipe, PipeTransform } from '@angular/core';
import { Operador, 
	Edades,
	EstadosParticipantes, 
	RangosSisben,
	CriteriosPriorizacion,
	TypeReport,
	RazonesNoElegibilidad 
} from './../struct';
import * as _ from 'underscore';

@Pipe({
	name: 'filterBeneficiarios'
})
export class FilterBeneficiariosPipe implements PipeTransform {



	transform(data: any[], filters: any): any {

		if(_.isEmpty(filters))
			return {filterData:data, counters:{}};
		
		let counters = {};

		var filterData;

		filterData = filters.buscar ? data.filter(item => this.filterText(item, filters.buscar)) : data;
		counters['buscar'] = filterData.length;
		
		filterData = filters.operador ? filterData.filter(item => item.operator_id == filters.operador): filterData;
		counters['operador'] = filterData.length;

		filterData = filters.estado ? filterData.filter(item => item.stage_id == filters.estado) : filterData;
		counters['estado'] = filterData.length;

		filterData = filters.atributo ? filterData.filter(item => this.filterByAttr(item, filters.atributo)) : filterData;
		counters['atributo'] = filterData.length;

		filterData = filters.numero_envio ? filterData.filter(item => item.cohorte == filters.numero_envio) : filterData;
		counters['numero_envio'] = filterData.length;

		filterData = filters.genero ? filterData.filter(item => item.gender_id == filters.genero) : filterData;
		counters['genero'] = filterData.length;
		
		filterData = filters.edad ? filterData.filter(item => this.getRangosEdad(item, filters.edad)): filterData;
		counters['edad'] = filterData.length;
		
		filterData = filters.sisben ? filterData.filter(item => this.getRangosSisben(item, filters.sisben)): filterData;
		counters['sisben'] = filterData.length;

		filterData = filters.faccion ? filterData.filter(item => item.score_sisben > 0) : filterData;
		counters['faccion'] = filterData.length;

		filterData = filters.jaccion ? filterData.filter(item => item.exist_jea == 1) : filterData;
		counters['jaccion'] = filterData.length;

		filterData = filters.sisben_chk ? filterData.filter(item => item.mfa_exist == 1) : filterData;
		counters['sisben_chk'] = filterData.length;

		filterData = filters.unidos ? filterData.filter(item => item.exist_united == 1) : filterData;
		counters['unidos'] = filterData.length;

		filterData = filters.victimas ? filterData.filter(item => item.exist_victim == 1) : filterData;
		counters['victimas'] = filterData.length;

		filterData = filters.imagen_documento ? filterData.filter(item => item.s3documento) : filterData;
		counters['imagen_documento'] = filterData.length;

		filterData = filters.imagen_diploma ? filterData.filter(item => item.s3diploma) : filterData;
		counters['imagen_documento'] = filterData.length;

		filterData = filters.acuerdo_pre ? filterData.filter(item => item.s3preinscription) : filterData;
		counters['acuerdo_pre'] = filterData.length;

		filterData = filters.acuerdo_insc ? filterData.filter(item => item.s3acuerdo) : filterData;
		counters['acuerdo_insc'] = filterData.length;

		filterData = filters.incluido ? filterData.filter(item => item.stage_id == 2) : filterData;
		counters['incluido'] = filterData.length;

		filterData = filters.no_incluido ? filterData.filter(item => item.no_elegible === 1) : filterData;
		counters['no_incluido'] = filterData.length;

		


		return {filterData:filterData, counters:counters};
	}

	getOperador(item, operador){
		return item.operator_id == operador;
	}

	getRangosEdad(item, rango_id){
		let rango = Edades.getItem(rango_id),
		edad = Math.floor(parseFloat(item.age))

		return edad >= rango.min && edad <= rango.max;
	}

	getRangosSisben(item, sisben_id){

		let sisben = RangosSisben.getItem(sisben_id),
		score = parseFloat(item.score_sisben);

		return score >= sisben.min && score <= sisben.max;
	}

	filterByAttr(item, attr){
		console.log(item, attr)
		switch (attr) {
			case 1:
				console.log(item.stage_id)
				return item.stage_id != 4
			case 2:
				return item['completa_inscripcion'] 
			case 3:
				return item['completa_perfilamiento']
			case 4:
				return item['completa_orientacion']
			case 5:
				return item['completa_preform']
			case 6:
				return item['completa_formacion']
			case 7:
				return item['completa_postform']
			case 8:
				return item['completa_psocial']
			case 10:
				return item['completa_intermediacion']
			case 13:
				return item.pendiente === 1
			case 14:
				return item.no_elegible === 1
			case 15:
				return item.componentreason == null
			case 16:
				return item.componentreason != null
			
			default:
				return true;
				
		}
	}

	filterText(obj: any, args: string): boolean{
		for(let key in obj){
			if(obj[key]){
				const search = obj[key].toString();
				var textInput = args.toLowerCase();
                var objatribute = search.toLowerCase();
				if(objatribute.indexOf(textInput) !== -1){
					return true;
				}		
			}
			
		}
		
		return false;
	}

	fitlterRange(obj: any, min_range: number, max_range: number, field: string){
		const search = obj[field];
		if(search >= min_range && search <= max_range){
			return true;
		}		
	}


	normalize(str){
		console.log("****")
		console.log(str)

		const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç", 
		to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
		mapping = {};

		for(let i = 0, j = from.length; i < j; i++ )
			mapping[ from.charAt( i ) ] = to.charAt( i );

		let ret = [];
		for( var i = 0, j = str.length; i < j; i++ ) {
			var c = str.charAt( i );
			if( mapping.hasOwnProperty( str.charAt( i ) ) )
				ret.push( mapping[ c ] );
			else
				ret.push( c );
		}      
		return ret.join( '' ).toLowerCase();
	}

}
