import { Component, OnInit, ViewChild } from '@angular/core';
import { EmpresaService, UtilService, AnalitycsService } from '../../../services';
import { Router, ActivatedRoute} from '@angular/router';
import { AppSettings } from './../../../appSettings';

@Component({
	selector: 'app-ver-empresa',
	templateUrl: './ver-empresa.component.html',
	styleUrls: ['./ver-empresa.component.css']
})
export class VerEmpresaComponent implements OnInit {

	@ViewChild('link_zip') link_zip;
	empresas: any = [];
	loadPage: boolean = true;
	type_user:any;
	url_form:any;
	token:string;

	constructor(private empresaService: EmpresaService,
		private utilService: UtilService,
		private router: Router, 
		private route: ActivatedRoute,
		private analyticsService: AnalitycsService ) { }

	ngOnInit() {
		this.token = localStorage.getItem('session_id');
		this.url_form = AppSettings.URL_BACKEND + 'excelEmpresasParticipantes.xlsx'
		this.type_user = localStorage.getItem('user_type');
		
		this.empresaService.getAllEmpresas().subscribe(
			data => {
				if(data.error) {
					console.log(data.msg);
					this.loadPage = false;
					return;
				}

				this.empresas = data.allcompany;
				this.loadPage = false;
			});
	}

	deleteEmpresa(event, empresa){
		event.stopPropagation();
		if(confirm('Desea eliminar el empresa ' + empresa['name_company'])){
			this.empresaService.deleteEmpresaById(empresa.id).subscribe(
				data => {

					if(data.error) {
						alert(data.msg);
						return;
					}
					for(let emp in this.empresas){
						console.log(data)
						if(empresa.id == this.empresas[emp].id){
							console.log(this.empresas);
							this.empresas.splice(emp, 1);
						}
					}
					this.analyticsService.eventAction('Empresas', 'BorrarEmpresa');
					this.loadPage = false;
				});
		}
	}

	downloadExcel(){
		this.loadPage = true;
		setTimeout( _ => {
			let form = <HTMLFormElement> document.getElementById('form-reporte');
			form.submit();
			this.loadPage = false;  
		}, 2000);
	}

}
