import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/';
import { AppSettings } from './../appSettings';
import { Edades, 
  EstadosParticipantes, 
  RangosSisben, 
  CriteriosPriorizacion, 
  TypeReport, 
  RazonesNoElegibilidad,
  Poblacion
} from './../struct';
import 'rxjs/add/operator/map';
import { mergeMap } from 'rxjs/operators';
import { forkJoin } from "rxjs/observable/forkJoin";

@Injectable()
export class UtilService {

  constructor(private http:Http) { }

  getAllDepartaments(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'department', data)
    .map((response: Response) => response.json());
  }

  getAllMunicipally(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'municipality', data)
    .map((response: Response) => response.json());
  }

  getAllZone(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'zone', data)
    .map((response: Response) => response.json());
  }

  getAllGender(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'gender', data)
    .map((response: Response) => response.json());
  }

  getAllTypeDocument(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'type_document', data)
    .map((response: Response) => response.json());
  }

  getAllMilestones(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'milestones', data)
    .map((response: Response) => response.json());
  }

  getHistoryDownloadPila(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'history_download_by_date', data)
    .map((response: Response) => response.json());
  }

  getHistoryPermanencia(type_permanencia:any){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('permanencia', type_permanencia)

    return this.http.post(AppSettings.URL_BACKEND + 'index/view_history_permanencia', data)
    .map((response: Response) => response.json());
  }

  validateDates(date_init, data_end){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('desde', date_init)
    data.append('hasta', data_end)

    return this.http.post(AppSettings.API_ENDPOINT + 'validatedate', data)
    .map((response: Response) => response.json());
  }

  getAllStages(){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)

    return this.http.post(AppSettings.API_ENDPOINT + 'view_all_stage', data)
    .map((response: Response) => response.json());
  }

  getTiempoRuta(operador = null, ciudad = null, ampliacion = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('operator_id', operador)
    data.append('city_id', ciudad)

    if(ampliacion){

      if(ampliacion.bis1a == true && ampliacion.bis1b == true){

      }else{
        if(ampliacion.bis1a == true){
          data.append('bis1a', ampliacion.bis1a)  
        }

        if(ampliacion.bis1b == true){
          data.append('bis1b', ampliacion.bis1b)  
        }  
      }

      
    }

    console.log(data);
    
    return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_tiempo_ruta', data)
    .map((response: Response) => response.json());
    
  }

  getIntermediacionReport(operador = null, ciudad = null,  ampliacion = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('operator_id', operador)
    data.append('city_id', ciudad)

    if(ampliacion){

      if(ampliacion.bis1a == true){
        data.append('bis1a', ampliacion.bis1a)  
      }

      if(ampliacion.bis1b == true){
        data.append('bis1b', ampliacion.bis1b)  
      }
    }
    

    return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_intermediacion', data)
    .map((response: Response) => response.json());
    
  }

  getColocacionReport(operador = null, ciudad = null, tipo_contrato = null,  ampliacion = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('operator_id', operador)
    data.append('city_id', ciudad)
    data.append('type_contratro', tipo_contrato)

    
    if(ampliacion){
      if(ampliacion.bis1a == true){
        data.append('bis1a', ampliacion.bis1a)  
      }

      if(ampliacion.bis1b == true){
        data.append('bis1b', ampliacion.bis1b)  
      }
    }


    return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_colocacion', data)
    .map((response: Response) => response.json());
    
  }

   getMotivosReport(etapa = null, novedad = null, operador = null, ciudad = null, ampliacion = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)
    data.append('type', novedad)
    data.append('type_novedad', etapa)
    data.append('operator_id', operador)
    data.append('city_id', ciudad)

    if(ampliacion){
      console.log(ampliacion)
      if(!(ampliacion.bis1a == true && ampliacion.bis1b == true)){
        if(ampliacion.bis1a){
          data.append('bis1a', ampliacion.bis1a)        
        }

        if(ampliacion.bis1b){
          data.append('bis1b', ampliacion.bis1b)        
        }    
      }
    }

    return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_motivos', data)
    .map((response: Response) => response.json());
    
  }

  getNovedadesPermanencia(mes = null, tipo = null, operador = null, ciudad = null, ampliacion = null){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();

    data.append('token', session_id)
    data.append('mes', mes)
    data.append('type', tipo)
    data.append('operator_id', operador)
    data.append('city_id', ciudad)

    if(ampliacion){
      if(!(ampliacion.bis1a == true && ampliacion.bis1b == true)){
        if(ampliacion.bis1a){
          data.append('bis1a', ampliacion.bis1a)        
        }

        if(ampliacion.bis1b){
          data.append('bis1b', ampliacion.bis1b)        
        }
      }
      
    }

    return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_niveles', data)
    
  }

  getMergeNovedadesPermanencia(mes = null, operador = null, ciudad = null){
    return forkJoin([this.getNovedadesPermanencia(mes, 1, operador, ciudad),
          this.getNovedadesPermanencia(mes, 2, operador, ciudad),
          this.getNovedadesPermanencia(mes, 3, operador, ciudad)])
        .map(value => value.map( (response:Response) => response.json() ));
  }


  getDemograficReport(operador = null, ciudad = null, etapa = null, bis1a = null, bis1b = null){
      var session_id = localStorage.getItem('session_id');
      var data = new URLSearchParams();
      data.append('token', session_id)
      data.append('stage_id', etapa)
      
      if(operador != null)
        data.append('operator_id', operador)

      if(ciudad != null)
        data.append('city_id', ciudad)

      if(bis1a)
        data.append('bis1a', bis1a)        

      if(bis1b)
        data.append('bis1b', bis1b)        
      

      //let data = { 'token': session_id, 'stage_id': etapa}

      // if(operador != null)
      //     data['operator_id'] =  operador;

      // if(ciudad != null)
      //   data['city_id']  =  ciudad;

      return this.http.post(AppSettings.URL_BACKEND + 'index/dashboard_demografico', data)
      .map((response: Response) => response.json());
  }

  

  getRangosEdades(){
    return Edades.getData();
  }

  getRangosSisben(){
    return RangosSisben.getData();
  }

  getEstadosParticipantes(){
    return EstadosParticipantes.getData();
  }

  getCriteriosPriorizacion(){
    return CriteriosPriorizacion.getData();
  }

  getRazonesNoElegibilidad(type: TypeReport){
    return RazonesNoElegibilidad.getData(type);
  }

  getPoblacion(){
    return Poblacion.getDataPoblacion();
  }

  getPriorizacion(){
    return null;
  }




}