import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { QuestionService } from '../question.service';
import { BeneficiarioDataService } from '../../../services';

@Component({
  selector: 'app-apoyo-psicosocial',
  templateUrl: './apoyo-psicosocial.component.html',
  styleUrls: ['./apoyo-psicosocial.component.css'],
  providers: [QuestionService]
})
export class ApoyoPsicosocialComponent implements OnInit {

  questions:any;
  type_user:any;
  complete:boolean;
  @ViewChild('apoyo_psicosocial') apoyo_psicosocial;
  @ViewChild('panel') panel;
  @Output() loadEnd = new EventEmitter();
  @Output() fullForm = new EventEmitter();
  @Input() beneficiario:any;
  @Input() enabled_module:any;
  @Input() disabled:any;

  constructor(private questionsService:QuestionService,
    private beneficiarioService: BeneficiarioDataService) { 
  }

  ngOnInit() {

    this.type_user = localStorage.getItem('user_type');
    console.log('asdasdads')

    this.questionsService.getQuestionsApoyoPsicosocial().map( quest => {
      this.beneficiarioService.getPsicosocial(this.beneficiario.user_xml_id)
      .subscribe( data  => {
        if(!data.error){

          if(data.acompanamiento == 1){
            quest[0].value = data.acompanamiento
            quest[1].value = data.psocial_close_reason;
            

            quest[0].disabled = false
            quest[1].disabled = false

            for(let q in quest){
              if(Number(q) > 1)
                quest[q].disabled = true;
            }  

          }else{

            quest[2].value = String(data.continue_accomp);

              if(data.continue_accomp == true){
                quest[3].disabled = false;
                quest[6].disabled = false;
                quest[9].disabled = false;

                console.log(data.tipoacompa[0])
                if(data.tipoacompa[0]){
                  console.log('oliiii')
                  quest[3].value = data.tipoacompa[0].type_accompaniment_id;
                  quest[4].value = data.tipoacompa[0].other;

                  if(quest[4].value != 11)
                    quest[4].disabled = true
                }

                if(data.tipoacompa[1]){
                  quest[6].value = data.tipoacompa[1].type_accompaniment_id;
                  quest[7].value = data.tipoacompa[1].other;

                  if(quest[6].value != 11)
                    quest[7].disabled = true
                }

                if(data.tipoacompa[2]){
                  quest[9].value = data.tipoacompa[2].type_accompaniment_id;
                  quest[10].value = data.tipoacompa[2].other;

                  if(quest[9].value != 11)
                    quest[10].disabled = true
                }

                quest[5].value = data.atencionpsico[0] ? data.atencionpsico[0].psocial_care_id : '';
                quest[8].value = data.atencionpsico[1] ? data.atencionpsico[1].psocial_care_id : '';
                quest[11].value = data.atencionpsico[2] ? data.atencionpsico[2].psocial_care_id: '';

                quest[5].disabled = data.atencionpsico[0] ? false : true;
                quest[8].disabled = data.atencionpsico[1] ? false : true;
                quest[11].disabled = data.atencionpsico[2] ? false: true;

                if(data.remision[0]){
                  quest[12].value = data.remision[0].id_remision;
                  quest[13].value = data.remision[0].other;  


                  if(quest[12].value == 5)
                    quest[13].disabled = false
                }

              }else{
                quest[4].disabled = true
                quest[7].disabled = true
                quest[10].disabled = true

               if(data.cierrepsico[0]){
                  quest[14].value = data.cierrepsico[0].psocial_close_id;  
                  quest[14].disabled = false
                }
              }
            }
          }

          for(let item in quest){
            if(quest[item].value)
              quest[item].disabled = !quest[item].editable;
          }
          
          setTimeout(_ => {
            if(this.panel)    
              window.scrollTo(0, this.panel.nativeElement.offsetTop - 120)
          });
          
          this.questions =  quest;
        })
    });
  }

  onSubmit(form){
    this.loadEnd.emit({load:true})

    Object.keys(form.formData).forEach(key => {
      if(form.formData[key] === true)
        form.formData[key] = 1;
    });

    this.beneficiarioService.updateForm(this.beneficiario.user_xml_id, form.formData, 3)
    .subscribe( data => {
      if(!data.error){


        if(data.completa == 0){
          this.apoyo_psicosocial.highWarrningInputsOn();
          alert(data.msg + '\nPero hay información pendiente para completar la etapa')
        }else{
          this.apoyo_psicosocial.highWarrningInputsOff();
          alert(data.msg)
        }

        this.complete = data.completa;
        this.fullForm.emit({isFill:(data.completa == 1)})
      }
      else
        alert("Hubo un error al enviar la información")

      
      this.loadEnd.emit({load:false})
    })
    
  }

  fillForm(event){
    this.fullForm.emit({isFill:event.isFill})
  }

}
