import { Component, OnInit } from '@angular/core';
import { Operador, Hito } from '../../../struct';
import { OperadorDataService, AnalitycsService } from '../../../services';
import { Router} from '@angular/router';
import { UtilService } from '../../../services/util.service' ;

@Component({
  selector: 'app-crear-operador',
  templateUrl: './crear-operador.component.html',
  styleUrls: ['./crear-operador.component.css']
})
export class CrearOperadorComponent implements OnInit {

  model:any = {}
  title_view:string;
  hitos: Array<Hito> = new Array<Hito>();
  loadPage: boolean = false;
  milestones: any;
  send_photo: boolean = false;
  id_operador: any;
  type_user:any;

  constructor(private operadorService: OperadorDataService,
  					private router: Router,
            private utilService: UtilService, 
            private analyticsService: AnalitycsService) { }

  ngOnInit() {
    this.title_view = 'Nuevo Operador';
    this.type_user = localStorage.getItem('user_type');
    this.utilService.getAllMilestones().subscribe(
      data => {
        this.milestones = data.milestones;
      }
    )
  }

  onSubmitOperador(){
  	var operador = new Operador(this.model);
  	operador.hitos = this.hitos;

    console.log(operador.hitos)

    this.loadPage = true;
  	this.operadorService.addOperador(operador)
  		.subscribe( data => {
  			if(data.error){
  				console.log(data.msg);
  				return;
  			}

        this.id_operador = data.id_operator;
        this.send_photo = true;
  			
        this.loadPage = false;
        this.analyticsService.eventAction('Operador', 'CrearOperador');
  		})
  }

  onAddHito(event){
  	event.preventDefault();
  	this.hitos.push(new Hito());
  }

  onQuitAddHito(event, index){
    event.preventDefault();
    if (index > -1) {
        this.hitos.splice(index, 1);
    }
  }

  submitFile(url_source, name_input){
    this.operadorService.addFile(url_source.url, name_input, this.id_operador)
      .subscribe( data => {
       this.router.navigate(['/operadores'])
     });
  }


}
