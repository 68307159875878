import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';

import { AppSettings } from './../../appSettings';

import 'rxjs/add/operator/map';

@Injectable()
export class AuthenticationService {

  constructor(private http: Http) { }

  login(username: string, password: string, type_bis: string){
    var data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('type_bis', type_bis);

  	return this.http.post(AppSettings.API_ENDPOINT + 'login', data)
  		.map((response: Response) => {
        console.log(response)
  			let body = response.json();
  			if(!body.error){
          localStorage.setItem('bono', type_bis);
  				localStorage.setItem('session_id', body.token);
          localStorage.setItem('username', body.username);
          localStorage.setItem('user_type', body.user_type);
          localStorage.setItem('user_id', body.user_id);
          localStorage.setItem('profile', body.profile);
          localStorage.setItem('time_init_sesion', new Date().getTime().toString());

  			}
        return body;
  		});
  }

  logout(){
  	localStorage.removeItem('session_id');
    localStorage.removeItem('username');
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_id');
    localStorage.removeItem('profile');
    localStorage.removeItem('filters');
    localStorage.removeItem('time_init_sesion');
    console.log('se cerro la sesion');
  }

  verify_session(token_id){
    var session_id = localStorage.getItem('session_id');
    var data = new URLSearchParams();
    data.append('token', session_id)


    return this.http.post(AppSettings.API_ENDPOINT + 'municipality', data)
      .map((response: Response) => response.json());
  }

}
