import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AnalitycsService } from './services/analitycs.service';


declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app works!';

  constructor(private router: Router, private analitycsService: AnalitycsService) {
     this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        let time = 0;
        if(localStorage.getItem("time_page")){
          time = (new Date().getTime() - parseInt(localStorage.getItem("time_page")))/1000/60;
        }
        
        localStorage.setItem('time_page', new Date().getTime().toString());

        this.analitycsService.pageView(event.urlAfterRedirects);
        this.analitycsService.eventLink(event.urlAfterRedirects, localStorage.getItem('username'), time)
      }
    });
  }
}
