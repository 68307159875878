import { Component, Input, OnInit, OnChanges,forwardRef, SimpleChanges } from '@angular/core';
import { 
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor, 
	Validator, 
	AbstractControl, 
	FormControl 
} from '@angular/forms';

const noop = () => {
};

@Component({
	selector: 'app-radio-list',
	templateUrl: './radio-list.component.html',
	styleUrls: ['./radio-list.component.css'],
	providers: [
	{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => RadioListComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => RadioListComponent),
		multi: true,
	}
	]
})
export class RadioListComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

	@Input() listData:any = [];
	@Input() name:string;
	@Input() current_criterio:number;
	innerValue:any = '';

	private onChangeCallback: (_: any) => void = noop;
	private onTouchedCallback: () => void = noop;
	

	constructor() { 
	}

	ngOnInit() {
		if(this.current_criterio){
			this.value(this.current_criterio)
		}
	}

	ngOnChanges(changes:any){
		if(changes.current_criterio){
			this.value = changes.current_criterio.currentValue;
		}
		
	}

	get value(){
		return this.innerValue;
	}

	set value(v:any){
		if(v !== this.innerValue){
			this.innerValue = v;
			this.onChangeCallback(v);
		}
	}

	onBlur() {
		this.onTouchedCallback();
	}

	onChange(event){
	}

	public writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
		}
	}

	public registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: FormControl){
		return (this.current_criterio) ? null : {
			inputError: {
				valid: false,
			},
		};
	}

}
