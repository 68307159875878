import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../services';
import { PerfilacionService, BeneficiarioDataService } from '../../../services';
import { GrupoPerfilacion } from '../../../struct';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-detalle-grupo',
  templateUrl: './detalle-grupo.component.html',
  styleUrls: ['./detalle-grupo.component.css']
})
export class DetalleGrupoComponent implements OnInit {

  model:any = {};
  type_documents = [];
  id_group:any;
  group_perfilacion:any = {};
  beneficiarios:any = [];
  type_user:any;

  constructor(private utilService: UtilService,
              private perfilacionService:PerfilacionService,
              private beneficiarioService:BeneficiarioDataService,
              private router: Router, 
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.id_group = this.route.params['value'].id;
    this.type_user = localStorage.getItem('user_type');
    
  	this.utilService.getAllTypeDocument().subscribe(
      data => {
        this.type_documents = data.type_document;
      }
    )

    this.perfilacionService.getGroupById(this.id_group)
      .subscribe( data => {
        this.group_perfilacion = data.detail;
        this.beneficiarios = data.beneficiaries;
      });
  }

  deleteBeneficiaryByGroup(event, beneficiario){
    event.stopPropagation();
    if(confirm('Desea eliminar el beneficario ' 
        + beneficiario.first_name +' '+ beneficiario.surname
          + ' del Grupo ' + this.group_perfilacion.name_group)){
      this.perfilacionService.deteleBeneficiaryByGroup(this.id_group, beneficiario.id).subscribe(
        data => {
          if(!data.error){
            for(let op in this.beneficiarios){
              if(data.beneficiarygroup[0].user_xml_id == this.beneficiarios[op].id){
                this.beneficiarios.splice(op, 1);
              }
            }
          }else{
            alert(data.msg);
          }
        })
    }
  }

  onAssing(){

    this.perfilacionService.assingBeneficiariosToGroup(this.id_group, '', '', null, this.model.documento, this.model.tipo_documento)
      .subscribe( data => {
        if(!data.error){
          if(!data.is_asgin){
            let id_beneficiario = parseInt(data.array_id_user_xml.split(',')[0]);
            this.beneficiarioService.getBeneficiarioById(id_beneficiario)
              .subscribe( data => {
                console.log(data);
                this.beneficiarios.push(data.beneficiary[0]);    
              });
            
          }else{
            alert('El beneficiario ya se encuentra asignado a este grupo');
          }
        }else{
          alert(data.msg);
        }
        
      });

  }

}
