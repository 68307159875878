import { Component, OnInit, OnChanges, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import {AccordionComponent} from './../accordion/accordion.component';
import { AnalitycsService } from './../../services/analitycs.service';

@Component({
  selector: 'app-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.css']
})
export class AccordionGroupComponent implements OnInit, OnChanges {

 private _isOpen:boolean = false;

  @Input() heading:string;
  @Input() attribute:string;
  @Input() opened: boolean = false;
  @Input() classColor:string;
  @Input() activate:boolean = false;
  @Output() onOpen = new EventEmitter();
  @ViewChild('ref') contentPanel: ElementRef;
  @ViewChild('panel_header') panelHeader: ElementRef;

  @Input()
  set isOpen(value:boolean){
  	this._isOpen = value;
    this.onOpen.emit({open:this._isOpen});

  	if(value)
  		this.accordion.colseOthers(this);
  }

  get isOpen(){
  	return this._isOpen;
  }

  constructor(private accordion:AccordionComponent, private analitycService:AnalitycsService) { 
      this.accordion.addGroup(this);
  }

  ngOnInit() {
    if(this.opened)
        this.isOpen = true;
  }

  ngOnChanges(changes){
    if(changes.activate){
      this.activate = changes.activate.currentValue;
    }

     if(changes.opened){
        this.isOpen = changes.opened.currentValue;
    }
  }

  toggleOpen(){
    //if(this.contentPanel.nativeElement['children'].length > 0){
      setTimeout(_ => {
       // window.scrollTo(0, this.panelHeader.nativeElement.offsetTop - 60);
      }, 500);

  	  this.isOpen = !this.isOpen;
    //}
  }

  getColorClass(){
    if(this.activate)
       return this.classColor;
  }


}
